/**
 * @param {TLocation} [locationId]
 */
export const useLocationConfig = (locationId) => {
  return getLocationConfigByLocationId(locationId);
};

/**
 * @param {TLocation} [locationId]
 */
export const getLocationConfigByLocationId = (locationId) => ({
  inStore: locationId === "Hilton",
  isPOSEnabled: locationId === "Hilton",
});

/**
 * @returns {TLocation[]}
 */
export const useLocations = () => {
  return ["Queenstown", "Wanaka", "Hilton"];
};
