import { useRef, useState } from "react";

import {
  Box,
  Button,
  Editable,
  EditablePreview,
  EditableTextarea,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";

import { FiEdit } from "react-icons/fi/index.esm.js";

import { db } from "@/services/firebase.js";

import { collection, doc, serverTimestamp, writeBatch } from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useShowError } from "@/lib/error.js";
import { initDateOrDefault, initRefInputSelect, initRefTextArea } from "@/lib/initialize-states.js";
import { useLocations } from "@/lib/locationLib.js";
import { getSupportCallTypes } from "@/lib/supportCallLib.jsx";
import { saveActivity } from "@/services/activityLog.js";

import ChangeDateTimeModal from "./ChangeDateTimeModal.jsx";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {TOrder} props.order
 * @returns {JSX.Element}
 */
export default function NewOrderSupportCallModal({ isOpen, onClose, order }) {
  const { showError } = useShowError();
  const locationList = useLocations();
  const dateSelectModal = useDisclosure();
  const [selectedDate, setSelectedDate] = useState(initDateOrDefault(null));
  const supportComments = useRef(initRefTextArea());
  const supportAddress = useRef(initRefTextArea());
  const supportType = useRef(initRefInputSelect());
  const supportLocation = useRef(initRefInputSelect());

  const { currentUserDetails } = useLoggedInUser();

  async function createSupportCall() {
    const batch = writeBatch(db);
    try {
      if (!selectedDate) {
        throw new Error("Please select a date and time");
      }
      if (!supportType.current?.value) {
        throw new Error("Please select a type of support");
      }

      const supportCallDocto = doc(collection(db, "orders", order.id, "supportCalls"));
      batch.set(supportCallDocto, {
        id: supportCallDocto.id,
        addressDelivery: supportAddress.current?.value,
        type: supportType.current.value,
        comments: supportComments.current?.value,
        created: serverTimestamp(),
        driveTime: selectedDate,
        firstName: order.firstName,
        lastName: order.lastName,
        isActive: true,
        location: supportLocation.current?.value,
        orderId: order.id,
        skierCount: order.skierCount,
        state: "toSupport",
        createdByUser: {
          id: currentUserDetails.id,
          firstName: currentUserDetails.firstName,
          lastName: currentUserDetails.lastName,
        },
      });
      await saveActivity(
        "support",
        `${currentUserDetails.firstName} created new support call for order "${order.firstName} ${order.lastName}"`,
        order.id,
        order,
        undefined,
        undefined,
        batch,
      );

      await batch.commit();

      setSelectedDate(null);
      if (supportComments.current) supportComments.current.value = "";
      if (supportAddress.current) supportAddress.current.value = "";
      if (supportType.current) supportType.current.value = "";
      onClose();
    } catch (error) {
      showError(error);
    }
  }

  return (
    <>
      <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Support Call</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={1}>
              <Box w="full" mb={4}>
                <HStack>
                  <Text fontSize={10} fontWeight="bold">
                    When
                  </Text>
                  <Icon as={FiEdit} boxSize="10px" />
                </HStack>
                {selectedDate == null ? (
                  <Button colorScheme="brand" size="sm" my={2} onClick={dateSelectModal.onOpen}>
                    set date & time
                  </Button>
                ) : (
                  <Text as="button" onClick={dateSelectModal.onOpen}>
                    {dayjs.tz(selectedDate).format("dddd, MMM D, YYYY h:mm A")}
                  </Text>
                )}
              </Box>
              <Box w="full">
                <HStack>
                  <Text fontSize={10} fontWeight="bold">
                    Where
                  </Text>
                  <Icon as={FiEdit} boxSize="10px" />
                </HStack>
                <Editable
                  defaultValue={order.addressDelivery}
                  sx={{ whiteSpace: "pre-line" }}
                  mb={4}
                >
                  <EditablePreview />
                  <EditableTextarea ref={supportAddress} />
                </Editable>
              </Box>
              <Box width="full">
                <Text fontSize={10} fontWeight="bold" mb={1}>
                  Type of support needed
                </Text>
                <Select ref={supportType} mb={6}>
                  {getSupportCallTypes().map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box width="full">
                <Text fontSize={10} fontWeight="bold" mb={1}>
                  Team to respond
                </Text>
                <Select ref={supportLocation} mb={6} defaultValue={order.location}>
                  {locationList.map((locationId) => (
                    <option key={locationId} value={locationId}>
                      {locationId}
                    </option>
                  ))}
                </Select>
              </Box>

              <Box width="full">
                <Text fontSize={10} fontWeight="bold" mb={1}>
                  Details
                </Text>
                <Textarea rows={3} ref={supportComments} />
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onClose}>
              CANCEL
            </Button>
            <Spacer />
            <Button
              isDisabled={!selectedDate}
              colorScheme="brand"
              size="sm"
              onClick={createSupportCall}
            >
              CREATE SUPPORT CALL
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {dateSelectModal.isOpen && (
        <ChangeDateTimeModal
          isOpen={dateSelectModal.isOpen}
          onClose={dateSelectModal.onClose}
          dateCallback={(value) => {
            setSelectedDate(getFieldAsDate(value) || null);
          }}
          date={
            selectedDate
              ? selectedDate
              : dayjs.tz().hour(7).minute(0).second(0).millisecond(0).toDate()
          }
        />
      )}
    </>
  );
}
