import { useState } from "react";

import {
  Box,
  Button,
  Divider,
  HStack,
  Text,
  VStack,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spacer,
} from "@chakra-ui/react";

import { HiExclamation } from "react-icons/hi/index.esm.js";

import { asPrice } from "@snopro/common/numbers.js";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useOrderModalContext } from "@/contexts/OrderModalContext.jsx";
import { completeSystemAlert } from "@/hooks/systemAlerts.js";
import dayjs from "@/lib/datetime.js";
import { useShowError } from "@/lib/error.js";

/** @type {Record<TSystemAlertType,string>} */
const __systemAlertTypeToText = {
  "order-ski-pass-added-to-delivered-order": "Ski Pass added to delivered order",
  "order-deliver-ski-pass": "Activate Ski Pass for delivered order",
  "order-deliver-overpaid": "Delivered order is overpaid",
  "order-deliver-unpaid": "Delivered order is unpaid",
  "order-deliver-paid-by-cash": "Delivered order was paid by cash",
  "order-deliver-without-customer-signature": "Delivered order without customer signature",
  "order-collect-unpaid": "Collected order is unpaid",
  "order-inactive": "Order was inactivated",
};
/** @type {Record<TSystemAlertType,string>} */
const __systemAlertTypeToCompletionQuestionText = {
  "order-ski-pass-added-to-delivered-order": "Did you activate all the Ski Pass?",
  "order-deliver-ski-pass": "Did you activate all the Ski Pass?",
  "order-deliver-overpaid": "Did you refund the overpaid amount?",
  "order-deliver-unpaid": "Did you receive the remaining payment?",
  "order-deliver-paid-by-cash": "Did you receive the cash from the driver?",
  "order-deliver-without-customer-signature": "Do you want to complete this alert?",
  "order-collect-unpaid": "Did you receive the payment?",
  "order-inactive": "Do you want to complete this alert of inactivated order?",
};
/**
 * @param {object} props
 * @param {TSystemAlert} props.alert
 * @returns {JSX.Element|null}
 */
const ExtraMessage = ({ alert }) => {
  if (alert.type === "order-inactive" && (alert.order.paidAmount ?? 0) > 0) {
    return (
      <Text color="red.500" as="span">
        {" "}
        - paid: {asPrice(alert.order.paidAmount)}
      </Text>
    );
  }
  return null;
};
/**
 * @param {object} props
 * @param {TSystemAlert} props.alert
 * @returns {JSX.Element}
 */
export function SystemAlertCard({ alert }) {
  const confirmCompleteModalDisclosure = useDisclosure();
  const [isBusy, setIsBusy] = useState(false);
  const { setModalId } = useOrderModalContext();
  const { showError } = useShowError();
  const { currentUserDetails } = useLoggedInUser();

  return (
    <>
      <VStack spacing="0" align="start" width="100%">
        <HStack align="start" width="100%">
          <Box
            width={"100%"}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              setModalId(alert.order.id, Boolean(e.ctrlKey || e.shiftKey));
            }}
          >
            <Text>
              <Icon as={HiExclamation} boxSize={5} top="4px" position="relative" mr={1} />
              {__systemAlertTypeToText[alert.type]}
            </Text>
            <HStack>
              <Text fontSize="xs" fontWeight="bold" m="0" pb={0}>
                {[
                  alert.order.location,
                  `#${alert.order.lookupId}`,
                  alert.order.firstName,
                  alert.order.lastName,
                ].join(" ")}
                <ExtraMessage alert={alert} />
              </Text>
            </HStack>
            {alert.message && <Text fontSize="xs">{alert.message} </Text>}
            <Box fontSize="xs" color="gray.500" pb={2}>
              <Text as="span" fontWeight="bold">
                {alert.user?.firstName || alert.user?.lastName}
              </Text>
              , {dayjs.tz(alert.createdAt).format("ddd, MMM D, h:mma")}
            </Box>
          </Box>

          <Button
            size="xs"
            onClick={() => confirmCompleteModalDisclosure.onOpen()}
            colorScheme={"gray"}
            alignSelf={"center"}
          >
            DONE
          </Button>
        </HStack>

        <Divider />
      </VStack>
      <Modal
        isOpen={confirmCompleteModalDisclosure.isOpen}
        onClose={confirmCompleteModalDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Complete Sytem Alert</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={0}>
              <Divider />
              <Text pt={4}>
                #{alert.order.lookupId} {alert.order.firstName} {alert.order.lastName}
              </Text>
              <Text fontWeight="bold" p={4}>
                {__systemAlertTypeToCompletionQuestionText[alert.type]}
              </Text>
              <Divider />
              <Divider />
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button size="sm" onClick={confirmCompleteModalDisclosure.onClose}>
              <Text>CANCEL</Text>
            </Button>
            <Spacer />
            <Button
              isLoading={isBusy}
              onClick={async () => {
                setIsBusy(true);
                try {
                  await completeSystemAlert(alert.id, currentUserDetails).catch(showError);
                  confirmCompleteModalDisclosure.onClose();
                } finally {
                  setIsBusy(false);
                }
              }}
            >
              <Text fontWeight="bold">YES</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
