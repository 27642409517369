import { HStack, Text, VStack } from "@chakra-ui/react";

import useTransaction from "@/hooks/useTransaction.jsx";

/**
 *
 *
 * Renders a TransactionItem for an order.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {TOrderPaymentTransaction} props.transaction - The transaction object containing payment information.
 * @returns {JSX.Element}
 */
export default function TransactionItem({ transaction }) {
  const { date, method, displayName, cardName, comments, maskedCardNumber } =
    useTransaction(transaction);
  return (
    <VStack w={"100%"} alignItems={"flex-start"}>
      <Text fontSize={12}>{date}</Text>
      <HStack spacing={4}>
        <Text>{displayName}</Text>
        <HStack>
          {method === "credit-card" && (
            <HStack spacing={1}>
              {cardName && <Text isTruncated>{cardName}</Text>}
              {maskedCardNumber && (
                <Text color={"gray.500"} fontStyle={"italic"}>
                  {maskedCardNumber}
                </Text>
              )}
            </HStack>
          )}
          {comments && <Text fontStyle={"italic"}>{comments}</Text>}
        </HStack>
      </HStack>
      {method === "pos" && <Text>Make sure to refund the payment in the POS system</Text>}
    </VStack>
  );
}
