import React, { useState, useEffect } from "react";

import {
  Button,
  Heading,
  Text,
  HStack,
  Box,
  VStack,
  Divider,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Switch,
} from "@chakra-ui/react";

import { deleteField } from "firebase/firestore";

import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";

import { datepickerLocale } from "@/data/datepickerLocale.js";
import { useShowError } from "@/lib/error.js";
import { updateOrder } from "@/lib/order.api.js";

/**
 *
 * @param {Object} props
 * @param {React.MutableRefObject} props.timeRef
 * @param {() => void} props.onClose
 * @param {Date} props.date
 * @param {TOrder} [props.order]
 * @param {keyof TOrder} [props.updateField]
 * @param {(date: Date|null) => void} [props.dateCallback]
 * @returns {JSX.Element}
 */
export default function ChangeDateTimeModalContent({
  timeRef,
  onClose,
  date,
  order,
  updateField,
  dateCallback,
}) {
  const { showError } = useShowError();
  const [selectedDate, setSelectedDate] = useState(date);

  const timeSlots = [];
  for (let i = 7; i <= 21; i++) {
    timeSlots.push(dayjs.tz().set("hour", i).set("minute", 0).set("second", 0));
    timeSlots.push(dayjs.tz().set("hour", i).set("minute", 30).set("second", 0));
  }

  useEffect(() => {
    timeRef.current?.scrollIntoView();
  }, [date, timeRef]);

  function selectDate(newDate) {
    setSelectedDate(
      dayjs
        .tz(selectedDate)
        .set("year", newDate.year)
        .set("month", newDate.month - 1)
        .set("date", newDate.day)
        .toDate(),
    );
  }

  function selectTime(hour, minute) {
    setSelectedDate(dayjs.tz(selectedDate).set("hour", hour).set("minute", minute).toDate());
  }

  async function updateDate() {
    if (dateCallback) {
      dateCallback(selectedDate);
    } else {
      if (!order || !updateField) {
        throw new Error("Order and updateField must be provided");
      }

      /** @type {SAS2.firebase.FirestoreFieldPatch<TOrder>} */
      const updates = { [updateField]: selectedDate };

      if (
        updateField == "deliverTime" &&
        (order.state == "toDeliver" || order.state == "toPack" || order.state == "toSize")
      ) {
        updates.driveTime = selectedDate;
        if (
          order.assignedTo &&
          !dayjs.tz(selectedDate).isSame(dayjs.tz(getFieldAsDate(order.deliverTime)), "day")
        ) {
          updates.assignedTo = deleteField();
        }
      }
      if (order.state == "toCollect" && updateField == "collectTime") {
        updates.driveTime = selectedDate;
        if (
          order.assignedTo &&
          !dayjs.tz(selectedDate).isSame(dayjs.tz(getFieldAsDate(order.collectTime)), "day")
        ) {
          updates.assignedTo = deleteField();
        }
      }
      await updateOrder(order, updates, "change-date-time-modal");
    }

    onClose();
  }

  async function deliveryLockChange() {
    if (!order) {
      throw new Error("Order must be provided");
    }
    // TODO: move this to order.api.js
    await updateOrder(order, { deliveryLocked: !order.deliveryLocked }, "change-date-time-modal");
  }

  function clearDate() {
    if (dateCallback) dateCallback(null);
    onClose();
  }

  return (
    <ModalContent>
      <ModalHeader>Select Date & Time</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing={2}>
          <HStack align="flex-start" spacing={12}>
            <Heading>
              <Calendar
                value={{
                  year: dayjs.tz(selectedDate).year(),
                  month: dayjs.tz(selectedDate).month() + 1,
                  day: dayjs.tz(selectedDate).date(),
                }}
                onChange={(date) => {
                  if (order?.deliveryLocked) {
                    showError("Delivery time is locked");
                    return;
                  }
                  selectDate(date);
                }}
                minimumDate={
                  order?.deliveryLocked
                    ? {
                        year: dayjs.tz(selectedDate).year(),
                        month: dayjs.tz(selectedDate).month() + 1,
                        day: dayjs.tz(selectedDate).date(),
                      }
                    : undefined
                }
                maximumDate={
                  order?.deliveryLocked
                    ? {
                        year: dayjs.tz(selectedDate).year(),
                        month: dayjs.tz(selectedDate).month() + 1,
                        day: dayjs.tz(selectedDate).date(),
                      }
                    : undefined
                }
                shouldHighlightWeekends
                colorPrimary="var(--chakra-colors-brand-500"
                locale={datepickerLocale}
                calendarTodayClassName="custom-today-day"
                customDaysClassName={[
                  // here we add some CSS classes
                  {
                    year: dayjs.tz(date).year(),
                    month: dayjs.tz(date).month() + 1,
                    day: dayjs.tz(date).date(),
                    className: "originalDay",
                  },
                ]}
              />
            </Heading>

            <Box
              h="368px"
              overflow="auto"
              sx={{ "::-webkit-scrollbar": { display: "none" } }}
              bgColor="white"
              borderWidth={1}
              borderRadius="6px"
            >
              <VStack gap={1} px={4}>
                {timeSlots.map((slot, i) => {
                  const itemProps =
                    slot.format("HHmm") == dayjs.tz(date).format("HHmm") ? { ref: timeRef } : {};
                  return (
                    <React.Fragment key={i}>
                      <Button
                        isDisabled={order?.deliveryLocked}
                        _hover={
                          slot.format("HHmm") == dayjs.tz(selectedDate).format("HHmm")
                            ? { bg: "brand.500" }
                            : { bg: "gray.100" }
                        }
                        {...itemProps}
                        px={2}
                        borderRadius="4px"
                        fontWeight="extrabold"
                        bgColor={
                          slot.format("HHmm") == dayjs.tz(selectedDate).format("HHmm")
                            ? "brand.500"
                            : slot.format("HHmm") == dayjs.tz(date).format("HHmm")
                            ? "brand.100"
                            : "white"
                        }
                        onClick={() => selectTime(slot.hour(), slot.minute())}
                      >
                        {slot.format("h.mma")}
                      </Button>
                    </React.Fragment>
                  );
                })}
              </VStack>
            </Box>
          </HStack>
          <Divider />
        </VStack>
      </ModalBody>
      <ModalFooter>
        {updateField == "deliverTime" && (
          <HStack>
            <Text>Lock delivery time</Text>
            <Switch
              isDisabled={!Boolean(order)}
              colorScheme="brand"
              onChange={deliveryLockChange}
              isChecked={order?.deliveryLocked}
            ></Switch>
          </HStack>
        )}
        {dateCallback && (
          <Button size="sm" onClick={clearDate}>
            Reset
          </Button>
        )}
        <Spacer />
        <Button
          colorScheme="brand"
          size="sm"
          onClick={() => updateDate()}
          isDisabled={order?.deliveryLocked === true}
        >
          Set Date & Time
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
