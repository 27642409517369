import { Box, Container, Fade } from "@chakra-ui/react";

import { useParams } from "react-router-dom";

import ErrorPage from "../global/ErrorPage.jsx";
import Order from "../modals/Order.jsx";

export default function OrderPage() {
  const { orderId } = useParams();

  if (!orderId) {
    return <ErrorPage />;
  }

  return (
    <Fade in={true}>
      <Container centerContent maxWidth="1200px">
        <Box>
          <Order orderId={orderId} />
        </Box>
      </Container>
    </Fade>
  );
}
