import { Box, FormControl, FormLabel, HStack, Textarea, VStack, Text } from "@chakra-ui/react";

import { useForm, FormProvider } from "react-hook-form";

import FormActions from "@/components/payment/FormActions.jsx";
import useOrderDetails from "@/hooks/useOrderDetails.js";
import usePaymentProcessing from "@/hooks/usePaymentProcessing.js";

import AmountFormControl from "../AmountFormControl.jsx";
import CommentsFormControl from "../CommentsFormControl.jsx";

/**
 * SavedCardForm component.
 * Renders a form for making a payment via account.
 * @param {Object} props - The component props.
 * @param {TOrderV2} props.order - The order object.
 * @param {() => void} props.onCancel - The function to close the modal.
 * @param {() => void} props.onComplete - The function to close the modal.
 * @param {TOrderPaymentTransactionSource} props.source - The source of the payment.
 * @returns {JSX.Element} The SavedCardForm component.
 */
export default function SavedCardForm({ onCancel, order, onComplete, source }) {
  const { isLoading, processOrderPayment } = usePaymentProcessing({ order, source, onComplete });
  const { balance, savedCreditCard } = useOrderDetails(order);
  const methods = useForm({
    shouldFocusError: false,
    mode: "all",
    defaultValues: { amount: balance, comments: "" },
  });

  const onSubmitForm = async (data) => {
    await processOrderPayment({
      amount: data.amount,
      comments: data.comments,
      type: "saved-credit-card",
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitForm)} autoComplete="off">
        <VStack mb={8} spacing={4} alignItems={"flex-start"}>
          <Box>
            <Text fontWeight={"bold"}>Card Details</Text>
            <HStack spacing={4} py={2}>
              <Text>{savedCreditCard?.name}</Text>
              <Text color="gray.600" fontStyle={"italic"}>
                {savedCreditCard?.maskedNumber}
              </Text>
            </HStack>
          </Box>
          <AmountFormControl maxAmount={balance} />
          <CommentsFormControl />
        </VStack>
        <FormActions
          isLoading={isLoading}
          isValid={methods.formState.isValid}
          onCancel={onCancel}
        />
      </form>
    </FormProvider>
  );
}
