import { useCallback, useContext, useEffect, useState } from "react";

import {
  Fade,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import { signal, effect } from "@preact/signals-core";
import { useNavigate } from "react-router-dom";

import Order from "@/components/modals/Order.jsx";
import { useAdvancedMode } from "@/lib/advancedModeLib.js";
import { initStringArray, initStringOrDefault } from "@/lib/initialize-states.js";

import { ModalContext } from "./OrderModalContextCreate.jsx";

/**
 * @param {Object} props
 * @param {string} props.modalId
 * @param {() => void} props.unSetModal
 * @returns {JSX.Element}
 */
const OrderModal = ({ modalId, unSetModal }) => {
  return (
    <Fade in={true}>
      <Modal
        isOpen={true}
        onClose={unSetModal}
        size="6xl"
        scrollBehavior="inside"
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent minH="80%">
          <ModalCloseButton />
          <ModalBody sx={{ "::-webkit-scrollbar": { display: "none" } }}>
            {modalId && <Order orderId={modalId} closeModal={unSetModal} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fade>
  );
};

/**
 * @typedef {Object} TOrderInfo
 * @property {string} id
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} lookupId
 * @property {TLocation} location
 */

/**
 * @param {any} [initialValue]
 * @returns {Map<string,TOrderInfo>}
 * */
const initOrderInfoMap = (initialValue) => new Map(initialValue);
/** @returns {TOrderInfo[]}  */
const initOrderInfoList = () => [];

// ids of opened orders sorted by last opened
const orderIdHistorySignal = signal(initStringArray());

// opened orders info map
const orderByIdSignal = signal(initOrderInfoMap());
// compiled opened order info list
const latestOrdersSignal = signal(initOrderInfoList());
// current open order id
const currentOpenOrderIdSignal = signal("");

/**
 * @param {string} orderId
 */
export const registerOrderIdHistory = (orderId) => {
  orderIdHistorySignal.value = [
    orderId,
    ...orderIdHistorySignal.value.filter((v) => v !== orderId),
  ];
};

/** @param {TOrder} order */
export const registerFetchedOrder = (order) => {
  if (orderByIdSignal.value.has(order.id) || !order) {
    return;
  }
  const newSet = initOrderInfoMap(orderByIdSignal.value);
  newSet.set(order.id, {
    id: order.id,
    firstName: order.firstName,
    lastName: order.lastName,
    lookupId: order.lookupId,
    location: order.location,
  });
  orderByIdSignal.value = newSet;
};

// calculate latest orders by open order history
effect(() => {
  /** @type {TOrderInfo[]} */
  const list = [];
  orderIdHistorySignal.value.forEach((orderId) => {
    const order = orderByIdSignal.value.get(orderId);
    if (order) {
      list.push(order);
    }
  });

  latestOrdersSignal.value = list;
});

export const useLatestOpenOrders = () => {
  const [latestOrders, setLatestOrders] = useState(initOrderInfoList());
  const [currentOpenOrderId, setCurrentOrderId] = useState(currentOpenOrderIdSignal.value);
  useEffect(() => {
    return effect(() => {
      setLatestOrders(latestOrdersSignal.value);
      setCurrentOrderId(currentOpenOrderIdSignal.value);
    });
  }, []);
  return { latestOrders, currentOpenOrderId };
};

const OrderModalProvider = (props) => {
  const navigate = useNavigate();
  const { isPathRouteModeOn } = useAdvancedMode();
  const [modalId, setModalId] = useState(initStringOrDefault(undefined));
  const unSetModal = useCallback(() => {
    currentOpenOrderIdSignal.value = "";
    setModalId(undefined);
  }, [setModalId]);

  const openOrderById = useCallback(
    (/** @type {string|undefined}*/ orderId, /** @type {boolean} */ openInNewTab = false) => {
      currentOpenOrderIdSignal.value = orderId ?? "";
      if (orderId) {
        registerOrderIdHistory(orderId);
      }
      if (openInNewTab === true) {
        window.open(`/order/${orderId}`, "_blank");
        return;
      }
      if (isPathRouteModeOn) {
        if (orderId) {
          navigate(`/order/${orderId}`);
        } else {
          navigate("/");
        }
      } else {
        setModalId(orderId);
      }
    },
    [isPathRouteModeOn],
  );
  return (
    <ModalContext.Provider
      value={{ unSetModal, setModalId: openOrderById, currentOrderId: modalId }}
      {...props}
    >
      {props.children}
      {modalId && <OrderModal modalId={modalId} unSetModal={unSetModal} />}
    </ModalContext.Provider>
  );
};

const useOrderModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    return { unSetModal: null, setModalId: null }; //to avoid error on dev code updates
  }

  return context;
};

export { OrderModalProvider, useOrderModalContext };
