import { FormControl, FormHelperText, FormLabel, Textarea } from "@chakra-ui/react";

import { useFormContext } from "react-hook-form";

/**
 *
 * @param {object} props
 * @param {number} [props.maxLength=150]
 * @param {string} [props.label="Comments"]
 * @param {boolean} [props.isDisabled=false]
 * @param {number} [props.minLength=0]
 * @returns
 */
const CommentsFormControl = ({
  maxLength = 150,
  label = "Comments",
  isDisabled = false,
  minLength = 0,
}) => {
  const { register, formState, getFieldState, watch } = useFormContext();
  const comments = watch("comments");
  const isDirty = getFieldState("comments").isDirty;
  const hasError = Boolean(formState.errors["comments"]);
  const remainingCharacters = maxLength - comments.length;

  return (
    <FormControl>
      <FormLabel fontWeight={"bold"}>{label}</FormLabel>
      <Textarea
        {...register("comments", { maxLength, minLength })}
        maxLength={maxLength}
        focusBorderColor={hasError ? "red.500" : "blue.500"}
        isDisabled={isDisabled}
        isInvalid={isDirty && hasError}
        resize={"none"}
        rows={3}
      />
      {!isDisabled && (
        <>
          {minLength > 0 && comments.length < minLength && (
            <FormHelperText minH={"18px"}>
              Comments must be at least {minLength} characters long
            </FormHelperText>
          )}
          <FormHelperText minH={"18px"}>
            {remainingCharacters > 0 && `${remainingCharacters} characters remaining`}
          </FormHelperText>
        </>
      )}
    </FormControl>
  );
};

export default CommentsFormControl;
