import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        borderRadius: "3xl",
        fontStyle: "'Poppins', sans-serif",
        fontWeight: "bold",
        size: "lg",
        _focus: { _focus: {} },
        _active: { _active: {} },
        _hover: { _hover: {} },
      },
    },
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
    Input: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
      defaultProps: {
        focusBorderColor: "gray.200",
      },
    },
  },
  colors: {
    brand: {
      50: "#F5FAEB",
      100: "#E4F1C6",
      200: "#D2E7A1",
      300: "#C0DE7D",
      400: "#AFD558",
      500: "#9DCC33",
      600: "#7EA329",
      700: "#5E7A1F",
      800: "#3F5214",
      900: "#1F290A",
    },
  },
  fonts: {
    heading: "'Poppins', sans-serif",
    body: "'DM Sans', sans-serif",
  },
  shadows: {
    // outline: "none !important",
    boxShadow: "none !important",
  },
});

export default theme;
