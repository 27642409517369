import { db } from "@/services/firebase.js";

import {
  collection,
  doc,
  getCountFromServer,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";

import { SystemAlertSchema } from "@snopro/common/system-alerts-models.js";

import { getLoggedInUser } from "@/contexts/AuthContext.jsx";

/**
 * @param {TOrder} order
 * @param {TUserV2} user
 * @param {import("firebase/firestore").WriteBatch} [batch]
 * @returns {Promise<void>}
 */
export const registerSystemAlertDeliveredOrderSkiPassAdded = async (order, user, batch) => {
  if (order.state !== "toCollect") {
    return;
  }

  const systemAlerts = await getCountFromServer(
    query(
      collection(db, "system-alerts"),
      where("type", "in", ["order-deliver-ski-pass", "order-ski-pass-added-to-delivered-order"]),
      where("order.id", "==", order.id),
      where("status", "==", "active"),
    ),
  );

  if (systemAlerts.data().count > 0) {
    return;
  }

  const alertDocto = doc(collection(db, "system-alerts"));
  /** @type {TSystemAlert} */
  const systemAlert = {
    type: "order-ski-pass-added-to-delivered-order",
    order,
    createdAt: new Date(),
    user: { id: user.id, firstName: user.firstName, lastName: user.lastName },
    status: "active",
    id: alertDocto.id,
  };
  const data = { ...SystemAlertSchema.parse(systemAlert), createdAt: serverTimestamp() };
  if (batch) {
    batch.set(alertDocto, data);
  } else {
    await setDoc(alertDocto, data);
  }
};
/**
 * @param {TOrder} order
 * @param {TUserV2} user
 * @param {TOrderChangeSource} source
 * @param {import("firebase/firestore").WriteBatch} [batch]
 * @returns {Promise<void>}
 */
export const registerSystemAlertUnpaidOrderCollected = async (order, user, source, batch) => {
  const alertDocto = doc(collection(db, "system-alerts"));
  /** @type {TSystemAlert} */
  const systemAlert = {
    type: "order-collect-unpaid",
    order: {
      id: order.id,
      lookupId: order.lookupId,
      firstName: order.firstName,
      lastName: order.lastName,
      location: order.location,
      balance: order.balance,
      paidAmount: order.paidAmount,
    },
    createdAt: new Date(),
    user: { id: user.id, firstName: user.firstName, lastName: user.lastName },
    status: "active",
    id: alertDocto.id,
  };
  const data = { ...SystemAlertSchema.parse(systemAlert), createdAt: serverTimestamp() };
  if (batch) {
    batch.set(alertDocto, data);
  } else {
    await setDoc(alertDocto, data);
  }
};

/**
 * @param {TSystemAlert["order"]} order
 * @param {TOrderChangeSource} source
 * @param {import("firebase/firestore").WriteBatch} [batch]
 * @returns {Promise<void>}
 */
export const registerSystemAlertInactiveOrder = async (order, source, batch) => {
  const user = getLoggedInUser();
  const alertDocto = doc(collection(db, "system-alerts"));
  /** @type {TSystemAlert} */
  const systemAlert = {
    type: "order-inactive",
    order: {
      id: order.id,
      lookupId: order.lookupId,
      firstName: order.firstName,
      lastName: order.lastName,
      location: order.location,
      balance: order.balance,
      paidAmount: order.paidAmount,
    },
    createdAt: new Date(),
    user: { id: user.id, firstName: user.firstName, lastName: user.lastName },
    status: "active",
    id: alertDocto.id,
  };
  const data = { ...SystemAlertSchema.parse(systemAlert), createdAt: serverTimestamp() };
  if (batch) {
    batch.set(alertDocto, data);
  } else {
    await setDoc(alertDocto, data);
  }
};
