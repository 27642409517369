import { useEffect, useState } from "react";

import { db } from "@/services/firebase.js";

import { doc, onSnapshot } from "firebase/firestore";

import { signal, effect } from "@preact/signals-core";
import { SASSystemConfigSchema } from "@snopro/common/config-models.js";

import { firebaseUserSignal } from "@/contexts/AuthContext.jsx";

/**
 * @type {SAS2.SystemConfig.TSASSystemConfig}
 */
const initSASSystemConfig = {
  availability: { smartSortingVersion: "" },
};

export const sasSystemConfigSignal = signal(initSASSystemConfig);

export const useSASSystemConfig = () => {
  const [SASSystemConfig, setSASSystemConfig] = useState(sasSystemConfigSignal.value);
  useEffect(() => {
    return effect(() => {
      setSASSystemConfig(sasSystemConfigSignal.value);
    });
  }, []);
  return { SASSystemConfig };
};

let unsubscribe;
effect(() => {
  if (firebaseUserSignal.value) {
    unsubscribe = onSnapshot(
      doc(db, "data", "system-config"),
      (doc) => {
        if (!doc.exists()) {
          sasSystemConfigSignal.value = initSASSystemConfig;
          return;
        }
        try {
          sasSystemConfigSignal.value = SASSystemConfigSchema.parse(doc.data());
        } catch (error) {
          console.error("Error parsing system config", error);
        }
      },
      (error) => {
        console.error("Error getting system config", error);
        unsubscribe = undefined;
      },
    );
  } else {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = undefined;
    }
  }
});
