import { Box, GridItem, SlideFade } from "@chakra-ui/react";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import { sundriesByPackageItemType } from "@/lib/order-ui-utils.js";

import CheckDIN from "../checks/CheckDIN.jsx";
import CheckHardwareDone from "../checks/CheckHardwareDone.jsx";
import CheckPoles from "../checks/CheckPoles.jsx";
import CheckSkierInfo from "../checks/CheckSkierInfo.jsx";
import RowCheck from "../checks/RowCheck.jsx";
import SkierGrid from "../common/SkierGrid.jsx";
import SkierRow from "../common/SkierRow.jsx";
import StepHeader from "../common/StepHeader.jsx";

/**
 * @param {object} props
 * @param {TOrderAndSkiersWithItems} props.order
 * @returns {JSX.Element}
 */
export default function StepPackHardware({ order }) {
  const config = useConfig();
  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <Box width="1100px">
        <StepHeader
          customerName={order?.firstName + " " + order?.lastName}
          orderId={order?.id}
          previousOrderId={order?.previousOrder}
          referrer={order?.referrer.name ?? ""}
        />
        <SkierGrid templateColumns="2fr 2fr 2fr 1fr 1fr">
          {order?.skiers.map((skier) => {
            const hasSundriesPoles = sundriesByPackageItemType(config, skier, ["poles"]).length > 0;
            const isCompleted = Boolean(
              hasSundriesPoles === false &&
                (skier?.progress?.packhardware || !skier.packageType) &&
                (skier?.progress?.sethardware || !skier.packageType || skier.ownBoots == true) &&
                (skier.packageType?.split(" ").pop() == "Snowboard" ||
                  skier?.progress?.polespacked ||
                  !skier.packageType),
            );
            return (
              <SkierRow key={skier.id} cols={5}>
                <CheckSkierInfo
                  skier={skier}
                  showIsOutOfRange
                  sundriesWarning={hasSundriesPoles ? `Additional sundries poles` : undefined}
                />
                {skier.packageType ? (
                  <CheckHardwareDone
                    skier={skier}
                    orderId={order.id}
                    orderLocation={order.location}
                    prefix="pack"
                  />
                ) : (
                  <GridItem />
                )}
                {skier.packageType ? <CheckDIN skier={skier} orderId={order.id} /> : <GridItem />}
                {skier.packageType ? <CheckPoles skier={skier} orderId={order.id} /> : <GridItem />}
                <RowCheck completed={isCompleted} />
              </SkierRow>
            );
          })}
        </SkierGrid>
      </Box>
    </SlideFade>
  );
}
