import { Box, SlideFade, GridItem } from "@chakra-ui/react";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import { isSkierRentalInventoryCollected, sundriesByType } from "@/lib/order-ui-utils.js";

import CheckBootDone from "../checks/CheckBootDone.jsx";
import CheckHardwareDone from "../checks/CheckHardwareDone.jsx";
import CheckOuterwear from "../checks/CheckOuterwear.jsx";
import CheckSkierInfo from "../checks/CheckSkierInfo.jsx";
import RowCheck from "../checks/RowCheck.jsx";
import RentalItemsSummary from "../common/RentalItemsSummary.jsx";
import SkierGrid from "../common/SkierGrid.jsx";
import SkierRow from "../common/SkierRow.jsx";
import StepHeader from "../common/StepHeader.jsx";

/**
 * @param {object} props
 * @param {TOrderAndSkiersWithItems} props.order
 * @returns {JSX.Element}
 */
export default function StepCollectItems({ order }) {
  const config = useConfig();

  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <Box width="1100px">
        <RentalItemsSummary order={order} />

        <StepHeader
          customerName={order.firstName + " " + order.lastName}
          orderId={order.id}
          referrer={order.referrer.name}
        />
        <SkierGrid templateColumns="2fr 3fr 3fr 1fr 1fr 1fr 1fr">
          {order.skiers.map((skier) => {
            const hasSundriesRentals = sundriesByType(config, skier, "Rental").length > 0;
            return (
              <SkierRow key={skier.id} cols={7}>
                <CheckSkierInfo
                  skier={skier}
                  showSimple={true}
                  sundriesWarning={hasSundriesRentals ? "Additional Sundries items" : undefined}
                />
                {skier.packageType ? (
                  <CheckHardwareDone
                    skier={skier}
                    orderId={order.id}
                    orderLocation={order.location}
                    prefix={"collect"}
                  />
                ) : (
                  <GridItem />
                )}
                {skier.packageType ? (
                  <CheckBootDone
                    skier={skier}
                    orderId={order.id}
                    prefix="collect"
                    isCollection={true}
                    source="flow-collect"
                  />
                ) : (
                  <GridItem />
                )}
                <CheckOuterwear skier={skier} orderId={order.id} type="jacket" prefix="collect" />
                <CheckOuterwear skier={skier} orderId={order.id} type="pants" prefix="collect" />
                <CheckOuterwear skier={skier} orderId={order.id} type="helmet" prefix="collect" />
                <RowCheck completed={isSkierRentalInventoryCollected(config, skier)} />
              </SkierRow>
            );
          })}
        </SkierGrid>
      </Box>
    </SlideFade>
  );
}
