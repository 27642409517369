import { FieldValue } from "firebase/firestore";

import { deepMapAllProps } from "@snopro/common/objects.js";

/**
 * @template {object} T
 * @param {T} obj
 * @returns {any}
 */
export const mapUpdateFirestoreToChangeLog = (obj) =>
  deepMapAllProps((obj, propName) => {
    if (obj instanceof FieldValue) {
      if ("_methodName" in obj) {
        if (obj._methodName == "deleteField") {
          return { mapped: true, value: { __deleted: true } };
        }
        if (obj._methodName == "arrayUnion") {
          if ("ya" in obj && Array.isArray(obj.ya)) {
            return { mapped: true, value: { __arrayUnion: obj.ya } };
          }
        }

        if (obj._methodName == "arrayRemove") {
          if ("ya" in obj && Array.isArray(obj.ya)) {
            return { mapped: true, value: { __arrayRemove: obj.ya } };
          }
        }
        if (obj._methodName == "serverTimestamp") {
          return { mapped: true, value: { __serverTimestamp: true } };
        }
      }

      console.warn("FieldValue", propName, obj);
      return { mapped: true, value: { __unknown: true } };
    }
    return undefined;
  }, obj);
