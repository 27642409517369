import React from "react";

import { Container, Input, Button, VStack, HStack, Image } from "@chakra-ui/react";

import { Link } from "react-router-dom";

import { logIn } from "@/services/auth.js";

import Logo from "../../assets/images/snoprologo.svg";

const loginWithErrorHandler = (email, password) =>
  logIn(email, password).catch((error) => {
    console.error(error);
    alert(error.message);
  });

function Login() {
  const [form, setForm] = React.useState({
    username: "",
    password: "",
  });
  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      loginWithErrorHandler(form.username, form.password);
    }
  }

  return (
    <Container centerContent={true}>
      <Image src={Logo} my={16} />
      <VStack spacing={6} width="400px">
        <Input
          name="username"
          placeholder="User name"
          value={form.username}
          onChange={handleChange}
        ></Input>
        <Input
          type="password"
          name="password"
          placeholder="Password"
          value={form.password}
          onChange={handleChange}
          onKeyDown={(e) => handleKeyDown(e)}
        ></Input>
        <Button
          colorScheme="brand"
          onClick={() => loginWithErrorHandler(form.username, form.password)}
        >
          LOG IN
        </Button>
        <HStack spacing={12}>
          <Link to="/reset">Forgot Password</Link>
        </HStack>
      </VStack>
    </Container>
  );
}

export default Login;
