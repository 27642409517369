import { useEffect, useState } from "react";

import { db } from "@/services/firebase.js";

import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";

import { SystemAlertSchema } from "@snopro/common/system-alerts-models.js";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { initalSystemAlertList } from "@/lib/initialize-states.js";

export const useSystemAlerts = () => {
  const [systemAlerts, setSystemAlerts] = useState(initalSystemAlertList());
  const [loading, setLoading] = useState(true);
  const { currentUserDetails } = useLoggedInUser();

  useEffect(() => {
    const constraints = [where("status", "==", "active")];
    if (!currentUserDetails.isManager) {
      constraints.push(
        where("type", "in", [
          "order-deliver-ski-pass",
          "order-ski-pass-added-to-delivered-order",
        ]),
      );
    }

    const systemAlertsUnsub = onSnapshot(query(collection(db, "system-alerts"), ...constraints), {
      next: (querySnapshot) => {
        const systemAlerts = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return SystemAlertSchema.parse(data);
        });
        setSystemAlerts(systemAlerts.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()));
        setLoading(false);
      },
      error: (error) => {
        setLoading(false);
        setSystemAlerts([]);
        console.error("Error getting system alerts", error);
      },
    });
    return systemAlertsUnsub;
  }, [currentUserDetails]);

  return { systemAlerts, loading };
};

/**
 * @param {string} systemAlertId
 * @param {TUserV2} user
 */
export async function completeSystemAlert(systemAlertId, user) {
  const systemAlertRef = doc(db, "system-alerts", systemAlertId);
  const systemAlertDocto = await getDoc(systemAlertRef);
  if (!systemAlertDocto.exists()) {
    throw new Error("System alert not found");
  }

  const systemAlert = SystemAlertSchema.parse(systemAlertDocto.data());
  if (systemAlert.status !== "active") {
    throw new Error("System alert is not active");
  }

  /** @type {SAS2.firebase.FirestoreFieldPatch<TSystemAlert>} */
  const update = {
    status: "completed",
    completion: {
      completedAt: serverTimestamp(),
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      },
    },
  };

  await updateDoc(systemAlertRef, update);
}
