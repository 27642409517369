import { Button, HStack, Spacer } from "@chakra-ui/react";

const FormActions = ({ onCancel, isLoading, isValid }) => (
  <HStack>
    <Button size="sm" onClick={onCancel}>
      CANCEL
    </Button>
    <Spacer />
    <Button colorScheme="brand" size="md" type="submit" isDisabled={!isValid} isLoading={isLoading}>
      SUBMIT
    </Button>
  </HStack>
);

export default FormActions;
