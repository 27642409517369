import { Fragment, useState } from "react";

import {
  HStack,
  Input, Radio,
  RadioGroup, VStack, Text, Divider
} from "@chakra-ui/react";



export default function AddSkiPassList({ passes, addSkiPass, firstSkiDay }) {
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [firstDay, setFirstDay] = useState(firstSkiDay);

  function sortObjectByFields(obj, fields) {
    // Get the entries of the object and sort them based on the specified fields
    const sortedEntries = Object.entries(obj).sort(([, valueA], [, valueB]) => {
      for (const { field, direction } of fields) {
        const fieldValueA = valueA[field];
        const fieldValueB = valueB[field];

        // Compare values based on sorting direction
        if (fieldValueA < fieldValueB) {
          return direction === "asc" ? -1 : 1;
        } else if (fieldValueA > fieldValueB) {
          return direction === "asc" ? 1 : -1;
        }
      }
      return 0; // If values are equal
    });
    // Create a new array of sorted objects
    const sortedObjects = sortedEntries.map(([key, value]) => ({ ...obj[key], id: key }));
    return sortedObjects;
  }

  return (
    <>
      <VStack spacing={6} m={6} divider={<Divider />}>
        <HStack spacing={12}>
          <VStack>
            <Input
              value={birthDay}
              placeholder="birth date(DD/MM/YYYY)"
              onChange={(e) => setBirthDay(e.target.value)}
            />
            <Text fontSize="xs" fontWeight="bold">
              Birthdate
            </Text>
          </VStack>
          <VStack>
            <Input value={firstDay} onChange={(e) => setFirstDay(e.target.value)} />
            <Text fontSize="xs" fontWeight="bold">
              First ski day
            </Text>
          </VStack>
        </HStack>
        <RadioGroup value={selectedGroup} onChange={setSelectedGroup} colorScheme="brand">
          <HStack direction="row" spacing={6}>
            <Radio value="Adult">
              <VStack spacing={0} align="flex-start">
                <Text>Adult</Text>
                <Text fontSize="xs">18+</Text>
              </VStack>
            </Radio>
            <Radio value="Child">
              <VStack spacing={0} align="flex-start">
                <Text>Child</Text>
                <Text fontSize="xs">6-17</Text>
              </VStack>
            </Radio>
            <Radio value="Student">
              <VStack spacing={0} align="flex-start">
                <Text>Student</Text>
                <Text fontSize="xs">AU/NZ full time</Text>
              </VStack>
            </Radio>
            <Radio value="Senior">
              <VStack spacing={0} align="flex-start">
                <Text>Senior</Text>
                <Text fontSize="xs">65-74</Text>
              </VStack>
            </Radio>
            <Radio value="Free">
              <VStack spacing={0} align="flex-start">
                <Text>Free</Text>
                <Text fontSize="xs">0-5 / 75+</Text>
              </VStack>
            </Radio>
          </HStack>
        </RadioGroup>
        <RadioGroup value={selectedType} onChange={setSelectedType} colorScheme="brand">
          <HStack direction="row" spacing={5}>
            <Radio value="Single">Single Mountain</Radio>
            <Radio value="Dual">Dual Mountain</Radio>
          </HStack>
        </RadioGroup>
      </VStack>
      <Divider />
      {selectedGroup && selectedType && birthDay && firstDay && (
        <>
          {sortObjectByFields(passes, [
            { field: "type", direction: "desc" },
            { field: "group", direction: "asc" },
            { field: "days", direction: "asc" },
          ]).map((pass) => {
            if (pass.group == selectedGroup && pass.type == selectedType)
              return (
                <Fragment key={pass.id}>
                  <Divider />
                  <HStack
                    spacing={0}
                    as="button"
                    onClick={() => addSkiPass(pass, birthDay, firstDay)}
                  >
                    <Text fontWeight="bold" p={4}>
                      {pass.days}
                    </Text>
                    <Text color="gray.300">${pass.price}</Text>
                  </HStack>
                </Fragment>
              );
          })}
        </>
      )}
    </>
  );
}
