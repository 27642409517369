import { HStack, Td, Text, Tr, Menu } from "@chakra-ui/react";
import { Icon, IconButton, MenuItem, MenuList, MenuButton } from "@chakra-ui/react";

import { GoKebabHorizontal } from "react-icons/go/index.esm.js";
import { RiRefund2Line } from "react-icons/ri/index.esm.js";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import useTransaction from "@/hooks/useTransaction.jsx";
import { asPrice } from "@/lib/numbers.js";

/**
 * @param {Object} props - Component props.
 * @param {TOrderPaymentTransaction} props.transaction - The transaction object containing payment information.
 * @param {(t: TOrderPaymentTransaction, allowTypeValue?:boolean) => void} props.onRefundClick - onClick handler for the refund button.
 * @param {boolean} [props.withActions=true] - Indicates if the component should render with actions. (Optional)
 * @param {TPaymentTableColumn[]} [props.visibleColumns] - The columns to show in the table. (Optional)
 * @returns {JSX.Element | null} The rendered TransactionTableRow component.
 */
export default function TransactionTableRow({ transaction, onRefundClick, visibleColumns = [] }) {
  const {
    sourceLabel,
    sourceIcon,
    date,
    method,
    comments,
    amountToShow,
    displayName,
    cardName,
    maskedCardNumber,
    showRefundButton,
    isRefund,
  } = useTransaction(transaction);
  const { currentUserDetails } = useLoggedInUser();

  return (
    <Tr {...(isRefund && { color: "red" })}>
      {visibleColumns.map((column) => {
        switch (column) {
          case "user":
            return (
              <Td key={column}>
                <Text isTruncated>{transaction.user?.name || "--"}</Text>
              </Td>
            );
          case "date":
            return (
              <Td key={column} py={5}>
                <Text fontSize="small" isTruncated>
                  {date}
                </Text>
              </Td>
            );
          case "made":
            return (
              <Td key={column}>
                <HStack>
                  {sourceIcon && sourceIcon}
                  {sourceLabel && <Text isTruncated>{sourceLabel}</Text>}
                </HStack>
              </Td>
            );
          case "method":
            return (
              <Td key={column}>
                <Text isTruncated>{displayName}</Text>
              </Td>
            );
          case "type":
            return (
              <Td key={column}>
                <Text>{isRefund ? "Refund" : "Payment"}</Text>
              </Td>
            );
          case "details":
            return (
              <Td key={column}>
                <Text fontStyle={"italic"} fontSize="small" pr={1}>
                  {comments ?? ""}
                </Text>
                {method === "credit-card" && (
                  <HStack spacing={0}>
                    {cardName && <Text isTruncated>{cardName}</Text>}
                    {maskedCardNumber && (
                      <Text
                        fontSize="small"
                        color={isRefund ? "red.500" : "gray.500"}
                        fontStyle={"italic"}
                      >
                        {maskedCardNumber}
                      </Text>
                    )}
                  </HStack>
                )}
              </Td>
            );
          case "amount":
            return (
              <Td key={column} textAlign="right">
                <Text>
                  {isRefund ? "-" : ""}
                  {asPrice(amountToShow)}
                </Text>
              </Td>
            );
          case "refundedAmount":
            return (
              <Td key={column} textAlign="right">
                <Text>{asPrice(transaction.refundedAmount, "")}</Text>
              </Td>
            );
          case "refundAvailable":
            return (
              <Td key={column} textAlign="right">
                {transaction.type === "payment" && (
                  <Text>{asPrice(transaction.amount - (transaction.refundedAmount ?? 0), "")}</Text>
                )}
              </Td>
            );
          case "actions":
            return (
              <Td key={column} textAlign="right" maxW="40px">
                {showRefundButton && (
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<Icon as={GoKebabHorizontal} boxSize={5} />}
                      onClick={(e) => e.stopPropagation()}
                      size={"sm"}
                      variant={"outline"}
                    />
                    <MenuList>
                      {showRefundButton && (
                        <MenuItem
                          icon={<RiRefund2Line />}
                          isDisabled={!currentUserDetails.isManager}
                          onClick={(e) => {
                            e.stopPropagation();
                            onRefundClick(transaction, true);
                          }}
                        >
                          {[transaction.method !== "credit-card" ? "Manual" : "", "Refund"]
                            .filter(Boolean)
                            .join(" ")}
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                )}
              </Td>
            );
          default:
            return <Td>{column}</Td>;
        }
      })}
    </Tr>
  );
}
