import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import AccountForm from "@/components/payment/AccountForm/AccountForm.jsx";
import CashForm from "@/components/payment/CashForm/CashForm.jsx";
import CreditCardForm from "@/components/payment/CreditCardForm/CreditCardForm.jsx";
import SavedCardForm from "@/components/payment/SavedCardForm/SavedCardForm.jsx";
import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import useOrderDetails from "@/hooks/useOrderDetails.js";

import NoPayForm from "./NoPayForm.jsx";

/**
 * @type {Record<TPaymentMethod, { label: string; Component: any }>}
 */
const COMPONENT_MAP = {
  "saved-credit-card": { label: "Card on file", Component: SavedCardForm },
  "credit-card": { label: "Credit Card", Component: CreditCardForm },
  cash: { label: "Cash", Component: CashForm },
  account: { label: "Account", Component: AccountForm },
  "no-pay": { label: "No Pay", Component: NoPayForm },
};

/**
 * PaymentOptions component.
 * Renders a modal for making a payment.
 * @param {Object} props - The component props.
 * @param {TOrderV2} props.order - The order object.
 * @param {TOrderPaymentTransactionSource} props.source - The source of the payment.
 * @param {() => void} props.onCancel - The function to cancel the payment.
 * @param {() => void} props.onComplete - The function to complete the payment.
 *
 * @returns {JSX.Element} The PaymentOptions component.
 */
const PaymentOptions = ({ order, source, onCancel, onComplete }) => {
  const { paymentMethods: tmpPaymentMethods } = useOrderDetails(order);
  const { currentUserDetails } = useLoggedInUser();
  const paymentMethods = tmpPaymentMethods.filter((method) => {
    if (method === "no-pay" && source !== "on-delivery") {
      return false;
    }
    if (method === "account") {
      if (order.location === "Hilton") {
        return true;
      }

      if (source !== "on-delivery" && ["manager", "admin"].includes(currentUserDetails.role)) {
        return true;
      }

      return false;
    }
    return true;
  });

  return (
    <Tabs variant="solid-rounded" colorScheme="brand" isLazy>
      <TabList mb={6} mx={-2}>
        {paymentMethods.map((method, i) => (
          <Tab key={i} mr={4}>
            {COMPONENT_MAP[method].label}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {paymentMethods.map((method) => {
          const Component = COMPONENT_MAP[method].Component;

          return (
            <TabPanel key={method} p={2}>
              <Component
                onCancel={onCancel}
                order={order}
                onComplete={onComplete}
                source={source}
              />
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

export default PaymentOptions;
