import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import { CiLink } from "react-icons/ci/index.esm.js";
import { FaBuildingCircleArrowRight } from "react-icons/fa6/index.esm.js";
import { FaBan, FaTrash } from "react-icons/fa/index.esm.js";
import { HiOutlineDocumentText } from "react-icons/hi/index.esm.js";
import { HiExclamation } from "react-icons/hi/index.esm.js";

import { db } from "@/services/firebase.js";

import { deleteField, doc, writeBatch } from "firebase/firestore";

import { plural } from "@snopro/common/string-utils.js";
import { Link } from "react-router-dom";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useOrderModalContext } from "@/contexts/OrderModalContext.jsx";
import { useShowError } from "@/lib/error.js";
import { makeHardwareAvailable } from "@/lib/hardwareLib.jsx";
import { saveActivity } from "@/services/activityLog.js";

import CheckImage from "../common/CheckImage.jsx";
import DisposeHardwareModal from "../modals/DisposeHardwareModal.jsx";
import MakeHardwareUnavailableModal from "../modals/MakeHardwareUnavailableModal.jsx";
import PackageName from "./PackageName.jsx";

/**
 * @param {object} props
 * @param {THardware} props.hardware
 * @param {boolean} [props.isAllocatedTomorrow]
 * @returns {JSX.Element}
 */
export default function TuneCard({ hardware, isAllocatedTomorrow }) {
  const { showError } = useShowError();
  const hardwareServiceDisclosure = useDisclosure();
  const makeHardwareUnavailableDisclosure = useDisclosure();
  const disposeHardwareDisclosure = useDisclosure();
  const { currentUserDetails } = useLoggedInUser();
  const { setModalId } = useOrderModalContext();

  /** @type {(type: "tuned" | "returned") => Promise<void>} */
  async function doTune(type) {
    try {
      const hardwareDoc = doc(db, "hardware", hardware.id);
      const batch = writeBatch(db);

      if (type == "tuned") {
        batch.update(hardwareDoc, { tunedOnly: true });

        await saveActivity(
          "tuned",
          `${hardware.code} tuned by ${currentUserDetails.firstName}`,
          undefined,
          undefined,
          undefined,
          undefined,
          batch,
        );
      } else {
        batch.update(hardwareDoc, { status: "Available", tunedOnly: deleteField() });

        await saveActivity(
          "returned",
          `${hardware.code} tuned/returned by ${currentUserDetails.firstName}`,
          undefined,
          undefined,
          undefined,
          undefined,
          batch,
        );
      }
      await batch.commit();

      hardwareServiceDisclosure.onClose();
    } catch (eror) {
      showError(eror);
    }
  }

  return (
    <>
      <HStack spacing={0}>
        <Box
          bg={"gray.50"}
          borderWidth={1}
          borderRadius="lg"
          p={2}
          w="340px"
          textAlign="left"
          as="button"
          onClick={hardwareServiceDisclosure.onOpen}
          opacity={hardware.tunedOnly ? 0.3 : undefined}
        >
          <HStack>
            <CheckImage type={hardware.type.toLowerCase()} />
            <Flex direction="column" flex="1">
              <HStack direction="row">
                <Text fontWeight="bold" isTruncated>
                  {hardware.lastSkierName}
                </Text>
                <Spacer />
                {hardware.lastOrderLocation && hardware.lastOrderLocation != hardware.location && (
                  <HStack spacing={0}>
                    <Icon as={FaBuildingCircleArrowRight} alignSelf="center" mr={1} />
                    <Text fontWeight="bold" mr={4}>
                      ({hardware.lastOrderLocation?.substring(0, 1)})
                    </Text>
                  </HStack>
                )}
                <Text>{plural("day", hardware.lastSkiDays ?? 0, { ifZero: "" })}</Text>
              </HStack>
              <Text isTruncated>
                {isAllocatedTomorrow && <Icon as={HiExclamation} color="red.500" />}
                {hardware.make} {hardware.model} ({hardware.code})
              </Text>
              <HStack>
                <PackageName packageName={hardware.package} />
                <Spacer />
                {hardware.lastSkierInsurance === false && (
                  <Badge colorScheme="orange" variant="solid">
                    No insurance
                  </Badge>
                )}
              </HStack>
              {hardware.notes && (
                <>
                  <Divider mt={1} />
                  <Text fontSize={"xs"} whiteSpace="pre-line">
                    {hardware.notes}
                  </Text>
                </>
              )}
            </Flex>
          </HStack>
        </Box>
        {hardware.lastOrderId && (
          <Box bg="gray.300" w={7} m={0} h={16} borderRightRadius="xl">
            <Flex
              as="button"
              flexDirection="column"
              alignItems="center"
              h="100%"
              justify="space-evenly"
              onClick={(e) => setModalId(hardware.lastOrderId, Boolean(e.ctrlKey || e.shiftKey))}
            >
              <Icon as={HiOutlineDocumentText} boxSize={7} color="white" />
            </Flex>
          </Box>
        )}
      </HStack>

      {hardwareServiceDisclosure.isOpen && (
        <Modal
          isOpen={hardwareServiceDisclosure.isOpen}
          onClose={hardwareServiceDisclosure.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Service Confirmation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={0}>
                <Divider />
                <Text
                  opacity={hardware.tunedOnly ? 0.3 : undefined}
                  as="button"
                  fontWeight="bold"
                  onClick={() => doTune("tuned")}
                  p={4}
                >
                  Tuned only
                </Text>
                <Divider />
                <Text as="button" fontWeight="bold" onClick={() => doTune("returned")} p={4}>
                  Tuned and returned
                </Text>
                <Divider />
                <Divider />
              </VStack>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <HStack flex="1">
                {hardware.status === "Unavailable" ? (
                  <Button onClick={() => makeHardwareAvailable(hardware.id).catch(showError)}>
                    Make available
                  </Button>
                ) : (
                  <Button
                    leftIcon={<Icon as={FaBan} />}
                    onClick={makeHardwareUnavailableDisclosure.onOpen}
                  >
                    Make Unavailable
                  </Button>
                )}
                <Spacer />
                <Tooltip label="Open hardware in new tab" aria-label="open hardware">
                  <IconButton
                    as={Link}
                    aria-label="open hardware"
                    icon={<CiLink />}
                    to={`/settings/hardware?location=${hardware.location}&hardwareId=${hardware.id}`}
                    target="_blank"
                  />
                </Tooltip>
                <Spacer />
                <Button leftIcon={<Icon as={FaTrash} />} onClick={disposeHardwareDisclosure.onOpen}>
                  Dispose of item
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <MakeHardwareUnavailableModal
        selfDisclosure={makeHardwareUnavailableDisclosure}
        hardware={hardware}
        onSaved={hardwareServiceDisclosure.onClose}
      />
      <DisposeHardwareModal
        selfDisclosure={disposeHardwareDisclosure}
        hardware={hardware}
        onSaved={disposeHardwareDisclosure.onClose}
      />
    </>
  );
}
