import {
  Box,
  Button,
  GridItem,
  Icon,
  VStack,
  Text,
  HStack,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { BsPass } from "react-icons/bs/index.esm.js";

import { db } from "@/services/firebase.js";

import { doc, writeBatch } from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import { useShowError } from "@/lib/error.js";
import { removeSkierItem, skierChanges } from "@/lib/skier.api.js";

import AddSkiPassList from "../common/AddSkiPassList.jsx";

export default function CheckDeliverSkiPass({ skier, order, isEditMode }) {
  const { showError } = useShowError();
  const lookup = useConfig();
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function removeItem() {
    await removeSkierItem(order.id, skier, "skiPass").catch(showError);
  }

  // TODO: move to skier.api.js
  async function addSkiPass(pass, birthDay, firstDay) {
    try {
      const batch = writeBatch(db);
      const skierDoc = doc(db, "orders", order.id, "skiers", skier.id);
      const skiPass = {
        "skiPass.birthdate": birthDay,
        "skiPass.id": pass.id,
        "skiPass.startDate": firstDay,
        "skiPass.activated": false,
        "skiPass.passInfo": "",
        "costs.skiPass": pass.price,
      };
      batch.update(skierDoc, skiPass);

      await skierChanges(order.id, skier.id, null, "update", skiPass, batch);
      await batch.commit();
      onClose();
    } catch (error) {
      showError(error);
    }
  }

  return (
    <>
      {skier.skiPass ? (
        <GridItem>
          <VStack>
            <HStack>
              <Icon as={BsPass} boxSize={8} />
              <VStack spacing={0} align="flex-start">
                <Text>
                  {lookup.passes[skier.skiPass.id].group} / {lookup.passes[skier.skiPass.id].type}{" "}
                  mountain / {lookup.passes[skier.skiPass.id].days}
                </Text>
                <Text fontSize="xs">
                  Birth {skier.skiPass.birthdate} / Start {skier.skiPass.startDate}
                </Text>
              </VStack>
            </HStack>

            {isEditMode && (
              <Button onClick={removeItem} size="xs" justifySelf="end">
                remove
              </Button>
            )}
          </VStack>
        </GridItem>
      ) : (
        <Text as="button" fontSize="xs" color="gray.400" onClick={onOpen}>
          + Ski Pass
        </Text>
      )}
      {isOpen && (
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            minH="60%"
            overflow="auto"
            sx={{ "::-webkit-scrollbar": { display: "none" } }}
          >
            <ModalHeader>Add Ski Pass</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box w="full" h="full" align="center">
                <AddSkiPassList
                  passes={lookup.passes}
                  addSkiPass={addSkiPass}
                  firstSkiDay={dayjs.tz(getFieldAsDate(order.deliverTime)).format("DD/MM/YYYY")}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
