import React from "react";

import { Editable, EditableInput, EditablePreview } from "@chakra-ui/react";

/**
 * @template {keyof {[K in keyof TOrder as TOrder[K] extends string ? K : never]: K;}  } TOrderFieldName
 * @param {object} props
 * @param {TOrderFieldName} props.fieldName
 * @param {(fieldName:TOrderFieldName, newValue:string) => Promise<void>} props.onChange
 * @param {boolean} props.isDisabled
 * @param {TOrder} props.order
 * @param {React.ComponentProps<typeof Editable>["maxW"]} [props.maxW]
 * @param {React.ComponentProps<typeof Editable>} [props.editableProps]
 * @param {React.ComponentProps<typeof EditablePreview>} [props.previewProps]
 * @param {boolean} [props.shouldTrimValueOnUpdate]
 * @returns {JSX.Element}
 * */
const EditableOrderStringField = ({
  isDisabled,
  fieldName,
  onChange,
  order,
  maxW,
  editableProps,
  previewProps,
  shouldTrimValueOnUpdate,
}) => {
  const [currentEditingValue, setCurrentEditingValue] = React.useState("");
  const [isEditing, setIsEditing] = React.useState(false);

  return (
    <Editable
      maxW={maxW}
      {...editableProps}
      value={isEditing ? currentEditingValue : order[fieldName]}
      onEdit={() => {
        setCurrentEditingValue(order[fieldName]);
        setIsEditing(true);
      }}
      onChange={(value) => {
        setCurrentEditingValue(value);
      }}
      onCancel={() => {
        setIsEditing(false);
        setCurrentEditingValue("");
      }}
      onSubmit={async (newValue) => {
        try {
          const value = shouldTrimValueOnUpdate ? newValue.trim() : newValue;
          if (value === order[fieldName]) {
            return;
          }
          await onChange(fieldName, value);
        } finally {
          setIsEditing(false);
        }
      }}
      isDisabled={isDisabled}
    >
      <EditablePreview
        maxW={maxW}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        {...previewProps}
      />

      <EditableInput />
    </Editable>
  );
};
export default EditableOrderStringField;
