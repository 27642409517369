import React from "react";

import { GridItem } from "@chakra-ui/react";

/**
 * @param {object} props
 * @param {React.ReactNode} [props.leftCellContent]
 * @param {React.ReactNode} [props.middleCellContent]
 * @param {React.ReactNode} [props.rightCellContent]
 * @param {"flex-end"} [props.leftCellContentJustifySelf]
 */
export const SummaryGridItem = ({
  leftCellContentJustifySelf,
  leftCellContent,
  middleCellContent,
  rightCellContent,
}) => {
  return (
    <>
      <GridItem colSpan={4} alignSelf="start" justifySelf={leftCellContentJustifySelf}>
        {leftCellContent}
      </GridItem>
      <GridItem fontSize="sm" colSpan={2}>
        {middleCellContent}
      </GridItem>
      <GridItem fontSize="sm" justifySelf="flex-end">
        {rightCellContent}
      </GridItem>
    </>
  );
};
