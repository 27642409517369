/**
 * @typedef {Pick<TSkier,"ability"|"weight"|"height"|"age"|"ownBootSole"|"selectedBoot"|"shoe">} TSkierCalcDIN
 */
/** @type {SAS2.DIN.TSkierCode[]} */
// prettier-ignore
export const __AllSkierCodes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
/** @type {SAS2.DIN.TSkierCodeLetter[]} */
// prettier-ignore
export const __AllSkierLetters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P"]

const __AllSoleLengthValues = [250, 270, 290, 310, 330, 331];

/**
 * @param {SAS2.DIN.TSkierCode|SAS2.DIN.TSkierCodeLetter} skierCode
 * @param {[number|null,number|null,number|null,number|null,number|null,number|null]} DINBySolesLengthPosition
 * @returns {SAS2.DIN.TSkierSoleLengthXDIN[]}
 */
const generateSoleLengths = (skierCode, DINBySolesLengthPosition) => {
  const numericSkierCode =
    typeof skierCode === "string"
      ? __AllSkierCodes[__AllSkierLetters.indexOf(skierCode)]
      : skierCode;
  const skierCodeLetter = __AllSkierLetters[__AllSkierCodes.indexOf(numericSkierCode)];
  return DINBySolesLengthPosition.map((DIN, index) => ({
    comparator: index === __AllSoleLengthValues.length - 1 ? ">=" : "<=",
    skierCode: numericSkierCode,
    skierCodeLetter,
    soleLengthMM: __AllSoleLengthValues[index],
    DIN,
  }));
};
export const __AllSoleLenghtsComparator = generateSoleLengths(1, [
  null,
  null,
  null,
  null,
  null,
  null,
]).map(({ soleLengthMM, comparator }) => ({ soleLengthMM, comparator }));

/** @type {SAS2.DIN.TSkierWeight[]} */
const __SkierWeight = [
  { weightKG: 13, comparator: "<=", skierCode: 1 },
  { weightKG: 17, comparator: "<=", skierCode: 2 },
  { weightKG: 21, comparator: "<=", skierCode: 3 },
  { weightKG: 25, comparator: "<=", skierCode: 4 },
  { weightKG: 30, comparator: "<=", skierCode: 5 },
  { weightKG: 35, comparator: "<=", skierCode: 6 },
  { weightKG: 41, comparator: "<=", skierCode: 7 },
  { weightKG: 48, comparator: "<=", skierCode: 8 },
  { weightKG: 57, comparator: "<=", skierCode: 9 },
  { weightKG: 66, comparator: "<=", skierCode: 10 },
  { weightKG: 78, comparator: "<=", skierCode: 11 },
  { weightKG: 94, comparator: "<=", skierCode: 12 },
  { weightKG: 95, comparator: ">=", skierCode: 13 },
];

/** @type {SAS2.DIN.TSkierHeight[]} */
const __SkierHeight = [
  { heightCM: 148, comparator: "<=", skierCode: 8 },
  { heightCM: 157, comparator: "<=", skierCode: 9 },
  { heightCM: 166, comparator: "<=", skierCode: 10 },
  { heightCM: 178, comparator: "<=", skierCode: 11 },
  { heightCM: 194, comparator: "<=", skierCode: 12 },
  { heightCM: 195, comparator: ">=", skierCode: 13 },
];

/** @type {SAS2.DIN.TSkierAge[]} */
const __SkierAge = [
  { age: 9, comparator: "<=", skierCodeAdd: -1 },
  { age: 49, comparator: "<=", skierCodeAdd: 0 },
  { age: 50, comparator: ">=", skierCodeAdd: -1 },
];

/** @type {SAS2.DIN.TSkierType[]} */
const __SkierType = [
  { type: 1, comparator: "<=", skierCodeAdd: 0 },
  { type: 2, comparator: "<=", skierCodeAdd: 1 },
  { type: 3, comparator: ">=", skierCodeAdd: 2 },
];

/** @type {SAS2.DIN.TSkierSoleLengthXDIN[]} */
// prettier-ignore
const __DINScenarios = [
  ...generateSoleLengths("A", [0.75, 0.75, null, null, null, null]),
  ...generateSoleLengths("B", [1.00, 1.00, 0.75, null, null, null]),
  ...generateSoleLengths("C", [1.50, 1.25, 1.00, null, null, null]),
  ...generateSoleLengths("D", [1.75, 1.50, 1.50, 1.25, null, null]),
  ...generateSoleLengths("E", [2.25, 2.00, 1.75, 1.50, 1.50, null]),
  ...generateSoleLengths("F", [2.75, 2.50, 2.25, 2.00, 1.75, 1.75]),
  ...generateSoleLengths("G", [3.50, 3.00, 2.75, 2.50, 2.25, 2.00]),
  ...generateSoleLengths("H", [null, 3.50, 3.00, 3.00, 2.75, 2.5]),
  ...generateSoleLengths("I", [null, 4.50, 4.00, 3.50, 3.50, 3.00]),
  ...generateSoleLengths("J", [null, 5.50, 5.00, 4.50, 4.00, 3.50]),
  ...generateSoleLengths("K", [null, 6.50, 6.00, 5.50, 5.00, 4.50]),
  ...generateSoleLengths("L", [null, 7.50, 7.00, 6.50, 6.00, 5.50]),
  ...generateSoleLengths("M", [null, null, 8.50, 8.00, 7.00, 6.50]),
  ...generateSoleLengths("N", [null, null, 10.0, 9.50, 8.50, 8.00]),
  ...generateSoleLengths("O", [null, null, 11.5, 11.0, 10.0, 9.50]),
  ...generateSoleLengths("P", [null, null, null, 13.0, 12.0, 11.5]),
];

/**
 * @template {{comparator: "<=" | ">="}} T
 * @param {number} inputValue
 * @param {T[]} table
 * @param { (item:T) => number} getValue
 * @returns {T|undefined}
 */
const tryAndfindItem = (inputValue, table, getValue) => {
  const item = table.find((t) => {
    const value = getValue(t);
    if (t.comparator === "<=") {
      return inputValue <= value;
    }
    return inputValue >= value;
  });

  return item;
};

/**
 * @template {{comparator: "<=" | ">="}} T
 * @param {number} inputValue
 * @param {T[]} table
 * @param { (item:T) => number} getValue
 * @returns {T}
 */
const findItem = (inputValue, table, getValue) => {
  const item = tryAndfindItem(inputValue, table, getValue);
  if (!item) {
    throw new Error("Item not found");
  }
  return item;
};
/**
 * @param {object} opts
 * @param {number} opts.weight
 * @param {number} opts.height
 * @param {number} opts.age
 * @param {SAS2.DIN.TSkierTypeCode} opts.type
 * @param {number} opts.bootSoleLength
 */
export function calculateDIN({ weight, height, age, bootSoleLength, type }) {
  const weightSkierCode = tryAndfindItem(weight, __SkierWeight, (t) => t.weightKG);
  const heightSkierCode = tryAndfindItem(height, __SkierHeight, (t) => t.heightCM);
  if (!weightSkierCode && !heightSkierCode) {
    throw new Error("Weight and height not found");
  }
  const ageSkierCode = findItem(age, __SkierAge, (t) => t.age);
  const typeSkierCode = findItem(type, __SkierType, (t) => t.type);

  const heightWeightSkierCodeRow = Math.min(
    weightSkierCode?.skierCode ?? 100,
    heightSkierCode?.skierCode ?? 100,
  );
  let skierCodeRow = heightWeightSkierCodeRow;
  if (ageSkierCode?.skierCodeAdd) {
    skierCodeRow += ageSkierCode.skierCodeAdd;
  }

  if (typeSkierCode?.skierCodeAdd) {
    skierCodeRow += typeSkierCode.skierCodeAdd;
  }
  const dinRow = __DINScenarios.filter((t) => t.skierCode === skierCodeRow);
  const dinCell = tryAndfindItem(bootSoleLength, dinRow, (t) => t.soleLengthMM);
  return {
    ...dinCell,
    heightWeightSkierCodeLetter:
      __AllSkierLetters[__AllSkierCodes.findIndex((d) => d === heightWeightSkierCodeRow)],
  };
}

export function getDINTable() {
  const rows = __AllSkierCodes.map((skierCode) => {
    const dinRow = __DINScenarios.filter((t) => t.skierCode === skierCode);
    const dinCells = __AllSoleLengthValues.map((soleLength) => {
      const dinCell = tryAndfindItem(soleLength, dinRow, (t) => t.soleLengthMM);
      return dinCell;
    });
    const weightSkierCode = __SkierWeight.find((t) => t.skierCode === skierCode);
    const heightSkierCode = __SkierHeight.find((t) => t.skierCode === skierCode);
    const skierCodeLetter = __AllSkierLetters[__AllSkierCodes.indexOf(skierCode)];
    return { skierCode, skierCodeLetter, dinCells, weightSkierCode, heightSkierCode };
  });
  return rows;
}

const __FirstNumber = /^to(\d+)$/;
const __RangeNumber = /^(\d+)-(\d+)$/;
const __LastNumber = /^(\d+)\+$/;
/**
 * @param {object} [opts]
 * @param {string} opts.label
 */
const parseValueFromLabel = (opts) => {
  if (!opts?.label) {
    return null;
  }
  const { label } = opts;
  let value;
  value = label.match(__FirstNumber)?.[1];
  if (!value) {
    value = label.match(__RangeNumber)?.[1];
  }

  if (!value) {
    value = label.match(__LastNumber)?.[1];
  }

  if (value) {
    return parseInt(value);
  }
  return null;
};
/** @type {Record<string, SAS2.DIN.TSkierTypeCode>} */
const __AbilityToType = {
  "type 0": 1,
  "type 1": 1,
  "type 2": 2,
  "type 3": 3,
};

/**
 * @param {TSkierCalcDIN} skier
 * @returns {number|null}
 */
export const getSkierBootSoleLength = (skier) => {
  if (skier.ownBootSole?.length) {
    // TODO: fix all of this Number calls, it shouldn't have to happen
    return Number(skier.ownBootSole.length);
  }

  if (typeof skier.selectedBoot?.soleLengths === "object") {
    const soleKey = skier.shoe.replace(/M|W|JR/g, "").replace(".", "_");
    // TODO: fix all of this Number calls, it shouldn't have to happen
    const value = Number(skier.selectedBoot.soleLengths[soleKey]);
    if (isNaN(value)) {
      return null;
    }
    return value;
  }

  return null;
};

/**
 * @param {TSkierCalcDIN} skier
 * @param {TConfigData} configData
 * @returns {ReturnType<typeof calculateDIN>|null}
 */
export function calculateSkierDIN(skier, configData) {
  const weight = parseValueFromLabel(
    configData.weight.kg.find(({ value }) => value === skier.weight),
  );
  const height = parseValueFromLabel(
    configData.height.cm.find(({ value }) => value === skier.height),
  );
  const age = parseValueFromLabel(configData.age.years.find(({ value }) => value === skier.age));
  const type = __AbilityToType[skier.ability];
  const bootSoleLength = getSkierBootSoleLength(skier);

  if (
    typeof weight !== "number" ||
    typeof height !== "number" ||
    typeof age !== "number" ||
    typeof type !== "number" ||
    typeof bootSoleLength !== "number"
  ) {
    return null;
  }

  return calculateDIN({ weight, height, age, type, bootSoleLength });
}
