import { useMemo } from "react";

import { twoDecimals } from "@snopro/common/numbers.js";
import { getOrderPaymentTransactionList } from "@snopro/common/order-utils.js";

import { asPrice } from "@/lib/numbers.js";
import { getAvailablePaymentMethods, getSavedCreditCard } from "@/lib/payment.js";

/**
 * @typedef {Object} TReturnOrderDetails
 * @property {boolean} hasBalance - Indicates if there is a balance.
 * @property {boolean} hasDiscount - Indicates if there is a discount.
 * @property {number} balance - The balance value.
 * @property {string} displayBalance - The formatted balance value with a dollar sign.
 * @property {number} orderFinalPrice - The total order value.
 * @property {string} displayOrderFinalPrice - The formatted total order value with a dollar sign.
 * @property {number} discount - The discount value.
 * @property {string} displayDiscount - The formatted discount value with a dollar sign.
 * @property {number} paidAmount - The paidAmount value.
 * @property {string} displayPaidAmount - The formatted paid value with a dollar sign.
 * @property {string} displayPaidAmount - The formatted paid value with a dollar sign.
 * @property {TPaymentMethod[]} paymentMethods - The available payment methods based on the order transactions.
 * @property {TOrderPaymentTransaction[]} transactions - The available payment methods based on the order transactions.
 * @property {ReturnType<typeof getSavedCreditCard>} savedCreditCard - The available payment methods based on the order transactions.
 *
 * @param {TOrderAndSkiersWithItems} order - The order object.
 * @returns {TReturnOrderDetails} - The object containing the order details.
 */
const useOrderDetails = (order) => {
  const balance = twoDecimals(order.balance);
  const hasBalance = balance > 0;
  const orderFinalPrice = twoDecimals(order.finalPrice);
  const discount = twoDecimals(order.totalDiscount);
  const hasDiscount = discount > 0;
  const paidAmount = twoDecimals(order.paidAmount);
  const transactions = useMemo(() => getOrderPaymentTransactionList(order), [order]);
  const savedCreditCard = useMemo(() => getSavedCreditCard(order.payment), [order.payment]);
  const paymentMethods = getAvailablePaymentMethods(savedCreditCard);

  return {
    hasBalance,
    hasDiscount,
    balance,
    discount,
    orderFinalPrice,
    paidAmount,
    displayBalance: asPrice(balance),
    displayOrderFinalPrice: asPrice(orderFinalPrice),
    displayDiscount: asPrice(discount),
    displayPaidAmount: asPrice(paidAmount),
    transactions,
    paymentMethods,
    savedCreditCard,
  };
};

export default useOrderDetails;
