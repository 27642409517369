import { db } from "@/services/firebase.js";

import { doc, getDoc } from "firebase/firestore";

import { ConfigDataSchema } from "@snopro/common/models.js";

/**
 * @type {Promise<TConfigData>|null}
 */
let __ConfigDataCache = null;

export const getConfigData = async () => {
  if (!__ConfigDataCache) {
    __ConfigDataCache = getDoc(doc(db, "data", "config")).then((doctoSnapshot) => {
      if (!doctoSnapshot.exists()) {
        throw new Error("Config data not found");
      }
      const parsed = ConfigDataSchema.safeParse(doctoSnapshot.data());
      if (!parsed.success) {
        console.error(parsed.error.flatten().fieldErrors);
        throw new Error("Config data is invalid");
      }
      return parsed.data;
    });
  }
  return __ConfigDataCache;
};
