import z from "zod";

import {
  OrderSchema,
  SkierSchema,
  SkierSkiPassSchema,
  CardDetailsSchema,
  OrderPaymentTransactionBaseSchema,
  UserSchema,
} from "./models.js";

export const UserAPICreatePayloadSchema = z.object({
  userName: z.string(),
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  role: z.union([z.literal("admin"), z.literal("manager"), z.literal("tech")]),
});
export const UserAPIChangePasswordPayloadSchema = z.object({
  uid: z.string(),
  newPassword: z.string(),
});
export const UserAPIUpdatePayloadSchema = z.object({
  uid: z.string(),
  isActive: z.boolean().optional(),
  firstName: UserSchema.shape.firstName.optional(),
  lastName: UserSchema.shape.lastName.optional(),
  isTrainingModeEnabled: UserSchema.shape.isTrainingModeEnabled.optional(),
  role: UserSchema.shape.role.optional(),
  image: UserSchema.shape.image.optional(),
});
const CreateOrderSkiPassPayloadSchema = SkierSkiPassSchema.pick({
  birthdate: true,
  id: true,
  startDate: true,
});
export const CreateOrderSkierPayloadSchema = SkierSchema.pick({
  firstName: true,
  lastName: true,
  gender: true,
  stance: true,
  ability: true,
  age: true,
  isJunior: true,
  height: true,
  weight: true,
  shoe: true,
  packageType: true,
  ownBoots: true,
  bootUpgrade: true,
  insurance: true,
  helmet: true,
  pants: true,
  jacket: true,
  extras: true,
  costs: true,
}).extend({
  skiPass: CreateOrderSkiPassPayloadSchema.optional().nullable(),
});
export const OrderAPICreatePayloadSchema = z.object({
  order: OrderSchema.pick({
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    referrer: true,
    addressDelivery: true,
    addressCollection: true,
    location: true,
    deliverTime: true,
    collectTime: true,
    skiDays: true,
    roomNumber: true,
    comments: true,
  }),
  skiers: z.array(CreateOrderSkierPayloadSchema),
  cardDetails: CardDetailsSchema.extend({ amount: z.number().min(10) }).optional(),
  paymentOverride: z
    .object({
      enforceCentsValue: z.number().int().min(0).max(99).optional(),
    })
    .optional(),
});

export const OrderSaveCustomerSignaturePayloadSchema = z.object({
  orderId: z.string(),
  signatureImage: z
    .string()
    .startsWith("data:image/webp;base64,")
    .or(z.string().startsWith("data:image/png;base64,")),
});

export const OrderRefundNegativeBalancePayloadSchema = z.object({
  orderId: z.string(),
  source: OrderPaymentTransactionBaseSchema.shape.source,
});

export const OrderUnpaidDeliveryPayloadSchema = z.object({
  orderId: z.string(),
  reason: z.string(),
});

export const OrderIdPayloadSchema = z.object({
  orderId: z.string(),
  ignoreUnrefundedPayments: z.boolean().optional(),
});
export const OrderAPIDeletePayloadSchema = OrderIdPayloadSchema;
export const OrderAPIRecalculatePayloadSchema = OrderIdPayloadSchema;
export const OrderAPIDuplicatePayloadSchema = OrderIdPayloadSchema;

export const OrderProcessPaymentPayloadSchema = z
  .object({
    source: OrderPaymentTransactionBaseSchema.shape.source,
    orderId: z.string(),
    sikerId: z.string().optional(),
    type: z.literal("credit-card"),
    cardDetails: CardDetailsSchema,
    amount: z.number().positive(),
    comments: z.string().optional(),
  })
  .or(
    z.object({
      source: OrderPaymentTransactionBaseSchema.shape.source,
      orderId: z.string(),
      sikerId: z.string().optional(),
      type: z.union([z.literal("saved-credit-card"), z.literal("cash"), z.literal("on-account")]),
      amount: z.number().positive(),
      comments: z.string().optional(),
    }),
  )
  .or(
    z.object({
      source: OrderPaymentTransactionBaseSchema.shape.source,
      orderId: z.string(),
      type: z.literal("refund"),
      amount: z.number().positive(),
      id: z.string(),
      comments: z.string().optional(),
    }),
  )
  .or(
    z.object({
      source: OrderPaymentTransactionBaseSchema.shape.source,
      orderId: z.string(),
      type: z.literal("refund"),
      amount: z.number().positive(),
      method: z.literal("on-account"),
      comments: z.string().optional(),
    }),
  );

export const LightspeedCreateSalesPayloadSchema = OrderIdPayloadSchema;
