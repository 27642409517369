import { Box, Image } from "@chakra-ui/react";

/**
 *
 * @param {Object} props
 * @param {string} [props.type]
 * @param {boolean} [props.isChecked]
 * @returns {JSX.Element}
 */
export default function CheckImage({ isChecked, type }) {
  const imageName = type?.split(" ").pop()?.toLowerCase();
  return (
    <Box align="center" boxSize={12} position="relative">
      {isChecked && (
        <Image boxSize={6} position="absolute" src={"/images/check.svg"} right="-6px" top="-4px" />
      )}
      {imageName && <Image src={`/images/${imageName}.svg`} />}
    </Box>
  );
}
