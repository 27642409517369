import { useEffect, useMemo, useState } from "react";

import { db } from "@/services/firebase.js";

import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { OrderSchema, SkierSchema } from "@snopro/common/models.js";
import {
  __AllOrderStatuses,
  compareSkierDefaultSorting,
  mapCalculatedOrder,
  orderStateToStatusDescription,
} from "@snopro/common/order-utils.js";

import { registerFetchedOrder } from "@/contexts/OrderModalContext.jsx";
import { useShowError } from "@/lib/error.js";
import {
  initSkiers,
  initialOrderOrUndefined,
  initialSupportCalls,
} from "@/lib/initialize-states.js";

/**
 * Hook that maps and memoizes the order and skiers to a new enhanced order object
 * @param {Object} props
 * @param {TOrder} [props.order]
 * @param {TSkier[]} [props.skiers]
 * @returns {{data?:TCalculatedOrder}}
 */
export const useCalculatedOrder = ({ order, skiers }) => {
  const orderV2 = useMemo(() => {
    if (!order) {
      return;
    }
    return mapCalculatedOrder({ order, skiers });
  }, [order, skiers]);

  return { data: orderV2 };
};

/**
 * Fetches the order and skiers for the given orderId
 * @param {Object} props
 * @param {string} [props.orderId]
 * @returns {{data?:TCalculatedOrder;}}
 */
export const useFetchOrderWithSkiers = ({ orderId }) => {
  const [order, setOrder] = useState(initialOrderOrUndefined());
  const [skiers, setSkiers] = useState(initSkiers());

  useEffect(() => {
    if (!orderId) {
      setOrder(initialOrderOrUndefined());
      return;
    }

    const orderUnsub = onSnapshot(
      doc(db, "orders", orderId),
      (doctoSnapshot) => {
        if (!doctoSnapshot.exists()) {
          setOrder(initialOrderOrUndefined());
          return;
        }
        /** @type {any} */
        const data = { ...doctoSnapshot.data(), id: doctoSnapshot.id };
        const parsed = OrderSchema.safeParse(data);
        // TODO: just until get all toDate() fixed
        if (!parsed.success) {
          console.warn(data.id, data, parsed.error.flatten().fieldErrors);
        }
        registerFetchedOrder(data);
        setOrder(data);
      },
      (error) => {
        console.error("Error getting order: ", error);
        setOrder(initialOrderOrUndefined());
      },
    );
    return orderUnsub;
  }, [orderId]);

  useEffect(() => {
    if (!orderId) {
      setSkiers([]);
      return;
    }

    const skiersUnsub = onSnapshot(
      query(collection(db, "orders", orderId, "skiers")),
      (querySnapshot) => {
        const items = querySnapshot.docs.map((doc) => {
          /** @type {any} */
          const data = { ...doc.data(), id: doc.id };
          // serverTimestamp fields are only updated after the first snapshot
          if (!data.created) {
            data.created = new Date();
          }
          const parsed = SkierSchema.safeParse(data);
          if (!parsed.success) {
            console.warn("skier", data.id, data, parsed.error.errors);
          }
          return data;
        });

        setSkiers(items.sort(compareSkierDefaultSorting));
      },
      (error) => {
        console.error("Error getting skiers: ", error);
        setSkiers([]);
      },
    );
    return skiersUnsub;
  }, [orderId]);

  const { data } = useCalculatedOrder({ order, skiers });
  return { data };
};
/**
 * @param {Object} props
 * @param {string} props.orderId
 * @returns {{supportCalls:TOrderSupportCall[]}}
 */
export const useOrderSupportCalls = ({ orderId }) => {
  const { showError } = useShowError({ title: "Error getting support calls" });
  const [supportCalls, setSupportCalls] = useState(initialSupportCalls());
  useEffect(() => {
    if (!orderId) {
      setSupportCalls(initialSupportCalls());
      return;
    }

    const unsub = onSnapshot(
      query(
        collectionGroup(db, "supportCalls"),
        where("orderId", "==", orderId),
        orderBy("driveTime", "asc"),
      ),
      (querySnapshot) => {
        /** @type {any[]} */
        const supportCalls = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setSupportCalls(supportCalls);
      },
      (error) => {
        showError(error);
        setSupportCalls(initialSupportCalls());
      },
    );
    return unsub;
  }, [orderId]);

  return { supportCalls };
};

/**
 * @returns {{ stateList:{state:TOrderState; statusLabel: string }[]}}
 */
export const useOrderState = () => {
  return {
    stateList: __AllOrderStatuses.map((state) => ({
      state,
      statusLabel: orderStateToStatusDescription(state),
    })),
  };
};

/**
 * @param {string} orderId
 * @returns {Promise<TOrder|undefined>}
 */
export const fetchOrderById = async (orderId) => {
  const orderDocto = await getDoc(doc(db, "orders", orderId));
  if (!orderDocto.exists) {
    return;
  }
  return OrderSchema.parse({ ...orderDocto.data(), id: orderDocto.id });
};
