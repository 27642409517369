import { useState } from "react";

import { useToast } from "@chakra-ui/react";

import { useShowError } from "@/lib/error.js";
import { deleteOrder } from "@/lib/order.api.js";

export const useDeleteOrder = () => {
  const { showError } = useShowError();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  /**
   * @param {string} orderId
   * @param {string} lookupId
   * @param {boolean} [ignoreUnrefundedPayments]
   * @returns {Promise<boolean>}
   */
  const processDeleteOrder = async (orderId, lookupId, ignoreUnrefundedPayments) => {
    setIsLoading(true);

    try {
      await deleteOrder(orderId, ignoreUnrefundedPayments);

      toast({
        title: `Order #${lookupId} deleted successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      return true;
    } catch (error) {
      showError(error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, processDeleteOrder };
};
