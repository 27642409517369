import { useState } from "react";

import {
  HStack,
  Heading,
  Spacer,
  Button,
  VStack,
  Textarea,
  FormLabel,
  Select,
  Switch,
  useDisclosure,
  Text,
  Icon,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from "@chakra-ui/react";

import { BsTextWrap } from "react-icons/bs/index.esm.js";
import { IoMdPeople } from "react-icons/io/index.esm.js";

import { db } from "@/services/firebase.js";

import { addDoc, collection, serverTimestamp } from "firebase/firestore";

import dayjs from "dayjs";

import { getUserAvatarByUID } from "@/contexts/AuthContext.jsx";
import useUserList from "@/lib/users.js";

import ChangeDateTimeModal from "../modals/ChangeDateTimeModal.jsx";

const UserAvatar = ({ user }) =>
  user.id && user.id !== "everyone" ? (
    <Avatar
      name={`${user.firstName} ${user.lastName}`}
      size="2xs"
      src={getUserAvatarByUID(user.id)}
      mr={2}
    />
  ) : (
    <Icon as={IoMdPeople} mr={2} />
  );

const UserSelect = ({ userList, recipient, setRecipient }) => {
  const handleSelect = (userId) => setRecipient(userId);

  const selectedUser = userList.find((user) => user.id === recipient) || {
    firstName: "Select a recipient...",
    lastName: "",
  };

  return (
    <Menu>
      <MenuButton as={Button} width={"full"}>
        <Box display="flex" alignItems="center">
          {selectedUser.id && <UserAvatar user={selectedUser} />}
          {selectedUser.firstName} {selectedUser.lastName}
        </Box>
      </MenuButton>
      <MenuList
        width={"full"}
        maxHeight="300px"
        overflowY="auto"
        sx={{ "::-webkit-scrollbar": { display: "none" } }}
      >
        {userList.map((user) => (
          <MenuItem key={user.id} onClick={() => handleSelect(user.id)}>
            <Box display="flex" alignItems="center">
              <UserAvatar user={user} />
              {user.firstName} {user.lastName}
            </Box>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default function NewMessage({ currentUserDetails, cancel }) {
  const [isTask, SetIsTask] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [recipient, setRecipient] = useState("");
  const [message, setMessage] = useState("");
  const dateSelectModal = useDisclosure();
  const { userList } = useUserList();

  function selectDate(date) {
    setSelectedDate(date);
  }

  function AddText(text) {
    setMessage(message + (message && "\n") + text);
  }

  async function createMessage() {
    await addDoc(collection(db, "alerts"), {
      fromUserId: currentUserDetails.id,
      fromUserName: currentUserDetails.firstName,
      text: message,
      timestamp: serverTimestamp(),
      dueDateTime: selectedDate,
      recipients: recipient == "everyone" ? userList.map((u) => u.id) : [recipient],
      messageType: isTask ? "task" : "message",
      completed: null,
    });
    cancel();
  }

  return (
    <VStack spacing={4} align="start">
      <HStack align="start" w="100%">
        <Heading size="md" mb={4}>
          New Message
        </Heading>
        <Spacer />
        <Button size="xs" onClick={cancel}>
          CANCEL
        </Button>
      </HStack>

      <UserSelect
        recipient={recipient}
        setRecipient={setRecipient}
        userList={[{ firstName: "Everyone", lastName: "", id: "everyone" }].concat(userList)}
      />

      <HStack>
        <FormLabel htmlFor="task" mb="0">
          Is this a task?
        </FormLabel>
        <Switch isChecked={isTask} id="task" onChange={(e) => SetIsTask(e.target.checked)} />
      </HStack>

      {isTask && (
        <Button size="sm" my={2} onClick={dateSelectModal.onOpen}>
          {selectedDate ? (
            <>{dayjs.tz(selectedDate).format("ddd DD MMM hh:mm")}</>
          ) : (
            <>Due date/time (optional)</>
          )}
        </Button>
      )}
      <Textarea
        value={message}
        placeholder="What's the message?"
        onChange={(e) => setMessage(e.target.value)}
      ></Textarea>
      <VStack align="start">
        <Text fontSize="2xs" fontWeight="bold">
          Add Preset Text
        </Text>
        <Text as="button" onClick={() => AddText("Back to base")}>
          <Icon as={BsTextWrap} pt="4px" /> Back to base
        </Text>
        <Text as="button" onClick={() => AddText("Priorities changed")}>
          <Icon as={BsTextWrap} pt="4px" /> Priorities changed
        </Text>
        <Text as="button" onClick={() => AddText("Order details updated")}>
          <Icon as={BsTextWrap} pt="4px" /> Order details updated
        </Text>
      </VStack>

      <Button colorScheme="brand" isDisabled={!recipient || !message} onClick={createMessage}>
        Send Message
      </Button>

      {dateSelectModal.isOpen && (
        <ChangeDateTimeModal
          isOpen={dateSelectModal.isOpen}
          onClose={dateSelectModal.onClose}
          dateCallback={selectDate}
          date={
            selectedDate
              ? selectedDate
              : dayjs.tz().hour(7).minute(0).second(0).millisecond(0).toDate()
          }
        />
      )}
    </VStack>
  );
}
