import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import { useShowError } from "@/lib/error.js";
import { updateSkier } from "@/lib/skier.api.js";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {TSkier} props.skier
 * @param {string} props.orderId
 * @param {TOrderChangeSource} [props.source]
 * @returns {JSX.Element}
 */
export default function BootSizeModal({ isOpen, onClose, skier, orderId, source }) {
  const { showError } = useShowError();
  const lookup = useConfig();
  const sizeData = lookup.shoe[skier.gender === "Female" ? "female" : "male"].mondo;

  /**
   * @param {string} size
   * @returns {Promise<void>}
   * */
  async function setBootSize(size) {
    try {
      await updateSkier(orderId, skier.id, { shoe: size }, source);
      onClose();
    } catch (error) {
      showError(error);
    }
  }

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {skier && (
        <ModalContent>
          <ModalHeader>Select Mondo Boot Size ({skier.gender})</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={3} spacing={2}>
              {Array.from(sizeData).map((size) => (
                <Box key={size.value} align="center">
                  <Divider mb={1} />
                  <Text as="button" fontWeight="bold" onClick={() => setBootSize(size.value)} p={2}>
                    {size.value}
                  </Text>
                </Box>
              ))}
              <Divider />
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onClose}>
              CANCEL
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
}
