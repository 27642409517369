import { FormControl, FormLabel, Grid, GridItem, HStack, Select } from "@chakra-ui/react";

import { zodResolver } from "@hookform/resolvers/zod";
import { isValidCardNumber } from "@snopro/common/card.js";
import { CardDetailsSchema } from "@snopro/common/models.js";
import { twoDecimals } from "@snopro/common/numbers.js";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import FormActions from "@/components/payment/FormActions.jsx";
import ValidatedInput from "@/components/payment/ValidatedInput.jsx";
import useOrderDetails from "@/hooks/useOrderDetails.js";
import usePaymentProcessing from "@/hooks/usePaymentProcessing.js";

import AmountFormControl from "../AmountFormControl.jsx";

/**
 *
 * @param {number} maxAmount
 * @returns
 */
const createCreditCardFormSchema = (maxAmount) => {
  return CardDetailsSchema.extend({
    number: z
      .string()
      .trim()
      .refine(
        (value) => {
          const valid = isValidCardNumber(value);
          return valid;
        },
        {
          message: "Invalid card number",
        },
      ),
    CVN: z.string().refine((s) => s.length >= 3 && s.length <= 4 && /^\d+$/.test(s), {
      message: "CVN must be a number of 3 to 4 digits",
    }),
    amount: z.coerce.number().min(0.01).max(maxAmount),
  });
};

/**
 * CreditCardForm component.
 * Renders a credit card form for making a payment.
 * @param {Object} props - The component props.
 * @param {TOrderV2} props.order - The order object.
 * @param {() => void} props.onCancel - The function to close the modal.
 * @param {() => void} props.onComplete - The function to close the modal.
 * @param {TOrderPaymentTransactionSource} props.source - The source of the payment.
 * @returns {JSX.Element} The CreditCardForm component.
 */
function CreditCardForm({ onCancel, order, onComplete, source }) {
  const { isLoading, processOrderPayment } = usePaymentProcessing({ order, source, onComplete });
  const { balance } = useOrderDetails(order);

  const methods = useForm({
    resolver: zodResolver(createCreditCardFormSchema(twoDecimals(balance))),
    shouldFocusError: false,
    mode: "all",
    defaultValues: {
      number: "",
      expiryMonth: "",
      expiryYear: "",
      CVN: "",
      firstName: order.firstName?.trim() ?? "",
      lastName: order.lastName?.trim() ?? "",
      amount: twoDecimals(balance),
    },
  });

  const onSubmit = async (data) => {
    await processOrderPayment({
      amount: data.amount,
      type: "credit-card",
      cardDetails: {
        number: data.number,
        expiryMonth: data.expiryMonth,
        expiryYear: data.expiryYear,
        CVN: data.CVN,
        firstName: data.firstName,
        lastName: data.lastName,
      },
    });
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid templateColumns="repeat(16, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={8}>
            <FormControl isRequired>
              <FormLabel fontWeight={"bold"}>Card Number</FormLabel>
              <ValidatedInput name="number" inputMode="numeric" type="number" />
            </FormControl>
          </GridItem>
          <GridItem colSpan={8}>
            <HStack>
              <FormControl isRequired>
                <FormLabel fontWeight={"bold"}>Card Expiry</FormLabel>
                <HStack width={"220px"}>
                  <Select
                    {...register("expiryMonth", { valueAsNumber: true })}
                    defaultValue={""}
                    isInvalid={!!errors.expiryMonth}
                    size={"lg"}
                    required
                    isRequired
                  >
                    <option value="" disabled>
                      MM
                    </option>
                    {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                      <option key={month} value={month}>
                        {month.toString().padStart(2, "0")}
                      </option>
                    ))}
                  </Select>
                  <Select
                    {...register("expiryYear", { valueAsNumber: true })}
                    defaultValue={""}
                    isInvalid={!!errors.expiryYear}
                    size={"lg"}
                    required
                  >
                    <option value="" disabled>
                      YYYY
                    </option>
                    {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i).map(
                      (year) => (
                        <option key={year} value={year - 2000}>
                          {year}
                        </option>
                      ),
                    )}
                  </Select>
                </HStack>
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontWeight={"bold"}>CVN</FormLabel>
                <ValidatedInput name="CVN" inputMode="numeric" />
              </FormControl>
            </HStack>
          </GridItem>
          <GridItem colSpan={8}>
            <FormControl isRequired>
              <FormLabel fontWeight={"bold"}>First Name</FormLabel>
              <ValidatedInput name="firstName" />
            </FormControl>
          </GridItem>
          <GridItem colSpan={8}>
            <FormControl isRequired>
              <FormLabel fontWeight={"bold"}>Last Name</FormLabel>
              <ValidatedInput name="lastName" />
            </FormControl>
          </GridItem>
          <GridItem colSpan={8}>
            <AmountFormControl maxAmount={twoDecimals(balance)} />
          </GridItem>
        </Grid>
        <FormActions isLoading={isLoading} isValid={isValid} onCancel={onCancel} />
      </form>
    </FormProvider>
  );
}

export default CreditCardForm;
