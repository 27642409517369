import { createIcon } from "@chakra-ui/icons";

export const SkisIcon = createIcon({
  displayName: "SkisIcon",
  viewBox: "0 0 24 24",
  path: [
    <path d="M4.66,16.94h0a.87.87,0,0,1,0-1.23l4.23-4c1-.91,1.91-1.86,2.82-2.82l4-4.23a.87.87,0,0,1,1.23,0h0a.86.86,0,0,1,0,1.22l-4.23,4q-1.46,1.37-2.82,2.82l-4,4.23A.86.86,0,0,1,4.66,16.94Z" />,
    <path d="M7.06,19.34h0a.86.86,0,0,1,0-1.22l4.23-4q1.46-1.37,2.82-2.82l4-4.23a.86.86,0,0,1,1.22,0h0a.87.87,0,0,1,0,1.23l-4.23,4c-1,.91-1.91,1.86-2.82,2.82l-4,4.23A.87.87,0,0,1,7.06,19.34Z" />,
  ],
});
