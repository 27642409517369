import { Box, Button, Divider, HStack, Text, VStack, Icon } from "@chakra-ui/react";

import { BiWrench } from "react-icons/bi/index.esm.js";
import { HiExclamation } from "react-icons/hi/index.esm.js";

import { db } from "@/services/firebase.js";

import { doc, updateDoc, serverTimestamp } from "firebase/firestore";

import dayjs from "@/lib/datetime.js";

/**
 *
 * @param {object} props
 * @param {object} props.alert
 * @param {boolean} [props.isUnread]
 * @returns
 */
export default function Alert({ alert, isUnread }) {
  async function dismissAlert() {
    const docRef = doc(db, "alerts", alert.id);
    await updateDoc(docRef, { completed: serverTimestamp() });
  }

  return (
    <VStack spacing="0" align="start" width="100%">
      <HStack align="start" width="100%">
        <Box boxSize={1}>{isUnread && <Text color="green.500">●</Text>}</Box>
        <Text>
          {alert.messageType == "task" && (
            <Icon as={BiWrench} boxSize={5} top="4px" position="relative" mr={1} />
          )}
          {alert.text}{" "}
          <Button
            size="xs"
            onClick={dismissAlert}
            colorScheme={alert.messageType == "task" ? "brand" : "gray"}
          >
            {alert.messageType == "message" ? "dismiss" : "DONE"}
          </Button>
        </Text>
      </HStack>
      {alert.messageType == "task" && alert.dueDateTime && (
        <HStack>
          <Text fontSize="xs" fontWeight="bold" m="0" pb={0} pl={3}>
            Due: {dayjs.tz(alert.dueDateTime.toDate()).format("ddd, MMM D, h:mma")}
          </Text>
          {dayjs.tz(alert.dueDateTime.toDate()) < dayjs.tz() && (
            <Icon as={HiExclamation} boxSize={5} color="red.500" />
          )}
        </HStack>
      )}
      <Box fontSize="xs" color="gray.300" pb={2} pl={3}>
        from{" "}
        <Text as="span" fontWeight="bold">
          {alert.fromUserName}
        </Text>
        , {dayjs.tz(alert.timestamp?.toDate()).format("ddd, MMM D, h:mma")}
      </Box>

      <Divider></Divider>
    </VStack>
  );
}
