import { Box, SlideFade } from "@chakra-ui/react";

import CheckExtras from "../checks/CheckExtras.jsx";
import CheckSkierInfo from "../checks/CheckSkierInfo.jsx";
import RowCheck from "../checks/RowCheck.jsx";
import SkierGrid from "../common/SkierGrid.jsx";
import SkierRow from "../common/SkierRow.jsx";
import StepHeader from "../common/StepHeader.jsx";

/**
 * @param {object} props
 * @param {TOrderAndSkiersWithItems} [props.order]
 */
export default function StepPackExtras({ order }) {
  function isRowChecked(skier) {
    let result = true;
    skier?.extras?.every((extra) => {
      if (!skier?.progress?.["pack" + extra.code]) {
        result = false;
        return false;
      } else {
        return true;
      }
    });
    return result;
  }

  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <Box width="1000px">
        <StepHeader
          customerName={order?.firstName + " " + order?.lastName}
          orderId={order?.id ?? ""}
          previousOrderId={order?.previousOrder}
          referrer={order?.referrer.name ?? ""}
        />
        <SkierGrid templateColumns="3fr 1fr 1fr 1fr 1fr">
          {order?.skiers.map((skier) => (
            <SkierRow key={skier.id} cols={5}>
              <CheckSkierInfo skier={skier} showIsOutOfRange />

              <CheckExtras skier={skier} orderId={order.id} type="goggle" prefix="pack" />
              <CheckExtras skier={skier} orderId={order.id} type="glove" prefix="pack" />
              <CheckExtras skier={skier} orderId={order.id} type="sock" prefix="pack" />

              <RowCheck completed={isRowChecked(skier)} />
            </SkierRow>
          ))}
        </SkierGrid>
      </Box>
    </SlideFade>
  );
}
