import { ChangeEventHandler } from "react";

import { GridItem, Select } from "@chakra-ui/react";

import { db } from "@/services/firebase.js";

import {
  doc,
  updateDoc,
  setDoc,
  deleteField,
  writeBatch,
  WriteBatch,
  FieldValue,
  serverTimestamp,
} from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";

import { skierChanges } from "@/lib/skier.api.js";

/**
 * @param {Object} props
 * @param {TOrder} props.order
 * @param {TSkier} props.skier
 * @param {TTypeOfCosts} [props.type]
 * @param {number} props.adjustment
 * @param {string} [props.position]
 * @param {string} props.skierId
 * @param {number} props.skiDays
 * @param {TSkierItem} props.item
 * @param {boolean} [props.hidePosition]
 * @param {boolean} [props.hideDayAdjuster]
 * @param { (number) => Promise<void> } [props.onChangeDayAdjustment]
 * @returns {JSX.Element}
 */
export default function DayAdjuster({
  skier,
  type,
  adjustment,
  position,
  order,
  skierId,
  skiDays,
  hidePosition,
  onChangeDayAdjustment,
  hideDayAdjuster,
}) {
  // TODO: move to skier.api.js

  async function changeDays(e) {
    const value = e.target.value ? Number(e.target.value) : 0;
    if (onChangeDayAdjustment) {
      await onChangeDayAdjustment(value);
      return;
    }
    const skierDoc = doc(db, "orders", order.id, "skiers", skierId);
    let changes = {};

    if (!adjustment) {
      //default to "end" for new adjustment
      changes = {
        ["adjustments." + type + "_days"]: parseInt(e.target.value),
      };
      if (type === "packageType") {
        changes["adjustments." + type + "_pos"] = "end";
      }
    } else if (e.target.value == "") {
      //clear both if "clear" selected
      changes = {
        ["adjustments." + type + "_days"]: deleteField(),
      };
      if (type === "packageType") {
        changes["adjustments." + type + "_pos"] = deleteField();
      }
    } else {
      changes = {
        ["adjustments." + type + "_days"]: parseInt(e.target.value),
      };
    }
    const batch = writeBatch(db);
    batch.update(skierDoc, changes);
    await updateAllocations(Number(e.target.value) || 0, position || "end", batch);
    await skierChanges(order.id, skierId, null, "update", changes, batch);
    await batch.commit();
  }

  // TODO: move to skier.api.js and order/changes/collections
  async function changePosition(e) {
    if (type !== "packageType") {
      throw new Error("Only packageType can have a position");
    }
    const batch = writeBatch(db);
    const skierDoc = doc(db, "orders", order.id, "skiers", skierId);
    const udpdate = { ["adjustments." + type + "_pos"]: e.target.value };
    batch.update(skierDoc, udpdate);

    await updateAllocations(Number(adjustment), e.target.value, batch);
    await skierChanges(order.id, skierId, null, "update", udpdate, batch);

    await batch.commit();
  }

  // TODO: move to skier.api.js
  /**
   * @param {*} adjustment
   * @param {*} position
   * @param {WriteBatch} batch
   */
  async function updateAllocations(adjustment, position, batch) {
    if (!skier.selectedHardware || type !== "packageType") {
      return;
    }
    //make positive for simplicity
    //see similar code in AvailabilityModal and cloud function
    const dayAdjustStart = position == "start" ? -adjustment : 0;
    const dayAdjustEnd = position == "end" ? -adjustment : 0;

    const skierStart = dayjs.tz(getFieldAsDate(order.deliverTime)).add(dayAdjustStart, "day");
    const skierEnd = dayjs.tz(getFieldAsDate(order.collectTime)).add(-dayAdjustEnd, "day");

    const skierDays = order.skiDays - dayAdjustStart - dayAdjustEnd;
    const totalDays = skierStart.hour() >= 13 ? skierDays + 1 : skierDays;

    const skiDates = [];
    for (let i = 0; i < totalDays; i++) {
      skiDates.push(skierStart.add(i, "day").format("YYYYMMDD"));
    }
    /** @type {SAS2.firebase.TAllocationUpdate} */
    const update = {
      skiDays: skierDays,
      start: skierStart.toDate(),
      end: skierEnd.toDate(),
      skierId,
      orderId: order.id,
      adjusted: skierDays != order.skiDays,
      dates: skiDates,
      updatedAt: serverTimestamp(),
    };
    batch.set(doc(db, "allocations", skierId), update, { merge: true });
  }

  const setOfDays = new Set(new Array(Math.max(order.skiDays, 0)).fill(0).map((_, i) => -(i + 1)));
  if (adjustment < 0) {
    setOfDays.add(adjustment);
  }
  const listOfDayAdjustments = Array.from(setOfDays).sort((l, r) => r - l);

  return (
    <>
      <GridItem fontSize="sm" justifySelf="flex-end">
        {!hideDayAdjuster && skiDays > 0 && (
          <Select value={adjustment ?? "0"} size="xs" onChange={changeDays}>
            <option key="0" value="0">
              {adjustment ? "clear" : "adjust"}
            </option>
            {listOfDayAdjustments.map((i) => (
              <option key={i + 1} value={`${i}`}>
                {i} day
              </option>
            ))}
          </Select>
        )}
      </GridItem>

      <GridItem fontSize="sm">
        {hidePosition !== true && adjustment && type === "packageType" ? (
          <Select value={position} size="xs" onChange={changePosition}>
            <option value="start">at start</option>
            <option value="mid">during</option>
            <option value="end">at end</option>
          </Select>
        ) : (
          <></>
        )}
      </GridItem>
    </>
  );
}
