import { useState } from "react";

import { useToast } from "@chakra-ui/react";

import { twoDecimals } from "@snopro/common/numbers.js";

import { useShowError } from "@/lib/error.js";
import { refundPaymentAPI } from "@/lib/payments.api.js";

/**
 *
 * @param {object} props
 * @param {TOrder} props.order
 * @param {TOrderPaymentTransactionSource} props.source
 * @param {() => void} props.onComplete
 */
const useRefundProcessing = ({ order, source, onComplete }) => {
  const { showError } = useShowError();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const toast = useToast();

  /**
   * @param {object} refundDetails
   * @param {string} refundDetails.id
   * @param {number} refundDetails.amount
   * @param {string} refundDetails.orderId
   * @param {string} refundDetails.comments
   * @returns {Promise<void>}
   */
  const processRefund = async (refundDetails) => {
    setIsLoading(true);
    try {
      if (order.state === "inactive") {
        throw new Error("Order is inactive");
      }

      if (!refundDetails) {
        throw new Error("No transaction selected");
      }

      await refundPaymentAPI({
        id: refundDetails.id,
        source,
        amount: twoDecimals(refundDetails.amount),
        orderId: order.id,
        comments: refundDetails.comments,
      });

      toast({
        title: "Refund completed successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsError(false);
      onComplete();
    } catch (error) {
      showError(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, isError, processRefund };
};

export default useRefundProcessing;
