import { useRef, useImperativeHandle, forwardRef, useEffect, useState } from "react";

import { Box, Button, VStack } from "@chakra-ui/react";

import SignatureCanvas from "react-signature-canvas";

const CustomerSignature = forwardRef(
  /**
   * CustomerSignature component.
   *
   * @component
   * @param {Object} props - Component props.
   * @param {function} props.onSignatureChange - Callback function that is called when the signature changes.
   * @param {Object} ref - Ref object.
   *
   * @example
   * <CustomerSignature onSignatureChange={handleSignatureChange} ref={signatureRef} />
   */ ({ onSignatureChange = () => {} }, ref) => {
    const sigCanvas = useRef({});
    const [isEmpty, setIsEmpty] = useState(true);

    const saveSignature = () => sigCanvas.current.getTrimmedCanvas().toDataURL("image/webp");
    const clearSignature = () => {
      sigCanvas.current.clear();
      setIsEmpty(true);
    };

    useImperativeHandle(ref, () => ({
      saveSignature,
    }));

    useEffect(() => {
      const sigPad = sigCanvas.current.getSignaturePad();

      const handleEnd = () => {
        setIsEmpty(sigPad.isEmpty());
      };

      sigPad.onEnd = handleEnd;

      // Clean up function to remove event listener
      return () => {
        sigPad.offEnd = handleEnd;
      };
    }, [sigCanvas, onSignatureChange]);

    useEffect(() => {
      onSignatureChange(isEmpty);
    }, [onSignatureChange, isEmpty]);

    return (
      <VStack spacing={2}>
        <Box borderColor="black" borderWidth={1} width="max-content">
          <SignatureCanvas
            ref={sigCanvas}
            canvasProps={{
              width: 600,
              height: 200,
            }}
          />
        </Box>
        <Button size={"sm"} onClick={clearSignature} isDisabled={isEmpty}>
          Clear
        </Button>
      </VStack>
    );
  },
);

export default CustomerSignature;
