import {
  Text,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  HStack,
  VStack,
  Icon,
  Button,
  Box,
} from "@chakra-ui/react";

import { FaMapMarkerAlt } from "react-icons/fa/index.esm.js";
import { FiEdit } from "react-icons/fi/index.esm.js";

/**
 *
 * @param {Object} props
 * @param {keyof TOrder} props.fieldName
 * @param {string} [props.value]
 * @param {string} props.displayName
 * @param {string} props.displayType
 * @param {Function} props.handleInput
 * @param {Function} props.handleUpdate
 * @param {TLocation} [props.location]
 * @param {boolean} [props.isSupportCall]
 * @param {boolean} [props.isDisabled]
 * @param {boolean} [props.isReadOnly]
 * @param {string} [props.width]
 * @returns {JSX.Element}
 */
export default function EditableField({
  fieldName,
  value,
  displayName,
  displayType,
  handleInput,
  handleUpdate,
  location,
  isSupportCall,
  isDisabled,
  width,
}) {
  const handleChange = (val) => {
    handleInput(fieldName, val);
  };

  const updateField = (val) => {
    handleUpdate(fieldName, val);
  };

  return (
    <VStack spacing={1} align="start" mb={4}>
      <Box width={width}>
        <HStack>
          <Text fontSize={10} fontWeight="bold" mb={-1}>
            {displayName}
          </Text>
          {!isDisabled && <Icon boxSize={3} as={FiEdit} position="relative" top="1px" />}
        </HStack>
        <Editable
          value={value}
          placeholder="---"
          onChange={handleChange}
          onSubmit={updateField}
          isDisabled={isDisabled}
          sx={{ whiteSpace: "pre-line" }}
        >
          <EditablePreview />
          {displayType == "textarea" ? <EditableTextarea /> : <EditableInput />}
        </Editable>
      </Box>
      <Box>
        {(fieldName == "addressDelivery" || fieldName == "addressCollection") && !isSupportCall && (
          <Button
            size="xs"
            leftIcon={<FaMapMarkerAlt />}
            onClick={() =>
              window.open(
                "https://www.google.com.au/maps/search/" + encodeURI(value + " " + location),
                "_blank",
              )
            }
          >
            map
          </Button>
        )}
      </Box>
    </VStack>
  );
}
