import { Grid } from "@chakra-ui/react";

export default function SkierGrid({ children, templateColumns }) {
  return (
    <Grid
      templateColumns={templateColumns}
      alignItems="center"
      gap={2}
      justifyItems="center"
      mb={6}
    >
      {children}
    </Grid>
  );
}
