import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import PaymentOptions from "@/components/payment/PaymentOptions.jsx";
import useOrderDetails from "@/hooks/useOrderDetails.js";

/**
 * PaymentModal component.
 * Renders a modal for making a payment.
 * @param {Object} props - The component props.
 * @param {TOrderV2} props.order - The order object.
 * @param {boolean} props.isOpen - Whether the modal is open or not.
 * @param {() => void} props.onClose - The function to close the modal.
 * @param {TOrderPaymentTransactionSource} props.source - The source of the payment.
 * @returns {JSX.Element} The PaymentModal component.
 */
export default function PaymentModal({ order, isOpen, onClose, source }) {
  const { hasBalance, displayBalance } = useOrderDetails(order);

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Make a payment {hasBalance && `(${displayBalance} left to pay)`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={3}>
          <PaymentOptions order={order} source={source} onCancel={onClose} onComplete={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
