import { functions } from "@/services/firebase.js";

import { httpsCallable } from "firebase/functions";

import {
  OrderProcessPaymentPayloadSchema,
  OrderRefundNegativeBalancePayloadSchema,
} from "@snopro/common/requests.js";

const PaymentsAPICallable = httpsCallable(functions, "PaymentsAPI");

/**
 * @param {SAS2.API.TPaymentsAPI} data
 * @returns {Promise<any>}
 */
const paymentsApi = async (data) => {
  const response = await PaymentsAPICallable(data);
  return response.data;
};

const __encryptKey = import.meta.env.VITE_APP_EWAY_WEB_ENCRYPT_KEY;
/**
 * @param {SAS2.API.TOrderProcessPaymentPayload} data
 * @returns {Promise<void>}
 */
export const processPaymentAPI = async (data) => {
  if (data.type === "credit-card") {
    data.cardDetails.CVN = window.eCrypt.encryptValue(data.cardDetails.CVN, __encryptKey);
    data.cardDetails.number = window.eCrypt.encryptValue(data.cardDetails.number, __encryptKey);
  }
  await paymentsApi({
    operation: "process-payment",
    payload: OrderProcessPaymentPayloadSchema.parse(data),
  });
};

/**
 * @param {{orderId: string; amount:number; id:string; source: TOrderPaymentTransactionSource; comments: string }} data
 * @returns {Promise<void>}
 */
export const refundPaymentAPI = async (data) => {
  /** @type {SAS2.API.TOrderProcessPaymentPayload} */
  const payload = {
    type: "refund",
    orderId: data.orderId,
    amount: data.amount,
    id: data.id,
    source: data.source,
    comments: data.comments,
  };

  await paymentsApi({
    operation: "process-payment",
    payload: OrderProcessPaymentPayloadSchema.parse(payload),
  });
};

/**
 * @param {{orderId: string; source: TOrderPaymentTransactionSource}} data
 * @returns {Promise<void>}
 */
export const refundNegativeBalanceAPI = async (data) => {
  /** @type {SAS2.API.TOrderRefundNegativeBalancePayload} */
  const payload = { orderId: data.orderId, source: data.source };
  await paymentsApi({
    operation: "refund-negative-balance",
    payload: OrderRefundNegativeBalancePayloadSchema.parse(payload),
  });
};
