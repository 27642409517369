export default function calcPoleSize(height) {
  return data[height];
}

const data = {
  1: "n/a",
  2: "n/a",
  3: "85mm",
  4: "85mm",
  5: "90mm",
  6: "95mm",
  7: "100mm",
  8: "105mm",
  9: "105mm",
  10: "110mm",
  11: "110mm",
  12: "115mm",
  13: "115mm",
  14: "120mm",
  15: "125mm",
  16: "130mm",
  17: "135mm",
};
