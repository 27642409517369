import { useState, useEffect } from "react";

import { Text, SlideFade, Grid, GridItem, Icon } from "@chakra-ui/react";

import EditableField from "@/components/common/EditableField.jsx";
import { SkisIcon } from "@/components/common/SkisIcon.jsx";
import { SnowboardIcon } from "@/components/common/SnowboardIcon.jsx";
import { useConfig } from "@/contexts/ConfigContext.jsx";
import { useShowError } from "@/lib/error.js";
import { asPrice } from "@/lib/numbers.js";
import { sundriesByType } from "@/lib/order-ui-utils.js";
import { updateOrderField } from "@/lib/order.api.js";

import CheckSkierInfo from "../checks/CheckSkierInfo.jsx";
import SkierGrid from "../common/SkierGrid.jsx";
import SkierRow from "../common/SkierRow.jsx";
import StepHeader from "../common/StepHeader.jsx";

/**
 * @param {object} props
 * @param {TOrderAndSkiersWithItems} props.order
 * @returns {JSX.Element}
 */
export default function StepCollectInfo({ order: orderIn }) {
  const { showError } = useShowError({ title: "Order Update Error" });
  const config = useConfig();
  const [order, setOrder] = useState(orderIn);

  useEffect(() => setOrder(orderIn), [orderIn]);

  const handleInputChange = (name, value) => setOrder({ ...order, [name]: value });

  const updateField = async (name, value) => {
    await updateOrderField(order, name, value, "step-collection").catch((error) => {
      setOrder({ ...orderIn });
      showError(error);
    });
  };

  if (!order) {
    return <></>;
  }

  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <Grid gridTemplateColumns={"1fr 1fr"} gap={24} width="1000px">
        <GridItem>
          <StepHeader
            customerName={order.firstName + " " + order.lastName}
            orderId={order.id}
            referrer={order.referrer.name}
          />
          <SkierGrid templateColumns="4fr 1fr 0.5fr">
            {order.skiers.map((skier) => {
              const hasSundriesRentals = sundriesByType(config, skier, "Rental").length > 0;
              return (
                <SkierRow key={skier.id} cols={3}>
                  <CheckSkierInfo
                    skier={skier}
                    sundriesWarning={hasSundriesRentals ? "Additional sundries items" : undefined}
                  />
                  {skier.packageType ? (
                    skier.packageType.split(" ").pop() == "Ski" ? (
                      <Icon as={SkisIcon} boxSize={16} r={2} />
                    ) : (
                      <Icon as={SnowboardIcon} boxSize={16} />
                    )
                  ) : (
                    <Text fontSize="xs">no package</Text>
                  )}
                  <Text> </Text>
                </SkierRow>
              );
            })}
          </SkierGrid>
        </GridItem>
        <GridItem>
          <EditableField
            fieldName="addressCollection"
            value={order.addressCollection}
            displayName="Collect from"
            displayType="textarea"
            handleInput={handleInputChange}
            handleUpdate={updateField}
            location={order.location}
          />

          <EditableField
            fieldName="roomNumber"
            value={order.roomNumber}
            displayName="Room Number"
            displayType="text"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />

          <EditableField
            fieldName="comments"
            value={order.comments}
            displayName="Customer Comments"
            displayType="textarea"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />
          <EditableField
            fieldName="commentsInternal"
            value={order.commentsInternal}
            displayName="Internal Comments"
            displayType="textarea"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />

          <Text fontSize={10} fontWeight="bold" mb={-1} mt={4}>
            Paid Amount
          </Text>
          <Text mt={1} mb={4}>
            {asPrice(order.paidAmount)} ({order.paidStatus})
          </Text>
        </GridItem>
      </Grid>
    </SlideFade>
  );
}
