import { deepMapAllProps } from "./objects.js";

/**
 * @param {any} value
 * @returns {undefined|Date}
 * */
let __getAsDate = (value) => undefined;

/** @param {{ getAsDate: typeof __getAsDate}} opts*/
export const init = ({ getAsDate }) => {
  __getAsDate = getAsDate;
};
/**
 * Convert Firestore document data to a plain JavaScript object format.
 * @param {any} obj The Firestore document data to be converted.
 * @returns {{ mapped: true; value: any } | undefined} The converted object, or undefined if no conversion was necessary.
 */
const convertFirestoreDoctoToObj = (obj) => {
  const date = __getAsDate(obj);
  if (date) {
    return { mapped: true, value: date };
  }

  if (obj?._seconds !== undefined && obj?._nanoseconds !== undefined) {
    // TODO: very unlikely to be used, should I do something with the timestamp?
    // return {
    //   mapped: true,
    //   value: new Timestamp(obj?._seconds, obj?._nanoseconds).toDate().toISOString(),
    // };
  }
};

/**
 * Deeply maps Firestore document data to a plain JavaScript object format.
 *
 * @param {any} obj The Firestore document data to be converted.
 * @returns {any} The converted object.
 */
export const deepFirestoreDoctoToObject = deepMapAllProps.bind(null, convertFirestoreDoctoToObj);

export const getFieldAsDate = (val) => {
  if (!val) {
    return val;
  }
  if (val instanceof Date) {
    return val;
  }
  if (val.toDate) {
    return val.toDate();
  }
  return val;
};
