import { db } from "./firebase.js";

import { collection, doc, serverTimestamp, writeBatch } from "firebase/firestore";

import { BaseActivitySchema } from "@snopro/common/models.js";

import { SASUserSignal } from "@/contexts/AuthContext.jsx";
import { getLocationConfigByLocationId } from "@/lib/locationLib.js";

/**
 * @param {TAllActivityTypes} type
 * @param {string} text
 * @param {string} [orderId]
 * @param {TActivityOrderInfo} [order]
 * @param {TLocation} [location]
 * @param {TUserV2|null} [user]
 * @param {import("firebase/firestore").WriteBatch} [firestoreBatch]
 * @returns {Promise<void>}
 */
export async function saveActivity(type, text, orderId, order, location, user, firestoreBatch) {
  const batch = firestoreBatch || writeBatch(db);
  user = user || SASUserSignal.value;
  location = location || user?.defaultLocation;
  const orderInfoParsed = BaseActivitySchema.shape.orderInfo.safeParse(order);
  let orderInfo = null;
  if (orderInfoParsed.success) {
    orderInfo = orderInfoParsed.data;
  } else {
    console.error("[saveActivity]: Failed to parse order info", orderInfoParsed.error.flatten());
  }
  const activityDocto = doc(collection(db, "activity"));

  batch.set(activityDocto, {
    id: activityDocto.id,
    type,
    text,
    ...(orderId && { orderId, order: orderId }),
    time: serverTimestamp(),
    ...(user && {
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.id,
      },
    }),
    location,
    ...(orderInfo ? { orderInfo } : {}),
  });
  if (!firestoreBatch) {
    await batch.commit();
  }
}
/**
 * @param {TStepFlowType} stepFlow
 * @param {TOrder} order
 * @param {import("firebase/firestore").WriteBatch} [firestoreBatch]
 * @returns {Promise<void>}
 */
export async function registerActivitySteFlow(stepFlow, order, firestoreBatch) {
  let extraText = "";
  /** @type {Parameters<typeof saveActivity>[0]} */
  let activityType;
  let activityTypeDescription = "";
  const user = SASUserSignal.value;
  const { inStore } = getLocationConfigByLocationId(order.location);
  switch (stepFlow) {
    case "size":
      activityType = "sized";
      activityTypeDescription = "allocated";
      break;
    case "pack":
      activityType = "packed";
      activityTypeDescription = "packed";
      break;
    case "deliver":
      if (order.unpaidDelivery) {
        extraText = `(no pay - ${order.unpaidDelivery.reason})`;
      } else if (order.balance > 0) {
        extraText = "(outstanding balance)";
      } else if (order.balance < 0) {
        extraText = "(refund)";
      }
      activityType = "delivered";
      activityTypeDescription = inStore ? "fitted" : "delivered";
      break;
    case "collect":
      activityType = "collected";
      activityTypeDescription = inStore ? "returned" : "collected";
      break;
    default:
      activityType = "unknown";
      activityTypeDescription = "unknown";
      break;
  }

  await saveActivity(
    activityType,
    [
      `${user?.firstName ?? ""} ${activityTypeDescription} order "${order.firstName} ${
        order.lastName
      }"`,
      extraText,
    ]
      .filter(Boolean)
      .join(" "),
    order.id,
    order,
    undefined,
    undefined,
    firestoreBatch,
  );
}

/**
 * @type {Record<TAllActivityTypes, string>}
 */
const __ActivityNameByType = {
  collected: "Order Collected",
  delivered: "Order Delivered",
  packed: "Order Packed",
  sized: "Order Allocated",
  support: "Support call",
  "new-order": "New Order",
  "order-deleted": "Order Deleted",
  "order-duplicated": "Order Duplicated",
  "order-lightspeed-sales-created": "Order Lightspeed Sales Created",
  "summary-email-sent": "Summary Email Sent",
  tuned: "Equipment Tuned",
  returned: "Equipment Returned",
  "support-call-deleted": "Support Call Deleted",
  "hardware-deleted": "Equipment Deleted",
  "hardware-disposed": "Equipment Disposed",
  "hardware-undisposed": "Equipment Undisposed",
  "hardware-unavailable": "Equipment Unavailable",
  "hardware-available" : "Equipment Available",
  unknown: "Unknown",
};

const __AllActivityTypes = Object.freeze(
  Object.entries(__ActivityNameByType)
    .map(([key, description]) => {
      /** @type {any} */
      const type = key;
      /** @type {{type:TAllActivityTypes; description: string;}} */
      const item = Object.freeze({
        type,
        description,
      });
      return item;
    })
    .sort((a, b) => a.description.localeCompare(b.description)),
);
/**
 * @param {TActivityType} type
 * @returns {string}
 */
export const getActivityName = (type) => __ActivityNameByType[type];

export const getAllActivityTypes = () => __AllActivityTypes;

/**
 * @returns {TAllActivityTypes[]}
 */
export const getDefaultOverviewActivityTypes = () => [
  "new-order",
  "packed",
  "delivered",
  "collected",
  "support",
  "order-deleted",
  "order-duplicated",
  "order-lightspeed-sales-created",
];
