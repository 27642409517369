import { useEffect, useState } from "react";

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";

import { CheckIcon } from "@chakra-ui/icons";

import { twoDecimals } from "@snopro/common/numbers.js";
import { useFormContext } from "react-hook-form";

import { asPrice } from "@/lib/numbers.js";

/**
 * @param {object} props
 * @param {number} [props.maxAmount=0]
 * @param {string} [props.label="Amount"]
 * @param {string} [props.prefix="balance"]
 * @param {boolean} [props.isDisabled=false]
 * @param {boolean} [props.autoFocus=false]
 * @returns {JSX.Element}
 */
const AmountFormControl = ({
  maxAmount = 0,
  label = "Amount",
  prefix = "balance",
  isDisabled = false,
  autoFocus = false,
}) => {
  const [focusWasSet, setFocusWasSet] = useState(false);
  const { register, formState, getFieldState, watch, setFocus } = useFormContext();
  const amount = watch("amount");
  const isDirty = getFieldState("amount").isDirty;
  const hasError = Boolean(formState.errors["amount"]);
  const remainingBalance = twoDecimals(maxAmount - amount);
  const exceedingAmount = twoDecimals(amount - maxAmount);

  useEffect(() => {
    if (!autoFocus || focusWasSet) {
      return;
    }

    setFocusWasSet(true);
    setFocus("amount", { shouldSelect: true });
  }, [autoFocus, focusWasSet, setFocus]);

  return (
    <FormControl isRequired>
      <FormLabel fontWeight={"bold"}>{label}</FormLabel>
      <InputGroup size={"lg"}>
        <InputLeftElement>$</InputLeftElement>
        <Input
          autoComplete="off"
          {...register("amount", { min: 0.01, max: maxAmount })}
          type="number"
          isInvalid={isDirty && hasError}
          inputMode="decimal"
          step="0.01"
          focusBorderColor={hasError ? "red.500" : "blue.500"}
          isDisabled={isDisabled}
          max={maxAmount}
        />
        {isDirty && !hasError && (
          <InputRightElement>
            <CheckIcon color="green.500" />
          </InputRightElement>
        )}
      </InputGroup>
      {maxAmount > 0 && !isDisabled && (
        <FormHelperText minH={"18px"}>
          {remainingBalance > 0 &&
            `${prefix.charAt(0).toUpperCase() + prefix.slice(1)} remaining: ${asPrice(
              remainingBalance,
            )}`}
          {exceedingAmount > 0 && (
            <Text color="red.600">
              Amount exceeds {prefix} by {asPrice(exceedingAmount)}
            </Text>
          )}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AmountFormControl;
