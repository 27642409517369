import { HStack, Box, Text, Icon, GridItem } from "@chakra-ui/react";
import { CiSliderVertical } from "react-icons/ci";
import { PolesIcon } from "../../common/PolesIcon";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../services/firebase";
import calcPoleSize from "../../../data/calcPoleSize";
import CheckImage from "../../common/CheckImage";

export default function CheckPoles({ skier, orderId }) {
  function toggle() {
    const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
    updateDoc(skierDoc, {
      "progress.polespacked": !skier?.progress?.polespacked,
    });
  }

  return (
    <GridItem justifySelf="start">
      <HStack alignItems="center" minW="120px">
        {skier.packageType.split(" ").pop() == "Ski" && (
          <HStack as="button" onClick={() => toggle()}>
            <CheckImage type="poles" isChecked={skier.progress?.polespacked} />
            <Box>
              <Text>{calcPoleSize(skier.height)}</Text>
            </Box>
          </HStack>
        )}
      </HStack>
    </GridItem>
  );
}
