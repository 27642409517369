/**
 * @type {TPaymentMethod[]}
 */
const PAYMENT_TYPES = ["saved-credit-card", "credit-card", "cash", "account", "no-pay"];

export function getAvailablePaymentMethods(savedCreditCard) {
  return PAYMENT_TYPES.filter((type) => {
    if (type === "saved-credit-card") {
      return savedCreditCard !== null;
    }

    return true;
  });
}

/**
 * @param {TOrderPaymentTransaction} transaction
 * @returns {boolean}
 */
export const defaultFilterTransactions = (transaction) => true;

/**
 * @param {TOrderPaymentTransaction} transaction
 * @returns {boolean}
 */
export const filterTransactionsByPaymentsOnly = (transaction) => transaction.type === "payment";

/**
 * Retrieves the saved credit card information from the payment object.
 * @param {TOrder['payment']} payment - The payment object.
 * @returns {{name: string; maskedNumber: string} | null} - The saved credit card information.
 */
export function getSavedCreditCard(payment) {
  if (payment?.saveCard?.cardToken) {
    return {
      name: payment.saveCard.cardName ?? "NO NAME",
      maskedNumber: payment.saveCard.maskedCardNumber ?? "NO NUMBER",
    };
  }

  return null;
}
