import { useEffect, useMemo, useState } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Select,
  Text,
  useDisclosure,
  HStack,
  Icon,
} from "@chakra-ui/react";

import { DeleteIcon } from "@chakra-ui/icons";
import { FiEdit } from "react-icons/fi/index.esm.js";

import { db } from "@/services/firebase.js";

import { doc, serverTimestamp, updateDoc, deleteField } from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useShowError } from "@/lib/error.js";
import { useLocations } from "@/lib/locationLib.js";
import { getSupportCallTypes } from "@/lib/supportCallLib.jsx";
import { saveActivity } from "@/services/activityLog.js";

import EditableField from "../common/EditableField.jsx";
import ChangeDateTimeModal from "../modals/ChangeDateTimeModal.jsx";
import DeleteSupportCallModal from "./DeleteSupportCallModal.jsx";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {()=> void} props.onClose
 * @param {TOrderSupportCall} props.supportCall
 * @param {TOrder} [props.order]
 * @returns {JSX.Element}
 */
export default function SupportCallModal({ isOpen, onClose, order, supportCall: supportCallIn }) {
  const { showError } = useShowError();
  const deleteSupportCallDisclosure = useDisclosure();
  const locationList = useLocations();
  const [supportCall, setSupportCall] = useState({ ...supportCallIn });
  const dateSelectModal = useDisclosure();
  const selectedDate = useMemo(
    () => getFieldAsDate(supportCall.driveTime),
    [supportCall.driveTime],
  );

  useEffect(() => setSupportCall({ ...supportCallIn }), [supportCallIn]);

  const { currentUserDetails } = useLoggedInUser();

  async function completeSupportCall() {
    try {
      const docRef = doc(db, "orders", supportCall.orderId, "supportCalls", supportCall.id);
      await updateDoc(docRef, {
        isActive: false,
        completed: serverTimestamp(),
        completedBy: `${currentUserDetails.firstName} ${currentUserDetails.lastName}`,
        completedByUser: {
          id: currentUserDetails.id,
          firstName: currentUserDetails.firstName,
          lastName: currentUserDetails.lastName,
        },
      });
      await saveActivity(
        "support",
        `${currentUserDetails.firstName} completed support call for order "${supportCall.firstName} ${supportCall.lastName}"`,
        supportCall.orderId,
      );
      onClose();
    } catch (error) {
      showError(error);
    }
  }

  const handleInputChange = (name, value) => {
    setSupportCall({
      ...supportCall,
      [name]: value,
    });
  };

  const updateField = async (name, value) => {
    try {
      const docRef = doc(db, "orders", supportCall.orderId, "supportCalls", supportCall.id);
      await updateDoc(docRef, { [name]: value });
    } catch (error) {
      showError(error);
      setSupportCall({ ...supportCallIn });
    }
  };

  async function selectDate(date) {
    try {
      if (date) {
        const docRef = doc(db, "orders", supportCall.orderId, "supportCalls", supportCall.id);
        await updateDoc(docRef, {
          driveTime: date,
          assignedTo:
            dayjs.tz(date).isSame(dayjs.tz(getFieldAsDate(supportCall.driveTime)), "day") &&
            supportCall.assignedTo
              ? supportCall.assignedTo
              : deleteField(),
        });
      }
    } catch (error) {
      showError(error);
    }
  }

  async function handleOptionChange(e) {
    try {
      const docRef = doc(db, "orders", supportCall.orderId, "supportCalls", supportCall.id);
      await updateDoc(docRef, { type: e.target.value });
    } catch (error) {
      showError(error);
    }
  }

  async function handleLocationChange(e) {
    try {
      const docRef = doc(db, "orders", supportCall.orderId, "supportCalls", supportCall.id);
      await updateDoc(docRef, { location: e.target.value });
    } catch (error) {
      showError(error);
    }
  }

  return (
    <>
      <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        {supportCall && (
          <ModalContent>
            <ModalHeader>Support Call</ModalHeader>
            <ModalCloseButton />
            <ModalBody px={12} py={6} width="fit-content">
              <HStack>
                <Text fontSize={10} fontWeight="bold">
                  When
                </Text>
                <Icon boxSize={3} as={FiEdit} position="relative" top="1px" />
              </HStack>
              <Text mb={6} onClick={dateSelectModal.onOpen}>
                {dayjs.tz(getFieldAsDate(supportCall.driveTime)).format("D MMM h.mma")}
              </Text>
              <HStack>
                <Text fontSize={10} fontWeight="bold">
                  Reason
                </Text>
                <Icon boxSize={3} as={FiEdit} position="relative" top="1px" />
              </HStack>
              <Select
                mb={6}
                value={supportCall.type}
                onChange={handleOptionChange}
                variant="unstyled"
              >
                {getSupportCallTypes().map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>

              <HStack>
                <Text fontSize={10} fontWeight="bold">
                  Team to action
                </Text>
                <Icon boxSize={3} as={FiEdit} position="relative" top="1px" />
              </HStack>

              <Select
                mb={6}
                value={supportCall.location}
                onChange={handleLocationChange}
                variant="unstyled"
              >
                {locationList.map((locationId) => (
                  <option key={locationId} value={locationId}>
                    {locationId}
                  </option>
                ))}
              </Select>

              <EditableField
                fieldName="addressDelivery"
                value={supportCall?.addressDelivery}
                displayName="Address"
                displayType="textarea"
                handleInput={handleInputChange}
                handleUpdate={updateField}
                isSupportCall
              />

              <EditableField
                fieldName="comments"
                value={supportCall?.comments}
                displayName="Details"
                displayType="textarea"
                handleInput={handleInputChange}
                handleUpdate={updateField}
                isSupportCall
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" onClick={onClose}>
                CLOSE
              </Button>
              {supportCall.isActive && (
                <>
                  <Spacer />
                  <Button
                    size="sm"
                    leftIcon={<DeleteIcon />}
                    onClick={() => deleteSupportCallDisclosure.onOpen()}
                  >
                    DELETE
                  </Button>
                </>
              )}
              <Spacer />
              {!supportCall.completedBy && (
                <Button colorScheme="brand" size="sm" onClick={completeSupportCall}>
                  JOB COMPLETED BY {currentUserDetails.firstName.toUpperCase()}
                </Button>
              )}
              {dateSelectModal.isOpen && (
                <ChangeDateTimeModal
                  isOpen={dateSelectModal.isOpen}
                  onClose={dateSelectModal.onClose}
                  dateCallback={selectDate}
                  date={
                    selectedDate
                      ? selectedDate
                      : dayjs.tz().hour(7).minute(0).second(0).millisecond(0)
                  }
                />
              )}
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
      <DeleteSupportCallModal
        isOpen={deleteSupportCallDisclosure.isOpen}
        onClose={() => {
          deleteSupportCallDisclosure.onClose();
          close();
        }}
        order={order}
        selectedSupportCall={supportCall}
      />
    </>
  );
}
