export function calcClothingSize(skier, type) {
  let result = "?";

  switch (type) {
    case "jacket":
    case "pants":
      if (skier.age <= 4) {
        if (clothingData.child.height[skier.height])
          result = clothingData.child.height[skier.height];
      } else {
        if (clothingData.adult.weight[skier.weight])
          result = clothingData.adult.weight[skier.weight][skier.gender];
      }
      break;
    case "helmet":
      if (helmetData.height[skier.height]) result = helmetData.height[skier.height][skier.gender];
      break;
    default:
      break;
  }
  return result;
}

const clothingData = {
  adult: {
    weight: {
      8: { Male: "XS", Female: "XS" },
      9: { Male: "XS", Female: "S" },
      10: { Male: "S", Female: "S" },
      11: { Male: "S", Female: "M" },
      12: { Male: "S", Female: "M" },
      13: { Male: "M", Female: "L" },
      14: { Male: "M", Female: "L" },
      15: { Male: "M", Female: "L" },
      16: { Male: "L", Female: "XL" },
      17: { Male: "L", Female: "XL" },
      18: { Male: "L", Female: "XL" },
      19: { Male: "XL", Female: "XXL" },
      20: { Male: "XXL", Female: "?" },
      21: { Male: "XXL", Female: "?" },
    },
  },
  child: {
    height: {
      1: "92",
      2: "104",
      3: "116",
      4: "116",
      5: "128",
      6: "128",
      7: "140",
      8: "140",
      9: "152",
      10: "152",
      11: "164",
    },
  },
};

const helmetData = {
  height: {
    1: { Male: "XS", Female: "XS" },
    2: { Male: "XS", Female: "XS" },
    3: { Male: "XS", Female: "XS" },
    4: { Male: "XS", Female: "XS" },
    5: { Male: "XS", Female: "XS" },
    6: { Male: "XS", Female: "XS" },
    7: { Male: "SM", Female: "SM" },
    8: { Male: "SM", Female: "SM" },
    9: { Male: "SM", Female: "SM" },
    10: { Male: "SM", Female: "SM" },
    11: { Male: "MD", Female: "MD" },
    12: { Male: "MD", Female: "MD" },
    13: { Male: "MD", Female: "MD" },
    14: { Male: "MD", Female: "MD" },
    15: { Male: "LG", Female: "MD" },
    16: { Male: "LG", Female: "MD" },
    17: { Male: "LG", Female: "MD" },
  },
};
