import {
  Box,
  Button,
  GridItem,
  HStack,
  Icon,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { FaBuildingCircleArrowRight } from "react-icons/fa6";
import { FaLock, FaUnlock } from "react-icons/fa/index.esm.js";
import { MdCurrencyExchange } from "react-icons/md/index.esm.js";

import { db } from "../../../services/firebase.js";

import { doc, updateDoc } from "firebase/firestore";

import CheckImage from "../../common/CheckImage.jsx";
import PackageName from "../../common/PackageName.jsx";
import AvailabilityModal from "../../modals/AvailabilityModal.jsx";

/**
 * @param {Object} props
 * @param {TSkier} props.skier
 * @param {TOrder} props.order
 * @param {boolean} [props.hideCheck]
 * @returns {JSX.Element}
 */
export default function CheckAllocate({ skier, order, hideCheck }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  // TODO: move this to skier.api.js
  async function toggleLocked() {
    if (order.state == "completed") {
      toast({
        title: "Can't change, booking is completed!",
        status: "warning",
        duration: 3000,
        isClosable: false,
      });
      return;
    }
    if (order.state == "inactive") {
      toast({
        title: "Can't change, booking is inactive!",
        status: "error",
        duration: 3000,
        isClosable: false,
      });
      return;
    }

    const skierDoc = doc(db, "orders", order.id, "skiers", skier.id);
    const isLocked = !skier.selectedHardware?.locked;
    await updateDoc(skierDoc, { "selectedHardware.locked": isLocked });
    const allocationsDoc = doc(db, "allocations", skier.id);
    await updateDoc(allocationsDoc, { locked: isLocked });
  }

  function tryOpen() {
    if (order.state == "completed") {
      toast({
        title: "Can't change, booking is completed!",
        status: "warning",
        duration: 3000,
        isClosable: false,
      });
      return;
    }
    if (order.state == "inactive") {
      toast({
        title: "Can't change, booking is inactive!",
        status: "error",
        duration: 3000,
        isClosable: false,
      });
      return;
    }
    if (skier.selectedHardware) {
      if (skier.selectedHardware?.locked) {
        toast({
          id: skier.id,
          title: "Hardware is locked!",
          status: "warning",
          duration: 3000,
          isClosable: false,
        });
        return;
      }
    }
    onOpen();
  }

  return (
    <>
      <GridItem justifySelf="start">
        <HStack alignItems="center" spacing={4}>
          {skier.selectedHardware ? (
            <Box as="button" onClick={toggleLocked}>
              <Icon
                as={skier.selectedHardware.locked ? FaLock : FaUnlock}
                boxSize={4}
                mt={1}
                color={skier.selectedHardware.locked ? "black" : "gray.200"}
              />
            </Box>
          ) : (
            <Box boxSize={4}></Box>
          )}
          {skier.selectedHardware ? (
            <HStack as="button" onClick={tryOpen}>
              <CheckImage
                type={skier.selectedHardware.type}
                isChecked={!hideCheck && true}
              />
              <VStack spacing="0" align="flex-start">
                <HStack spacing="1">
                  <Text fontWeight="bold" isTruncated>
                    {skier.selectedHardware.make} {skier.selectedHardware.model}{" "}
                    {skier.selectedHardware.length}cm
                  </Text>
                  {skier.selectedHardware?.package != skier.packageType && (
                    <Icon boxSize={5} as={MdCurrencyExchange}></Icon>
                  )}
                  {skier.selectedHardware?.location != order.location && (
                    <Icon boxSize={5} as={FaBuildingCircleArrowRight}></Icon>
                  )}
                </HStack>
                <PackageName
                  packageName={skier.selectedHardware?.package}
                  suffix={` (${skier.selectedHardware.code})`}
                />
              </VStack>
            </HStack>
          ) : (
            skier.packageType && (
              <HStack>
                <Box as="button">
                  <CheckImage type={skier.packageType.toLowerCase()} isChecked={false} />
                </Box>
                <VStack spacing="0" align="flex-start">
                  <Button size="sm" onClick={tryOpen}>
                    <PackageName packageName={"Select " + skier.packageType} />
                  </Button>
                </VStack>
              </HStack>
            )
          )}
        </HStack>
      </GridItem>
      {isOpen && (
        <AvailabilityModal isOpen={isOpen} onClose={onClose} skier={skier} order={order} />
      )}
    </>
  );
}
