import {
  Button,
  GridItem,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import { HiExclamation } from "react-icons/hi/index.esm.js";

import { db } from "@/services/firebase.js";

import { doc, updateDoc } from "firebase/firestore";

import { calculateSkierDIN } from "@snopro/common/din-utils.js";

import { useConfig } from "@/contexts/ConfigContext.jsx";

import CheckImage from "../../common/CheckImage.jsx";
import BootSelectModal from "../../modals/BootSelectModal.jsx";
import BootSizeModal from "../../modals/BootSizeModal.jsx";
import SoleLengthModal from "../../modals/SoleLengthModal.jsx";

/**
 *
 * @param {object} props
 * @param {TSkier} props.skier
 * @param {string} props.orderId
 * @param {string} props.prefix
 * @param {TOrderChangeSource} props.source
 * @param {boolean} [props.isEditMode]
 * @param {boolean} [props.isCollection]
 * @returns {JSX.Element}
 */
export default function CheckBootDone({
  skier,
  orderId,
  prefix,
  isEditMode,
  isCollection,
  source,
}) {
  const bootChangeModal = useDisclosure();
  const soleLengthModal = useDisclosure();
  const bootSizeModal = useDisclosure();
  const configData = useConfig();

  const bootType = String(skier?.packageType).includes("Snowboard") ? "bootsb" : "bootsk";

  async function toggleBootDone() {
    const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
    await updateDoc(skierDoc, {
      [`progress.${prefix}boot`]: !skier?.progress?.[`${prefix}boot`],
    });
  }

  function renderComponent() {
    if (skier.ownBoots) {
      return (
        <GridItem justifySelf="start">
          {isEditMode ? (
            <>
              <Text fontSize="xs">own boots</Text>
              <Button mt={2} onClick={bootChangeModal.onOpen} size="xs" justifySelf="end">
                change
              </Button>
            </>
          ) : (
            <VStack
              as="button"
              onClick={soleLengthModal.onOpen}
              spacing={0}
              align="start"
              position="relative"
            >
              {!isCollection ? (
                skier.ownBootSole ? (
                  <>
                    <Text isTruncated>{skier.ownBootSole.label}mm</Text>
                    <HStack>
                      <Text fontWeight="bold">
                        DIN{" " + (calculateSkierDIN(skier, configData)?.DIN?.toFixed(2) ?? "?.??")}
                      </Text>
                      <Image boxSize={6} src={"/images/check.svg"} />
                    </HStack>
                  </>
                ) : (
                  <Button as="span" mt={2} size="xs">
                    set own sole
                  </Button>
                )
              ) : (
                <Text fontSize="sm">own boot</Text>
              )}
            </VStack>
          )}
        </GridItem>
      );
    }

    if (skier?.selectedBoot) {
      return (
        <GridItem justifySelf="start">
          <VStack spacing={2}>
            <HStack minW="120px" as="button" onClick={() => toggleBootDone()}>
              <CheckImage type={bootType} isChecked={skier.progress?.[prefix + "boot"]} />
              <VStack spacing="-3px" align="flex-start">
                <HStack>
                  <Text fontSize="sm" fontWeight="bold" isTruncated>
                    {skier.selectedBoot.brand} {skier.selectedBoot.model}
                    {skier.selectedBoot.isDeluxe && " ★"}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold" whiteSpace="nowrap">
                    {skier.shoe}{" "}
                    {bootType == "bootsk" && (
                      <>
                        {" "}
                        / DIN
                        {" " + (calculateSkierDIN(skier, configData)?.DIN?.toFixed(2) ?? "?.??")}
                      </>
                    )}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
            {isEditMode && (
              <HStack>
                <Button onClick={bootChangeModal.onOpen} size="xs" justifySelf="end">
                  change boot
                </Button>
                <Button onClick={bootSizeModal.onOpen} size="xs" justifySelf="end">
                  change size
                </Button>
              </HStack>
            )}
          </VStack>
        </GridItem>
      );
    }

    return (
      <GridItem>
        {!skier.packageType ? (
          <Text fontSize="xs">{isCollection ? "" : "no fitting"}</Text>
        ) : (
          <HStack minW="200px" justify="center">
            <Icon as={HiExclamation} boxSize={6}></Icon>
            <Text>missing?</Text>
          </HStack>
        )}
      </GridItem>
    );
  }

  return (
    <>
      {renderComponent()}

      {bootChangeModal.isOpen && (
        <BootSelectModal
          isOpen={bootChangeModal.isOpen}
          onClose={bootChangeModal.onClose}
          skier={skier}
          orderId={orderId}
          source={source}
        />
      )}

      {soleLengthModal.isOpen && (
        <SoleLengthModal
          isOpen={soleLengthModal.isOpen}
          onClose={soleLengthModal.onClose}
          skier={skier}
          orderId={orderId}
          source={source}
        />
      )}

      {bootSizeModal.isOpen && (
        <BootSizeModal
          isOpen={bootSizeModal.isOpen}
          onClose={bootSizeModal.onClose}
          skier={skier}
          orderId={orderId}
          source={source}
        />
      )}
    </>
  );
}
