import { useCallback } from "react";

import { useToast } from "@chakra-ui/react";

/**
 * @param {Partial<import("@chakra-ui/react").UseToastOptions>|undefined} toastOptions
 * @param {boolean} logError
 * */
export const useShowError = (toastOptions = undefined, logError = true) => {
  const toast = useToast();

  const showError = useCallback(
    /**
     * @param {string|any} error
     * @param {Partial<import("@chakra-ui/react").UseToastOptions>|undefined} [toastOpts]
     */
    (error, toastOpts) => {
      console.log("error", error);
      let title = toastOpts?.title || toastOptions?.title || "Error";
      if (logError) {
        console.error(title, error);
      }
      let description = error?.message || error;
      if (typeof description === "string") {
        description = description.substring(0, 100);
      }
      if (typeof title === "string") {
        title = title.substring(0, 50);
      }

      return toast({
        title,
        description,
        status: "error",
        duration: 5000,
        isClosable: true,
        ...toastOptions,
        ...toastOpts,
      });
    },
    [toastOptions, logError, toast],
  );
  return {
    showError,
  };
};
