import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverHeader,
  Box,
  Text,
  HStack,
  Icon,
  VStack,
} from "@chakra-ui/react";

import { CheckIcon, QuestionIcon } from "@chakra-ui/icons";
import { BiSolidDollarCircle } from "react-icons/bi/index.esm.js";
import {
  FaBuildingCircleArrowRight,
  FaSuitcase,
  FaFileSignature,
} from "react-icons/fa6/index.esm.js";
import { FaDoorOpen, FaLock } from "react-icons/fa/index.esm.js";
import { HiExclamation, HiOutlineDocumentText, HiUsers } from "react-icons/hi/index.esm.js";
import { MdEventRepeat, MdCurrencyExchange } from "react-icons/md/index.esm.js";
import { PiFirstAidFill, PiLockersBold } from "react-icons/pi";

export default function Legend() {
  return (
    <Box>
      <Popover>
        <PopoverTrigger>
          <Button>?</Button>
        </PopoverTrigger>
        <PopoverContent textAlign="left" w="250px">
          <PopoverArrow />
          {/* <PopoverCloseButton size="md" /> */}
          <PopoverHeader>
            <Text fontWeight="bold">Icon Legend</Text>
          </PopoverHeader>
          <PopoverBody>
            <VStack align="flex-start" spacing={1} mb={4}>
              <HStack>
                <FaDoorOpen />
                <Text>Delivery</Text>
              </HStack>
              <HStack>
                <PiLockersBold />
                <Text>Collection</Text>
              </HStack>
              <HStack>
                <PiFirstAidFill />
                <Text>Support call</Text>
              </HStack>
            </VStack>
            <VStack align="flex-start" spacing={1} mb={4}>
              <HStack>
                <Icon as={MdEventRepeat} color="brand.500"></Icon>
                <Text>Repeat customer</Text>
              </HStack>
              <HStack>
                <HiUsers />
                <Text>Skier count</Text>
              </HStack>
              <HStack>
                <FaBuildingCircleArrowRight />
                <Text>Transferred</Text>
              </HStack>{" "}
              <HStack>
                <Icon as={BiSolidDollarCircle} color="red.500"></Icon>
                <Text>Unpaid booking</Text>
              </HStack>
              <HStack>
                <Icon color="red.500" as={FaSuitcase} />
                <Text>Delivery needs packing</Text>
              </HStack>
              <HStack>
                <Icon color="brand.500" as={FaFileSignature} />
                <Text>Signed but unfinished</Text>
              </HStack>
              <HStack>
                <FaLock />
                <Text>Delivery time locked</Text>
              </HStack>
              <HStack>
                <Icon color="red.500" as={HiExclamation} />
                <Text>Job overdue</Text>
              </HStack>
            </VStack>
            <VStack align="flex-start" spacing={1} mb={4}>
              <HStack>
                <MdCurrencyExchange />
                <Text>Upgraded package</Text>
              </HStack>
              <HStack>
                <Text>★</Text>
                <Text>Deluxe Boot required</Text>
              </HStack>
              <HStack>
                <Icon color="orange.500" as={HiExclamation} />
                <Text>Deluxe Boot warning</Text>
              </HStack>
              <HStack>
                <QuestionIcon color="orange.500" />
                <Text>Unusual skier metrics</Text>
              </HStack>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
