import { useMemo } from "react";

import { Text, HStack } from "@chakra-ui/react";

import { plural } from "@snopro/common/string-utils.js";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import {
    getOrderRentalInventorySummary
} from "@/lib/order-ui-utils.js";


/**
 * @param {object} props
 * @param {TOrderAndSkiersWithItems} props.order
 * @returns {JSX.Element}
 */
export default function RentalItemsSummary({ order }) {
  const config = useConfig();

  const { skis, snowboards, boots, jackets, pants, helmets, polesSet } = useMemo(
    () => getOrderRentalInventorySummary(config, order.skiers),
    [config, order],
  );

  return (
    <HStack spacing={6} mb={6} justify="center" fontSize="lg">
      {skis > 0 && (
        <HStack spacing={1}>
          <Text fontWeight="bold">{skis}</Text>
          <Text>{plural("ski set", skis, { noNumber: true })}</Text>
        </HStack>
      )}
      {polesSet > 0 && (
        <HStack spacing={1}>
          <Text fontWeight="bold">{polesSet}</Text>
          <Text>{plural("pole set", polesSet, { noNumber: true })}</Text>
        </HStack>
      )}
      {snowboards > 0 && (
        <HStack spacing={1}>
          <Text fontWeight="bold">{snowboards}</Text>
          <Text>{plural("snowboard", snowboards, { noNumber: true })}</Text>
        </HStack>
      )}

      {boots > 0 && (
        <HStack spacing={1}>
          <Text fontWeight="bold">{boots}</Text>
          <Text>{plural("boot pair", boots, { noNumber: true })}</Text>
        </HStack>
      )}

      {jackets > 0 && (
        <HStack spacing={1}>
          <Text fontWeight="bold">{jackets}</Text>
          <Text>{plural("jacket", jackets, { noNumber: true })}</Text>
        </HStack>
      )}
      {pants > 0 && (
        <HStack spacing={1}>
          <Text fontWeight="bold">{pants}</Text>
          <Text>pants</Text>
        </HStack>
      )}
      {helmets > 0 && (
        <HStack spacing={1}>
          <Text fontWeight="bold">{helmets}</Text>
          <Text>{plural("helmet", helmets, { noNumber: true })}</Text>
        </HStack>
      )}
    </HStack>
  );
}
