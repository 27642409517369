/**
 *
 * @param {string} cardNumber
 * @returns {"visa"|"mastercard"|"amex"|"invalid"}
 */
export function getCardType(cardNumber) {
  // Ensure the input is a string to prevent issues with leading zeros
  const number = cardNumber.toString();

  // Regular expressions for different card types
  const cardPatterns = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
  };

  if (cardPatterns.visa.test(number)) {
    return "visa";
  }

  if (cardPatterns.mastercard.test(number)) {
    return "mastercard";
  }

  if (cardPatterns.amex.test(number)) {
    return "amex";
  }

  return "invalid";
}

const __onlyNumbers = /^\d+$/;
/**
 *
 * @param {string} cardNumber
 * @returns {boolean}
 */
export function isValidCardNumber(cardNumber) {
  const number = cardNumber.toString();
  if (!__onlyNumbers.test(cardNumber)) {
    return false;
  }
  if (number.length < 13 || number.length > 16) {
    return false;
  }
  let sum = 0;
  let shouldDouble = false;

  // Process each digit starting from the right
  for (let i = number.length - 1; i >= 0; i--) {
    let digit = parseInt(number.charAt(i), 10);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
}
