import { Badge, Box, Button, GridItem, VStack } from "@chakra-ui/react";

import { db } from "@/services/firebase.js";

import { doc, updateDoc } from "firebase/firestore";

import { calcClothingSize } from "@/data/calcClothingSize.js";
import { useShowError } from "@/lib/error.js";
import { removeSkierItem } from "@/lib/skier.api.js";

import CheckImage from "../../common/CheckImage.jsx";
import CheckEmptyItem from "./CheckEmptyItem.jsx";

/**
 *
 * @param {object} props
 * @param {TSkier} props.skier
 * @param {string} props.orderId
 * @param {TTypeOfCosts} props.type
 * @param {boolean} props.isEditMode
 * @returns
 */
export default function CheckDeliverOuterwear({ skier, orderId, type, isEditMode }) {
  const { showError } = useShowError();

  async function toggle() {
    const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
    await updateDoc(skierDoc, {
      [`progress.deliver${type}`]: !skier?.progress?.["deliver" + type],
    }).catch(showError);
  }

  async function removeItem() {
    await removeSkierItem(orderId, skier, type).catch(showError);
  }

  return (
    <>
      {skier[type] ? (
        <GridItem justifySelf="center">
          <VStack>
            <Box as="button" align="center" onClick={() => toggle()} position="relative">
              <CheckImage type={type} isChecked={skier.progress?.[`deliver${type}`]} />
              <Badge position="absolute" top="0px" left="-8px">
                {calcClothingSize(skier, type)}
              </Badge>
            </Box>
            {isEditMode && (
              <Button onClick={removeItem} size="xs" justifySelf="end">
                remove
              </Button>
            )}
          </VStack>
        </GridItem>
      ) : (
        <CheckEmptyItem skier={skier} type={type} orderId={orderId} />
      )}
    </>
  );
}
