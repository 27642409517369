export default function calcTargetHardwareSize(height, age, ability, type) {
  if (height <= 17) {
    if (type.split(" ").pop() == "Ski") {
      switch (ability) {
        case "type 0":
        case "type 1":
          return data[height].ski1;
        case "type 2":
          return data[height].ski2;
        case "type 3":
          return data[height].ski3;
        default:
          return null;
      }
    } else {
      return data[height].board;
    }
  } else {
    return null;
  }
}

const data = {
  1: { ski1: 70, ski2: 75, ski3: 80, board: 80 },
  2: { ski1: 80, ski2: 85, ski3: 90, board: 80 },
  3: { ski1: 90, ski2: 95, ski3: 100, board: 90 },
  4: { ski1: 100, ski2: 105, ski3: 110, board: 90 },
  5: { ski1: 105, ski2: 110, ski3: 115, board: 100 },
  6: { ski1: 110, ski2: 120, ski3: 130, board: 110 },
  7: { ski1: 120, ski2: 130, ski3: 140, board: 120 },
  8: { ski1: 125, ski2: 135, ski3: 145, board: 130 },
  9: { ski1: 135, ski2: 145, ski3: 150, board: 135 },
  10: { ski1: 140, ski2: 150, ski3: 155, board: 140 },
  11: { ski1: 145, ski2: 155, ski3: 160, board: 143 },
  12: { ski1: 150, ski2: 160, ski3: 165, board: 147 },
  13: { ski1: 155, ski2: 165, ski3: 170, board: 152 },
  14: { ski1: 160, ski2: 165, ski3: 175, board: 155 },
  15: { ski1: 165, ski2: 175, ski3: 180, board: 158 },
  16: { ski1: 170, ski2: 180, ski3: 185, board: 160 },
  17: { ski1: 175, ski2: 180, ski3: 185, board: 163 },
};
