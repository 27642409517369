import { useEffect, useState } from "react";

import {
  Fade,
  HStack,
  Heading,
  SlideFade,
  Text,
  Wrap,
  WrapItem,
  Box,
  Center,
  Spacer,
} from "@chakra-ui/react";

import { db } from "@/services/firebase.js";

import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";

import JobCard from "@/components/common/JobCard.jsx";
import { useLoggedInUser } from "@/contexts/AuthContext.jsx";

import Legend from "../../common/Legend.jsx";

/** @type {TOrder[]} */
const initialOrders = [];
/**
 *
 * @param {object} props
 * @param {"Size"|"Pack"} props.jobType
 * @returns {JSX.Element}
 */
export default function JobList({ jobType }) {
  const [ordersToday, setOrdersToday] = useState(initialOrders);
  const [ordersTomorrow, setOrdersTomorrow] = useState(initialOrders);
  const [ordersLater, setOrdersLater] = useState(initialOrders);
  const [isTomorrowOpen, setIsTomorrowOpen] = useState(false);
  const [isLaterOpen, setIsLaterOpen] = useState(false);
  const { currentUserDetails } = useLoggedInUser();
  const jobTypeLabel = jobType == "Size" ? "Allocate" : jobType;
  const jobLabel = currentUserDetails.defaultLocation == "Hilton" ? "Fittings" : "Deliveries";
  const [tomorrowEnd, setTomorrowEnd] = useState(dayjs.tz().add(1, "day").endOf("day").toDate());
  const [tomorrowStart, setTomorrowStart] = useState(
    dayjs.tz().add(1, "day").startOf("day").toDate(),
  );

  useEffect(() => {
    const nextDay = dayjs.tz().add(1, "day").startOf("day").toDate().getTime() - +new Date();
    const timeout = setTimeout(() => {
      setTomorrowEnd(dayjs.tz().add(1, "day").endOf("day").toDate());
      setTomorrowStart(dayjs.tz().add(1, "day").startOf("day").toDate());
    }, nextDay);
    return () => clearTimeout(timeout);
  }, [tomorrowEnd, tomorrowStart]);

  useEffect(() => {
    const q = query(
      collection(db, "orders"),
      where("location", "==", currentUserDetails?.defaultLocation),
      where("deliverTime", "<=", tomorrowEnd),
      where("state", "==", "to" + jobType),
      orderBy("deliverTime", "asc"),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orders = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setOrdersToday(orders.filter((order) => getFieldAsDate(order.deliverTime) < tomorrowStart));
      setIsTomorrowOpen(true);
      setOrdersTomorrow(
        orders.filter((order) => getFieldAsDate(order.deliverTime) >= tomorrowStart),
      );
      setIsLaterOpen(true);
    });
    return unsubscribe;
  }, [jobType, tomorrowEnd, tomorrowStart]);

  useEffect(() => {
    if (isLaterOpen) {
      const q = query(
        collection(db, "orders"),
        where("location", "==", currentUserDetails?.defaultLocation),
        where("deliverTime", ">", tomorrowEnd),
        where("state", "==", "to" + jobType),
        orderBy("deliverTime", "asc"),
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const orders = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setOrdersLater(orders);
      });
      return unsubscribe;
    }
  }, [isLaterOpen, tomorrowEnd, jobType, currentUserDetails]);

  return (
    <SlideFade in={true} offsetY="30px">
      <Center>
        <Box w="1240px">
          <Fade in={true}>
            <HStack>
              <Heading size="lg" mb={4}>
                {jobTypeLabel} Today's {jobLabel}
              </Heading>
              <Text fontSize={24} pb={3} color="gray.300">
                {ordersToday.length}
              </Text>
              <Spacer />
              <Legend />
            </HStack>
          </Fade>
          <Wrap spacing={6} overflow="visible">
            {ordersToday?.map((order, i) => (
              <WrapItem key={i}>
                <JobCard order={order} />
              </WrapItem>
            ))}
          </Wrap>
          <Fade in={isTomorrowOpen}>
            <HStack pt={8}>
              <Heading size="lg" mb={4}>
                {jobTypeLabel} Tomorrow's {jobLabel}
              </Heading>
              <Text fontSize={24} pb={3} color="gray.300">
                {ordersTomorrow.length}
              </Text>
            </HStack>
          </Fade>
          <Wrap spacing={6} overflow="visible">
            {ordersTomorrow?.map((order, i) => (
              <WrapItem key={i}>
                <JobCard order={order} />
              </WrapItem>
            ))}
          </Wrap>
          <Fade in={isTomorrowOpen}>
            <HStack pt={8}>
              <HStack>
                <Heading size="lg" mb={4}>
                  {jobTypeLabel} Future {jobLabel}
                </Heading>
                <Text fontSize={24} pb={3} color="gray.300">
                  {ordersLater.length}
                </Text>
              </HStack>
            </HStack>
          </Fade>
          {isLaterOpen && (
            <Wrap spacing={6} overflow="visible">
              {ordersLater?.map((order, i) => (
                <WrapItem key={i}>
                  <JobCard order={order} isLater={true} />
                </WrapItem>
              ))}
            </Wrap>
          )}
        </Box>
      </Center>
    </SlideFade>
  );
}
