import { useState } from "react";

import { Box, SlideFade } from "@chakra-ui/react";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import { sundriesByPackageItemType } from "@/lib/order-ui-utils.js";

import CheckBootSelect from "../checks/CheckBootSelect.jsx";
import CheckPackageType from "../checks/CheckPackageType.jsx";
import CheckShoeSize from "../checks/CheckShoeSize.jsx";
import CheckSkierInfo from "../checks/CheckSkierInfo.jsx";
import RowCheck from "../checks/RowCheck.jsx";
import SkierGrid from "../common/SkierGrid.jsx";
import SkierRow from "../common/SkierRow.jsx";
import StepHeader from "../common/StepHeader.jsx";

/**
 * @param {object} props
 * @param {TOrderAndSkiersWithItems} props.order
 * @returns {JSX.Element}
 */
export default function StepPackBoots({ order }) {
  const [isEditMode, SetEditMode] = useState(false);
  const config = useConfig();

  function toggleEditMode() {
    SetEditMode(!isEditMode);
  }

  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <Box width="1100px">
        <StepHeader
          customerName={order?.firstName + " " + order?.lastName}
          orderId={order?.id}
          previousOrderId={order?.previousOrder}
          referrer={order?.referrer.name}
          showEditMode={true}
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
        />
        <SkierGrid templateColumns="4fr 2fr 2fr 3fr 1fr">
          {order.skiers.map((skier) => {
            const hasSundriesBoots =
              sundriesByPackageItemType(config, skier, ["boots", "boots-deluxe"]).length > 0;
            const isCompleted = Boolean(
              hasSundriesBoots === false &&
                (!skier.packageType || skier.selectedBoot || skier.ownBoots),
            );
            return (
              <SkierRow cols={5} key={skier.id}>
                <CheckSkierInfo
                  skier={skier}
                  showIsOutOfRange
                  sundriesWarning={hasSundriesBoots ? `Additional sundries boots` : undefined}
                />
                <CheckPackageType skier={skier} />
                <CheckShoeSize skier={skier} />
                <CheckBootSelect
                  skier={skier}
                  order={order}
                  isEditMode={isEditMode}
                  source="flow-pack"
                />
                <RowCheck isHidden={skier.ownBoots} completed={isCompleted} />
              </SkierRow>
            );
          })}
        </SkierGrid>
      </Box>
    </SlideFade>
  );
}
