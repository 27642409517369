import React, { useState, useRef } from "react";

import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  SlideFade,
  Spacer,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import { plural } from "@snopro/common/string-utils.js";
import dayjs from "dayjs";

import CustomerSignature from "@/components/common/CustomerSignature.jsx";
import OrderTotalSummary from "@/components/order/OrderTotalSummary.jsx";
import OrderPaymentsList from "@/components/payment/OrderPaymentsList/OrderPaymentsList.jsx";
import { useOrderModalContext } from "@/contexts/OrderModalContext.jsx";
import { asPrice } from "@/lib/numbers.js";
import { saveCustomerSignature } from "@/lib/order.api.js";
import { filterTransactionsByPaymentsOnly } from "@/lib/payment.js";
import { useTerms } from "@/lib/terms.js";

import DateBox from "../../common/DateBox.jsx";
import CheckSkierInfo from "../checks/CheckSkierInfo.jsx";
import SkierRow from "../common/SkierRow.jsx";

/**
 *
 * @param {Object} props
 * @param {TOrderV2} props.order
 * @param {TCalculatedOrder} props.calculatedOrder
 * @returns {JSX.Element}
 */
export default function StepDeliverCustomerSummary({ order, calculatedOrder }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCompletingOrder, setIsCompletingOrder] = useState(false);
  const [isCompleteButtonEnabled, setIsCompleteButtonEnabled] = useState(false);
  const handleSignatureChange = (isEmpty) => {
    setIsCompleteButtonEnabled(!isEmpty);
  };

  const toast = useToast();

  const { setModalId } = useOrderModalContext();

  /** @type {React.MutableRefObject<{saveSignature?: () => string }|undefined>} */
  const signatureRef = useRef();

  const handleCompleteOrder = async () => {
    try {
      setIsCompletingOrder(true);

      const signatureImage = signatureRef?.current?.saveSignature?.();
      if (!signatureImage) {
        throw new Error("No signature provided");
      }
      await saveCustomerSignature({ orderId: order.id, signatureImage });

      if (order.balance == 0) {
        onClose();
      }
    } catch (/** @type {any} */ err) {
      toast({
        title: "Order completion failed",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsCompletingOrder(false);
    }
  };

  const { termsText } = useTerms();

  return (
    <>
      <SlideFade in={true} offsetX={12} offsetY={0}>
        <VStack spacing={20} mt={20}>
          <Button onClick={onOpen}>Open for customer to sign</Button>
          <Button
            size="xs"
            onClick={(e) => {
              setModalId(order.id, Boolean(e.ctrlKey || e.shiftKey));
            }}
          >
            VIEW/EDIT BOOKING
          </Button>
        </VStack>
      </SlideFade>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <HStack my={4}>
                <VStack align="flex-start">
                  <Heading>
                    {order?.firstName} {order?.lastName}
                  </Heading>
                  <HStack>
                    <Text>
                      Order {order?.lookupId} • Booked{" "}
                      {dayjs.tz(getFieldAsDate(order.bookingTime)).format("D MMM YYYY")}
                    </Text>
                  </HStack>
                  <Box my={4}>
                    <Text fontSize={10} fontWeight="bold" mb={-1}>
                      Collect from
                    </Text>
                    <Text sx={{ whiteSpace: "pre-line" }}>{order.addressCollection}</Text>
                  </Box>
                </VStack>
                <Spacer />
                <HStack spacing={0}>
                  <DateBox date={getFieldAsDate(order.deliverTime)} includeTime={true} />
                  <Divider variant="dashed" w="12px" borderColor="black" />
                  <Text fontWeight="bold" fontSize={13} px={1}>
                    {order?.skiDays} days
                  </Text>
                  <Divider variant="dashed" w="12px" borderColor="black" />
                  <DateBox date={getFieldAsDate(order.collectTime)} includeTime={true} />
                </HStack>
              </HStack>

              <Divider my={1} borderColor="black" />
              <Grid gap={1} pb={1} templateColumns="1fr 1fr">
                {order?.skiers.map((skier) => (
                  <SkierRow key={skier.id} cols={4}>
                    <CheckSkierInfo skier={skier} isCompact={true} />
                    <Grid gap={1} pr={6} templateColumns="2fr 1fr 1fr 1fr">
                      <ItemsSummary skier={skier} />
                    </Grid>
                  </SkierRow>
                ))}
              </Grid>
              <OrderTotalSummary calculatedOrder={calculatedOrder} source="on-delivery" />
              <Divider mb={6} borderColor="black" />

              <VStack mb={6} alignContent={"center"}>
                <OrderPaymentsList
                  order={order}
                  source="on-delivery"
                  title={<Heading size="sm">Payments made</Heading>}
                  filterFunc={filterTransactionsByPaymentsOnly}
                  columnsToShow={["date", "method", "amount", "refundedAmount", "details"]}
                />
              </VStack>

              {!order.deliveryAcceptance?.accepted ? (
                <VStack>
                  <Text fontSize="xs">
                    <b>Terms & Conditions</b>
                  </Text>

                  <Box fontSize="xs" sx={{ columnCount: 2, whiteSpace: "pre-line" }} w="800px">
                    {termsText}
                  </Box>
                  <Text pt={6} fontWeight="bold">
                    Customer to sign in the box below:
                  </Text>
                  <Box width="max-content">
                    <CustomerSignature
                      ref={signatureRef}
                      onSignatureChange={handleSignatureChange}
                    />
                  </Box>
                </VStack>
              ) : (
                <VStack>
                  <Text fontWeight={"bold"}>
                    Order has been signed by customer. Please finalize the payments
                  </Text>
                </VStack>
              )}
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="center" pb={20}>
            {!order.deliveryAcceptance?.accepted && (
              <Button
                loadingText="ACCEPTING..."
                isLoading={isCompletingOrder}
                colorScheme="brand"
                onClick={handleCompleteOrder}
                isDisabled={!isCompleteButtonEnabled}
              >
                ACCEPT
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

/**
 * @param {Object} props
 * @param {TSkierWitItems} props.skier
 * @returns {JSX.Element}
 */
function ItemsSummary({ skier }) {
  return (
    <>
      {skier.items.map((item) => {
        const renderQtyInfo = item.type == "rental" || item.costType == "insurance";
        return (
          <React.Fragment key={item.id}>
            <GridItem>
              <Text fontWeight="bold" isTruncated>
                {item.description || item.code}
              </Text>
            </GridItem>
            <GridItem alignSelf="flex-start" justifySelf="flex-end">
              {renderQtyInfo ? asPrice(item.price.unitPriceDiscount) : "-"}
            </GridItem>
            <GridItem alignSelf="flex-start" justifySelf="flex-end">
              {renderQtyInfo ? plural("day", item.quantity) : "-"}
            </GridItem>

            <GridItem fontSize="sm" alignSelf="flex-start" justifySelf="flex-end">
              {asPrice(item.price.finalPrice)}
            </GridItem>
          </React.Fragment>
        );
      })}
      <React.Fragment key="total">
        <GridItem></GridItem>
        <GridItem></GridItem>
        <GridItem alignSelf="flex-start" justifySelf="flex-end">
          <Text fontWeight={"bold"}>Total</Text>
        </GridItem>
        <GridItem fontSize="sm" alignSelf="flex-start" justifySelf="flex-end">
          <Text fontWeight={"bold"}>{asPrice(skier.finalPrice)}</Text>
        </GridItem>
      </React.Fragment>
    </>
  );
}
