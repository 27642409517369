import React from "react";

import {
  Box,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";

import { WarningTwoIcon } from "@chakra-ui/icons";

import { calculateSkierDIN, getSkierBootSoleLength } from "@snopro/common/din-utils.js";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import calcPoleSize from "@/data/calcPoleSize.js";
import { useLocationConfig } from "@/lib/locationLib.js";
import { sundriesByPackageItemType } from "@/lib/order-ui-utils.js";

import CheckDIN from "../checks/CheckDIN.jsx";
import RentalItemsSummary from "../common/RentalItemsSummary.jsx";

/**
 *
 * @param {object} props
 * @param {TOrderAndSkiersWithItems} props.order
 * @returns {JSX.Element}
 */
export default function StepDeliverDriverSummary({ order }) {
  const { inStore } = useLocationConfig(order.location);
  const config = useConfig();
  const skiersShoeSizeChanged = order.skiers.filter(
    (skier) => skier.hasBootShoeSizeChangedOnDelivery,
  );

  function getSize(height) {
    if (height <= 3) return "JR";
    if (height <= 9) return "S";
    if (height <= 14) return "M";
    if (height <= 16) return "L";
    return "XL";
  }

  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <Box width="1000px">
        <RentalItemsSummary order={order} />
        <Heading mb={6}>Items {inStore ? "Issued" : "Delivered"}</Heading>
        <Divider borderColor="black" my={6} />
        <Grid templateColumns="2fr 4fr 4fr 4fr 4fr" gap={4} fontSize="sm">
          {order.skiers.map((skier) => {
            const hasSundriesBoots = sundriesByPackageItemType(config, skier, ["boots"]).length > 0;
            const hasSundriesDeluxeBoots =
              sundriesByPackageItemType(config, skier, ["boots-deluxe"]).length > 0;
            const hasSundriesPoles = sundriesByPackageItemType(config, skier, ["poles"]).length > 0;
            return (
              <React.Fragment key={skier.id}>
                <GridItem>
                  <Text fontWeight="bold">{skier.firstName}</Text>
                </GridItem>
                <GridItem>
                  <HStack divider={<Text px="1">·</Text>}>
                    <Text isTruncated>{skier.packageType}</Text>
                    {skier.packageType?.split(" ").pop() == "Ski" && (
                      <Text>{calcPoleSize(skier.height)}</Text>
                    )}
                    {hasSundriesPoles && <Text>Aditional Poles</Text>}
                  </HStack>
                </GridItem>
                <GridItem>
                  {!skier.ownBoots && skier.selectedBoot && (
                    <Text isTruncated>
                      {skier.selectedBoot.brand + " " + skier.selectedBoot.model + " " + skier.shoe}
                    </Text>
                  )}
                  {hasSundriesBoots && <Text>Additional Sundries Boots</Text>}
                  {hasSundriesDeluxeBoots && <Text>Additional Sundries Deluxe Boots</Text>}
                </GridItem>
                <GridItem>
                  <HStack divider={<Text px="1">·</Text>}>
                    {skier.jacket && <Text>{getSize(skier.height)} Jacket</Text>}
                    {skier.pants && <Text>{getSize(skier.height)} Pants</Text>}
                    {skier.helmet && <Text>Helmet</Text>}
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack divider={<Text px="1">·</Text>}>
                    {skier.extras?.map((product, i) => (
                      <Text key={i}>{product.type}</Text>
                    ))}
                  </HStack>
                </GridItem>
              </React.Fragment>
            );
          })}
        </Grid>
        <Divider borderColor="black" my={6} />
        <Center>
          <VStack>
            <VStack spacing={6} align="start">
              {order.skiers
                .filter((skier) => skier.ownBoots)
                .map((skier) => (
                  <CheckDIN key={skier.id} skier={skier} orderId={order.id} isSummary={true} />
                ))}
            </VStack>
            {skiersShoeSizeChanged.length > 0 && (
              <VStack pt={2} spacing={3} align="start">
                {skiersShoeSizeChanged.map((skier) => {
                  /** @type {number|string|null} */
                  let soleLength = getSkierBootSoleLength(skier);
                  if (soleLength) {
                    soleLength = `${soleLength}mm`;
                  }
                  return (
                    <HStack key={skier.id} width={"100%"}>
                      <WarningTwoIcon boxSize={3} />
                      <Text>
                        Changed boot {skier.firstName}:{" "}
                        <Text as="span" fontWeight={"bold"}>
                          {[
                            skier.selectedBoot?.model,
                            skier.shoe,
                            "(DIN ",
                            calculateSkierDIN(skier, config)?.DIN?.toPrecision(2) ?? "?.??",
                            (soleLength ?? "") + ")",
                          ]
                            .filter(Boolean)
                            .join(" ")}
                        </Text>
                      </Text>
                    </HStack>
                  );
                })}
              </VStack>
            )}
          </VStack>
        </Center>
      </Box>
    </SlideFade>
  );
}
