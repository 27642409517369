import z from "zod";

import { ISODateSchema, OrderSchema } from "./models.js";

export const SystemAlertTypeSchema = z.union([
  z.literal("order-deliver-unpaid"),
  z.literal("order-deliver-overpaid"),
  z.literal("order-deliver-paid-by-cash"),
  z.literal("order-deliver-ski-pass"),
  z.literal("order-ski-pass-added-to-delivered-order"),
  z.literal("order-deliver-without-customer-signature"),
  z.literal("order-collect-unpaid"),
  z.literal("order-inactive"),
]);

export const SystemAlertSchema = z.object({
  id: z.string(),
  status: z.union([z.literal("active"), z.literal("completed")]),
  message: z.string().optional(),
  type: SystemAlertTypeSchema,
  createdAt: ISODateSchema,
  user: z
    .object({
      id: z.string(),
      firstName: z.string().optional(),
      lastName: z.string().optional(),
    })
    .optional(),
  order: OrderSchema.pick({
    id: true,
    lookupId: true,
    firstName: true,
    lastName: true,
    location: true,
    balance: true,
    paidAmount: true,
  })
    .partial({ balance: true, paidAmount: true })
    .strip(),
  completion: z
    .object({
      user: z.object({
        id: z.string(),
        firstName: z.string().optional(),
        lastName: z.string().optional(),
      }),
      completedAt: ISODateSchema,
    })
    .optional(),
});
