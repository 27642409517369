/**
 *
 * @param {[string, string] | string} word
 * @param {number} count
 * @param {{ noNumber?: boolean; ifZero?:string }} [opts]
 * @returns {string}
 */
export const plural = (word, count, opts) => {
  const singular = Array.isArray(word) ? word[0] : word;
  const plural = Array.isArray(word) ? word[1] : word + "s";
  if (Math.abs(count) >= 1) {
    return [opts?.noNumber !== true ? count : "", count === 1 ? singular : plural]
      .filter((v) => v)
      .join(" ");
  }

  return opts?.ifZero ?? `no ${singular}`;
};

/**
 * @param {string} text
 * @returns {string}
 */
export function cleanText(text) {
  return text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-z0-9]/gi, "");
}
