import { Box, GridItem, HStack, Icon, Text, VStack } from "@chakra-ui/react";

import { HiExclamation } from "react-icons/hi/index.esm.js";

import { db } from "@/services/firebase.js";

import { doc, updateDoc } from "firebase/firestore";

import { calculateSkierDIN } from "@snopro/common/din-utils.js";

import { useConfig } from "@/contexts/ConfigContext.jsx";

import CheckImage from "../../common/CheckImage.jsx";

/**
 *
 * @param {Object} props
 * @param {TSkier} props.skier
 * @param {string} props.orderId
 * @param {boolean} [props.isSummary]
 * @returns {JSX.Element}
 */
export default function CheckDIN({ skier, orderId, isSummary }) {
  const configData = useConfig();
  let sbLeft = " ?";
  let sbRight = " ?";
  if (skier.packageType?.split(" ").pop() == "Snowboard") {
    switch (skier.stance) {
      case "Regular":
        sbLeft = ["type 0", "type 1"].includes(skier.ability) ? "+12" : "+15";
        sbRight = ["type 0", "type 1"].includes(skier.ability) ? "-12" : "-9";
        break;
      case "Goofy":
        sbLeft = ["type 0", "type 1"].includes(skier.ability) ? "-12" : "-9";
        sbRight = ["type 0", "type 1"].includes(skier.ability) ? "+12" : "+15";
        break;
      case "":
      case null:
      case undefined:
        sbLeft = "-9";
        sbRight = "-9";
        break;
      default:
        break;
    }
  }

  function toggle() {
    const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
    updateDoc(skierDoc, {
      "progress.sethardware": !skier?.progress?.sethardware,
    });
  }

  return skier.packageType ? (
    <>
      {skier.ownBoots ? (
        skier.ownBootSole ? (
          <HStack as="button" onClick={() => toggle()} align="start">
            <CheckImage type="screwdriver" isChecked={skier.progress?.sethardware} />
            <Box>
              <VStack spacing="0" align="start" justify="start">
                <Text fontWeight="bold" isTruncated>
                  {skier.selectedHardware?.make} {skier.selectedHardware?.model}{" "}
                  {skier.selectedHardware?.code}
                </Text>
                <Text>
                  {skier.ownBootSole.label}mm / DIN{" "}
                  {calculateSkierDIN(skier, configData)?.DIN?.toFixed(2) ?? "?.??"}
                </Text>
              </VStack>
            </Box>
          </HStack>
        ) : isSummary ? (
          <HStack>
            <Icon as={HiExclamation} boxSize={5} />
            <Text>need sole length!</Text>
          </HStack>
        ) : (
          <GridItem align="start">
            <>own boots</>
          </GridItem>
        )
      ) : (
        <GridItem align="start">
          <HStack as="button" onClick={() => toggle()}>
            <CheckImage type="screwdriver" isChecked={skier.progress?.sethardware} />
            <Box>
              {skier.packageType.split(" ").pop() == "Ski" && (
                <VStack spacing="0" align="start">
                  <HStack>
                    <Text fontWeight="bold" isTruncated>
                      {
                        skier.selectedBoot?.soleLengths?.[
                          skier.shoe.replace(/M|W|JR/g, "").replace(".", "_")
                        ]
                      }
                      mm / DIN
                      {" " + (calculateSkierDIN(skier, configData)?.DIN?.toFixed(2) ?? "?.??")}
                    </Text>
                  </HStack>
                  {skier.selectedBoot && (
                    <Text fontSize="md">
                      {skier.selectedBoot.brand} {skier.selectedBoot.model}
                    </Text>
                  )}
                </VStack>
              )}
              {skier.packageType.split(" ").pop() == "Snowboard" && (
                <VStack spacing={0} align="start">
                  <Text>
                    <b>Left</b>
                    {sbLeft}
                  </Text>
                  <Text>
                    <b>Right</b>
                    {sbRight}
                  </Text>
                </VStack>
              )}
            </Box>
          </HStack>
        </GridItem>
      )}
    </>
  ) : (
    <GridItem />
  );
}
