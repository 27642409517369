import { useEffect, useState } from "react";

import { db } from "@/services/firebase.js";

import { collection, onSnapshot, query, where } from "firebase/firestore";

import { IUserSchema } from "@snopro/common/models.js";

import { useShowError } from "@/lib/error.js";

/**
 *
 * @param {TUser} l
 * @param {TUser} r
 * @returns {number}
 */
const compareUsers = (l, r) => {
  let result = 0;
  if (l.isActive && !r.isActive) {
    result = -1;
  } else if (!l.isActive && r.isActive) {
    result = 1;
  }

  if (result === 0) {
    result = l.firstName.localeCompare(r.firstName);
  }
  if (result === 0) {
    result = l.lastName.localeCompare(r.lastName);
  }
  return result;
};
/** @type {TIUser[]} */
const __initialUserList = [];
/**
 * @param {object} props
 * @param {boolean} [props.shouldFetch=true]
 */
export default function useUserList({ shouldFetch = true } = {}) {
  const { showError } = useShowError({ title: "Error loading users" });
  const [userList, setUserList] = useState(__initialUserList);

  useEffect(() => {
    if (!shouldFetch) {
      return;
    }

    const unsub = onSnapshot(
      query(collection(db, "users"), where("isActive", "==", true)),
      (querySnapshot) => {
        let users = querySnapshot.docs
          .map((doc) => IUserSchema.parse({ ...doc.data(), id: doc.id }))
          .sort(compareUsers);

        setUserList(users);
      },
      (error) => {
        console.error("[userList] snapshot error", error);
        setUserList([]);
        showError(error);
      },
    );
    return unsub;
  }, [shouldFetch]);
  return { userList };
}
