import { useCallback, useEffect, useState } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";

import { db } from "../../services/firebase.js";

import { collection, doc, getDoc, updateDoc } from "firebase/firestore";

import dayjs from "dayjs";

import { useAuth } from "../../contexts/AuthContext.jsx";

export default function VanStockModal({ date, techNo, vanName }) {
  const dateId = dayjs(date).format("YYYYMMDD");
  const [stockInfo, setStockInfo] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUserDetails } = useAuth();

  const getVanStock = useCallback(
    async function () {
      const docRef = doc(db, "techs", dateId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setStockInfo(
          docSnap.data()["tech" + techNo + "_" + currentUserDetails?.defaultLocation]?.stockInfo,
        );
      }
    },
    [dateId, techNo, currentUserDetails],
  );

  useEffect(() => {
    if (isOpen) {
      getVanStock();
    }
  }, [isOpen]);

  function handleTextChange(e) {
    setStockInfo(e.target.value);
  }

  function updateStockInfo() {
    const docRef = collection(db, "techs");

    updateDoc(doc(docRef, dateId), {
      ["tech" + techNo + "_" + currentUserDetails?.defaultLocation + ".stockInfo"]: stockInfo,
    });
  }

  return (
    <>
      <Button size="xs" onClick={onOpen}>
        {vanName}
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Van Stock Notes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Textarea
                minH="300px"
                value={stockInfo}
                onChange={handleTextChange}
                onBlur={updateStockInfo}
              ></Textarea>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
