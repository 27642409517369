import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

import { FaFemale } from "react-icons/fa/index.esm.js";

import { db } from "@/services/firebase.js";

import { collection, deleteField, getDocs, query, where } from "firebase/firestore";

import { BootSchema } from "@snopro/common/models.js";

import { useShowError } from "@/lib/error.js";
import { initBootList } from "@/lib/initialize-states.js";
import { updateSkier } from "@/lib/skier.api.js";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {TSkier} props.skier
 * @param {string} props.orderId
 * @param {TOrderChangeSource} [props.source]
 * @returns {JSX.Element}
 */
export default function BootSelectModal({ isOpen, onClose, skier, orderId, source }) {
  const { showError } = useShowError();
  const [bootList, setBootList] = useState(initBootList());
  const shoeMondo = skier.shoe.replace(/M|W|JR/g, "");
  const packageType = skier.packageType?.split(" ").pop();

  useEffect(() => {
    if (isOpen) {
      getBootList();
    }
  }, [isOpen]);

  async function getBootList() {
    if (!packageType) {
      return;
    }
    const constraints = [];
    constraints.push(where("type", "==", packageType));
    constraints.push(where("isActive", "==", true));
    if (packageType == "Ski")
      constraints.push(where("soleLengths.index", "array-contains", shoeMondo));

    const q = query(collection(db, "boots"), ...constraints);

    const querySnapshot = await getDocs(q);

    const bootList = querySnapshot.docs.map((doc) =>
      BootSchema.parse({
        ...doc.data(),
        id: doc.id,
      }),
    );

    bootList.sort((a, b) => a.brand.localeCompare(b.brand) || a.model.localeCompare(b.model));

    setBootList(bootList);
  }

  // TODO: move this to skier.api.js
  /**
   * @param {TBoot} boot
   */
  async function setBoot(boot) {
    try {
      const soleLengths =
        packageType == "Ski" && boot.soleLengths ? { ...boot.soleLengths } : undefined;
      if (soleLengths) {
        for (const key in soleLengths) {
          if (key === "index" || !soleLengths[key] || Number(soleLengths[key]) <= 0) {
            delete soleLengths[key];
          }
        }
      }

      /** @type {import("@/lib/skier.api.js").TUpdateSkierPatch} */
      const payload = {
        ownBoots: false,
        selectedBoot: {
          id: boot.id,
          model: boot.model,
          brand: boot.brand,
          type: boot.type,
          isDeluxe: boot?.isDeluxe ? boot.isDeluxe : false,
          ...(soleLengths ? { soleLengths } : {}),
        },
      };

      await updateSkier(orderId, skier.id, payload, source);

      onClose();
    } catch (error) {
      showError(error);
    }
  }

  async function clearBoot() {
    try {
      if (!skier.ownBoots) {
        await updateSkier(
          orderId,
          skier.id,
          { selectedBoot: deleteField(), ownBoots: false },
          source,
        );
      }
      onClose();
    } catch (error) {
      showError(error);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {skier && (
        <ModalContent>
          <ModalHeader>
            Select {packageType} Boot ({skier.shoe}) {skier.bootUpgrade && " ★"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={2}>
              {bootList?.map((boot, i) => (
                <Box key={i} width="full">
                  <Divider mb={1} />
                  <HStack spacing={0} key={i} width="100%" justify="center">
                    <Text as="button" fontWeight="bold" onClick={() => setBoot(boot)} p={4}>
                      {boot.brand} {boot.model}
                      {boot.isDeluxe && " ★"}
                    </Text>
                    {boot.isFemale && <FaFemale />}
                  </HStack>
                </Box>
              ))}
              <Divider />
            </VStack>
          </ModalBody>
          <ModalFooter alignSelf="center">
            <Button size="sm" onClick={() => clearBoot()}>
              CLEAR SELECTED
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
}
