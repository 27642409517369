import { VStack } from "@chakra-ui/react";

import { useForm, FormProvider } from "react-hook-form";

import FormActions from "@/components/payment/FormActions.jsx";
import useOrderDetails from "@/hooks/useOrderDetails.js";
import usePaymentProcessing from "@/hooks/usePaymentProcessing.js";

import AmountFormControl from "../AmountFormControl.jsx";
import CommentsFormControl from "../CommentsFormControl.jsx";

/**
 * CashForm component.
 * Renders a form for making a payment via account.
 * @param {Object} props - The component props.
 * @param {TOrderV2} props.order - The order object.
 * @param {() => void} props.onCancel - The function to close the modal.
 * @param {() => void} props.onComplete - The function to close the modal.
 * @param {TOrderPaymentTransactionSource} props.source - The source of the payment.
 * @returns {JSX.Element} The CashForm component.
 */
export default function CashForm({ onCancel, order, onComplete, source }) {
  const { isLoading, processOrderPayment } = usePaymentProcessing({ order, source, onComplete });
  const { balance } = useOrderDetails(order);
  const methods = useForm({
    shouldFocusError: false,
    mode: "all",
    defaultValues: { amount: balance, comments: "" },
  });

  const onSubmitForm = async (data) => {
    await processOrderPayment({
      amount: data.amount,
      comments: data.comments,
      type: "cash",
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitForm)} autoComplete="off">
        <VStack mb={8} spacing={4} alignItems={"flex-start"}>
          <AmountFormControl maxAmount={balance} label="Cash Amount" />
          <CommentsFormControl />
        </VStack>
        <FormActions
          isLoading={isLoading}
          isValid={methods.formState.isValid}
          onCancel={onCancel}
        />
      </form>
    </FormProvider>
  );
}
