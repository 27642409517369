import z from "zod";

export const LocationSchema = z.union([
  z.literal("Queenstown"),
  z.literal("Wanaka"),
  z.literal("Hilton"),
]);
export const OrderSourceSchema = z.union([
  z.literal("web"),
  z.literal("sas1"),
  z.literal("duplicated"),
]);
export const PaymentStatusSchema = z.union([
  z.literal("paid"),
  z.literal("partial"),
  z.literal("unpaid"),
  z.literal("overpaid"),
]);
const GenderSchema = z.union([z.literal("Male"), z.literal("Female")]);
const StanceSchema = z.union([
  z.literal("Regular"),
  z.literal("Goofy"),
  z.literal("Unsure"),
  z.literal(""),
]);
const AbilitySchema = z.string();

export const OrderStateSchema = z.union([
  z.literal("toSize"),
  z.literal("toPack"),
  z.literal("toDeliver"),
  z.literal("toCollect"),
  z.literal("completed"),
  z.literal("inactive"),
]);

export const ShoeSchema = z.string();
export const PackageTypeSchema = z.union([
  z.literal("Junior Ski"),
  z.literal("Junior Snowboard"),
  z.literal("Performer Ski"),
  z.literal("Performer Snowboard"),
  z.literal("Premier Ski"),
  z.literal("Premier Snowboard"),
  z.literal("Progression Ski"),
  z.literal("Tweener Ski"),
]);

export const ISODateSchema = z.preprocess((/** @type {any} */ val) => {
  if (val instanceof Date) {
    return val;
  } else if (typeof val === "string") {
    const parsedDate = +new Date(val);
    if (!isNaN(parsedDate)) {
      return new Date(parsedDate);
    }
  } else if (val?.toDate) {
    return val.toDate();
  }
  return val;
}, z.date());

export const TypeOfCostsSchema = z.union([
  z.literal("combo"),
  z.literal("jacket"),
  z.literal("pants"),
  z.literal("bootUpgrade"),
  z.literal("helmet"),
  z.literal("insurance"),
  z.literal("ownBoots"),
  z.literal("packageType"),
  z.literal("skiPass"),
]);
export const TypeOfDiscountsSchema = z.union([
  z.literal("combo"),
  z.literal("pants"),
  z.literal("bootUpgrade"),
  z.literal("jacket"),
  z.literal("helmet"),
  z.literal("packageType"),
]);

const CostsSchema = z.record(TypeOfCostsSchema, z.number().optional());
export const DiscountsPercentageSchema = z.record(TypeOfDiscountsSchema, z.number().optional());
export const SkierPriceSchema = z.object({
  discountPercentage: z.number(),
  unitPrice: z.number(),
  unitPriceDiscount: z.number(),
  totalPrice: z.number(),
  totalPriceDiscount: z.number(),
  finalPrice: z.number(),
});
export const SkierItemTypeSchema = z.union([
  /** @description subject to automatic discount based on the number of days */
  z.literal("rental"),
  /** @description sundries of type sales */
  z.literal("sales"),
  /** @description items like insurance  */
  z.literal("miscellaneous"),
  /** @description products from the propertie extras */
  z.literal("product"),
  z.literal("skipass"),
]);
export const SkierItemSchema = z.object({
  id: z.string(),
  sequence: z.number(),
  type: SkierItemTypeSchema,
  isSundries: z.boolean().optional(),
  costType: TypeOfCostsSchema.optional(),
  code: z.string().optional(),
  description: z.string().optional(),
  quantity: z.number(),
  price: SkierPriceSchema,
  shortName: z.string().optional(),
  discountPercentage: z.number().optional(),
  adjustmentDays: z.number().optional(),
});

export const SkierAdjustmentDayPositionSchema = z.union([
  z.literal("start"),
  /**  @deprecated I think there shouldn't be any during */
  z.literal("during"),
  z.literal("mid"),
  z.literal("end"),
]);

export const SkierAdjustmentsSchema = z.object({
  bootUpgrade_days: z.number().optional(),
  /** @deprecated it's not used, only package type has position adjustment */
  bootUpgrade_pos: SkierAdjustmentDayPositionSchema.optional(),

  combo_days: z.number().optional(),
  /** @deprecated it's not used, only package type has position adjustment */
  combo_pos: SkierAdjustmentDayPositionSchema.optional(),

  helmet_days: z.number().optional(),
  /** @deprecated it's not used, only package type has position adjustment */
  helmet_pos: SkierAdjustmentDayPositionSchema.optional(),

  insurance_days: z.number().optional(),

  jacket_days: z.number().optional(),
  /** @deprecated it's not used, only package type has position adjustment */
  jacket_pos: SkierAdjustmentDayPositionSchema.optional(),

  ownBoots_days: z.number().optional(),
  /** @deprecated it's not used, only package type has position adjustment */
  ownBoots_pos: SkierAdjustmentDayPositionSchema.optional(),

  packageType_days: z.number().optional(),
  packageType_pos: SkierAdjustmentDayPositionSchema.optional(),

  pants_days: z.number().optional(),
  /** @deprecated it's not used, only package type has position adjustment */
  pants_pos: SkierAdjustmentDayPositionSchema.optional(),
});

export const SkierExtraSchema = z.object({
  code: z.string(),
  name: z.string(),
  shortname: z.string(),
  type: z.string(),
  price: z.number(),
});

const SkierProgressSchema = z
  .object({
    packhardware: z.boolean().optional(),
    polespacked: z.boolean().optional(),
    sethardware: z.boolean().optional(),
    collecthardware: z.boolean().optional(),
    collectboot: z.boolean().optional(),
    collectjacket: z.boolean().optional(),
    collectpants: z.boolean().optional(),
    collecthelmet: z.boolean().optional(),
    deliverboot: z.boolean().optional(),
    deliverjacket: z.boolean().optional(),
    deliverpants: z.boolean().optional(),
    deliverhelmet: z.boolean().optional(),
  })
  .or(z.record(z.string(), z.boolean()));

const SelectedHardwareSchema = z.object({
  id: z.string(),
  length: z.number(),
  package: z.string(),
  type: z.string(),
  locked: z.boolean().optional(),
  model: z.string(),
  make: z.string(),
  location: LocationSchema,
  code: z.string(),
});

const ParseAsNumberSchema = z.preprocess((val) => {
  if (typeof val === "string") {
    if (val === "") {
      return undefined;
    }
  }
  return val ? Number(val) : val;
}, z.number().or(z.undefined()));

const SoleLengthsSchema = z.object({
  "15_5": ParseAsNumberSchema,
  "16_5": ParseAsNumberSchema,
  "17_5": ParseAsNumberSchema,
  18: ParseAsNumberSchema,
  "18_5": ParseAsNumberSchema,
  "19_5": ParseAsNumberSchema,
  "20_5": ParseAsNumberSchema,
  "21_5": ParseAsNumberSchema,
  22: ParseAsNumberSchema,
  "22_5": ParseAsNumberSchema,
  23: ParseAsNumberSchema,
  "23_5": ParseAsNumberSchema,
  24: ParseAsNumberSchema,
  "24_5": ParseAsNumberSchema,
  25: ParseAsNumberSchema,
  "25_5": ParseAsNumberSchema,
  26: ParseAsNumberSchema,
  "26_5": ParseAsNumberSchema,
  27: ParseAsNumberSchema,
  "27_5": ParseAsNumberSchema,
  28: ParseAsNumberSchema,
  "28_5": ParseAsNumberSchema,
  29: ParseAsNumberSchema,
  "29_5": ParseAsNumberSchema,
  30: ParseAsNumberSchema,
  "30_5": ParseAsNumberSchema,
  31: ParseAsNumberSchema,
  "31_5": ParseAsNumberSchema,
  32: ParseAsNumberSchema,
  "32_5": ParseAsNumberSchema,
  33: ParseAsNumberSchema,
  "33_5": ParseAsNumberSchema,
  index: z.array(ParseAsNumberSchema),
});

const SelectedBootSchema = z.object({
  model: z.string(),
  brand: z.string().optional(),
  id: z.string().optional(),
  isDeluxe: z.boolean().optional(),
  soleLengths: SoleLengthsSchema.optional(),
  type: z.string().optional(),
  index: z.array(ParseAsNumberSchema).optional(),
});

export const HardwareStatusSchema = z.union([
  z.literal("Available"),
  z.literal("Unavailable"),
  z.literal("Disposed"),
  z.literal("Tune"),
  z.literal("Deleted"),
]);
export const HardwareStatusHistorySchema = z.object({
  newStatus: HardwareStatusSchema,
  previousStatus: HardwareStatusSchema,
  notes: z.string().optional(),
  timestamp: ISODateSchema,
  user: z.object({ id: z.string(), firstName: z.string(), lastName: z.string() }),
});
export const HardwareSchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
  type: z.union([z.literal("Ski"), z.literal("Snowboard")]),

  isActive: z.boolean(),
  isFemale: z.boolean().optional(),
  isJunior: z.boolean().optional(),
  length: z.number(),
  lengthActual: z.number(),
  location: LocationSchema,
  make: z.string(),
  model: z.string(),
  package: z.union([
    z.literal("Progression Ski"),
    z.literal("Performer Ski"),
    z.literal("Premier Ski"),
    z.literal("Performer Snowboard"),
    z.literal("Premier Snowboard"),
    z.literal("Junior Ski"),
    z.literal("Tweener Ski"),
    z.literal("Junior Snowboard"),
  ]),
  status: HardwareStatusSchema,
  notes: z.string().optional(),
  tunedOnly: z.boolean().optional(),
  lastOrderId: z.string().optional(),
  lastOrderLocation: z.string().optional(),
  lastSkiDays: z.number().optional(),
  lastSkierInsurance: z.boolean().optional(),
  lastSkierName: z.string().optional(),
  lastTune: z.string().optional(),
  statusHistory: z.array(HardwareStatusHistorySchema).optional(),
  disposeTimestamp: ISODateSchema.optional(),
  usage: z
    .object({
      skiersById: z.record(
        z.string(),
        z.object({
          orderId: z.string(),
          skierId: z.string(),
          price: z.number(),
          /** @description total of days out */
          daysOut: z.number(),
          /** @description total of days actual subtracting and days adjustments */
          daysActual: z.number(),
          collectTime: ISODateSchema,
        }),
      ),
      /** @description total of days out */
      totalDaysOut: z.number(),
      /** @description total of days actual subtracting and days adjustments */
      totalDaysActual: z.number(),
      totalOrders: z.number(),
      totalPrice: z.number(),
    })
    .optional(),
  updateTimestamp: ISODateSchema.optional(),
});
export const BootSchema = z.object({
  id: z.string(),
  brand: z.string(),
  isActive: z.boolean(),
  isDeluxe: z.preprocess((val) => Boolean(val), z.boolean()).optional(),
  isFemale: z.preprocess((val) => Boolean(val), z.boolean()).optional(),
  isJunior: z.preprocess((val) => Boolean(val), z.boolean()).optional(),
  model: z.string(),
  type: z.string(),
  soleLengths: SoleLengthsSchema.optional(),
});

export const ReferrerSchema = z.object({
  id: z.string(),
  discountApplicable: z.boolean(),
  emails: z.string().optional(),
  hideTotal: z.boolean(),
  includeSkiPassSales: z.boolean(),
  location: z.string(),
  name: z.string(),
  onAccount: z.boolean(),
  route: z.string().optional(),
  blockOrderStateChange: z.boolean().optional(),
});

export const CardDetailsSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  number: z.string().startsWith("eCrypted:"),
  expiryMonth: z.number().min(1).max(12),
  expiryYear: z.number().min(24).max(99),
  CVN: z.string().startsWith("eCrypted:"),
});
export const UserRoleSchema = z.union([
  z.literal("admin"),
  z.literal("manager"),
  z.literal("tech"),
]);
export const UserSchema = z.object({
  userName: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  role: UserRoleSchema,
  isTrainingModeEnabled: z.boolean().optional(),
  isActive: z.boolean(),
  defaultLocation: LocationSchema.default("Queenstown"),
  image: z.string().optional(),
  updatedAt: ISODateSchema.optional(),
  updatedPasswordAt: ISODateSchema.optional(),
});
export const IUserSchema = UserSchema.extend({ id: z.string() });
export const OrderPaymentTransactionSourceSchema = z.union([
  z.literal("on-delivery"),
  z.literal("on-booking"),
  z.literal("manager"),
  z.literal("sas1"),
]);
export const OrderPaymentTransactionBaseSchema = z.object({
  id: z.string(),
  amount: z.number(),
  createdOn: ISODateSchema,
  source: OrderPaymentTransactionSourceSchema,
  comments: z.string().optional(),
  sas1Id: z.number().optional(),
  eWayTransactionId: z.string().optional(),
  refundedAmount: z.number().optional(),
  refundedId: z.string().optional(),
  cardName: z.string().optional(),
  maskedCardNumber: z.string().optional(),
  cardToken: z.string().optional(),
  user: z.object({ id: z.string(), name: z.string().optional() }).optional(),
  POSTransactionId: z.string().optional(),
  isPending: z.boolean().optional(),
});

export const OrderPaymentTransactionSchema = OrderPaymentTransactionBaseSchema.extend({
  method: z.union([
    z.literal("on-account"),
    z.literal("credit-card"),
    z.literal("pos"),
    z.literal("cash"),
  ]),
  type: z.literal("refund"),
  eWayTransactionId: z.string().optional(),
  refundedId: z.string().optional(),
  cardToken: z.string().optional(),
  cardName: z.string().optional(),
  maskedCardNumber: z.string().optional(),
}).or(
  OrderPaymentTransactionBaseSchema.extend({
    method: z.union([z.literal("on-account"), z.literal("cash"), z.literal("pos")]),
    type: z.literal("payment"),
  }).or(
    OrderPaymentTransactionBaseSchema.extend({
      method: z.literal("credit-card"),
      type: z.literal("payment"),
      eWayTransactionId: z.string(),
      refundedAmount: z.number().optional(),
      cardName: z.string().optional(),
      maskedCardNumber: z.string().optional(),
      cardToken: z.string().optional(),
    }),
  ),
);

export const OrderSupportCallSchema = z.object({
  id: z.string(),
  addressDelivery: z.string().optional(),
  comments: z.string(),
  created: ISODateSchema,
  driveTime: ISODateSchema.optional(),
  assignedTo: z.string().optional(),
  firstName: z.string().trim(),
  lastName: z.string().trim().optional(),
  isActive: z.boolean(),
  location: LocationSchema,
  orderId: z.string(),
  skierCount: z.number(),
  state: z.string(),
  type: z.string(),
  completed: ISODateSchema.optional(),
  completedBy: z.string().optional(),
  completedByUser: z
    .object({
      id: z.string(),
      firstName: z.string(),
      lastName: z.string(),
    })
    .optional(),
  createdByUser: z
    .object({
      id: z.string(),
      firstName: z.string(),
      lastName: z.string(),
    })
    .optional(),
});

export const DirectSupportCallSchema = z.object({
  id: z.string(),
  addressDelivery: z.string().optional(),
  comments: z.string(),
  createTimestamp: ISODateSchema.optional(),
  driveTime: ISODateSchema,
  deliveryLocked: z.boolean().optional(),
  assignedTo: z.string().optional(),
  location: LocationSchema,
  type: z.string(),
  createdByUser: z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  }),
});

export const OrderActivitySchema = z.object({
  user: z.object({ id: z.string(), name: z.string() }),
  startTime: ISODateSchema,
  endTime: ISODateSchema.optional(),
  state: OrderStateSchema,
});

const LightspeedSalesSchema = z.object({
  salesId: z.string(),
  rentalAmount: z.number().optional(),
  skiPassAmount: z.number().optional(),
  createdAt: ISODateSchema,
});
export const OrderSchema = z.object({
  lightspeed: z.object({ sales: z.array(LightspeedSalesSchema) }).optional(),
  unpaidDelivery: z
    .object({
      reason: z.string(),
      created: ISODateSchema,
      user: z.object({ id: z.string(), name: z.string().optional() }),
      balanceAmountAtTime: z.number(),
    })
    .optional(),
  id: z.string(),
  source: OrderSourceSchema.default("web"),
  lookupId: z.string(),
  activeUser: z
    .object({ id: z.string(), name: z.string(), image: z.string().optional() })
    .optional(),
  stateActivity: z.record(OrderStateSchema, OrderActivitySchema).optional(),
  skiDays: z.number(),
  completedBy: z.string().trim().optional(),
  deliveryLocked: z.boolean().optional(),
  firstName: z.string().trim(),
  lastName: z.string().trim(),
  email: z.string().trim().email().optional(),
  phone: z.string().trim().optional(),
  /**
   * @deprecated for now it's just a partial referrer, but it should not be partial
   */
  referrer: ReferrerSchema.pick({ id: true, name: true }).partial(),
  addressDelivery: z.string().trim().optional(),
  addressCollection: z.string().trim().optional(),
  roomNumber: z.string().trim().optional(),
  skierCount: z.number(),
  location: LocationSchema,
  team: LocationSchema,

  previousOrder: z.string().optional(),

  paidStatus: PaymentStatusSchema,
  paidAmount: z.number(),
  totalPrice: z.number(),
  finalPrice: z.number(),
  totalDiscount: z.number(),
  balance: z.number(),

  state: OrderStateSchema,

  driveTime: ISODateSchema.optional(),
  bookingTime: ISODateSchema,
  deliverTime: ISODateSchema,
  collectTime: ISODateSchema,

  searchTerms: z.array(z.string()).optional(),
  feedbackComments: z.string().optional(),
  comments: z.string().optional(),
  commentsInternal: z.string().optional(),
  // TODO: remove this field, backward compatible change
  /** @deprecated backward compatible change, should be removed */
  deliverSignatureUrl: z.string().optional(),
  deliveryAcceptance: z
    .object({
      accepted: z.boolean(),
      date: ISODateSchema.optional(),
      user: z.object({ id: z.string(), name: z.string().optional() }).optional(),
      signatureUrl: z.string(),
    })
    .optional(),
  payment: z.object({
    transactionsById: z.record(z.string(), OrderPaymentTransactionSchema),
    saveCard: z
      .object({
        cardToken: z.string(),
        cardName: z.string().optional(),
        maskedCardNumber: z.string().optional(),
      })
      .optional(),
  }),
  autoCalcDiscount: z.boolean(),
  /** @description the driver/tech that is assigned to this order */
  assignedTo: z.string().optional(),
  createdBy: z.object({ id: z.string(), name: z.string() }).optional(),
  stepFlows: z
    .record(
      z.union([
        z.literal("sized"),
        z.literal("packed"),
        z.literal("delivered"),
        z.literal("collected"),
      ]),
      z.object({
        timestamp: ISODateSchema,
        user: z.object({ id: z.string(), firstName: z.string(), lastName: z.string() }),
      }),
    )
    .optional(),
});

export const SkierSkiPassSchema = z.object({
  /**
   * @description unfortunately, the field has no validation, so I'm just parsing it as a string
   */
  birthdate: z.string().optional(),
  id: z.string(),
  activated: z.boolean(),
  passInfo: z.string().optional(),
  startDate: z.string().optional(),
});
export const PackageItemTypeSchema = z.union([
  z.literal("boots"),
  z.literal("boots-deluxe"),
  z.literal("poles"),
]);

export const SundriesTypeSchema = z.union([z.literal("Rental"), z.literal("Sale")]);
export const SundriesItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: SundriesTypeSchema,
  packageType: z.union([z.literal("ski"), z.literal("snowboard")]).optional(),
  packageItemType: PackageItemTypeSchema.optional(),
  amount: z.number(),
});

export const SkierSundriesItemSchema = SundriesItemSchema.extend({
  sundriesId: z.string(),
  discountPercentage: z.number().optional(),
  adjustmentDays: z.number().optional(),
});

export const SkierSchema = z.object({
  id: z.string(),
  /** @description it indicates that this skier is a duplicate of another skier, basically all products and services of this skier should be added to the skier with the id `duplicateOf` */
  duplicateOf: z.string().optional(),
  SAS1Id: z.number().optional(),
  firstName: z.string().trim(),
  lastName: z.string().trim().optional(),
  gender: GenderSchema,
  adjustments: SkierAdjustmentsSchema.default({}),
  stance: StanceSchema.optional(),
  hasBootShoeSizeChangedOnDelivery: z.boolean().optional(),
  ability: AbilitySchema,
  age: z.number(),
  isJunior: z.boolean().optional(),
  height: z.number(),
  weight: z.number(),
  shoe: ShoeSchema,
  packageType: PackageTypeSchema.optional(),

  progress: SkierProgressSchema.optional(),
  selectedBoot: SelectedBootSchema.extend({
    /**  @deprecated I don't think soleLengths should be a boolean */
    soleLengths: z.any(),
    // SoleLengthsSchema.or(z.boolean()).optional(),
  }).optional(),
  // had to make it partial because of the legacy data from SAS1
  selectedHardware: SelectedHardwareSchema.partial().optional(),

  ownBoots: z.boolean().optional(),
  bootUpgrade: z.boolean().optional(),
  insurance: z.boolean().optional(),
  helmet: z.boolean().optional(),
  pants: z.boolean().optional(),
  jacket: z.boolean().optional(),
  skiPass: SkierSkiPassSchema.optional(),
  costs: CostsSchema.default({}),
  discountsPercentage: DiscountsPercentageSchema.default({}),
  extras: z.array(SkierExtraSchema).default([]),
  created: ISODateSchema,
  totalPrice: z.number().default(0),
  totalDiscount: z.number().default(0),
  finalPrice: z.number().default(0),
  ownBootSole: z.object({ label: z.string(), length: z.number() }).optional(),
  sundries: z.record(z.string(), SkierSundriesItemSchema).optional(),
});

export const ConfigDataProductSchema = z.object({
  code: z.string(),
  description: z.string(),
  image: z.string().optional(),
  instock: z.boolean().optional(),
  name: z.string(),
  price: z.preprocess((val) => (val ? Number(val) : 0), z.number()),
  shortname: z.string(),
  size: z.union([z.literal("adult"), z.literal("junior")]),
  type: z.string(),
  soldOut: z.boolean().optional(),
});
export const SkiPassSchema = z.object({
  /** @description it is sotred as `3 Days` and I'm just parsing it, may I should fix it in the future */
  days: z.preprocess((val) => {
    return typeof val === "string" ? Number(val.replace(/[^\d]/gi, "")) : Number(val);
  }, z.number()),
  group: z.union([
    z.literal("Adult"),
    z.literal("Child"),
    z.literal("Free"),
    z.literal("Student"),
    z.literal("Senior"),
  ]),
  price: z.number(),
  type: z.union([z.literal("Single"), z.literal("Dual")]),
});

const ConfigDataItem = z.object({
  name: z.string(),
  adult: z.preprocess((v) => Number(v), z.number()),
  junior: z.preprocess((v) => Number(v), z.number()),
});
const MiscItemSchema = z.object({
  name: z.string(),
  price: z.preprocess((val) => (val ? Number(val) : 0), z.number()),
});
const ConfigDataPackageSchema = z.object({
  code: z.string(),
  description: z.string(),
  image: z.string().optional(),
  name: z.string(),
  price: z.preprocess((val) => (val ? Number(val) : 0), z.number()),
  sortOrder: z.number(),
  type: z.preprocess(
    (val) => (typeof val === "string" ? val.trim() : val),
    z.union([z.literal("ski"), z.literal("snowboard")]),
  ),
});
const ShoeMesurementSchema = z.object({
  eu: z.array(z.object({ label: z.string(), value: z.string() })),
  mondo: z.array(z.object({ label: z.string(), value: z.string() })),
  uk: z.array(z.object({ label: z.string(), value: z.string() })),
  us: z.array(z.object({ label: z.string(), value: z.string() })),
  usmondo: z.array(z.object({ label: z.string(), value: z.string() })),
});
export const ConfigDataSchema = z.object({
  age: z.object({ years: z.array(z.object({ label: z.string(), value: z.number() })) }),
  height: z.object({ cm: z.array(z.object({ label: z.string(), value: z.number() })) }),
  items: z.object({
    combo: ConfigDataItem,
    helmet: ConfigDataItem,
    jacket: ConfigDataItem,
    pants: ConfigDataItem,
  }),
  misc: z.object({
    bootUpgrade: MiscItemSchema,
    insurance: MiscItemSchema,
    ownBoots: MiscItemSchema,
  }),
  packages: z.record(LocationSchema, z.record(PackageTypeSchema, ConfigDataPackageSchema)),
  passes: z.record(z.string(), SkiPassSchema),
  products: z.record(LocationSchema, z.record(z.string(), ConfigDataProductSchema)),
  shoe: z.object({
    female: ShoeMesurementSchema,
    male: ShoeMesurementSchema,
  }),
  sundries: z.record(z.string(), SundriesItemSchema).optional(),
  weight: z.object({ kg: z.array(z.object({ label: z.string(), value: z.number() })) }),
});

const DeletedFieldSchema = z.object({ __deleted: z.boolean() });
export const OrderChangeSourceSchema = z.union([
  z.literal("order-page"),
  z.literal("driver-page"),
  z.literal("job-card"),
  z.literal("step-collection"),
  z.literal("flow-size"),
  z.literal("flow-pack"),
  z.literal("flow-deliver"),
  z.literal("flow-collect"),
  z.literal("flow-support"),
  z.literal("change-date-time-modal"),
  z.literal("availability"),
  z.literal("gear-page"),
]);
export const OrderChangeSchema = z.object({
  source: OrderChangeSourceSchema.optional(),
  orderId: z.string(),
  /**
   * @description only the fields that has an impact on the order should be here, also deleted fields will come with a different format
   */
  order: OrderSchema.pick({
    collectTime: true,
    email: true,
    lastName: true,
    firstName: true,
    autoCalcDiscount: true,
    deliverTime: true,
    state: true,
    phone: true,
    referrer: true,
    addressCollection: true,
    addressDelivery: true,
    comments: true,
    commentsInternal: true,
    roomNumber: true,
  })
    .extend({
      activeUser: OrderSchema.shape.activeUser.or(DeletedFieldSchema).optional(),
    })
    .partial()
    .optional(),
  createdAt: ISODateSchema,
  processed: z.boolean().optional(),
  status: z.union([z.literal("queued"), z.literal("processed"), z.literal("failed")]).optional(),
  processedDateTime: ISODateSchema.optional(),
  totalTimeMS: z.number().optional(),
  skiers: z
    .array(
      z.object({
        id: SkierSchema.shape.id,
        skier: SkierSchema.partial().optional(),
        updatePayload: z.any().optional(),
        changeType: z.union([z.literal("update"), z.literal("delete"), z.literal("add")]),
      }),
    )
    .optional(),
  user: z.object({ id: z.string(), name: z.string().optional() }).optional(),
  expiresAt: ISODateSchema.optional(),
});

export const ActivityTypeSchema = z.union([
  z.literal("new-order"),
  z.literal("tuned"),
  z.literal("returned"),
  z.literal("sized"),
  z.literal("packed"),
  z.literal("delivered"),
  z.literal("collected"),
  z.literal("unknown"),
  z.literal("support"),
  z.literal("returned"),
  z.literal("hardware-deleted"),
  z.literal("hardware-disposed"),
  z.literal("hardware-undisposed"),
  z.literal("hardware-unavailable"),
  z.literal("hardware-available"),
]);

export const ActivityOrderInfoSchema = OrderSchema.pick({
  skiDays: true,
  skierCount: true,
  lastName: true,
  firstName: true,
  lookupId: true,
  state: true,
  unpaidDelivery: true,
  balance: true,
}).partial();
export const BaseActivitySchema = z.object({
  id: z.string(),
  time: ISODateSchema,
  text: z.string(),
  location: LocationSchema,
  /** @deprecated this is legacy field and should not be used */
  order: z.string().optional(),
  orderId: z.string(),
  orderInfo: ActivityOrderInfoSchema.optional(),
  orderState: OrderStateSchema.optional(),
  user: z
    .object({
      id: z.string(),
      firstName: z.string(),
      lastName: z.string(),
    })
    .optional(),
});
const NewOrder = BaseActivitySchema.extend({
  type: z.literal("new-order"),
  orderSource: OrderSourceSchema,
});
export const ActivityTypeOrderSchema = z.union([
  z.literal("sized"),
  z.literal("packed"),
  z.literal("delivered"),
  z.literal("collected"),
  z.literal("support"),
  z.literal("summary-email-sent"),
  z.literal("order-deleted"),
  z.literal("order-duplicated"),
  z.literal("order-lightspeed-sales-created"),
  z.literal("support-call-deleted"),
]);

export const ActivitySchema = BaseActivitySchema.required({ user: true })
  .extend({ type: ActivityTypeOrderSchema })
  .or(NewOrder)
  .or(
    BaseActivitySchema.pick({ time: true, text: true, location: true, user: true, id: true })
      .required({ user: true })
      .extend({
        type: z.union([z.literal("unknown"), z.literal("tuned"), z.literal("returned")]),
      }),
  );

export const TermsSchema = z.object({
  text: z.string(),
});
export const AllocationSchema = z.object({
  id: z.string(),
  ability: z.string().optional(),
  adjusted: z.boolean(),
  customerName: z.string().optional(),
  dates: z.array(z.string()),
  end: ISODateSchema,
  hardwareCode: z.string().optional(),
  hardwareId: z.string().optional(),
  hardwareMake: z.string().optional(),
  hardwareModel: z.string().optional(),
  hardwareName: z.string().optional(),
  hardwareType: z.string().optional(),
  height: z.number().optional(),
  location: z.string().optional(),
  locked: z.boolean().optional(),
  orderId: z.string().optional(),
  packageType: z.string().optional(),
  skiDays: z.number(),
  skierId: z.string().optional(),
  skierName: z.string().optional(),
  start: ISODateSchema,
  updatedAt: ISODateSchema.optional(),
});
