import { Flex, HStack, Spacer, Text } from "@chakra-ui/react";

import CheckImage from "../common/CheckImage.jsx";
import PackageName from "../common/PackageName.jsx";

/**
 * @param {object} props
 * @param {THardware} props.hardware
 * @returns {JSX.Element}
 */
export default function BasicHardwareInfo({ hardware }) {
  return (
    <HStack>
      <CheckImage type={hardware.type.toLowerCase()} />
      <Flex direction="column" flex="1">
        <Text isTruncated>
          {hardware.make} {hardware.model} ({hardware.code})
        </Text>
        <HStack>
          <PackageName packageName={hardware.package} />
          <Spacer />
        </HStack>
      </Flex>
    </HStack>
  );
}
