import { HStack, Box, Text, Icon, Image, GridItem } from "@chakra-ui/react";

import { GiBarefoot } from "react-icons/gi";
import { TbShoe } from "react-icons/tb";

export default function CheckShoeSize({ skier }) {
  return (
    <GridItem justifySelf="center">
      {skier.shoe && skier.packageType && (
        <HStack>
          <Box mt={-1}>
            {/* <Icon as={GiBarefoot} boxSize={6} mt={2} /> */}
            <Image boxSize={8} src="/images/shoe.svg" />
          </Box>
          <Box>
            <Text>{skier.shoe}</Text>
          </Box>
        </HStack>
      )}
    </GridItem>
  );
}
