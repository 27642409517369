import { HStack, Box, Badge, Text, GridItem } from "@chakra-ui/react";

import { QuestionIcon } from "@chakra-ui/icons";
import { FaMale, FaFemale } from "react-icons/fa/index.esm.js";

import { useConfig } from "../../../contexts/ConfigContext.jsx";
import { isOutOfRange } from "../../../data/calcRangeAlert.js";

/**
 *
 * @param {Object} props
 * @param {TISkier} props.skier
 * @param {boolean} [props.showSimple]
 * @param {boolean} [props.isCompact]
 * @param {boolean} [props.hideSurname]
 * @param {boolean} [props.showIsOutOfRange]
 * @param {string} [props.sundriesWarning]
 * @returns {JSX.Element}
 */
export default function CheckSkierInfo({
  skier,
  showSimple,
  isCompact,
  hideSurname: shortSurname,
  showIsOutOfRange,
  sundriesWarning,
}) {
  const lookup = useConfig();

  return (
    <GridItem justifySelf="start" mr={6}>
      <HStack alignItems="center" mr={4} minH={isCompact ? "0px" : "80px"}>
        <Box
          display="flex"
          justifyContent="center"
          width={12}
          pt={1}
          opacity={skier.duplicateOf && "0"}
        >
          {skier.gender == "Male" ? (
            <FaMale size={skier.age <= 4 ? 22 : 44} />
          ) : (
            <FaFemale size={skier.age <= 4 ? 22 : 44} />
          )}
        </Box>
        <Box>
          <HStack>
            <Text fontWeight="bold">{skier.firstName}</Text>

            {shortSurname ? (
              <Text fontWeight="bold">{skier.lastName?.substring(0, 1)}</Text>
            ) : (
              <Text fontWeight="bold" maxW={24} isTruncated>
                {skier.lastName}
              </Text>
            )}

            <Text whiteSpace="nowrap">
              {!showSimple && (
                <>
                  <Badge fontSize="2xs" variant="outline" color="black" px={2} ml={2}>
                    {skier.ability.replace("type ", "T")}
                  </Badge>
                  {skier.packageType?.split(" ").pop() == "Snowboard" && (
                    <Badge fontSize="2xs" variant="outline" color="black" px={2} ml={2}>
                      {skier.stance ? skier.stance.substring(0, 1) : "?"}
                    </Badge>
                  )}
                </>
              )}
            </Text>
          </HStack>
          {showSimple ? (
            <>
              {" "}
              {!skier.insurance && (
                <Badge colorScheme="orange" variant="solid">
                  No insurance
                </Badge>
              )}
            </>
          ) : (
            <HStack spacing={2} fontSize="sm">
              <Text whiteSpace="nowrap">{lookup.age.years[skier.age - 1]?.label}yrs</Text>
              <Text whiteSpace="nowrap">
                {lookup.weight.kg.find((weight) => weight.value == skier.weight)?.label}
                {skier.height < 99 && "kg"}
              </Text>
              <Text whiteSpace="nowrap">
                {lookup.height.cm.find((height) => height.value == skier.height)?.label}
                {skier.height < 99 && "cm"}
              </Text>
              {showIsOutOfRange && isOutOfRange(skier) && <QuestionIcon color="orange.500" />}
            </HStack>
          )}
          {sundriesWarning && (
            <Badge colorScheme="yellow" variant="solid" fontSize={"x-small"}>
              {sundriesWarning}
              {" - View booking"}
            </Badge>
          )}
        </Box>
      </HStack>
    </GridItem>
  );
}
