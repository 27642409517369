import { path as rPath } from "ramda";

/**
 * @typedef {Object} TOptsBase
 * @type {{sortObjectKeys?: boolean; removeEmptyStringProperties?:boolean; removeUndefinedProperties?: boolean; removeNullProperties?: boolean}}
 */
/**
 * @typedef {Object} TOptsUpdate
 * @type {TOptsBase & {setFieldDeleteToNullProperties?: boolean}}
 */

/**
 * @param {string} propName The property name to check.
 * @returns {boolean} True if the property name is a date/time property.
 **/
export const isDateTimePropName = (propName) =>
  propName === "timestamp" ||
  propName === "created" ||
  propName.endsWith("Time") ||
  propName.endsWith("At");

/**
 * Deeply maps all properties of an object or elements of an array using the provided mapping function.
 *
 * @template T The type of the input object or array, and the expected return type.
 * @param {(obj: any, propName: string | number, propsPath: (string | number)[]) => { mapped: boolean; value: any | undefined } | undefined} mapper A function that applies a transformation to each property or element.
 * @param {T} obj The object or array to map.
 * @param {TOptsUpdate} [opts={}] Options to control the mapping behavior.
 * @param {string | number} [propName="$root"] The current property name.
 * @param {Array<string | number>} [propsPath=[]] The path to the current property.
 * @returns {T} The new object or array with deeply mapped properties.
 */
export const deepMapAllProps = (mapper, obj, opts = {}, propName = "$root", propsPath = []) => {
  if (typeof obj === "function") {
    return obj;
  }

  if (Array.isArray(obj)) {
    //  @ts-ignore
    return obj.map((v, index) =>
      deepMapAllProps(mapper, v, opts, index, propsPath.concat(`${propName}`)),
    );
  }

  const mappedValue = mapper(obj, propName, propsPath);
  if (mappedValue && mappedValue.mapped === true) {
    return mappedValue.value;
  }

  if (obj && typeof obj === "object" && !(obj instanceof Date)) {
    const entries = Object.entries(obj)
      .filter(([_, value]) => {
        if (value === null && opts.removeNullProperties === true) {
          return false;
        }

        if (value === undefined && opts.removeUndefinedProperties === true) {
          return false;
        }

        if (value === "" && opts.removeEmptyStringProperties === true) {
          return false;
        }

        return true;
      })
      .map(([key, value]) => [
        key,
        deepMapAllProps(mapper, value, opts, key, propsPath.concat(`${propName}`)),
      ]);

    if (opts.sortObjectKeys === true) {
      entries.sort(([l], [r]) => l.localeCompare(r));
    }
    return Object.fromEntries(entries);
  }

  return obj;
};
export const isPrimitive = (v) =>
  ["string", "number", "bigint", "boolean", "symbol"].indexOf(typeof v) >= 0 || v instanceof Date;

/**
 *
 * @param { { includeArrays?: boolean } } opts
 * @param {Record<string, any>} obj
 * @param {string[]} path
 * @returns {string[]}
 */
export const getAllKeysPaths = (opts = {}, obj, path = []) => {
  return Object.keys(obj).reduce(
    /**
     * @param {string[]} acc
     * @param {string} key
     * @returns
     */
    (acc, key) => {
      const value = obj[key];
      const newPath = [...path, key];
      if (Array.isArray(value)) {
        if (opts.includeArrays !== true) {
          return [...acc, ...newPath];
        }
        // eslint-disable-next-line
        const arrayKeys = value.map((item, index) => {
          if (item && isPrimitive(item) === false) {
            return getAllKeysPaths(opts, item, newPath.concat([`${index}`]));
          }
          return newPath.concat([`${index}`]).join(".");
        });
        return acc.concat(...(typeof arrayKeys === "string" ? [arrayKeys] : arrayKeys));
      }
      if (!isPrimitive(value) && typeof value === "object" && value !== null) {
        return [...acc, ...getAllKeysPaths(opts, value, newPath)];
      }
      return [...acc, newPath.join(".")];
    },
    [],
  );
};

/**
 *
 * @param {Record<string, any> | null} left
 * @param {Record<string, any> | null} right
 * @returns {{ key: string; left?: any; right?: any }[]}
 */

export const getDiff = (left, right) => {
  const allKeys = Array.from(
    new Set(
      getAllKeysPaths({ includeArrays: true }, left ?? {}).concat(
        getAllKeysPaths({ includeArrays: true }, right ?? {}),
      ),
    ),
  );
  const { list: allDiff } = allKeys.reduce(
    /**
     * @param {{ list: Array<{ key: string; left: any; right: any }> }} acc
     * @param {string} key
     * @returns {{ list: Array<{ key: string; left: any; right: any }> }}
     */
    (acc, key) => {
      /** @type {any} */
      let lValue = rPath(key.split("."), left);
      /** @type {any} */
      let rValue = rPath(key.split("."), right);

      let isEqual = false;

      const couldBeDate =
        isDateTimePropName(key) || lValue instanceof Date || rValue instanceof Date;
      if (couldBeDate) {
        if (lValue && lValue instanceof Date === false)
          try {
            lValue = new Date(lValue);
          } catch (error) {}

        if (rValue && rValue instanceof Date === false)
          try {
            rValue = new Date(rValue);
          } catch (error) {}

        if (lValue?.getTime && rValue?.getTime) {
          isEqual = lValue.getTime() === rValue.getTime();
        }
      }

      if (isEqual === false && lValue !== rValue) {
        acc.list.push({ key, left: lValue, right: rValue });
      }
      return acc;
    },
    { list: [] },
  );

  return allDiff;
};

/**
 *
 * @param {Object} target
 * @param {string[]} keyPath
 * @param {any} value
 * @param {{ifNullDelete?:boolean }} [opts]
 */
export function setValueToObjectByKeyPath(target, keyPath, value, opts = { ifNullDelete: false }) {
  let currentTarget = target;
  for (let i = 0; i < keyPath.length; i++) {
    const key = keyPath[i];
    if (typeof key === "number") {
      throw new Error("keyPath cannot contain numbers");
    }

    if (i === keyPath.length - 1) {
      if (value === null && opts?.ifNullDelete === true) {
        delete currentTarget[key];
      } else {
        currentTarget[key] = value;
      }
      return;
    }
    currentTarget[key] = currentTarget[key] || {};
    currentTarget = currentTarget[key];
  }
}
