import { Text, VStack, Badge, Box, GridItem } from "@chakra-ui/react";

import { db } from "@/services/firebase.js";

import { doc, updateDoc } from "firebase/firestore";

import { useConfig } from "@/contexts/ConfigContext.jsx";

import CheckImage from "../../common/CheckImage.jsx";
import CheckEmptyItem from "./CheckEmptyItem.jsx";

/**
 * @param {object} props
 * @param {TSkier} props.skier
 * @param {string} props.orderId
 * @param {string} props.type
 * @param {string} props.prefix
 */
export default function CheckExtras({ skier, orderId, type, prefix }) {
  const lookup = useConfig();

  function toggle(productCode) {
    const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
    updateDoc(skierDoc, {
      [`progress.${prefix}${productCode}`]: !skier?.progress?.[prefix + productCode],
    });
  }

  function getSockSize(size) {
    let gender = "";
    if (["M", "F"].includes(size.substring(0, 1))) {
      gender = size.substring(0, 1) == "M" ? "male" : "female";
    } else {
      gender = skier.gender.toLowerCase();
    }

    const sizeArray = lookup.shoe[gender].us;
    for (let i = 0; i < sizeArray.length; i++) {
      if (sizeArray[i].value == size) {
        return sizeArray[i].label;
      }
    }
    return null;
  }
  function renderIcon() {
    for (let i = 0; i < skier?.extras?.length; i++) {
      const product = skier?.extras[i];
      if (product.type == type) {
        return (
          <GridItem>
            <VStack as="button" onClick={() => toggle(product.code)} spacing={0}>
              <Box align="center" position="relative">
                <CheckImage type={type} isChecked={skier.progress?.[prefix + product.code]} />
                {type == "glove" && (
                  <Badge textTransform="capitalize" position="absolute" top="0px" left="-4px">
                    {skier.gender.substring(0, 1)}
                  </Badge>
                )}
                {type == "sock" && (
                  <Badge position="absolute" top="0px" left="-10px">
                    US{getSockSize(skier.shoe)}
                  </Badge>
                )}
                <Text fontSize="xs">{product.shortname}</Text>
              </Box>
            </VStack>
          </GridItem>
        );
      }
    }
    return <CheckEmptyItem />;
  }

  return renderIcon();
}
