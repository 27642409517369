import { useState } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Icon,
  HStack,
  VStack,
  Checkbox,
  Input,
} from "@chakra-ui/react";

import { BsFillPassFill } from "react-icons/bs/index.esm.js";

import { db } from "@/services/firebase.js";

import { deleteField, doc, writeBatch } from "firebase/firestore";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import { skierChanges } from "@/lib/skier.api.js";

/**
 * @param {{skier: TSkier; orderId:string; text?:string}} props
 * @returns {JSX.Element}
 */
export default function SkiPassModal({ skier, orderId, text }) {
  const lookup = useConfig();
  const passModal = useDisclosure();
  const [passInfo, setPassInfo] = useState(skier.skiPass?.passInfo || "");

  if (!skier?.skiPass) {
    return <></>;
  }

  // TODO: move to skier.api.js
  async function updateActivated(e) {
    const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
    const updatePayload = { "skiPass.activated": e.target.checked };
    const batch = writeBatch(db);
    batch.update(skierDoc, updatePayload);
    await skierChanges(orderId, skier.id, null, "update", updatePayload, batch);
    await batch.commit();
  }

  function handleTextChange(e) {
    setPassInfo(e.target.value);
  }

  // TODO: move to skier.api.js
  async function savePassInfo() {
    const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
    const updatePayload = { "skiPass.passInfo": passInfo ? passInfo : deleteField() };
    const batch = writeBatch(db);
    batch.update(skierDoc, updatePayload);
    await skierChanges(orderId, skier.id, null, "update", updatePayload, batch);
    await batch.commit();
  }

  return (
    <>
      <HStack spacing={1} style={{ cursor: "pointer" }} onClick={passModal.onOpen}>
        <Text fontWeight="bold" size="sm" variant="unstyled">
          {text}
        </Text>
        <Icon as={BsFillPassFill} color={skier.skiPass.activated ? "brand.500" : undefined} />
      </HStack>
      {passModal.isOpen && (
        <Modal size="lg" isOpen={passModal.isOpen} onClose={passModal.onClose}>
          <ModalOverlay />
          {skier && (
            <ModalContent>
              <ModalHeader>Ski Pass Details</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack w="fit-content" spacing={6}>
                  <Text fontWeight="bold">
                    {lookup.passes[skier.skiPass.id].group +
                      " / " +
                      lookup.passes[skier.skiPass.id].type +
                      " mountain / " +
                      lookup.passes[skier.skiPass.id].days}
                  </Text>
                  <Text>Birthdate: {skier.skiPass.birthdate}</Text>
                  <Text>First ski day: {skier.skiPass.startDate}</Text>
                  <Checkbox
                    isChecked={skier.skiPass.activated}
                    colorScheme="brand"
                    onChange={updateActivated}
                  >
                    Activated
                  </Checkbox>

                  <Input
                    onChange={handleTextChange}
                    onBlur={savePassInfo}
                    value={passInfo}
                    w="200px"
                    placeholder="Enter reference..."
                  />
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button size="sm" onClick={passModal.onClose}>
                  CLOSE
                </Button>
              </ModalFooter>
            </ModalContent>
          )}
        </Modal>
      )}
    </>
  );
}
