import { useEffect, useState } from "react";

import { db } from "@/services/firebase.js";

import { doc, getDoc } from "firebase/firestore";

import { TermsSchema } from "@snopro/common/models.js";

/**
 * @type {Promise<TTerms>|null}
 */
let __cache = null;

export const getTerms = async () => {
  if (!__cache) {
    const docRef = doc(db, "data", "terms");
    __cache = getDoc(docRef).then((docSnap) => {
      const data = TermsSchema.parse(docSnap.data());
      return data;
    });
  }
  return __cache;
};

export const useTerms = () => {
  const [termsText, setTermsText] = useState("");
  useEffect(() => {
    getTerms().then((data) => {
      setTermsText(data.text);
    });
  }, []);
  return { termsText };
};
