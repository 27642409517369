import { Text, VStack, Badge, Button, Box, GridItem } from "@chakra-ui/react";

import { db } from "@/services/firebase.js";

import { doc, updateDoc } from "firebase/firestore";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import { useShowError } from "@/lib/error.js";
import { removeSkierProduct } from "@/lib/skier.api.js";

import CheckImage from "../../common/CheckImage.jsx";
import CheckEmptyItem from "./CheckEmptyItem.jsx";

/**
 *
 * @param {object} props
 * @param {TSkier} props.skier
 * @param {string} props.orderId
 * @param {string} props.type
 * @param {boolean} props.isEditMode
 */
export default function CheckDeliverExtras({ skier, orderId, type, isEditMode }) {
  const { showError } = useShowError();
  const lookup = useConfig();

  function toggle(productCode) {
    const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
    updateDoc(skierDoc, {
      [`progress.deliver${productCode}`]: !skier?.progress?.["deliver" + productCode],
    });
  }

  function getSockSize(size) {
    let gender = "";
    if (["M", "F"].includes(size.substring(0, 1))) {
      gender = size.substring(0, 1) == "M" ? "male" : "female";
    } else {
      gender = skier.gender.toLowerCase();
    }

    const sizeArray = lookup.shoe[gender].us;
    for (let i = 0; i < sizeArray.length; i++) {
      if (sizeArray[i].value == size) {
        return sizeArray[i].label;
      }
    }
    return null;
  }

  async function removeItem(product) {
    await removeSkierProduct(orderId, skier.id, product).catch(showError);
  }

  function renderIcon() {
    for (let i = 0; i < skier.extras?.length; i++) {
      const product = skier.extras[i];
      if (product.type == type) {
        return (
          <GridItem>
            <VStack spacing={2}>
              <VStack
                spacing={0}
                as="button"
                onClick={() => toggle(product.code)}
                width={16}
                position="relative"
              >
                <Box>
                  <CheckImage type={type} isChecked={skier.progress?.["deliver" + product.code]} />
                  {type == "glove" && (
                    <Badge position="absolute" top="0px" left="0px">
                      {skier.gender.substring(0, 1)}
                    </Badge>
                  )}
                  {type == "sock" && (
                    <Badge position="absolute" top="0px" left="-10px">
                      US{getSockSize(skier.shoe)}
                    </Badge>
                  )}
                </Box>
                <Text as="span" fontSize="xs">
                  {product.shortname}
                </Text>
              </VStack>

              {isEditMode && (
                <Button onClick={() => removeItem(product)} size="xs" justifySelf="end">
                  remove
                </Button>
              )}
            </VStack>
          </GridItem>
        );
      }
    }
    return <CheckEmptyItem type={type} skier={skier} orderId={orderId} />;
  }

  return renderIcon();
}
