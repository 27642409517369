import { VStack } from "@chakra-ui/react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";

import FormActions from "@/components/payment/FormActions.jsx";
import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import useOrderDetails from "@/hooks/useOrderDetails.js";
import usePaymentProcessing from "@/hooks/usePaymentProcessing.js";

import AmountFormControl from "../AmountFormControl.jsx";
import CommentsFormControl from "../CommentsFormControl.jsx";

const FormSchema = z.object({
  amount: z.preprocess((val) => {
    if (typeof val === "string") {
      return parseFloat(val);
    }
    return val;
  }, z.number().nonnegative()),
  comments: z.string().min(2).max(150),
});

/**
 * AccountForm component.
 * Renders a form for making a payment via account.
 * @param {Object} props - The component props.
 * @param {TOrderAndSkiersWithItems} props.order - The order object.
 * @param {() => void} props.onCancel - The function to close the modal.
 * @param {() => void} props.onComplete - The function to close the modal.
 * @param {TOrderPaymentTransactionSource} props.source - The source of the payment.
 * @returns {JSX.Element} The AccountForm component.
 */
export default function AccountForm({ onCancel, order, onComplete, source }) {
  const { isLoading, processOrderPayment } = usePaymentProcessing({ order, source, onComplete });
  const { currentUserDetails } = useLoggedInUser();
  const { balance } = useOrderDetails(order);
  const methods = useForm({
    resolver: zodResolver(FormSchema),
    shouldFocusError: false,
    mode: "all",
    defaultValues: { amount: balance, comments: "" },
  });

  const onSubmitForm = async (data) => {
    await processOrderPayment({
      amount: data.amount,
      comments: data.comments,
      type: "on-account",
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitForm)} autoComplete="off">
        <VStack mb={8} spacing={4} alignItems={"flex-start"}>
          <AmountFormControl maxAmount={balance} isDisabled={!currentUserDetails.isManager} />
          <CommentsFormControl minLength={2} />
        </VStack>
        <FormActions
          isLoading={isLoading}
          isValid={methods.formState.isValid}
          onCancel={onCancel}
        />
      </form>
    </FormProvider>
  );
}
