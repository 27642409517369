import { useEffect, useRef, useState } from "react";

import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { FiLogOut, FiMapPin } from "react-icons/fi";

import { db } from "@/services/firebase.js";

import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";

import dayjs from "dayjs";

import { useAuth } from "@/contexts/AuthContext.jsx";

import Alert from "../common/Alert.jsx";
import NewMessage from "../common/NewMessage.jsx";

export default function UserDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser, currentUserDetails, logOut, switchLocation } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const [newMessage, setNewMessage] = useState(false);
  const [lastViewMessages, setLastViewMessages] = useState(dayjs.tz().toDate());
  const fetchTime = useRef(null);
  const toast = useToast();

  useEffect(() => {
    if (currentUser) {
      const q = query(
        collection(db, "alerts"),
        where("recipients", "array-contains", currentUser.uid),
        where("messageType", "==", "message"),
        where("completed", "==", null),
        orderBy("messageType", "desc"),
        orderBy("timestamp", "desc"),
      );
      const unsub = onSnapshot(q, (snapshot) => {
        const alerts = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setAlerts(alerts);
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            if (
              fetchTime.current &&
              dayjs.tz(change.doc.data().timestamp?.toDate()) > dayjs.tz(fetchTime.current)
            ) {
              toast({
                title: "New message from " + change.doc.data().fromUserName,
                description: change.doc.data().text,
                status: "info",
                duration: null,
                isClosable: true,
              });
            }
          }
        });
        fetchTime.current = dayjs().toDate();
      });
      return unsub;
    }
  }, [isOpen]);

  function closeDrawer() {
    setNewMessage(false);
    setLastViewMessages(dayjs.tz().toDate());

    onClose();
    closeToasts();
  }

  function drawerOpen() {
    onOpen();
    closeToasts();
  }

  async function closeToasts() {
    toast.closeAll();
  }

  return (
    <>
      <Box pl="8" as="button" onClick={() => drawerOpen()}>
        <HStack>
          <Avatar
            name={currentUserDetails.firstName + " " + currentUserDetails.lastName}
            boxSize="10"
            mr={3}
            src={currentUserDetails?.image}
          >
            {alerts.length > 0 && (
              <AvatarBadge
                borderColor="gray.200"
                boxSize="5"
                bg="green.500"
                borderWidth="2px"
                fontSize={"xx-small"}
                alignItems="center"
                justifyContent="center"
              >
                {alerts.length}
              </AvatarBadge>
            )}
          </Avatar>
          <VStack spacing={0} alignItems="flex-start">
            <Text fontSize="md" fontWeight="bold">
              {currentUserDetails?.firstName} {currentUserDetails?.lastName}
            </Text>
            <Text fontSize="md">{currentUserDetails?.defaultLocation}</Text>
          </VStack>
        </HStack>
      </Box>
      <Drawer isOpen={isOpen} placement="left" onClose={() => closeDrawer()}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Box pl="0" as="button">
              <HStack>
                <Avatar
                  name={currentUserDetails.firstName + " " + currentUserDetails.lastName}
                  boxSize="10"
                  mr={3}
                  src={currentUserDetails?.image}
                ></Avatar>
                <VStack spacing={0} alignItems="flex-start">
                  <Text fontSize="md" fontWeight="bold">
                    {currentUserDetails?.firstName} {currentUserDetails?.lastName}
                  </Text>
                  <Text fontSize="md">{currentUserDetails?.defaultLocation}</Text>
                </VStack>
              </HStack>
            </Box>
          </DrawerHeader>
          <DrawerBody>
            <VStack minH="98%" align="left">
              {newMessage ? (
                <NewMessage
                  currentUserDetails={currentUserDetails}
                  cancel={() => setNewMessage(false)}
                ></NewMessage>
              ) : (
                <>
                  <HStack align="start">
                    <Heading size="md" mb={4}>
                      Messages
                    </Heading>
                    <Spacer />
                    <Button size="xs" onClick={() => setNewMessage(true)}>
                      NEW
                    </Button>
                  </HStack>
                  <Divider />
                  <VStack align="start">
                    {alerts?.map((alert, i) => {
                      return (
                        <Alert
                          key={alert.id}
                          alert={alert}
                          isUnread={alert.timestamp?.toDate() > lastViewMessages}
                        ></Alert>
                      );
                    })}
                    {alerts.length == 0 && <Text>nothing to show</Text>}
                  </VStack>
                </>
              )}

              <Spacer />
              <VStack spacing={3}>
                {["Queenstown", "Wanaka", "Hilton"].map((loc) => {
                  return (
                    loc != currentUserDetails?.defaultLocation && (
                      <Button
                        key={loc}
                        size="sm"
                        leftIcon={<FiMapPin />}
                        onClick={() => switchLocation(loc)}
                      >
                        Switch to {loc}
                      </Button>
                    )
                  );
                })}

                <Button size="sm" leftIcon={<FiLogOut />} onClick={logOut}>
                  LOG OUT
                </Button>
              </VStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
