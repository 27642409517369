import CheckAllocate from "../checks/CheckAllocate.jsx";
import CheckShoeSize from "../checks/CheckShoeSize.jsx";
import CheckSkierInfo from "../checks/CheckSkierInfo.jsx";
import RowCheck from "../checks/RowCheck.jsx";
import SkierGrid from "../common/SkierGrid.jsx";
import SkierList from "../common/SkierList.jsx";
import SkierRow from "../common/SkierRow.jsx";
import StepHeader from "../common/StepHeader.jsx";

export default function StepSizeAllocate({ order }) {
  return (
    <SkierList>
      <StepHeader
        customerName={order?.firstName + " " + order?.lastName ?? ""}
        orderId={order?.id}
        previousOrderId={order?.previousOrder}
        referrer={order?.referrer.name}
      />
      <SkierGrid templateColumns="3fr 1fr 3fr 1fr">
        {order?.skiers.map((skier) => (
          <SkierRow key={skier.id} cols={4}>
            <CheckSkierInfo skier={skier} showIsOutOfRange />
            <CheckShoeSize skier={skier} />
            <CheckAllocate skier={skier} order={order} />
            <RowCheck completed={!skier?.packageType || skier?.selectedHardware} />
          </SkierRow>
        ))}
      </SkierGrid>
    </SkierList>
  );
}
