import { Divider, GridItem } from "@chakra-ui/react";

export default function SkierRow({ children, cols }) {
  return (
    <>
      {children}
      <GridItem colSpan={cols} w="100%" height="auto">
        <Divider borderColor="black" />
      </GridItem>
    </>
  );
}
