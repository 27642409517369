import { useState } from "react";

import {
  Button,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { twoDecimals } from "@snopro/common/numbers.js";

import PaymentModal from "@/components/modals/PaymentModal.jsx";
import useOrderDetails from "@/hooks/useOrderDetails.js";
import { useShowError } from "@/lib/error.js";
import { lightspeedCreateSales } from "@/lib/lightspeed.api.js";
import { useLocationConfig } from "@/lib/locationLib.js";
import { asPrice } from "@/lib/numbers.js";
import { refundNegativeBalanceAPI } from "@/lib/payments.api.js";

/**
 * OrderTotalSummary component.
 * Renders a summary of the order total as well as the payment modal
 * @param {Object} props - The component props.
 * @param {TCalculatedOrder} props.calculatedOrder - The order object.
 * @param {TOrderPaymentTransactionSource} props.source - The source of the payment.
 * @returns {JSX.Element} The OrderTotalSummary component.
 */
const OrderTotalSummary = ({ calculatedOrder, source }) => {
  const { showError } = useShowError();
  const toast = useToast();
  const [isRefunding, setIsRefunding] = useState(false);
  const { order } = calculatedOrder;
  const { displayBalance, displayDiscount, displayOrderFinalPrice, displayPaidAmount } =
    useOrderDetails(order);
  const { isPOSEnabled } = useLocationConfig(order.location);
  const [isCreatingSales, setIsCreatingSales] = useState(false);
  const {
    isOpen: isOpenPaymentModal,
    onClose: onClosePaymentModal,
    onOpen: onOpenPaymentModal,
  } = useDisclosure();

  const showDiscount = order.totalDiscount > 0;
  const showSkipass = calculatedOrder.skiPassesTotal > 0;
  const extraRows = [showDiscount, showSkipass].filter(Boolean).length;

  return (
    <>
      <HStack justifyContent="space-between">
        <Spacer />
        <Grid gap={1} pr={8} templateColumns="330px 2fr 1fr 1fr 1fr">
          <GridItem
            colSpan={3}
            rowSpan={5 + extraRows}
            alignSelf={"center"}
            justifySelf={"flex-end"}
            pr={4}
          >
            {order.deliveryAcceptance?.accepted && Boolean(order.unpaidDelivery) === false && (
              <>
                {twoDecimals(order.balance) > 0 &&
                  (isPOSEnabled ? (
                    <Button
                      colorScheme="brand"
                      isLoading={isCreatingSales}
                      onClick={async () => {
                        setIsCreatingSales(true);
                        try {
                          await lightspeedCreateSales({ orderId: order.id });

                          toast({
                            title: `Sales created in Lightspeed for order #${order.lookupId}`,
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                          });
                        } catch (error) {
                          showError(error);
                        } finally {
                          setIsCreatingSales(false);
                        }
                      }}
                    >
                      SEND to P.O.S
                    </Button>
                  ) : (
                    <Button colorScheme="brand" onClick={onOpenPaymentModal}>
                      PAYMENT
                    </Button>
                  ))}
                {twoDecimals(order.balance) < 0 && (
                  <Button
                    isLoading={isRefunding}
                    colorScheme="brand"
                    onClick={async () => {
                      if (isRefunding) {
                        return;
                      }
                      setIsRefunding(true);
                      await refundNegativeBalanceAPI({ orderId: order.id, source })
                        .catch(showError)
                        .finally(() => {
                          setIsRefunding(false);
                        });
                    }}
                  >
                    MAKE REFUND
                  </Button>
                )}
              </>
            )}
          </GridItem>

          <GridItem>
            <Text isTruncated>Rental Total</Text>
          </GridItem>
          <GridItem alignSelf="flex-start" justifySelf="flex-end">
            {asPrice(calculatedOrder.rentalsTotal)}
          </GridItem>
          {showSkipass && (
            <>
              <GridItem>
                <Text isTruncated>Ski Pass Total</Text>
              </GridItem>
              <GridItem alignSelf="flex-start" justifySelf="flex-end">
                {asPrice(calculatedOrder.skiPassesTotal)}
              </GridItem>
            </>
          )}
          <GridItem>
            <Text isTruncated>Order Total</Text>
          </GridItem>
          <GridItem alignSelf="flex-start" justifySelf="flex-end">
            {displayOrderFinalPrice}
          </GridItem>
          <GridItem>
            <Text isTruncated>Paid</Text>
          </GridItem>
          <GridItem alignSelf="flex-start" justifySelf="flex-end">
            {displayPaidAmount}
          </GridItem>
          <GridItem>
            <Text fontWeight="bold" isTruncated>
              {order.balance < 0 ? "Refund Due Now" : "Balance Due Now"}
            </Text>
          </GridItem>
          <GridItem alignSelf="flex-start" justifySelf="flex-end" fontWeight="bold">
            {displayBalance}
          </GridItem>
          {showDiscount && (
            <>
              <GridItem fontStyle={"italic"}>
                <Text color={"gray.500"}>incl. discount</Text>
              </GridItem>
              <GridItem fontStyle={"italic"} alignSelf="flex-start" justifySelf="flex-end">
                <Text color={"gray.500"}>-{displayDiscount}</Text>
              </GridItem>
            </>
          )}
          {order.unpaidDelivery && (
            <GridItem colSpan={2}>
              <Text fontStyle={"italic"} color={"red"}>
                This order has been marked as unpaid delivery by {order.unpaidDelivery.user.name}
              </Text>
            </GridItem>
          )}
        </Grid>
      </HStack>
      <PaymentModal
        isOpen={isOpenPaymentModal}
        onClose={onClosePaymentModal}
        order={order}
        source={source}
      />
    </>
  );
};

export default OrderTotalSummary;
