import { Box, SlideFade } from "@chakra-ui/react";

export default function SkierList({ children }) {
  return (
    <>
      <SlideFade in={true} offsetX={12} offsetY={0}>
        <Box width="1000px">{children}</Box>
      </SlideFade>
    </>
  );
}
