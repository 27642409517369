import { useEffect, useState } from "react";

import { Fade, HStack, Heading, SlideFade, Text, Grid, GridItem, VStack } from "@chakra-ui/react";

import { db } from "@/services/firebase.js";

import { collection, onSnapshot, query, where } from "firebase/firestore";

import { AllocationSchema, HardwareSchema } from "@snopro/common/models.js";
import dayjs from "dayjs";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { compareHardware } from "@/lib/hardwareLib.jsx";
import { initHardwareList, initSetString } from "@/lib/initialize-states.js";

import TuneCard from "../common/TuneCard.jsx";

export default function Gear() {
  const [skiList, setSkiList] = useState(initHardwareList());
  const [snowboardList, setSnowboardList] = useState(initHardwareList());
  const [unavailableHardwareList, setUnavailableHardwareList] = useState(initHardwareList());

  const [allocatedTomorrow, setAllocatedTomorrow] = useState(initSetString());
  const { currentUserDetails } = useLoggedInUser();
  const [todayDate, setTodayDate] = useState(dayjs.tz().startOf("day"));

  useEffect(() => {
    const nextDay = dayjs.tz().add(1, "day").startOf("day").toDate().getTime() - +new Date();
    const timeout = setTimeout(() => {
      setTodayDate(dayjs.tz().startOf("day"));
    }, nextDay);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "hardware"),
      where("location", "==", currentUserDetails.defaultLocation),
      where("isActive", "==", true),
      where("status", "in", ["Tune", "Unavailable"]),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const unavailableList = initHardwareList();
      const skiList = initHardwareList();
      const snowboardList = initHardwareList();
      querySnapshot.docs.forEach((doc) => {
        const hardware = HardwareSchema.parse({
          ...doc.data(),
          id: doc.id,
        });
        if (hardware.status === "Unavailable") {
          unavailableList.push(hardware);
        } else if (hardware.status === "Tune") {
          if (hardware.type === "Ski") {
            skiList.push(hardware);
          } else if (hardware.type === "Snowboard") {
            snowboardList.push(hardware);
          }
        }
      });

      unavailableList.sort(compareHardware);
      skiList.sort(compareHardware);
      snowboardList.sort(compareHardware);

      setSkiList(skiList);
      setSnowboardList(snowboardList);
      setUnavailableHardwareList(unavailableList);
    });
    return unsubscribe;
  }, [currentUserDetails.defaultLocation]);

  useEffect(() => {
    const q = query(
      collection(db, "allocations"),
      where("dates", "array-contains", todayDate.add(1, "day").format("YYYYMMDD")),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      /** @type {Set<string>} */
      const allocatedTomorrow = initSetString();
      querySnapshot.docs.forEach((doc) => {
        const allocaton = AllocationSchema.parse({ ...doc.data(), id: doc.id });
        if (allocaton.hardwareId) {
          allocatedTomorrow.add(allocaton.hardwareId);
        }
      });

      setAllocatedTomorrow(allocatedTomorrow);
    });
    return unsubscribe;
  }, [todayDate]);

  return (
    <SlideFade in={true} offsetY="30px">
      <Fade in={true}>
        <Grid templateColumns={"1fr 1fr 1fr 0fr"}>
          <GridItem>
            <HStack>
              <Heading size="lg" mb={4}>
                Skis to Service
              </Heading>
              <Text fontSize={24} pb={3} color="gray.300">
                {skiList.length}
              </Text>
            </HStack>
            <VStack spacing={4} alignItems="flex-start">
              {skiList.map((hardware) => (
                <TuneCard
                  key={hardware.id}
                  hardware={hardware}
                  isAllocatedTomorrow={allocatedTomorrow.has(hardware.id)}
                />
              ))}
            </VStack>
          </GridItem>

          <GridItem>
            <HStack>
              <Heading size="lg" mb={4}>
                Snowboards to Service
              </Heading>
              <Text fontSize={24} pb={3} color="blackAlpha.500">
                {snowboardList.length}
              </Text>
            </HStack>
            <VStack spacing={4} alignItems="flex-start">
              {snowboardList.map((hardware) => (
                <TuneCard
                  key={hardware.id}
                  hardware={hardware}
                  isAllocatedTomorrow={allocatedTomorrow.has(hardware.id)}
                />
              ))}
            </VStack>
          </GridItem>

          <GridItem>
            <HStack>
              <Heading size="lg" mb={4}>
                Unavailable Hardware
              </Heading>
              <Text fontSize={24} pb={3} color="blackAlpha.500">
                {unavailableHardwareList.length}
              </Text>
            </HStack>
            <VStack spacing={4} alignItems="flex-start">
              {unavailableHardwareList.map((hardware) => (
                <TuneCard
                  key={hardware.id}
                  hardware={hardware}
                  isAllocatedTomorrow={allocatedTomorrow.has(hardware.id)}
                />
              ))}
            </VStack>
          </GridItem>
          <GridItem />
        </Grid>
      </Fade>
    </SlideFade>
  );
}
