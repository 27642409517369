import React, { useMemo, useState } from "react";

import {
  Box,
  HStack,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  ModalFooter,
  Button,
  Spacer,
  Table,
  Tbody,
  VStack,
  FormControl,
  FormLabel,
  Thead,
  Th,
  Tr,
} from "@chakra-ui/react";

import { zodResolver } from "@hookform/resolvers/zod";
import { twoDecimals } from "@snopro/common/numbers.js";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";

import AmountFormControl from "@/components/payment/AmountFormControl.jsx";
import useOrderDetails from "@/hooks/useOrderDetails.js";
import useRefundProcessing from "@/hooks/useRefundProcessing.js";
import { defaultFilterTransactions } from "@/lib/payment.js";

import CommentsFormControl from "../CommentsFormControl.jsx";
import TransactionItem from "./TransactionItem.jsx";
import TransactionTableRow from "./TransactionTableRow.jsx";

/** @type {Record<TPaymentTableColumn, {name:TPaymentTableColumn; label:string; textAlign?:"right"}>} */
const __columns = {
  date: { name: "date", label: "Date" },
  user: { name: "user", label: "User" },
  made: { name: "made", label: "Made" },
  method: { name: "method", label: "Method" },
  type: { name: "type", label: "Type" },
  amount: { name: "amount", label: "Amount", textAlign: "right" },
  refundAvailable: { name: "refundAvailable", label: "Refund available", textAlign: "right" },
  refundedAmount: { name: "refundedAmount", label: "Refunded", textAlign: "right" },
  details: { name: "details", label: "Details" },
  actions: { name: "actions", label: "" },
};
const RefundSchema = z.object({
  amount: z.preprocess((val) => {
    if (typeof val === "string") {
      return parseFloat(val);
    }
    return val;
  }, z.number().nonnegative()),
  comments: z.string().min(2).max(150),
});

/**
 * @returns {TOrderPaymentTransaction|null}
 */
const initialActiveTransaction = () => null;
/**
 * @component
 * @param {object} props - Component props.
 * @param {TOrderV2} props.order - The order object containing payment information.
 * @param {TOrderPaymentTransactionSource} props.source - The source of the payment.
 * @param {(transaction: TOrderPaymentTransaction) => boolean} [props.filterFunc] - The source of the payment.
 * @param {TPaymentTableColumn[]} props.columnsToShow - The columns to exclude from the list.
 * @param {React.ReactNode} [props.title] - Whether to show the title or not.
 * @returns {JSX.Element | null} The rendered OrderPaymentsList component.
 */
const OrderPaymentsList = ({
  order,
  source,
  title,
  filterFunc = defaultFilterTransactions,
  columnsToShow,
}) => {
  const refundClosure = useDisclosure();
  const [activeTransaction, setActiveTransaction] = useState(initialActiveTransaction());
  const { transactions } = useOrderDetails(order);
  const filteredTransactions = transactions.filter(filterFunc);
  const maxRefundAmount = useMemo(() => {
    if (!activeTransaction) {
      return 0;
    }
    return twoDecimals(activeTransaction.amount - (activeTransaction.refundedAmount ?? 0));
  }, [activeTransaction]);
  const [isInputAmountEnabled, setIsInputAmountEnabled] = useState(false);

  const visibleColumns = useMemo(() => columnsToShow.map((c) => __columns[c]), [columnsToShow]);

  const hasTransactions = filteredTransactions.length > 0;
  const formMethods = useForm({
    resolver: zodResolver(RefundSchema),
    shouldFocusError: false,
    mode: "all",
    values: { amount: 0, comments: "" },
  });

  const refundAmount = formMethods.watch("amount");
  const comments = formMethods.watch("comments");
  const isValid =
    formMethods.formState.isValid &&
    refundAmount > 0 &&
    refundAmount <= maxRefundAmount &&
    comments.length >= 2;

  /**
   *
   * @param {TOrderPaymentTransaction} transaction
   * @param {boolean} [allowTypeValue=false]
   */
  const handleOnConfirmRefund = (transaction, allowTypeValue) => {
    setActiveTransaction(transaction);
    setIsInputAmountEnabled(allowTypeValue === true);
    refundClosure.onOpen();
  };

  const resetForm = () => {
    setActiveTransaction(null);
    setIsInputAmountEnabled(false);
    formMethods.reset({ amount: 0, comments: "" });
    refundClosure.onClose();
  };

  const { isLoading, isError, processRefund } = useRefundProcessing({
    order,
    source,
    onComplete: resetForm,
  });

  const handleRefund = async () => {
    if (!activeTransaction) {
      throw new Error("No transaction selected");
    }

    await processRefund({
      id: activeTransaction.id,
      orderId: order.id,
      amount: refundAmount,
      comments: formMethods.getValues("comments"),
    });
  };

  if (!hasTransactions)
    return (
      <Box minW={800}>
        <HStack spacing={4} mb={4}>
          {title}
        </HStack>
        <Box p={4} alignContent={"center"}>
          <Text>No payments have been made for this order.</Text>
        </Box>
      </Box>
    );

  return (
    <>
      <Box minW={800}>
        {title && (
          <HStack spacing={4} mb={4}>
            {title}
          </HStack>
        )}

        <Box p={3} alignContent={"center"}>
          <Table size={"sm"} variant="striped">
            <Thead>
              <Tr>
                {visibleColumns.map((column) => (
                  <Th key={column.name} textAlign={column.textAlign ?? "left"}>
                    {column.label}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {filteredTransactions.map((transaction) => (
                <TransactionTableRow
                  key={transaction.id}
                  transaction={transaction}
                  onRefundClick={handleOnConfirmRefund}
                  visibleColumns={visibleColumns.map((column) => column.name)}
                />
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>

      <Modal size="xl" isOpen={refundClosure.isOpen} onClose={resetForm} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {activeTransaction
              ? "Are you sure you want to refund this payment?"
              : "Negative adjustment"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={3}>
            <FormProvider {...formMethods}>
              <VStack spacing={5}>
                {activeTransaction && (
                  <FormControl>
                    <FormLabel fontWeight={"bold"} mb={2}>
                      Payment details
                    </FormLabel>
                    <TransactionItem transaction={activeTransaction} />
                  </FormControl>
                )}
                <AmountFormControl
                  maxAmount={maxRefundAmount}
                  prefix={"original amount"}
                  isDisabled={isInputAmountEnabled !== true}
                  autoFocus
                />
                <CommentsFormControl minLength={2} />
              </VStack>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={resetForm}>
              <Text casing={"uppercase"}>Cancel</Text>
            </Button>
            <Spacer />
            <Button
              onClick={handleRefund}
              colorScheme="brand"
              isLoading={isLoading}
              isDisabled={!isValid}
            >
              <Text casing={"uppercase"}>{isError ? "Try again" : "Confirm"}</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrderPaymentsList;
