import { z } from "zod";

export const LightspeedConfigSchema = z.object({
  register_id: z.string(),
  user_id: z.string(),
  skipass_product_id: z.string(),
  rentalSales_product_id: z.string(),
  apiUrl: z.string(),
  apiToken: z.string(),
  tax_id: z.string(),
});

export const SASSystemConfigSchema = z.object({
  availability: z
    .object({
      smartSortingVersion: z.string(),
    })
    .optional(),
});
