import React from "react";

import {
  HStack,
  VStack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  GridItem,
} from "@chakra-ui/react";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useConfig } from "@/contexts/ConfigContext.jsx";
import { useShowError } from "@/lib/error.js";
import { compareProduct } from "@/lib/productsLib.js";
import { addSkierItem, skierAddProduct } from "@/lib/skier.api.js";

/**
 * @param {object} props
 * @param {TSkier} [props.skier]
 * @param {TTypeOfCosts|string} [props.type]
 * @param {string} [props.orderId]
 */
export default function CheckEmptyItem({ skier, type, orderId }) {
  const { showError } = useShowError();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const lookup = useConfig();
  const { currentUserDetails } = useLoggedInUser();
  const isRental = ["jacket", "pants", "helmet"].includes(type ?? "");
  const rentalItemOptionsList = [type ?? ""].filter(Boolean);
  if (type == "jacket" || type == "pants") rentalItemOptionsList.push("combo");
  if ((type == "pants" && skier?.jacket) || (type == "jacket" && skier?.pants))
    rentalItemOptionsList.shift();

  function createAddItemRows() {
    if (!skier || !currentUserDetails) return;
    return isRental
      ? rentalItemOptionsList.map((item) => (
          <React.Fragment key={item}>
            <Divider />
            <HStack spacing={0} as="button" onClick={() => addRentalItem(item)}>
              <Text fontWeight="bold" p={4}>
                + {skier.isJunior && "Junior"} {lookup.items[item].name}
              </Text>
              <Text color="gray.300">
                ${skier.isJunior ? lookup.items[item].junior : lookup.items[item].adult}
              </Text>
            </HStack>
          </React.Fragment>
        ))
      : Object.entries(lookup.products[currentUserDetails.defaultLocation] ?? {})
          .sort(compareProduct)
          .map(([, product]) => {
            if (
              product &&
              product.type === type &&
              (skier.isJunior || (product.size === "adult" && !skier.isJunior))
            ) {
              return (
                <React.Fragment key={product.shortname}>
                  <Divider />
                  <HStack spacing={0} as="button" onClick={() => addProduct(product)}>
                    <Text fontWeight="bold" p={4}>
                      + {product.name}
                    </Text>
                    <Text color="gray.300">${product.price}</Text>
                  </HStack>
                </React.Fragment>
              );
            }
          });
  }

  /**
   * @param {TTypeOfCosts} itemType
   */
  async function addRentalItem(itemType) {
    if (!itemType || !skier || !orderId) {
      showError("No item type selected");
      return;
    }

    await addSkierItem({
      itemType,
      skier,
      orderId,
    })
      .then(() => onClose())
      .catch(showError);
  }

  async function addProduct(product) {
    if (!product || !skier || !orderId) {
      showError("No product selected");
      return;
    }
    await skierAddProduct(orderId, skier.id, currentUserDetails.defaultLocation, product.code)
      .then(() => onClose())
      .catch(showError);
  }

  return (
    <GridItem>
      <HStack justifyContent="center">
        <Text as="button" fontSize="xs" color="gray.400" onClick={type ? onOpen : undefined}>
          {type ? "+" + type : "-"}
        </Text>
      </HStack>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add {type}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={2}>
                {createAddItemRows()}
                <Divider />
              </VStack>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </GridItem>
  );
}
