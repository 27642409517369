import { ChakraProvider } from "@chakra-ui/react";

import "@fontsource/dm-sans/400.css";
import "@fontsource/kalam";
import "@fontsource/poppins/700.css";
import "@fontsource/titillium-web/400.css";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App.jsx";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import "./lib/datetime.js";
import "./styles/main.css";
import theme from "./styles/theme.js";

console.log({
  APP_ENV: import.meta.env.VITE_APP_ENV,
  APP_VERSION: import.meta.env.VITE_APP_VERSION,
  APP_EWAY_WEB_ENCRYPT_KEY: import.meta.env.VITE_APP_EWAY_WEB_ENCRYPT_KEY?.length,
});

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </ChakraProvider>,
  // </React.StrictMode>
);
