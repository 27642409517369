import { HStack, Box, Text, Icon, VStack, GridItem } from "@chakra-ui/react";

import { FaBuildingCircleArrowRight } from "react-icons/fa6";
import { HiExclamation } from "react-icons/hi";
import { MdCurrencyExchange } from "react-icons/md";
import { RiExchangeBoxLine } from "react-icons/ri";

import { doc, updateDoc } from "firebase/firestore";

import { db } from "../../../services/firebase";
import CheckImage from "../../common/CheckImage";
import PackageName from "../../common/PackageName";
import { SkiIcon } from "../../common/SkiIcon";

export default function CheckHardwareDone({ skier, orderId, orderLocation, prefix }) {
  function toggle() {
    const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
    updateDoc(skierDoc, {
      [`progress.${prefix}hardware`]: !skier.progress?.[`${prefix}hardware`],
    });
  }

  return (
    <GridItem justifySelf="start" mr={12}>
      {skier.selectedHardware ? (
        <HStack as="button" onClick={() => toggle()}>
          <CheckImage type={skier.packageType} isChecked={skier.progress?.[`${prefix}hardware`]} />
          <VStack spacing={0} align="flex-start">
            <HStack spacing="1">
              <Text fontWeight="bold" fontSize="md" isTruncated>
                {skier.selectedHardware?.make} {skier.selectedHardware?.model}
              </Text>
              {skier.selectedHardware?.package != skier.packageType && (
                <Icon boxSize={5} as={MdCurrencyExchange}></Icon>
              )}
              {skier.selectedHardware?.location != orderLocation && (
                <Icon boxSize={5} as={FaBuildingCircleArrowRight}></Icon>
              )}
            </HStack>
            <HStack>
              <PackageName packageName={skier.selectedHardware?.package} />
              <Text fontSize="md" isTruncated>
                {skier.selectedHardware?.code}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      ) : skier.packageType ? (
        <HStack>
          <Text>Not sized!</Text>
        </HStack>
      ) : (
        <></>
      )}
    </GridItem>
  );
}
