import { useEffect, useState } from "react";

import { db } from "@/services/firebase.js";

import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import { DirectSupportCallSchema } from "@snopro/common/models.js";
import dayjs from "dayjs";
import { z } from "zod";

import { getLoggedInUser, SASUserSignal } from "@/contexts/AuthContext.jsx";
import { saveActivity } from "@/services/activityLog.js";

import { useShowError } from "./error.js";
import { initDirectSupportCalls } from "./initialize-states.js";

/**
 * @param {TOrderSupportCall} supportCall
 * @param {TActivityOrderInfo} [orderInfo]
 * @returns {Promise<void>}
 */
export async function deleteSupporCall(supportCall, orderInfo) {
  const user = SASUserSignal.value;
  if (!user) {
    throw new Error("User not found");
  }

  const batch = writeBatch(db);

  batch.delete(doc(db, "orders", supportCall.orderId, "supportCalls", supportCall.id));
  await saveActivity(
    "support-call-deleted",
    `${user.firstName} delete support call "${supportCall.type} - ${supportCall.location}" for order "${supportCall.firstName} ${supportCall.lastName}"`,
    supportCall.orderId,
    orderInfo,
    supportCall.location,
    undefined,
    batch,
  );

  await batch.commit();
}
/**
 * @param {object} filter
 * @param {Date} filter.driveTime
 * @param {TLocation} filter.locationId
 */
export function listenForDirectSupportCallsBy(filter, next, error) {
  const supportCallsRef = collection(db, "supportCalls");
  const startDate = dayjs.tz(filter.driveTime).startOf("day").toDate();
  const endDate = dayjs.tz(filter.driveTime).endOf("day").toDate();
  return onSnapshot(
    query(
      supportCallsRef,
      where("location", "==", filter.locationId),
      where("driveTime", ">=", startDate),
      where("driveTime", "<=", endDate),
    ),
    (snapshot) => {
      try {
        next(
          snapshot.docs.map((doc) => {
            try {
              const data = doc.data();
              if (!data.createTimestamp) {
                delete data.createTimestamp;
              }
              return DirectSupportCallSchema.parse(data);
            } catch (e) {
              console.error(e);
              console.error(doc.data());
              throw e;
            }
          }),
        );
      } catch (e) {
        error(e);
      }
    },
    error,
  );
}
/**
 * @param {object} filter
 * @param {dayjs.Dayjs} filter.date
 */
export const useDirectSupportCalls = ({ date }) => {
  const [supportCalls, setSupportCalls] = useState(initDirectSupportCalls());
  const { showError } = useShowError();
  const user = getLoggedInUser();

  useEffect(() => {
    const unsubscribe = listenForDirectSupportCallsBy(
      { driveTime: date.toDate(), locationId: user.defaultLocation },
      setSupportCalls,
      showError,
    );
    return unsubscribe;
  }, [date, user.defaultLocation]);
  return { supportCalls };
};
const NewSupportCallSchema = DirectSupportCallSchema.omit({
  id: true,
  createdByUser: true,
  createTimestamp: true,
});
/**
 * @param {z.infer<typeof NewSupportCallSchema>} data
 * @returns {Promise<void>}
 */
export async function createDirectSupportCall(data) {
  const values = NewSupportCallSchema.parse(data);
  const user = getLoggedInUser();
  const supportRef = doc(collection(db, "supportCalls"));
  /** @type {SAS2.firebase.FirestoreNewObject<TDirectSupportCall, import("firebase/firestore").FieldValue>} */
  const updatedData = {
    ...values,
    id: supportRef.id,
    createTimestamp: serverTimestamp(),
    createdByUser: {
      firstName: user.firstName,
      lastName: user.lastName,
      id: user.id,
    },
  };

  await setDoc(supportRef, updatedData);
}

/**
 * @param {TDirectSupportCall} supportCall
 * @param {SAS2.firebase.FirestoreFieldPatch<TDirectSupportCall>} data
 * @returns {Promise<void>}
 */
export async function updateDirectSupportCall(supportCall, data) {
  if (
    "driveTime" in data &&
    "assignedTo" in data !== true &&
    !dayjs(supportCall.driveTime).isSame(getFieldAsDate(data.driveTime), "date")
  ) {
    data.assignedTo = deleteField();
  }
  const supportRef = doc(db, "supportCalls", supportCall.id);
  await updateDoc(supportRef, data);
}

/**
 * @param {string} id
 * @returns {Promise<void>}
 */
export async function completeDirectSupportCall(id) {
  const supportRef = doc(db, "supportCalls", id);
  await deleteDoc(supportRef);
}

export const getSupportCallTypes = () => [
  "Boot Refit",
  "Delivery Assist",
  "Gear Swap",
  "Collection",
  "Gear Drop",
  "Other",
];
