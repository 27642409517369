import { useState } from "react";

import { FormControl, FormHelperText, FormLabel, Textarea, VStack } from "@chakra-ui/react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";

import FormActions from "@/components/payment/FormActions.jsx";
import useOrderDetails from "@/hooks/useOrderDetails.js";
import { useShowError } from "@/lib/error.js";
import { orderUnpaidDelivery } from "@/lib/order.api.js";

import AmountFormControl from "./AmountFormControl.jsx";

const FormSchema = z.object({
  amount: z.preprocess((val) => {
    if (typeof val === "string") {
      return parseFloat(val);
    }
    return val;
  }, z.number().nonnegative()),
  reason: z.string().min(2).max(150),
  type: z.literal("no-pay"),
});

/**
 * AccountForm component.
 * Renders a form for making a payment via account.
 * @param {Object} props - The component props.
 * @param {TOrderV2} props.order - The order object.
 * @param {() => void} props.onCancel - The function to close the modal.
 * @param {() => void} props.onComplete - The function to close the modal.
 * @param {TOrderPaymentTransactionSource} props.source - The source of the payment.
 * @returns {JSX.Element} The AccountForm component.
 */
export default function NoPayForm({ onCancel, order, onComplete, source }) {
  const { showError } = useShowError();
  const [isBusy, setIsBusy] = useState(false);
  const { balance } = useOrderDetails(order);
  const formMethods = useForm({
    resolver: zodResolver(FormSchema),
    shouldFocusError: false,
    mode: "all",
    defaultValues: { amount: balance, reason: "", type: "no-pay" },
  });
  const { register, watch } = formMethods;

  const reason = watch("reason");
  const remainingCharacters = 150 - reason.length;

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(async (data) => {
          setIsBusy(true);
          try {
            await orderUnpaidDelivery({ orderId: order.id, reason: data.reason });
            onComplete();
          } catch (error) {
            showError(error);
          } finally {
            setIsBusy(false);
          }
        })}
        autoComplete="off"
      >
        <VStack mb={8} spacing={4} alignItems={"flex-start"}>
          <AmountFormControl maxAmount={balance} isDisabled />
          <FormControl>
            <FormLabel fontWeight={"bold"}>Reason</FormLabel>
            <Textarea
              {...register("reason", { maxLength: 2, minLength: 150 })}
              maxLength={150}
              focusBorderColor={formMethods.formState.errors?.reason ? "red.500" : "blue.500"}
              isInvalid={
                Boolean(formMethods.formState.dirtyFields.reason) &&
                Boolean(formMethods.formState.errors?.reason)
              }
              resize={"none"}
              rows={3}
            />
            {formMethods.formState.errors?.reason?.message && (
              <FormHelperText minH={"18px"}>
                {formMethods.formState.errors?.reason?.message}
              </FormHelperText>
            )}
            <FormHelperText minH={"18px"}>
              {remainingCharacters > 0 && `${remainingCharacters} characters remaining`}
            </FormHelperText>
          </FormControl>
        </VStack>
        <FormActions
          isLoading={isBusy}
          isValid={formMethods.formState.isValid}
          onCancel={onCancel}
        />
      </form>
    </FormProvider>
  );
}
