import { useEffect, useState } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
  Icon,
  Divider,
} from "@chakra-ui/react";

import { FaTrash } from "react-icons/fa/index.esm.js";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";

import { fetchOrderById } from "@/hooks/orderHooks.js";
import { useShowError } from "@/lib/error.js";
import { deleteSupporCall } from "@/lib/supportCallLib.jsx";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {TOrderSupportCall} props.selectedSupportCall
 * @param {TOrder} [props.order]
 * @returns {JSX.Element}
 */
export default function DeleteSupportCallModal({
  isOpen,
  onClose,
  selectedSupportCall,
  order: orderIn,
}) {
  const { showError } = useShowError();
  const [isDeleting, setIsDeleting] = useState(false);
  const [order, setOrder] = useState(orderIn);
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (orderIn && orderIn.id === selectedSupportCall.orderId) {
      setOrder(orderIn);
      return;
    }
    fetchOrderById(selectedSupportCall.orderId)
      .then((fetchedOrder) => {
        if (fetchedOrder) {
          setOrder(fetchedOrder);
          return;
        }
        showError(new Error("Order not found"));
      })
      .catch(showError);
  }, [orderIn, selectedSupportCall.orderId, isOpen]);

  return (
    <Modal isOpen={Boolean(isOpen)} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={0}>
            <Divider />
            <Text fontWeight="bold" p={4}>
              {[
                selectedSupportCall?.location,
                selectedSupportCall?.type,
                selectedSupportCall?.driveTime
                  ? dayjs
                      .tz(getFieldAsDate(selectedSupportCall?.driveTime))
                      .format("ddd D MMM h.mma")
                  : "",
              ]
                .filter(Boolean)
                .join(" - ")}
            </Text>
            {order && (
              <Text fontWeight="bold" p={4}>
                {[order.firstName + " " + order.lastName, "#" + order.lookupId]
                  .filter(Boolean)
                  .join(" - ")}
              </Text>
            )}
            <Divider />
            <Divider />
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button isDisabled={isDeleting} size="sm" onClick={onClose}>
            CANCEL
          </Button>
          <Spacer />
          <Button
            size="sm"
            isLoading={isDeleting}
            onClick={async () => {
              try {
                if (!selectedSupportCall) {
                  throw new Error("Order or support call not found");
                }
                const orderObj = order || (await fetchOrderById(selectedSupportCall.orderId));
                setIsDeleting(true);
                await deleteSupporCall(selectedSupportCall, orderObj);
                onClose();
              } catch (error) {
                showError(error);
              } finally {
                setIsDeleting(false);
              }
            }}
            leftIcon={<Icon as={FaTrash} />}
          >
            DELETE
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
