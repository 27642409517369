export function isOutOfRange(skier) {
  if (skier.height == 99 || skier.shoe == "TBC") return true;

  const shoeVal = Number(skier.shoe.replace("JR", "").replace("M", "").replace("F", ""));

  if (skier.isJunior) {
    return (
      shoeVal < rangeData.JuniorHeight[skier.height].shoeMin ||
      shoeVal > rangeData.JuniorHeight[skier.height].shoeMax
    );
  } else {
    return (
      skier.weight < rangeData[skier.gender + "Height"][skier.height].weightMin ||
      skier.weight > rangeData[skier.gender + "Height"][skier.height].weightMax ||
      shoeVal < rangeData[skier.gender + "Height"][skier.height].shoeMin ||
      shoeVal > rangeData[skier.gender + "Height"][skier.height].shoeMax
    );
  }
}

const rangeData = {
  JuniorHeight: {
    1: {
      shoeMin: 15.5,
      shoeMax: 18.5,
    },
    2: {
      shoeMin: 15.5,
      shoeMax: 19.5,
    },
    3: {
      shoeMin: 16.5,
      shoeMax: 20.5,
    },
    4: {
      shoeMin: 17.5,
      shoeMax: 21.5,
    },
    5: {
      shoeMin: 18.5,
      shoeMax: 22.5,
    },
    6: {
      shoeMin: 19.5,
      shoeMax: 23.5,
    },
    7: {
      shoeMin: 20.5,
      shoeMax: 24.5,
    },
    8: {
      shoeMin: 21.5,
      shoeMax: 25.5,
    },
    9: {
      shoeMin: 22.5,
      shoeMax: 26.5,
    },
    10: {
      shoeMin: 22.5,
      shoeMax: 26.5,
    },
    11: {
      shoeMin: 22.5,
      shoeMax: 26.5,
    },
    12: {
      shoeMin: 23.5,
      shoeMax: 27.5,
    },
    13: {
      shoeMin: 24.5,
      shoeMax: 28.5,
    },
    14: {
      shoeMin: 25.5,
      shoeMax: 30.5,
    },
    15: {
      shoeMin: 26.5,
      shoeMax: 32.5,
    },
    16: {
      shoeMin: 27.5,
      shoeMax: 33.5,
    },
    17: {
      shoeMin: 27.5,
      shoeMax: 33.5,
    },
  },
  MaleHeight: {
    1: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    2: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    3: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    4: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    5: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    6: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    7: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    8: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    9: {
      weightMin: 8,
      weightMax: 13,
      shoeMin: 22.5,
      shoeMax: 25.5,
    },
    10: {
      weightMin: 9,
      weightMax: 14,
      shoeMin: 22.5,
      shoeMax: 26.5,
    },
    11: {
      weightMin: 9,
      weightMax: 15,
      shoeMin: 23.5,
      shoeMax: 26.5,
    },
    12: {
      weightMin: 10,
      weightMax: 16,
      shoeMin: 24.5,
      shoeMax: 27.5,
    },
    13: {
      weightMin: 10,
      weightMax: 17,
      shoeMin: 25.5,
      shoeMax: 28.5,
    },
    14: {
      weightMin: 11,
      weightMax: 19,
      shoeMin: 26.5,
      shoeMax: 30.5,
    },
    15: {
      weightMin: 12,
      weightMax: 20,
      shoeMin: 26.5,
      shoeMax: 31.5,
    },
    16: {
      weightMin: 13,
      weightMax: 21,
      shoeMin: 27.5,
      shoeMax: 33.5,
    },
    17: {
      weightMin: 14,
      weightMax: 21,
      shoeMin: 28.5,
      shoeMax: 33.5,
    },
  },
  FemaleHeight: {
    1: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    2: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    3: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    4: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    5: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    6: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    7: {
      weightMin: 0,
      weightMax: 99,
      shoeMin: 0,
      shoeMax: 99,
    },
    8: {
      weightMin: 8,
      weightMax: 11,
      shoeMin: 21.5,
      shoeMax: 24.5,
    },
    9: {
      weightMin: 8,
      weightMax: 11,
      shoeMin: 22.5,
      shoeMax: 24.5,
    },
    10: {
      weightMin: 8,
      weightMax: 12,
      shoeMin: 22.5,
      shoeMax: 25.5,
    },
    11: {
      weightMin: 8,
      weightMax: 14,
      shoeMin: 22.5,
      shoeMax: 26.5,
    },
    12: {
      weightMin: 9,
      weightMax: 16,
      shoeMin: 23.5,
      shoeMax: 26.5,
    },
    13: {
      weightMin: 10,
      weightMax: 17,
      shoeMin: 24.5,
      shoeMax: 27.5,
    },
    14: {
      weightMin: 10,
      weightMax: 18,
      shoeMin: 25.5,
      shoeMax: 27.5,
    },
    15: {
      weightMin: 11,
      weightMax: 18,
      shoeMin: 26.5,
      shoeMax: 28.5,
    },
    16: {
      weightMin: 12,
      weightMax: 18,
      shoeMin: 26.5,
      shoeMax: 28.5,
    },
    17: {
      weightMin: 13,
      weightMax: 19,
      shoeMin: 27.5,
      shoeMax: 29.5,
    },
  },
};
