/**
 * @param {TSkier} skier
 * @param {number} targetLength
 * @returns {(l: THardware, r: THardware) => number}
 */
export const compareAvailableHardwareV2 = (skier, targetLength) => (l, r) => {
  let result = 0;

  let hardwareIsSameGender = Boolean(l.isFemale) === Boolean(r.isFemale);
  if (!hardwareIsSameGender) {
    if (
      (skier.gender === "Female" && Boolean(l.isFemale)) ||
      (skier.gender === "Male" && !l.isFemale)
    ) {
      result = -1;
    } else if (
      (skier.gender === "Female" && Boolean(r.isFemale)) ||
      (skier.gender === "Male" && !r.isFemale)
    ) {
      result = 1;
    }
  }

  if (result === 0) {
    result = Math.abs(l.lengthActual - targetLength) - Math.abs(r.lengthActual - targetLength);
  }
  return result;
};

/**
 * @param {TSkier} skier
 * @param {number} targetLength
 * @returns {(l: THardware, r: THardware) => number}
 */
export const compareAvailableHardwareV1 = (skier, targetLength) => (l, r) => {
  return Math.abs(l.lengthActual - targetLength) - Math.abs(r.lengthActual - targetLength);
};
