import { useMemo, useState } from "react";

import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  HStack,
  IconButton,
  Icon,
  MenuOptionGroup,
  MenuItemOption,
  Avatar,
} from "@chakra-ui/react";

import { CiUser } from "react-icons/ci/index.esm.js";
import { MdFilterList } from "react-icons/md/index.esm.js";

import { getUserAvatarByUID } from "@/contexts/AuthContext.jsx";
import useUserList from "@/lib/users.js";
import { getAllActivityTypes } from "@/services/activityLog.js";

const UserAvatar = ({ user }) =>
  user.id && user.id !== "everyone" ? (
    <Avatar
      name={`${user.firstName} ${user.lastName}`}
      size="2xs"
      src={getUserAvatarByUID(user.id)}
      mr={2}
    />
  ) : null;

/**
 * @param {object} props
 * @param {TAllActivityTypes[]} props.selectdActivityTypes
 * @param {string|null} [props.selectedUserId=null]
 * @param {( userId:string|null, selectdActivityTypes:TAllActivityTypes[]) => void} props.onChange
 * @returns {JSX.Element}
 */
function ActivityFeedFilter({ selectdActivityTypes, selectedUserId = null, onChange }) {
  const activityTypeFilter = useMemo(() => new Set(selectdActivityTypes), [selectdActivityTypes]);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const handleActivityTypeChange = (type, removeFromSet) => {
    return (e) => {
      e.preventDefault();

      const newSet = new Set(activityTypeFilter);
      if (!removeFromSet) {
        newSet.add(type);
      } else {
        newSet.delete(type);
      }

      onChange(selectedUserId, Array.from(newSet));
    };
  };
  const { userList } = useUserList({ shouldFetch: isUserMenuOpen });

  return (
    <HStack align="start" pb={2} w="100%">
      <Box flexGrow={1} />
      <Menu isLazy onOpen={() => setIsUserMenuOpen(true)} onClose={() => setIsUserMenuOpen(false)}>
        <MenuButton
          as={IconButton}
          size="sm"
          variant={"outline"}
          icon={<Icon as={CiUser} boxSize={5} />}
        >
          Users
        </MenuButton>
        <MenuList>
          <MenuOptionGroup type="radio" value={selectedUserId ?? ""}>
            <MenuItemOption value={""} onClick={() => onChange("", selectdActivityTypes)}>
              All users
            </MenuItemOption>
            <MenuList
              width={"full"}
              overflowY="auto"
              maxHeight="calc(100vh - 250px)"
              borderBottom={0}
              borderRight={0}
              borderLeft={0}
              sx={{ "::-webkit-scrollbar": { display: "none" } }}
            >
              {userList.map((user) => (
                <MenuItemOption
                  key={user.id}
                  isChecked={selectedUserId === user.id}
                  onClick={() => {
                    onChange(user.id, selectdActivityTypes);
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <UserAvatar user={user} />
                    {user.firstName} {user.lastName}
                  </Box>
                </MenuItemOption>
              ))}
            </MenuList>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      <Menu closeOnSelect={false} isLazy>
        <MenuButton
          as={IconButton}
          size="sm"
          variant={"outline"}
          icon={<Icon as={MdFilterList} boxSize={5} />}
        >
          Activity type
        </MenuButton>
        <MenuList
          overflowY="auto"
          maxHeight="calc(100vh - 250px)"
          borderBottom={0}
          borderRight={0}
          borderLeft={0}
          sx={{ "::-webkit-scrollbar": { display: "none" } }}
        >
          {getAllActivityTypes().map((activityType) => (
            <MenuItemOption
              key={activityType.type}
              isChecked={activityTypeFilter.has(activityType.type)}
              onClick={handleActivityTypeChange(
                activityType.type,
                activityTypeFilter.has(activityType.type),
              )}
            >
              {activityType.description}
            </MenuItemOption>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  );
}

export default ActivityFeedFilter;
