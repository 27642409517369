import { useEffect, useState } from "react";

import { signal, effect } from "@preact/signals-core";

// Define signals for each mode
const modeSignals = {
  advancedMode: signal(false),
  pathRouteMode: signal(false),
};

// Configuration for each toggleable feature
/** @type {{ [key in keyof typeof modeSignals]:{detect: (e:KeyboardEvent) => boolean; toggle:() => void; } }} */
const toggleConfig = {
  advancedMode: {
    detect: (event) => event.key === "Shift" && event.ctrlKey && !event.altKey,
    toggle: () => (modeSignals.advancedMode.value = !modeSignals.advancedMode.value),
  },
  pathRouteMode: {
    detect: (event) => event.key === "Shift" && event.ctrlKey && event.altKey,
    toggle: () => (modeSignals.pathRouteMode.value = !modeSignals.pathRouteMode.value),
  },
};

Object.entries(modeSignals).forEach(([mode, signal]) =>
  effect(() => {
    console.log(`${mode} is:`, signal.value ? "ON" : "OFF");
  }),
);

/**
 * @type {{ [key in keyof typeof modeSignals]:{keyUpCount:number; keyPressCount:number; firstKeyPressTime:number;} }}
 */
const modeState = {
  advancedMode: { keyUpCount: 0, keyPressCount: 0, firstKeyPressTime: 0 },
  pathRouteMode: { keyUpCount: 0, keyPressCount: 0, firstKeyPressTime: 0 },
};

/**
 * @param {KeyboardEvent} event
 */
const handleKeyPress = (event) => {
  for (const mode in modeState) {
    const config = toggleConfig[mode];
    if (!config.detect(event)) {
      continue;
    }

    const currentTime = Date.now();
    const state = modeState[mode];

    if (state.keyPressCount === 0 || currentTime - state.firstKeyPressTime > 3000) {
      state.firstKeyPressTime = currentTime;
      state.keyPressCount = 1;
      state.keyUpCount = 0;
    } else {
      state.keyPressCount++;
    }

    if (state.keyPressCount >= 3 && state.keyUpCount >= 2) {
      config.toggle();
      state.keyUpCount = 0;
      state.keyPressCount = 0;
      state.firstKeyPressTime = 0;
    }
  }
};

document.addEventListener("keydown", handleKeyPress);
document.addEventListener("keyup", (event) => {
  Object.entries(toggleConfig).forEach(([mode, config]) => {
    if (config.detect(event)) {
      modeState[mode].keyUpCount++;
    }
  });
});

export const useAdvancedMode = () => {
  const [isAdvancedModeOn, setIsAdvancedModeOn] = useState(modeSignals.advancedMode.value);
  const [isPathRouteModeOn, setIsPathRouteModeOn] = useState(modeSignals.pathRouteMode.value);
  useEffect(() => {
    return effect(() => {
      setIsAdvancedModeOn(modeSignals.advancedMode.value);
      setIsPathRouteModeOn(modeSignals.pathRouteMode.value);
    });
  }, []);
  return { isAdvancedModeOn, isPathRouteModeOn };
};
