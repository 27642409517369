/**
 * @typedef {object} TOrderRentalInventorySummary
 * @property {number} skis
 * @property {number} snowboards
 * @property {number} boots
 * @property {number} jackets
 * @property {number} pants
 * @property {number} helmets
 * @property {number} polesSet
 */
/**
 * @param {TConfigData} config
 * @param {TSkier[]} skiers
 * @returns {TOrderRentalInventorySummary}
 */
export const getOrderRentalInventorySummary = (config, skiers) => {
  let skis = 0;
  let snowboards = 0;
  let boots = 0;
  let jackets = 0;
  let pants = 0;
  let helmets = 0;
  let polesSet = 0;

  skiers.forEach((skier) => {
    if (skier.selectedHardware?.type == "Ski") {
      skis++;
      polesSet++;
    }
    if (skier.selectedHardware?.type == "Snowboard") {
      snowboards++;
    }

    if (Boolean(skier.ownBoots) === false && skier.packageType && skier.selectedBoot) {
      boots++;
    }
    if (skier.jacket == true) {
      jackets++;
    }
    if (skier.pants == true) {
      pants++;
    }
    if (skier.helmet == true) {
      helmets++;
    }

    Object.values(skier.sundries ?? {}).forEach((skierSundries) => {
      if (skierSundries.type !== "Rental") {
        return;
      }
      const { packageItemType } = config.sundries?.[skierSundries.sundriesId] ?? skierSundries;

      switch (packageItemType) {
        case "boots":
        case "boots-deluxe":
          boots++;
          break;
        case "poles":
          polesSet++;
          break;
      }
    });
  });

  return { skis, snowboards, boots, jackets, pants, helmets, polesSet };
};

/**
 * @param {TConfigData} config
 * @param {TSkier} skier
 * @returns {boolean}
 */
export const isSkierRentalInventoryCollected = (config, skier) => {
  const hardwareChecked = skier?.progress?.collecthardware || !skier.selectedHardware;
  let bootChecked = skier?.progress?.collectboot || skier.ownBoots || !skier.selectedBoot;
  let jacketChecked = false;
  let pantsChecked = false;
  let helmetChecked = false;

  if (skier.jacket) {
    jacketChecked = Boolean(skier.progress?.collectjacket);
  } else {
    jacketChecked = true;
  }

  if (skier.pants) {
    pantsChecked = Boolean(skier.progress?.collectpants);
  } else {
    pantsChecked = true;
  }

  if (skier.helmet) {
    helmetChecked = Boolean(skier.progress?.collecthelmet);
  } else {
    helmetChecked = true;
  }

  return hardwareChecked && bootChecked && jacketChecked && pantsChecked && helmetChecked;
};

/**
 * @param {TConfigData} config
 * @param {TSkier} skier
 * @param {TPackageItemType[]} packageItemTypes
 */
export const sundriesByPackageItemType = (config, skier, packageItemTypes) => {
  return Object.values(skier.sundries ?? {}).filter((skierSundries) => {
    const item = config.sundries?.[skierSundries.sundriesId] ?? skierSundries;
    return Boolean(item.packageItemType && packageItemTypes.includes(item.packageItemType));
  });
};
/**
 * @param {TConfigData} config
 * @param {TSkier} skier
 * @param {TSundriesType} sundriesType
 */
export const sundriesByType = (config, skier, sundriesType) => {
  return Object.values(skier.sundries ?? {}).filter((skierSundries) => {
    const item = config.sundries?.[skierSundries.sundriesId] ?? skierSundries;
    return item.type === sundriesType;
  });
};
