import { Text, Icon, HStack } from "@chakra-ui/react";

import { MdDoubleArrow } from "react-icons/md/index.esm.js";

const theme = {
  "Junior Ski": "#06cadf",
  "Tweener Ski": "#02b0e0",
  "Progression Ski": "#965A38",
  "Performer Ski": "#A8A8A8",
  "Premier Ski": "#D9A441",
  "Junior Snowboard": "#06cadf",
  "Performer Snowboard": "#A8A8A8",
  "Premier Snowboard": "#D9A441",
  Unknown: "red",
};
/**
 *
 * @param {Object} props
 * @param {string} [props.suffix]
 * @param {string} [props.packageName]
 * @returns {JSX.Element}
 */
export default function PackageName({ packageName, suffix }) {
  return (
    <HStack spacing={0}>
      <Icon
        position="relative"
        left="-4px"
        mr="-4px"
        boxSize={4}
        as={MdDoubleArrow}
        color={theme[packageName?.replace("Select ", "") || "Unknown"]}
        sx={{ transform: "rotate(90deg)" }}
      />
      <Text as="span" fontSize="sm" isTruncated>
        {packageName?.replace("Snowboard", "SB") ?? "???"}
        {suffix}
      </Text>
    </HStack>
  );
}
