import { Text, Button, HStack, Divider, Icon, Spacer } from "@chakra-ui/react";

import { MdEventRepeat } from "react-icons/md/index.esm.js";

import { useOrderModalContext } from "../../../contexts/OrderModalContext.jsx";

/**
 *
 * @param {Object} props
 * @param {string} props.customerName
 * @param {string} props.orderId
 * @param {string} [props.previousOrderId]
 * @param {boolean} [props.showEditMode]
 * @param {boolean} [props.isEditMode]
 * @param {() => void} [props.toggleEditMode]
 * @param {string} [props.referrer]
 * @returns {JSX.Element}
 */
export default function StepHeader({
  customerName,
  orderId,
  previousOrderId,
  showEditMode,
  isEditMode,
  toggleEditMode,
  referrer,
}) {
  const { setModalId } = useOrderModalContext();

  return (
    <>
      <HStack mb={2}>
        {(previousOrderId || referrer == "Repeat Client") && (
          <Icon boxSize={5} mt="1px" color="brand.500" as={MdEventRepeat} />
        )}
        <Text fontSize="xl" fontWeight="bold" mr={3}>
          {customerName}
        </Text>
        <Button
          size="xs"
          onClick={(e) => {
            setModalId(orderId, Boolean(e.ctrlKey || e.shiftKey));
          }}
        >
          VIEW BOOKING
        </Button>
        {previousOrderId && (
          <Button
            color="gray.400"
            variant="outline"
            size="xs"
            onClick={(e) => {
              setModalId(previousOrderId, Boolean(e.ctrlKey || e.shiftKey));
            }}
          >
            VIEW PREVIOUS
          </Button>
        )}
        <Spacer />
        {showEditMode && (
          <Button onClick={toggleEditMode} size="xs" colorScheme={isEditMode ? "brand" : "gray"}>
            {isEditMode ? "DONE EDITING" : "EDIT MODE"}
          </Button>
        )}
      </HStack>
      <Divider mb={isEditMode ? 2 : 2} borderColor="black" />
    </>
  );
}
