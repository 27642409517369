import { HStack, Badge, GridItem } from "@chakra-ui/react";

import { db } from "@/services/firebase.js";

import { doc, updateDoc } from "firebase/firestore";

import { calcClothingSize } from "@/data/calcClothingSize.js";

import CheckImage from "../../common/CheckImage.jsx";
import CheckEmptyItem from "./CheckEmptyItem.jsx";

/**
 *
 * @param {object} props
 * @param {TSkier} props.skier
 * @param {string} props.orderId
 * @param {TTypeOfCosts} props.type
 * @param {string} props.prefix
 */
export default function CheckOuterwear({ skier, orderId, type, prefix }) {
  function toggle() {
    const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
    updateDoc(skierDoc, {
      [`progress.${prefix}${type}`]: !skier?.progress?.[prefix + type],
    });
  }

  return (
    <>
      {skier[type] ? (
        <GridItem justifySelf="center">
          <HStack as="button" alignItems="center" onClick={() => toggle()} position="relative">
            <CheckImage type={type} isChecked={skier.progress?.[`${prefix}${type}`]} />
            <Badge position="absolute" top="0px" left="-16px">
              {calcClothingSize(skier, type)}
            </Badge>
          </HStack>
        </GridItem>
      ) : (
        <CheckEmptyItem orderId={orderId} skier={skier} type={type} />
      )}
    </>
  );
}
