import { db, functions, auth } from "@/services/firebase.js";

import {
  doc,
  collection,
  serverTimestamp,
  writeBatch,
  deleteField,
  WriteBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { OrderSchema } from "@snopro/common/models.js";
import {
  OrderSaveCustomerSignaturePayloadSchema,
  OrderAPIDeletePayloadSchema,
  OrderAPIDuplicatePayloadSchema,
} from "@snopro/common/requests.js";

import { mapUpdateFirestoreToChangeLog } from "./firebase-utils.js";
import { getReferrersByLocation } from "./referrers.js";
import { registerSystemAlertInactiveOrder } from "./systemAlertLib.js";

const OrderSaveCustomerSignatureApi = httpsCallable(functions, "OrderSaveCustomerSignature");
const OrderAPICallable = httpsCallable(functions, "OrderAPI");
/**
 * @param {SAS2.API.TOrderAPI} data
 * @returns {Promise<any>}
 */
const orderApi = async (data) => {
  const response = await OrderAPICallable(data);
  return response.data;
};

/**
 * @param {TOrderSaveCustomerSignaturePayload} data
 * @returns {Promise<void>}
 */
export const saveCustomerSignature = async (data) => {
  await OrderSaveCustomerSignatureApi(OrderSaveCustomerSignaturePayloadSchema.parse(data));
};

/**
 * @param {TOrder['id']} orderId
 * @returns {Promise<{id:string; lookupId:string;} >}
 */
export const duplicateOrder = async (orderId) => {
  /** @type {SAS2.API.TOrderAPIDuplicatePayload} */
  const payload = { orderId };

  const response = await orderApi({
    operation: "duplicate",
    payload: OrderAPIDuplicatePayloadSchema.parse(payload),
  });

  return response;
};

/**
 * @param {TOrder['id']} orderId
 * @param {boolean} [ignoreUnrefundedPayments]
 * @returns {Promise<void>}
 */
export const deleteOrder = async (orderId, ignoreUnrefundedPayments) => {
  /** @type {SAS2.API.TOrderAPIDeletePayload} */
  const payload = { orderId };
  if (ignoreUnrefundedPayments) {
    payload.ignoreUnrefundedPayments = ignoreUnrefundedPayments;
  }

  await orderApi({ operation: "delete", payload: OrderAPIDeletePayloadSchema.parse(payload) });
};

/**
 * @param {Partial<TOrder|TOrderAndSkiers> & { id:string}} order
 * @param {SAS2.firebase.FirestoreFieldPatch<TOrder>} payload
 * @param {TOrderChangeSource} source
 * @param {WriteBatch} [requestBatch]
 */
export const updateOrder = async (order, payload, source, requestBatch) => {
  if (!order.id) {
    throw new Error("Missing order id");
  }
  if (typeof payload.email === "string") {
    const parsedValue = OrderSchema.shape.email.safeParse(payload.email);
    if (!parsedValue.success) {
      throw new Error("Invalid email");
    }
    payload.email = parsedValue.data;
  }
  if (payload.activeUser === null) {
    payload.activeUser = deleteField();
  }
  if (
    typeof payload.state === "string" &&
    payload.state !== "toSize" &&
    order.location &&
    order.referrer?.id
  ) {
    const referrerList = await getReferrersByLocation(order.location);
    const referrer = referrerList.find((r) => order.referrer && r.id === order.referrer.id);
    if (referrer && referrer.blockOrderStateChange) {
      throw new Error("Select the correct referrer before changing the order state");
    }
  }
  const batch = requestBatch || writeBatch(db);
  const orderDoc = doc(db, "orders", order.id);
  const newChangeDoc = doc(collection(db, "orders", order.id, "changes"));

  if ("firstName" in payload) {
    if (!payload.firstName?.trim()) {
      throw new Error("First name is required");
    }
    payload.firstName = payload.firstName.trim();
  }

  if ("lastName" in payload) {
    if (!payload.lastName?.trim()) {
      throw new Error("Last name is required");
    }
    payload.lastName = payload.lastName.trim();
  }

  // order is being set as completed, check if the payment status is OK
  if (payload.state === "inactive" && order.state !== "inactive") {
    const parsedOrder = OrderSchema.safeParse(order);
    if (!parsedOrder.success) {
      throw new Error("Invalid order data");
    }
    if ("skiers" in order === false || !order.skiers) {
      throw new Error("Missing skiers in order");
    }
    for (const skier of order.skiers) {
      if (skier.selectedHardware?.id) {
        throw new Error("Cannot set order as inactive with selected hardware");
      }
    }

    await registerSystemAlertInactiveOrder(parsedOrder.data, source, batch);
  }

  batch.update(orderDoc, payload);
  const user = auth.currentUser
    ? { id: auth.currentUser.uid, name: auth.currentUser.displayName ?? "" }
    : undefined;
  /** @type {TOrderChange} */
  const changeData = {
    source,
    ...(user && { user }),
    orderId: order.id,
    order: mapUpdateFirestoreToChangeLog(payload),
    createdAt: new Date(),
    status: "queued",
  };

  batch.set(newChangeDoc, {
    ...changeData,
    createdAt: serverTimestamp(),
  });
  if (!requestBatch) {
    await batch.commit();
  }
};

/**
 * @param {Partial<TOrder> & { id:string}} order
 * @param {keyof TOrder} name
 * @param {*} value
 * @param {TOrderChangeSource} source
 */
export const updateOrderField = async (order, name, value, source) => {
  if (!order) {
    throw new Error("Missing order id");
  }

  await updateOrder(order, { [name]: value }, source);
};

/**
 * @description Get a base64 encoded image of the customer signature
 * @param {string} orderId
 * @returns {Promise<string>}
 */
export const getCustomerSignature = async (orderId) =>
  orderApi({ operation: "get-customer-delivery-signature", payload: { orderId } });

/**
 * @param {string} orderId
 */
export const sendOrderReceipt = async (orderId) => {
  await orderApi({ operation: "send-summary-email", payload: { orderId } });
};

/**
 * @param {object} param
 * @param {string} param.orderId
 * @param {string} param.reason
 * @returns {Promise<void>}
 */
export const orderUnpaidDelivery = async ({ orderId, reason }) => {
  await orderApi({ operation: "unpaid-delivery", payload: { orderId, reason } });
};

/**
 * @param {object} param
 * @param {string} param.orderId
 * @returns {Promise<void>}
 */
export const orderRecalculateTotals = async ({ orderId }) => {
  await orderApi({ operation: "re-calculate-totals", payload: { orderId } });
};

/**
 * @param {object} param
 * @param {string} param.orderId
 * @returns {Promise<void>}
 */
export const orderCompleteDelivery = async ({ orderId }) => {
  await orderApi({ operation: "complete-delivery", payload: { orderId } });
};

/**
 * @param {object} param
 * @param {TOrderAndSkiers} param.order
 * @param {number} [param.discountPercentage]
 * @param {number} [param.dayAdjustment]
 * @param {TSkierAdjustmentDayPosition} [param.dayAdjustmentPosition]
 * @param {TOrderChangeSource} param.source
 * @returns {Promise<void>}
 */

export const orderApplyDiscountOrDaysAdjusments = async ({
  order,
  discountPercentage,
  dayAdjustment,
  dayAdjustmentPosition,
  source,
}) => {
  if (order.state === "completed") {
    throw new Error("Cannot apply discounts or days adjustments to a completed order");
  }
  if (typeof discountPercentage === "number" && order.autoCalcDiscount) {
    throw new Error("Cannot apply discounts to an order with auto-calculated discounts");
  }
  if (typeof dayAdjustment === "number" && Math.abs(dayAdjustment) > order.skiDays) {
    throw new Error("Cannot adjust days to more than the number of ski days");
  }

  /** @type {Required<TOrderChange>["skiers"]} */
  const skiersChanges = [];

  const batch = writeBatch(db);
  order.skiers.forEach((skier) => {
    /** @type {Required<TOrderChange>["skiers"][number]} */
    const skierChange = {
      id: skier.id,
      changeType: "update",
      skier,
    };

    const changes = {};

    if (typeof discountPercentage === "number") {
      /** @type {Record<TTypeOfDiscounts,number>} */
      const discountsPercentageChanges = {
        bootUpgrade: discountPercentage,
        helmet: discountPercentage,
        combo: discountPercentage,
        jacket: discountPercentage,
        pants: discountPercentage,
        packageType: discountPercentage,
      };
      changes.discountsPercentage = discountsPercentageChanges;
      Object.entries(skier.sundries ?? {}).forEach(([sundriesId, sundries]) => {
        if (sundries.type !== "Rental") {
          return;
        }
        changes[`sundries.${sundriesId}.discountPercentage`] = discountPercentage;
      });
    }

    if (typeof dayAdjustment === "number" || typeof dayAdjustmentPosition !== "undefined") {
      const adjustmentChanges = { ...skier.adjustments };
      if (typeof dayAdjustment === "number") {
        adjustmentChanges.bootUpgrade_days = dayAdjustment;
        adjustmentChanges.combo_days = dayAdjustment;
        adjustmentChanges.helmet_days = dayAdjustment;
        adjustmentChanges.insurance_days = dayAdjustment;
        adjustmentChanges.jacket_days = dayAdjustment;
        adjustmentChanges.ownBoots_days = dayAdjustment;
        adjustmentChanges.packageType_days = dayAdjustment;
        adjustmentChanges.pants_days = dayAdjustment;
        Object.entries(skier.sundries ?? {}).forEach(([sundriesId, sundries]) => {
          if (sundries.type !== "Rental") {
            return;
          }
          changes[`sundries.${sundriesId}.adjustmentDays`] = dayAdjustment;
        });
      }
      if (typeof dayAdjustmentPosition !== "undefined") {
        adjustmentChanges.packageType_pos = dayAdjustmentPosition;
      }
      changes.adjustments = adjustmentChanges;
    }

    if (Object.keys(changes).length) {
      /** @type {any} */
      const changesAsAny = changes;
      skierChange.updatePayload = mapUpdateFirestoreToChangeLog(changes);
      skiersChanges.push(skierChange);
      const skierDoc = doc(db, "orders", order.id, "skiers", skier.id);
      batch.update(skierDoc, changesAsAny);
    }
  });

  const newChangeDoc = doc(collection(db, "orders", order.id, "changes"));
  const user = auth.currentUser
    ? { id: auth.currentUser.uid, name: auth.currentUser.displayName ?? "" }
    : undefined;
  /** @type {TOrderChange} */
  const changeData = {
    source,
    ...(user && { user }),
    orderId: order.id,
    createdAt: new Date(),
    status: "queued",
    skiers: skiersChanges,
  };
  batch.set(newChangeDoc, changeData);
  await batch.commit();
};
