import { useState } from "react";

import {
  AlertDescription,
  Box,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  useToast,
  CloseButton,
} from "@chakra-ui/react";

import { useOrderModalContext } from "@/contexts/OrderModalContext.jsx";
import { useShowError } from "@/lib/error.js";
import { duplicateOrder } from "@/lib/order.api.js";

const useDuplicateOrder = () => {
  const { showError } = useShowError();
  const [isLoading, setIsLoading] = useState(false);
  const { setModalId } = useOrderModalContext();
  const toast = useToast();

  /**
   * @param {string} orderId
   * @returns {Promise<{id:string; lookupId:string}|undefined>}
   */
  const processDuplicateOrder = async (orderId) => {
    setIsLoading(true);

    try {
      const response = await duplicateOrder(orderId);

      toast({
        title: `Order duplicated successfully #${response.lookupId}`,
        render: ({ onClose }) => (
          <Alert status="success" variant="solid">
            <AlertIcon />
            <Box>
              <AlertTitle>Order duplicated successfully!</AlertTitle>
              <AlertDescription>
                <Text
                  as="button"
                  textDecoration={"underline"}
                  onClick={(e) => {
                    const isCtrlOrShiftPressed = Boolean(e.ctrlKey || e.shiftKey);
                    setModalId(response.id, isCtrlOrShiftPressed);
                    if (isCtrlOrShiftPressed) {
                      return;
                    }
                    onClose();
                  }}
                >
                  View #{response.lookupId} order
                </Text>
              </AlertDescription>
            </Box>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={onClose}
            />
          </Alert>
        ),
        status: "success",
        duration: 10000,
      });

      return response;
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, processDuplicateOrder };
};

export default useDuplicateOrder;
