import { useState } from "react";

import { useToast } from "@chakra-ui/react";

import { twoDecimals } from "@snopro/common/numbers.js";

import { processPaymentAPI } from "@/lib/payments.api.js";

const usePaymentProcessing = ({ order, source, onComplete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const processOrderPayment = async (paymentDetails) => {
    setIsLoading(true);

    try {
      await processPaymentAPI({
        ...paymentDetails,
        amount: twoDecimals(Number(paymentDetails.amount)),
        orderId: order.id,
        source,
      });

      toast({
        title: "Payment completed successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onComplete();
    } catch (error) {
      toast({
        title: "Error processing payment",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, processOrderPayment };
};

export default usePaymentProcessing;
