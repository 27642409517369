import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Box,
  useToast,
} from "@chakra-ui/react";

import OrderPaymentsList from "@/components/payment/OrderPaymentsList/OrderPaymentsList.jsx";
import { useShowError } from "@/lib/error.js";
import { lightspeedCreateSales } from "@/lib/lightspeed.api.js";
import { useLocationConfig } from "@/lib/locationLib.js";

import PaymentModal from "./PaymentModal.jsx";

/**
 * PaymentsListModal component.
 * Renders a modal for lists payments made against an order
 * @param {Object} props - The component props.
 * @param {TOrderAndSkiersWithItems} props.order - The order object.
 * @param {boolean} props.isOpen - Whether the modal is open or not.
 * @param {() => void} props.onClose - The function to close the modal.
 * @returns {JSX.Element} The PaymentsListModal component.
 */
export default function PaymentsListModal({ order, isOpen, onClose }) {
  const toast = useToast();
  const { showError } = useShowError();
  const addPaymentDisclosure = useDisclosure();
  const { isPOSEnabled } = useLocationConfig(order.location);

  return (
    <>
      <Modal
        size="6xl"
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mb={3}>
            <OrderPaymentsList
              order={order}
              source="manager"
              title={
                <>
                  <ModalHeader>Payments</ModalHeader>
                  {order.state !== "inactive" && order.balance > 0 && (
                    <>
                      <Button onClick={addPaymentDisclosure.onOpen} colorScheme={"brand"}>
                        Make a payment
                      </Button>
                      {isPOSEnabled && (
                        <Button
                          onClick={() => {
                            lightspeedCreateSales({ orderId: order.id })
                              .then(() =>
                                toast({
                                  title: `Sales created in Lightspeed for order #${order.lookupId}`,
                                  status: "success",
                                  duration: 5000,
                                  isClosable: true,
                                }),
                              )
                              .catch(showError);
                          }}
                          colorScheme={"brand"}
                          ml={3}
                        >
                          Send to P.O.S
                        </Button>
                      )}
                    </>
                  )}
                </>
              }
              columnsToShow={[
                "actions",
                "date",
                "type",
                "made",
                "method",
                "amount",
                "refundAvailable",
                "details",
                "user",
              ]}
            />
            {order.balance > 0 && order.unpaidDelivery?.reason && (
              <Box ml={3}>
                <Text as="span" fontStyle={"italic"} color={"red"}>
                  *order has been marked as unpaid delivery by {order.unpaidDelivery.user.name}:
                </Text>{" "}
                <Text as="span">{order.unpaidDelivery?.reason}</Text>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <PaymentModal
        isOpen={addPaymentDisclosure.isOpen}
        onClose={addPaymentDisclosure.onClose}
        order={order}
        source={"manager"}
      />
    </>
  );
}
