import { useEffect, useState } from "react";

import { db } from "@/services/firebase.js";

import { collection, getDocs, query, where } from "firebase/firestore";

import { ReferrerSchema } from "@snopro/common/models.js";

/** @type {Record<TLocation,TReferrer[]>}  */
const __referrersCacheByLocation = {
  Hilton: [],
  Queenstown: [],
  Wanaka: [],
};
/**
 * @param {TLocation} location
 * @returns {Promise<TReferrer[]>}
 */
export const getReferrersByLocation = async (location) => {
  if (!__referrersCacheByLocation[location]) {
    return [];
  }
  if (!__referrersCacheByLocation[location].length) {
    const q = query(collection(db, "referrers"), where("location", "==", location));
    const querySnapshot = await getDocs(q);
    __referrersCacheByLocation[location] = querySnapshot.docs.map((doc) =>
      ReferrerSchema.parse({ id: doc.id, ...doc.data() }),
    );
  }
  return __referrersCacheByLocation[location];
};

/** @typedef {Pick<TReferrer, "name"|"id"|"route"|"blockOrderStateChange">} TReferrerItem */

/** @returns {TReferrerItem[]} */
const __defaultReferrers = [];
/**
 * React hook to get referrers by location
 * @param {TLocation} [location]
 * @returns {TReferrerItem[]}
 */
export const useReferrersByLocation = (location) => {
  const [referrers, setReferrers] = useState(__defaultReferrers);

  useEffect(() => {
    const updateReferrers = async () => {
      if (!location) {
        return;
      }

      setReferrers(await getReferrersByLocation(location));
    };

    updateReferrers();
  }, [location]);

  return referrers;
};
