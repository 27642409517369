import { Flex, Box, Heading, Link, Text } from "@chakra-ui/react";

import { IoSettingsSharp } from "react-icons/io5/index.esm.js";
import { TbReport } from "react-icons/tb/index.esm.js";

import { app } from "@/services/firebase.js";

import { NavLink } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext.jsx";
import { OrderNavigationHistory } from "../common/OrderNavigationHistory.jsx";
import SearchDrawer from "./SearchDrawer.jsx";
import UserDrawer from "./UserDrawer.jsx";

/**
 * @returns {JSX.Element}
 */
export default function AppNav() {
  const { currentUserDetails } = useAuth();

  if (!currentUserDetails) {
    return <></>;
  }

  return (
    <>
      <Flex alignContent="center" h="100%" alignItems="center">
        <UserDrawer />
        {(import.meta.env.VITE_APP_ENV != "prod" || location.hostname === "localhost") &&
          import.meta.env.VITE_APP_LOCAL_FIRESTORE != "true" && (
            <Box ml={5}>
              {app.options.projectId !== "sas2-test" ? (
                <Text fontWeight="bold" color="red">
                  {app.options.projectId}
                </Text>
              ) : (
                <Text fontWeight="bold" color="blue">
                  TEST
                </Text>
              )}
            </Box>
          )}
        <Box flex="1">
          <Flex justifyContent="center" justifyItems="center">
            <Link
              as={NavLink}
              to="/"
              _hover={{ textDecoration: "none" }}
              _focus={{ outline: "none" }}
              _activeLink={{ color: "brand.500" }}
            >
              <Box px={4}>
                <Heading size="md">OVERVIEW</Heading>
              </Box>
            </Link>
            <Link
              as={NavLink}
              to="/size"
              _hover={{ textDecoration: "none" }}
              _focus={{ outline: "none" }}
              _activeLink={{ color: "brand.500" }}
            >
              <Box px={4}>
                <Heading size="md">ALLOCATE</Heading>
              </Box>
            </Link>
            {currentUserDetails.defaultLocation != "Hilton" && (
              <Link
                as={NavLink}
                to="/pack"
                _hover={{ textDecoration: "none" }}
                _focus={{ outline: "none" }}
                _activeLink={{ color: "brand.500" }}
              >
                <Box px={4}>
                  <Heading size="md">PACK</Heading>
                </Box>
              </Link>
            )}
            <Link
              as={NavLink}
              to="/drive"
              _hover={{ textDecoration: "none" }}
              _focus={{ outline: "none" }}
              _activeLink={{ color: "brand.500" }}
            >
              <Box px={4}>
                <Heading size="md">
                  {currentUserDetails.defaultLocation != "Hilton" ? "DRIVE" : "SCHEDULE"}
                </Heading>
              </Box>
            </Link>
            <Link
              as={NavLink}
              to="/gear"
              _hover={{ textDecoration: "none" }}
              _focus={{ outline: "none" }}
              _activeLink={{ color: "brand.500" }}
            >
              <Box px={4}>
                <Heading size="md">GEAR</Heading>
              </Box>
            </Link>

            <Link
              as={NavLink}
              to="/settings"
              _hover={{ textDecoration: "none" }}
              _focus={{ outline: "none" }}
              _activeLink={{ color: "brand.500" }}
            >
              <Box px={4}>
                <Heading size="md" mt="2px">
                  <IoSettingsSharp />
                </Heading>
              </Box>
            </Link>
            {currentUserDetails.isManager && (
              <Box mt={"-4px"}>
                <OrderNavigationHistory />
              </Box>
            )}
          </Flex>
        </Box>
        <SearchDrawer />
      </Flex>
    </>
  );
}
