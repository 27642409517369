import { useEffect, useState } from "react";

import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  VStack,
  useDisclosure,
  Icon,
  Switch,
} from "@chakra-ui/react";

import { FiEdit } from "react-icons/fi/index.esm.js";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import { LocationSchema } from "@snopro/common/models.js";
import dayjs from "dayjs";

import { getLoggedInUser, useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useShowError } from "@/lib/error.js";
import { useLocations } from "@/lib/locationLib.js";
import {
  completeDirectSupportCall,
  createDirectSupportCall,
  getSupportCallTypes,
  updateDirectSupportCall,
} from "@/lib/supportCallLib.jsx";

import EditableField from "../common/EditableField.jsx";
import ChangeDateTimeModal from "./ChangeDateTimeModal.jsx";

/**
 * @param  {dayjs.Dayjs} [date]
 * @returns {TDirectSupportCall}
 */
function getDefaultNewSupportCall(date) {
  const user = getLoggedInUser();
  let driveTime = dayjs.tz().set("second", 0).set("millisecond", 0);
  if (date) {
    driveTime = driveTime
      .set("year", date.year())
      .set("month", date.month())
      .set("date", date.date());
  }
  if (driveTime.minute() < 30) {
    driveTime = driveTime.set("minutes", 30);
  } else {
    driveTime = driveTime.add(1, "hour").set("minutes", 0);
  }
  return {
    id: "",
    comments: "",
    createdByUser: {
      id: "",
      firstName: "",
      lastName: "",
    },
    createTimestamp: new Date(),
    driveTime: driveTime.toDate(),
    location: user.defaultLocation,
    type: getSupportCallTypes()[0],
  };
}
/**
 * @param {object} props
 * @param {TChakraDisclosure} props.selfDisclosure
 * @param {dayjs.Dayjs} [props.deafaultDate]
 * @param {TDirectSupportCall} [props.supportCall]
 * @param {"create"|"complete"} props.operation
 * @returns {JSX.Element}
 */
export default function DirectSupportCallModal({
  selfDisclosure,
  supportCall: supportCallIn,
  operation,
  deafaultDate,
}) {
  const { currentUserDetails } = useLoggedInUser();
  const { showError } = useShowError();
  const locationList = useLocations();
  const dateSelectModal = useDisclosure();
  const [supportCall, setSupportCall] = useState(
    supportCallIn ?? getDefaultNewSupportCall(deafaultDate),
  );
  useEffect(
    () => setSupportCall(supportCallIn ?? getDefaultNewSupportCall(deafaultDate)),
    [supportCallIn],
  );
  const handleInputChange = (name, value) => {
    setSupportCall({ ...supportCall, [name]: value });
  };
  const handleUpdateField = (fieldName, value) => {
    if (operation === "create") {
      return;
    }
    updateDirectSupportCall(supportCall, { [fieldName]: value }).catch((error) => {
      setSupportCall((prev) => ({ ...prev, [fieldName]: supportCallIn?.[fieldName] }));
      showError(error);
    });
  };
  const completeSupportCall = async () => {
    await completeDirectSupportCall(supportCall.id)
      .then(() => {
        selfDisclosure.onClose();
      })
      .catch(showError);
  };
  const createSupportCall = async () => {
    await createDirectSupportCall(supportCall)
      .then(() => {
        selfDisclosure.onClose();
      })
      .catch(showError);
  };

  return (
    <Modal size="md" isOpen={selfDisclosure.isOpen} onClose={selfDisclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{operation === "create" ? "New " : ""}Support Call</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={1}>
            <Box w="full" mb={4}>
              <HStack>
                <Text fontSize={10} fontWeight="bold">
                  When
                </Text>
                <Icon as={FiEdit} boxSize="10px" />
              </HStack>
              <Button
                as="text"
                onClick={() => {
                  if (supportCall.deliveryLocked) {
                    return;
                  }
                  dateSelectModal.onOpen();
                }}
                variant="unstyled"
                fontWeight="normal"
                cursor={supportCall.deliveryLocked ? "not-allowed" : "pointer"}
                isDisabled={Boolean(supportCall.deliveryLocked)}
              >
                {dayjs.tz(supportCall.driveTime).format("dddd, MMM D, YYYY h:mm A")}
              </Button>
            </Box>
            <Box w="full" pb={4}>
              <HStack>
                <Text>Time Locked</Text>
                <Switch
                  colorScheme="brand"
                  onChange={() => {
                    handleInputChange("deliveryLocked", !supportCall.deliveryLocked);
                    handleUpdateField("deliveryLocked", !supportCall.deliveryLocked);
                  }}
                  isChecked={Boolean(supportCall.deliveryLocked)}
                />
              </HStack>
            </Box>

            <Box w="full">
              <EditableField
                width="full"
                fieldName="addressDelivery"
                value={supportCall.addressDelivery}
                displayName="Where"
                displayType="textarea"
                handleInput={handleInputChange}
                handleUpdate={handleUpdateField}
                isSupportCall
              />
            </Box>
            <Box width="full">
              <Text fontSize={10} fontWeight="bold" mb={1}>
                Type of support needed
              </Text>
              <Select
                mb={6}
                value={supportCall.type}
                onChange={(e) => {
                  handleInputChange("type", e.target.value);
                  handleUpdateField("type", e.target.value);
                }}
              >
                {getSupportCallTypes().map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </Box>

            <Box width="full">
              <Text fontSize={10} fontWeight="bold" mb={1}>
                Team to respond
              </Text>
              <Select
                mb={6}
                value={supportCall.location}
                onChange={(e) => {
                  const locationId = LocationSchema.parse(e.target.value);
                  handleInputChange("location", locationId);
                  handleUpdateField("location", locationId);
                }}
              >
                {locationList.map((locationId) => (
                  <option key={locationId} value={locationId}>
                    {locationId}
                  </option>
                ))}
              </Select>
            </Box>

            <Box width="full">
              <EditableField
                width="full"
                fieldName="comments"
                value={supportCall.comments}
                displayName="Details"
                displayType="textarea"
                handleInput={handleInputChange}
                handleUpdate={handleUpdateField}
                isSupportCall
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={selfDisclosure.onClose}>
            CANCEL
          </Button>
          <Spacer />
          {operation === "create" && (
            <Button
              isDisabled={!supportCall.driveTime}
              colorScheme="brand"
              size="sm"
              onClick={createSupportCall}
            >
              CREATE SUPPORT CALL
            </Button>
          )}
          {operation === "complete" && (
            <Button colorScheme="brand" size="sm" onClick={completeSupportCall}>
              JOB COMPLETED BY {currentUserDetails.firstName.toUpperCase()}
            </Button>
          )}
        </ModalFooter>
        {dateSelectModal.isOpen && (
          <ChangeDateTimeModal
            isOpen={dateSelectModal.isOpen}
            onClose={dateSelectModal.onClose}
            dateCallback={(value) => {
              if (value === null) {
                return;
              }
              handleInputChange("driveTime", value);
              handleUpdateField("driveTime", value);
            }}
            date={getFieldAsDate(supportCall.driveTime)}
          />
        )}
      </ModalContent>
    </Modal>
  );
}
