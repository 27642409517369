import React, { Suspense, useCallback } from "react";

import { Box, HStack, useToast, VStack, Text } from "@chakra-ui/react";

import { Routes, Route, Navigate } from "react-router-dom";

import StepFlow from "./components/flows/steps/StepFlow.jsx";
import AppNav from "./components/global/AppNav.jsx";
import ErrorPage from "./components/global/ErrorPage.jsx";
import Drive from "./components/pages/Drive.jsx";
import Gear from "./components/pages/Gear.jsx";
import Login from "./components/pages/Login.jsx";
import OrderPage from "./components/pages/OrderPage.jsx";
import Overview from "./components/pages/Overview.jsx";
import Pack from "./components/pages/Pack.jsx";
import Size from "./components/pages/Size.jsx";
import { useAuth } from "./contexts/AuthContext.jsx";
import { ConfigProvider } from "./contexts/ConfigContext.jsx";
import { ItemModalContextProvider } from "./contexts/ItemModalContext.jsx";
import { OrderModalProvider } from "./contexts/OrderModalContext.jsx";
import { useWatchLatestWebSiteVersion } from "./lib/versionLib.js";

const Reports = React.lazy(() => import("./components/pages/Reports.jsx"));
const Settings = React.lazy(() => import("./components/pages/Settings.jsx"));

export default function App() {
  const toast = useToast();
  const { currentUser, currentUserDetails } = useAuth();
  const onNewVersion = useCallback(
    ({ newVersion, currentVersion }) => {
      toast({
        title: "New version available",
        description: (
          <Box>
            Version {newVersion} is available. Please refresh the page.
            <Text fontStyle="italic">You are currently on version {currentVersion}</Text>
          </Box>
        ),
        status: "info",
        duration: null,
        isClosable: true,
      });
    },
    [toast],
  );
  useWatchLatestWebSiteVersion({ onNewVersion });

  if (!currentUser) {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }

  return (
    <ConfigProvider>
      <OrderModalProvider>
        <ItemModalContextProvider>
          <HStack height="100vh" alignItems="stretch" spacing={2} align="flex-start" p={0} m={0}>
            {currentUserDetails && (
              <VStack align="flex-start" spacing="0">
                <Box w="100vw" h="70px" bg="gray.200">
                  <AppNav />
                </Box>
                <Box
                  w="100vw"
                  flex="1"
                  overflow="auto"
                  sx={{ "::-webkit-scrollbar": { display: "none" } }}
                  p={6}
                >
                  <Routes>
                    <Route path="/" element={<Overview />} />
                    <Route path="/order/:orderId" element={<OrderPage />} />
                    <Route path="/size" element={<Size />} />
                    <Route path="/size/:orderId" element={<StepFlow flowType="size" />} />
                    <Route path="/pack" element={<Pack />} />
                    <Route path="/pack/:orderId" element={<StepFlow flowType="pack" />} />
                    <Route path="/drive/:displayDate?" element={<Drive />} />
                    <Route
                      path="/drive/deliver/:orderId"
                      element={<StepFlow flowType="deliver" />}
                    />
                    <Route
                      path="/drive/collect/:orderId"
                      element={<StepFlow flowType="collect" />}
                    />
                    {currentUserDetails.isManager && (
                      <Route
                        path="/reports/:reportname?"
                        element={
                          <Suspense>
                            <Reports />
                          </Suspense>
                        }
                      />
                    )}

                    <Route path="/gear" element={<Gear />} />
                    <Route
                      path="/settings/:tabname?"
                      element={
                        <Suspense>
                          <Settings />
                        </Suspense>
                      }
                    />
                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </Box>
              </VStack>
            )}
          </HStack>
        </ItemModalContextProvider>
      </OrderModalProvider>
    </ConfigProvider>
  );
}
