import { useEffect, useRef, useState } from "react";

import {
  Avatar,
  Box,
  Button,
  Divider,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import { db } from "@/services/firebase.js";

import { deleteField, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

import dayjs from "dayjs";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useShowError } from "@/lib/error.js";
import { initRefInput, initUserV2OrNull } from "@/lib/initialize-states.js";
import useUserList from "@/lib/users.js";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {dayjs.Dayjs} props.date
 * @param {number} props.tech
 * @returns {JSX.Element}
 */
export default function TechSelectModal({ isOpen, onClose, date, tech }) {
  const [selectedUser, setSelectedUser] = useState(initUserV2OrNull());
  const labelRef = useRef(initRefInput());
  const dateId = dayjs(date).format("YYYYMMDD");
  const { userList } = useUserList({ shouldFetch: isOpen });
  const { currentUserDetails } = useLoggedInUser();
  const { showError } = useShowError();

  async function setTech() {
    try {
      if (!selectedUser) {
        throw new Error("No user selected");
      }
      const techDoc = doc(db, "techs", dateId);
      const label = labelRef.current?.value;
      await setDoc(
        techDoc,
        {
          ["tech" + tech + "_" + currentUserDetails.defaultLocation]: {
            id: selectedUser.id,
            name: selectedUser.firstName,
            ...(label ? { label } : {}),
          },
        },
        { merge: true },
      );
      onClose();
    } catch (error) {
      showError(error);
    }
  }

  async function clearTech() {
    try {
      const techDoc = doc(db, "techs", dateId);
      await updateDoc(techDoc, {
        ["tech" + tech + "_" + currentUserDetails.defaultLocation]: deleteField(),
      });
      onClose();
    } catch (error) {
      showError(error);
    }
  }
  useEffect(() => {
    if (!isOpen || userList.length === 0) {
      setSelectedUser(initUserV2OrNull());
      if (labelRef.current) {
        labelRef.current.value = "";
      }
      return;
    }
    const techDoc = doc(db, "techs", dateId);
    getDoc(techDoc)
      .then((doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data["tech" + tech + "_" + currentUserDetails.defaultLocation]) {
            const user = userList.find(
              (user) =>
                user.id === data["tech" + tech + "_" + currentUserDetails.defaultLocation].id,
            );
            if (!user) {
              return;
            }

            document.getElementById(user.id)?.scrollIntoView();

            setSelectedUser(user);
            if (labelRef.current) {
              labelRef.current.value =
                data["tech" + tech + "_" + currentUserDetails.defaultLocation].label || "";
            }
          }
        }
      })
      .catch(showError);
  }, [isOpen, userList, dateId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Tech {tech}</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow="auto" sx={{ "::-webkit-scrollbar": { display: "none" } }}>
          <VStack spacing={2}>
            {userList.map((user) => (
              <Box id={user.id} key={user.id} width="full" onClick={() => setSelectedUser(user)}>
                <Divider mb={1} />
                <HStack as="button" spacing={0} width="100%" justify="center">
                  <Avatar
                    name={user.firstName + " " + user.lastName}
                    bg="gray.600"
                    color="white"
                    src={user.image}
                    borderWidth={4}
                    borderColor={selectedUser?.id == user.id ? "brand.500" : undefined}
                  />
                  <Text fontWeight="bold" p={4}>
                    {user.firstName} {user.lastName}
                  </Text>
                </HStack>
              </Box>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <VStack w="100%" spacing={4}>
            <Box width="full">
              <Divider />
              <Input ref={labelRef} placeholder="Enter label (e.g. van name)" />
            </Box>
            <HStack width="full">
              <Button size="sm" onClick={onClose}>
                CANCEL
              </Button>
              <Spacer />
              <Button size="sm" onClick={clearTech}>
                RESET
              </Button>
              <Spacer />
              <Button isDisabled={!selectedUser} size="sm" onClick={setTech} colorScheme="brand">
                SET SELECTED
              </Button>
            </HStack>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
