import { Box, SlideFade } from "@chakra-ui/react";

import CheckOuterwear from "../checks/CheckOuterwear.jsx";
import CheckSkierInfo from "../checks/CheckSkierInfo.jsx";
import RowCheck from "../checks/RowCheck.jsx";
import SkierGrid from "../common/SkierGrid.jsx";
import SkierRow from "../common/SkierRow.jsx";
import StepHeader from "../common/StepHeader.jsx";

export default function StepPackOuterwear({ order }) {
  function isRowChecked(skier) {
    let jacketChecked = false;
    let pantsChecked = false;
    let helmetChecked = false;

    if (skier.jacket) {
      jacketChecked = skier.progress?.packjacket;
    } else {
      jacketChecked = true;
    }

    if (skier.pants) {
      pantsChecked = skier.progress?.packpants;
    } else {
      pantsChecked = true;
    }

    if (skier.helmet) {
      helmetChecked = skier.progress?.packhelmet;
    } else {
      helmetChecked = true;
    }

    return jacketChecked && pantsChecked && helmetChecked;
  }

  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <Box width="1000px">
        <StepHeader
          customerName={order?.firstName + " " + order?.lastName}
          orderId={order?.id}
          previousOrderId={order?.previousOrder}
          referrer={order?.referrer.name}
        />
        <SkierGrid templateColumns="3fr 1fr 1fr 1fr 1fr">
          {order?.skiers.map((skier) => (
            <SkierRow key={skier.id} cols={5}>
              <CheckSkierInfo skier={skier} showIsOutOfRange />
              <CheckOuterwear skier={skier} orderId={order.id} type="jacket" prefix="pack" />
              <CheckOuterwear skier={skier} orderId={order.id} type="pants" prefix="pack" />
              <CheckOuterwear skier={skier} orderId={order.id} type="helmet" prefix="pack" />
              <RowCheck completed={isRowChecked(skier)} />
            </SkierRow>
          ))}
        </SkierGrid>
      </Box>
    </SlideFade>
  );
}
