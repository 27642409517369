import { Divider } from "@chakra-ui/react";

export default function ({ timeSlot }) {
  // const currentTime = dayjs.tz().format("HHmm");
  // var isCurrentTimeSlot =
  //   currentTime >= timeSlot.format("HHmm") &&
  //   currentTime < timeSlot.add(30, "minutes").format("HHmm");

  return (
    <Divider
      // borderColor={isCurrentTimeSlot ? "orange.500" : "gray.300"}
      // borderBottomWidth={isCurrentTimeSlot ? "2px" : "1px"}
      borderColor="gray.400"
      mb="10px"
      variant="dashed"
    ></Divider>
  );
}
