import { useRef } from "react";

import { Modal, ModalOverlay } from "@chakra-ui/react";

import ChangeDateTimeModalContent from "./ChangeDateTimeModalContent.jsx";

/**
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {Date} props.date
 * @param {TOrder} [props.order]
 * @param {keyof TOrder} [props.updateField]
 * @param {(date: Date|null) => void} [props.dateCallback]
 * @returns {JSX.Element}
 */
export default function ChangeDateTimeModal({
  isOpen,
  onClose,
  date,
  order,
  updateField,
  dateCallback,
}) {
  const timeRef = useRef(null);
  // needed to put Modal Content into another component to make useRef() work for highlighting the selected time. In Chakra's Portal (which is used by Modal). Portals mount their content in a useSafeLayoutEffect callback, which is executed *after* the useEffect. See: https://github.com/chakra-ui/chakra-ui/issues/5257
  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ChangeDateTimeModalContent
        timeRef={timeRef}
        onClose={onClose}
        date={date}
        order={order}
        updateField={updateField}
        dateCallback={dateCallback}
      />
    </Modal>
  );
}
