// TODO: Talk to justin about this hard-coded email
/**
 * @param {string} username
 * @returns {string}
 */
export const maskEmail = (username) => {
  if (!/^[^@]+@.+/.test(username)) {
    return ("deanworth+" + username + "@gmail.com").toLowerCase();
  }
  return username;
};
