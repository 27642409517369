import { useEffect, useState } from "react";

import { Box, HStack, Icon, Progress, Text, VStack } from "@chakra-ui/react";

import { FaDoorOpen } from "react-icons/fa/index.esm.js";
import { TbTruckDelivery } from "react-icons/tb/index.esm.js";

import { db } from "@/services/firebase.js";

import { collection, getCountFromServer, query, where } from "firebase/firestore";

import dayjs from "dayjs";

import { useAuth } from "@/contexts/AuthContext.jsx";

export default function Stats() {
  const [countDeliveriesToday, SetCountToday] = useState(0);
  const [countDeliveriesDone, SetCountDone] = useState(0);
  const [countCollectionsToday, SetCountCollectionsToday] = useState(0);
  const [countCollectionsDone, SetCountCollectionsDone] = useState(0);

  const { currentUserDetails } = useAuth();
  const [todayStart, setTodayStart] = useState(dayjs.tz().startOf("day").toDate());
  const [todayEnd, setTodayEnd] = useState(dayjs.tz().endOf("day").toDate());

  useEffect(() => {
    const nextDay = dayjs.tz().add(1, "day").startOf("day").toDate().getTime() - +new Date();

    const timeout = setTimeout(() => {
      setTodayStart(dayjs.tz().startOf("day").toDate());
      setTodayEnd(dayjs.tz().endOf("day").toDate());
    }, nextDay);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    async function getStats() {
      const q1 = query(
        collection(db, "orders"),
        where("location", "==", currentUserDetails?.defaultLocation),
        where("deliverTime", "<=", todayEnd),
        where("deliverTime", ">=", todayStart),
      );

      const q2 = query(
        collection(db, "orders"),
        where("location", "==", currentUserDetails?.defaultLocation),
        where("deliverTime", "<=", todayEnd),
        where("deliverTime", ">=", todayStart),
        where("state", "in", ["toCollect", "completed"]),
      );

      const q3 = query(
        collection(db, "orders"),
        where("location", "==", currentUserDetails?.defaultLocation),
        where("collectTime", "<=", todayEnd),
        where("collectTime", ">=", todayStart),
      );

      const q4 = query(
        collection(db, "orders"),
        where("location", "==", currentUserDetails?.defaultLocation),
        where("collectTime", "<=", todayEnd),
        where("collectTime", ">=", todayStart),
        where("state", "==", "completed"),
      );

      const [snap1, snap2, snap3, snap4] = await Promise.all([
        getCountFromServer(q1),
        getCountFromServer(q2),
        getCountFromServer(q3),
        getCountFromServer(q4),
      ]);

      SetCountToday(snap1.data().count);
      SetCountDone(snap2.data().count);
      SetCountCollectionsToday(snap3.data().count);
      SetCountCollectionsDone(snap4.data().count);
    }

    getStats();
  }, [todayStart, todayEnd, currentUserDetails]);

  return (
    <VStack align="left" w="sm" spacing={6}>
      {countDeliveriesToday >= 0 && (
        <Box>
          <HStack>
            <Icon as={FaDoorOpen} boxSize={6} />
            <Text>
              Deliveries today: {countDeliveriesDone} done of {countDeliveriesToday}
            </Text>
          </HStack>
          <Progress value={(countDeliveriesDone / countDeliveriesToday) * 100} />
        </Box>
      )}
      {countCollectionsToday >= 0 && (
        <Box>
          <HStack>
            <Icon as={TbTruckDelivery} boxSize={6} />
            <Text>
              Collections today: {countCollectionsDone} done of {countCollectionsToday}
            </Text>
          </HStack>
          <Progress value={(countCollectionsDone / countCollectionsToday) * 100} />
        </Box>
      )}
    </VStack>
  );
}
