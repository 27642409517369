import { useState, useEffect, useMemo } from "react";

import { Avatar, HStack, Text, VStack, Icon, Button } from "@chakra-ui/react";

import { TbDatabaseExport } from "react-icons/tb/index.esm.js";
import { TiStarburst } from "react-icons/ti/index.esm.js";

import { db } from "@/services/firebase.js";

import { collection, limit, onSnapshot, orderBy, query, where, getDocs } from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import { getDiff } from "@snopro/common/objects.js";

import { getUserAvatarByUID, useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useOrderModalContext } from "@/contexts/OrderModalContext.jsx";
import dayjs from "@/lib/datetime.js";

/**
 * @type {TActivity[]}
 */
const initialActivities = [];
/**
 * @param {Date} [time]
 * @returns {string}
 */
function formatActivityTime(time) {
  const value = dayjs.tz(getFieldAsDate(time));
  const format = value.isSame(dayjs.tz(), "day") ? "H:mma" : "MMM D, H:mma";
  return value.format(format);
}

/**
 * @param {object} props
 * @param {TActivity} props.activity
 * @param {function} props.setModalId
 * @param {boolean} [props.disableOpenOrder]
 * @param {string} [props.orderId]
 * @returns {JSX.Element}
 * */
const Activity = ({ activity, disableOpenOrder, setModalId, orderId }) => (
  <HStack
    key={activity.id}
    align="flex-start"
    justifySelf="flex-start"
    as={
      "orderId" in activity == false ||
      !activity.orderId ||
      disableOpenOrder ||
      activity.type === "order-deleted"
        ? undefined
        : "button"
    }
    onClick={(e) => {
      if (
        activity.type === "order-deleted" ||
        disableOpenOrder ||
        "orderId" in activity == false ||
        !activity.orderId
      ) {
        return;
      }
      setModalId(activity.orderId, Boolean(e.ctrlKey || e.shiftKey));
    }}
  >
    {activity.user ? (
      <Avatar
        name={activity.user.firstName + " " + activity.user.lastName}
        size={orderId ? "2xs" : "xs"}
        src={getUserAvatarByUID(activity.user.id)}
      />
    ) : activity.type === "new-order" &&
      activity.orderSource == "sas1" &&
      activity.orderState === "completed" ? (
      <Icon as={TbDatabaseExport} color="brand.500" boxSize={orderId ? 5 : 6} />
    ) : (
      <Icon as={TiStarburst} color="brand.500" boxSize={orderId ? 5 : 6} />
    )}
    <Text as="span" align="left" fontSize={orderId ? "2xs" : "md"}>
      {activity.text}
      {activity.time && (
        <Text as="span" color="gray.400" ml={2} fontSize={orderId ? "xs" : "sm"}>
          {formatActivityTime(getFieldAsDate(activity.time))}
        </Text>
      )}
    </Text>
  </HStack>
);

const __PageSize = 40;
/**
 * @param {object} props
 * @param {string} [props.orderId]
 * @param {boolean} [props.disableOpenOrder]
 * @param {string} [props.userId]
 * @param {Array<TActivityType|TActivityTypeOrder>} [props.onlyActivityTypes]
 * @returns {JSX.Element}
 */
export default function ActivityList({ orderId, disableOpenOrder, userId, onlyActivityTypes }) {
  const [activities, setActivities] = useState(initialActivities);
  const [moreActivities, setMoreActivities] = useState(initialActivities);
  const { setModalId } = useOrderModalContext();
  const { currentUserDetails } = useLoggedInUser();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [latsFilter, setLastFilter] = useState({ userId, onlyActivityTypes });

  const hasActivityTypesChanged = useMemo(() => {
    return getDiff({ userId, onlyActivityTypes }, latsFilter).length > 0;
  }, [onlyActivityTypes, latsFilter, userId]);

  /**
   * @param {Date} [beforeTime]
   */
  const getConstraints = (beforeTime) => {
    if (!currentUserDetails) {
      return [];
    }
    const pageSize = orderId ? 100 : __PageSize;
    /** @type {any[]} */
    const constraints = [];
    if (orderId) {
      constraints.push(where("orderId", "==", orderId));
    } else {
      constraints.push(where("location", "==", currentUserDetails.defaultLocation));
      if (userId) {
        constraints.push(where("user.id", "==", userId));
      }
      if (onlyActivityTypes) {
        if (onlyActivityTypes.length === 0) {
          return [];
        }
        constraints.push(where("type", "in", Array.from(new Set(onlyActivityTypes))));
      }
      if (beforeTime) {
        constraints.push(where("time", "<", beforeTime));
      }
    }
    return [...constraints, orderBy("time", "desc"), limit(pageSize)];
  };
  useEffect(() => {
    const constraints = getConstraints();
    setLastFilter({ userId, onlyActivityTypes });
    if (!constraints.length) {
      setActivities([]);
      setMoreActivities([]);
      return;
    }
    if (hasActivityTypesChanged) {
      setMoreActivities([]);
    }
    const unsubscribe = onSnapshot(
      query(collection(db, "activity"), ...constraints),
      (querySnapshot) => {
        const results = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setActivities(results);
      },
      (error) => {
        console.error("Error getting activities", error);
        setActivities([]);
      },
    );
    return unsubscribe;
  }, [orderId, currentUserDetails, onlyActivityTypes, userId, hasActivityTypesChanged]);

  const loadMoreActivities = async () => {
    if (orderId || !currentUserDetails.defaultLocation) {
      return;
    }

    const latsActivity =
      moreActivities[moreActivities.length - 1] ?? activities[activities.length - 1];
    setIsLoadingMore(true);
    try {
      await getDocs(
        query(collection(db, "activity"), ...getConstraints(getFieldAsDate(latsActivity.time))),
      ).then((querySnapshot) => {
        const results = [];
        querySnapshot.docs.forEach((doc) => {
          results.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        setMoreActivities(moreActivities.concat(results));
      });
    } finally {
      setIsLoadingMore(false);
    }
  };
  return (
    <VStack align="left" mr={6}>
      {activities.map((activity) => (
        <Activity
          key={activity.id}
          activity={activity}
          disableOpenOrder={disableOpenOrder}
          orderId={orderId}
          setModalId={setModalId}
        />
      ))}
      {moreActivities.map((activity) => (
        <Activity
          key={activity.id}
          activity={activity}
          disableOpenOrder={disableOpenOrder}
          orderId={orderId}
          setModalId={setModalId}
        />
      ))}
      {!orderId && (
        <HStack key={"more"} align="flex-start" justifySelf="flex-start">
          <Button
            isLoading={isLoadingMore}
            onClick={() => loadMoreActivities()}
            colorScheme="brand"
            size="xs"
            variant="outline"
            leftIcon={<TbDatabaseExport />}
          >
            <Text as="span" align="left" fontSize={"md"}>
              Load more...
            </Text>
          </Button>
        </HStack>
      )}
    </VStack>
  );
}
