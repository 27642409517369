import {
  Box,
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import { deleteField } from "firebase/firestore";

import { useShowError } from "@/lib/error.js";
import { updateSkier } from "@/lib/skier.api.js";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {TSkier} props.skier
 * @param {string} props.orderId
 * @param {TOrderChangeSource} props.source
 * @returns {JSX.Element}
 */
export default function SoleLengthModal({ isOpen, onClose, skier, orderId, source }) {
  const { showError } = useShowError();

  const soleLengthRanges = [
    { label: "up to 250", value: 250 },
    { label: "251-270", value: 270 },
    { label: "271-290", value: 290 },
    { label: "291-310", value: 310 },
    { label: "311-330", value: 330 },
    { label: "331 and over", value: 999 },
  ];

  /**
   * @param {object} sole
   * @param {number} sole.value
   * @param {string} sole.label
   * @returns {Promise<void>}
   * */
  async function setSole(sole) {
    try {
      await updateSkier(
        orderId,
        skier.id,
        { ownBootSole: { length: sole.value, label: sole.label } },
        source,
      );
      onClose();
    } catch (error) {
      showError(error);
    }
  }

  async function clearBoot() {
    try {
      await updateSkier(orderId, skier.id, { ownBootSole: deleteField() }, source);
      onClose();
    } catch (error) {
      showError(error);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {skier && (
        <ModalContent>
          <ModalHeader>Select own boot sole length ({skier.shoe})</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={2}>
              {soleLengthRanges.map((sole) => (
                <Box key={sole.value} width="full">
                  <Divider mb={1} />
                  <HStack spacing={0} width="100%" justify="center">
                    <Text as="button" fontWeight="bold" onClick={() => setSole(sole)} p={4}>
                      {sole.label}
                    </Text>
                  </HStack>
                </Box>
              ))}
              <Divider />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={clearBoot}>
              CLEAR
            </Button>
            <Spacer />
            <Button size="sm" onClick={onClose}>
              CANCEL
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
}
