import {
  Box,
  Button,
  GridItem,
  HStack,
  Icon,
  Text,
  VStack,
  useDisclosure,
  useToast,
  Image,
} from "@chakra-ui/react";

import { HiExclamation } from "react-icons/hi/index.esm.js";

import { calculateSkierDIN } from "@snopro/common/din-utils.js";

import { useConfig } from "@/contexts/ConfigContext.jsx";

import CheckImage from "../../common/CheckImage.jsx";
import BootSelectModal from "../../modals/BootSelectModal.jsx";
import BootSizeModal from "../../modals/BootSizeModal.jsx";
import SoleLengthModal from "../../modals/SoleLengthModal.jsx";

/**
 *
 * @param {Object} props
 * @param {TSkier} props.skier
 * @param {TOrder} props.order
 * @param {boolean} [props.showDIN]
 * @param {boolean} [props.isEditMode]
 * @param {boolean} [props.inStore]
 * @param {TOrderChangeSource} props.source
 * @returns {JSX.Element}
 */
export default function CheckBootSelect({ skier, order, showDIN, isEditMode, inStore, source }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const configData = useConfig();
  const bootSizeModal = useDisclosure();
  const soleLengthModal = useDisclosure();

  const toast = useToast();
  function tryOpen() {
    if (order.state == "completed") {
      toast({
        title: "Can't change, booking is completed!",
        status: "warning",
        duration: 3000,
        isClosable: false,
      });
      return;
    }
    if (order.state == "inactive") {
      toast({
        title: "Can't change, booking is inactive!",
        status: "error",
        duration: 3000,
        isClosable: false,
      });
      return;
    }

    onOpen();
  }

  function renderComponent() {
    const bootType = String(skier?.packageType).includes("Snowboard") ? "bootsb" : "bootsk";
    if (skier.ownBoots) {
      return (
        <GridItem justifySelf="start">
          {inStore ? (
            skier.ownBootSole ? (
              <Box as="button" onClick={soleLengthModal.onOpen}>
                <Text isTruncated>{skier.ownBootSole.label}mm</Text>
                <HStack>
                  <Text fontWeight="bold">
                    DIN{" " + (calculateSkierDIN(skier, configData)?.DIN?.toFixed(2) ?? "?.??")}
                  </Text>
                  <Image boxSize={6} src={"/images/check.svg"} />
                </HStack>
              </Box>
            ) : (
              <Button as="span" mt={2} size="xs" onClick={soleLengthModal.onOpen}>
                set own sole
              </Button>
            )
          ) : (
            <Text ml={6} fontSize="sm" isTruncated as="button" onClick={tryOpen}>
              own boots
            </Text>
          )}
        </GridItem>
      );
    } else {
      if (skier.selectedBoot) {
        return (
          <GridItem justifySelf="start">
            <VStack>
              <HStack as="button" onClick={tryOpen}>
                <CheckImage type={bootType} isChecked={true} />
                <VStack spacing="-3px" align="flex-start">
                  <HStack>
                    <Text isTruncated fontWeight="bold">
                      {skier.selectedBoot.brand} {skier.selectedBoot.model}
                      {skier.selectedBoot.isDeluxe && " ★"}
                      {skier.bootUpgrade == true && !skier.selectedBoot.isDeluxe && (
                        <Icon
                          position="relative"
                          top="5px"
                          boxSize={5}
                          ml={1}
                          color="orange.500"
                          as={HiExclamation}
                        />
                      )}
                    </Text>
                  </HStack>
                  {showDIN && (
                    <Text fontSize="sm">
                      {skier.shoe}
                      {bootType == "bootsk" && (
                        <>
                          /DIN
                          {" " + (calculateSkierDIN(skier, configData)?.DIN?.toFixed(2) ?? "?.??")}
                        </>
                      )}
                    </Text>
                  )}
                </VStack>
              </HStack>

              {isEditMode && (
                <HStack>
                  <Button onClick={bootSizeModal.onOpen} size="xs" justifySelf="end">
                    change size
                  </Button>
                </HStack>
              )}
            </VStack>
          </GridItem>
        );
      } else {
        return (
          <GridItem justifySelf="start">
            {skier.packageType && (
              <VStack>
                <HStack>
                  <Box as="button" onClick={tryOpen}>
                    <CheckImage type={bootType} />
                  </Box>
                  <Button size="sm" onClick={tryOpen}>
                    Select Boot{skier.bootUpgrade && " ★"} {"(" + skier.shoe + ")"}
                  </Button>
                </HStack>
                {isEditMode && (
                  <HStack>
                    <Button onClick={bootSizeModal.onOpen} size="xs" justifySelf="end">
                      change size
                    </Button>
                  </HStack>
                )}
              </VStack>
            )}
          </GridItem>
        );
      }
    }
  }

  return (
    <>
      {renderComponent()}
      {isOpen && (
        <BootSelectModal
          isOpen={isOpen}
          onClose={onClose}
          skier={skier}
          orderId={order.id}
          source={source}
        />
      )}
      {soleLengthModal.isOpen && (
        <SoleLengthModal
          isOpen={soleLengthModal.isOpen}
          onClose={soleLengthModal.onClose}
          skier={skier}
          orderId={order.id}
          source={source}
        />
      )}
      {bootSizeModal.isOpen && (
        <BootSizeModal
          isOpen={bootSizeModal.isOpen}
          onClose={bootSizeModal.onClose}
          skier={skier}
          orderId={order.id}
          source={source}
        />
      )}
    </>
  );
}
