import { useState } from "react";

import {
  Text,
  useDisclosure,
  Icon,
  VStack,
  Spacer,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  Divider,
  ModalFooter,
  Button,
  useToast,
  MenuDivider,
  Checkbox,
  HStack,
  FormLabel,
} from "@chakra-ui/react";

import { RepeatIcon, DeleteIcon } from "@chakra-ui/icons";
import { GoKebabHorizontal } from "react-icons/go/index.esm.js";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useDeleteOrder } from "@/hooks/useDeleteOrder.jsx";
import useDuplicateOrder from "@/hooks/useDuplicateOrder.jsx";
import { useAdvancedMode } from "@/lib/advancedModeLib.js";
import { useShowError } from "@/lib/error.js";
import { lightspeedCreateSales } from "@/lib/lightspeed.api.js";
import { orderRecalculateTotals } from "@/lib/order.api.js";

/**
 *
 * @param {object} props
 * @param {TOrder|TOrderAndSkiers} props.order
 * @param {Array<"duplicate"|"delete"|"recalculate"|"export-to-lightspeed">} props.actionsToShow
 * @param { (order:TOrder) => void } [props.onOrderDeleted]
 *
 * @param { (originalOrder:TOrder, newOrder:{id: string; lookupId:string}) => void } [props.onOrderDuplicated]
 */
export const OrderActionMenu = ({ order, onOrderDeleted, onOrderDuplicated, actionsToShow }) => {
  const toast = useToast();
  const deleteModalDisclosure = useDisclosure();
  const { isLoading: isDuplicatingOrder, processDuplicateOrder } = useDuplicateOrder();
  const { isLoading: isDeletingOrder, processDeleteOrder } = useDeleteOrder();
  const { currentUserDetails } = useLoggedInUser();
  const { showError } = useShowError();
  const { isAdvancedModeOn } = useAdvancedMode();
  const [ignoreUnrefundedPayments, setIgnoreUnrefundedPayments] = useState(false);
  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<Icon as={GoKebabHorizontal} boxSize={5} />}
          onClick={(e) => e.stopPropagation()}
          size={"sm"}
          variant={"outline"}
          isLoading={isDuplicatingOrder || isDeletingOrder}
        />
        <MenuList>
          {actionsToShow.includes("duplicate") && (
            <MenuItem
              icon={<RepeatIcon />}
              onClick={async (e) => {
                e.stopPropagation();
                const duplicated = await processDuplicateOrder(order.id);
                if (duplicated) {
                  onOrderDuplicated && onOrderDuplicated(order, duplicated);
                }
              }}
            >
              Duplicate order
            </MenuItem>
          )}

          {actionsToShow.includes("delete") && (
            <>
              <MenuDivider />
              <MenuItem
                isDisabled={!currentUserDetails.isManager}
                onClick={async (e) => {
                  e.stopPropagation();
                  deleteModalDisclosure.onOpen();
                }}
                icon={<DeleteIcon />}
              >
                Delete order
              </MenuItem>
            </>
          )}
          {currentUserDetails.isAdmin && isAdvancedModeOn && (
            <>
              <MenuDivider />
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  orderRecalculateTotals({ orderId: order.id })
                    .then(() =>
                      toast({
                        title: `Order #${order.lookupId} totals recalculated`,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                      }),
                    )
                    .catch(showError);
                }}
              >
                Re-calculate totals
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  lightspeedCreateSales({ orderId: order.id })
                    .then(() =>
                      toast({
                        title: `Sales created in Lightspeed for order #${order.lookupId}`,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                      }),
                    )
                    .catch(showError);
                }}
              >
                Create sales in Lightspeed
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>

      <Modal
        isOpen={deleteModalDisclosure.isOpen && Boolean(order)}
        onClose={deleteModalDisclosure.onClose}
      >
        <ModalOverlay />
        {order && (
          <ModalContent>
            <ModalHeader>Delete Confirmation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={0}>
                <Divider />
                <Text fontWeight="bold" p={4}>
                  {order.firstName} {order.lastName} - #{order.lookupId}
                </Text>
                {isAdvancedModeOn && (
                  <HStack>
                    <FormLabel htmlFor="ignoreUnrefundedPayments">
                      Delete even if there are unrefunded credit card payments
                    </FormLabel>
                    <Checkbox
                      id="ignoreUnrefundedPayments"
                      isChecked={ignoreUnrefundedPayments}
                      onChange={(e) => setIgnoreUnrefundedPayments(e.target.checked)}
                      m={1}
                      colorScheme="brand"
                    />
                  </HStack>
                )}
                <Divider />
                <Divider />
              </VStack>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button size="sm" onClick={deleteModalDisclosure.onClose}>
                <Text casing={"uppercase"}>Cancel</Text>
              </Button>
              <Spacer />
              <Button
                fontSize="sm"
                isLoading={isDeletingOrder}
                leftIcon={<Icon as={DeleteIcon} />}
                onClick={async () => {
                  const deleted = await processDeleteOrder(
                    order.id,
                    order.lookupId,
                    ignoreUnrefundedPayments,
                  );
                  deleteModalDisclosure.onClose();
                  deleted && onOrderDeleted && onOrderDeleted(order);
                }}
              >
                DELETE
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </>
  );
};
