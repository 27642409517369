import React, { useMemo, useState } from "react";

import {
  Card,
  CardBody,
  Box,
  Text,
  Input,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Icon,
  HStack,
  VStack,
  Spacer,
  Flex,
} from "@chakra-ui/react";

import { BiSolidDollarCircle } from "react-icons/bi/index.esm.js";
import { FiSearch } from "react-icons/fi/index.esm.js";
import { HiUsers } from "react-icons/hi/index.esm.js";

import { db } from "@/services/firebase.js";

import {
  query,
  getDocs,
  collection,
  where,
  QueryDocumentSnapshot,
  QuerySnapshot,
  orderBy,
  limit,
} from "firebase/firestore";

import { deepFirestoreDoctoToObject } from "@snopro/common/firestore.js";
import { OrderSchema } from "@snopro/common/models.js";
import { cleanText } from "@snopro/common/string-utils.js";
import dayjs from "dayjs";
import Fuse from "fuse.js";

import { useOrderModalContext } from "../../contexts/OrderModalContext.jsx";
import { asPrice } from "../../lib/numbers.js";
import { OrderActionMenu } from "../order/OrderActionsMenu.jsx";

/** @typedef {TOrder & { id:string;}} TIOrder */

/** @type {TIOrder[]} */
const __initialSearchResults = [];

/** @param {QueryDocumentSnapshot<TOrder> } doc */
const mapToOrder = (doc) => {
  /** @type {Object} */
  const value = deepFirestoreDoctoToObject(doc.data());
  const parsed = OrderSchema.safeParse(value);
  if (parsed.success) {
    return parsed.data;
  }
  console.error(parsed.error, parsed.error.errors);
  return value;
};

export default function SearchDrawer() {
  const [searchResults, setSearchResults] = useState(__initialSearchResults);
  const [searchTerms, setSearchTerms] = useState("");
  const [dbSearchTerms, setDbSearchTerms] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = React.useRef();
  const { setModalId } = useOrderModalContext();

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      doSearch();
    }
  };

  function closeDrawer() {
    setSearchResults([]);
    setSearchTerms("");
    setDbSearchTerms("");
    onClose();
  }

  const handleChange = (e) => {
    setSearchTerms(e.target.value);
  };

  async function doSearch() {
    if (!searchTerms.trim()) {
      setSearchResults([]);
      return;
    }
    const splitTerms = searchTerms.split(" ").map((term) => cleanText(term));
    setDbSearchTerms(searchTerms);
    const q = query(
      collection(db, "orders"),
      where(
        "searchTerms",
        "array-contains-any",
        Array.from(new Set([cleanText(searchTerms)].concat(splitTerms))),
      ),
      limit(100),
      orderBy("bookingTime", "desc"),
    );
    /** @type {QuerySnapshot<TOrder>} */ // @ts-expect-error can't cast in JS
    const querySnapshot = await getDocs(q);
    setSearchResults(querySnapshot.docs.map((doc) => mapToOrder(doc)));
  }

  const fuseIndex = useMemo(() => {
    const index = new Fuse(
      searchResults.map((o) => ({
        name: [o.firstName, o.lastName]
          .map((s) => s.trim())
          .filter(Boolean)
          .join(" "),
        month: dayjs.tz(o.deliverTime).format("MMM"),
        year: dayjs.tz(o.deliverTime).year(),
        skiDaysStr: `${o.skiDays}d`,
        skierCountStr: `${o.skierCount}p`,
        ...o,
      })),
      {
        includeScore: true,
        keys: [
          "name",
          "firstName",
          "lastName",
          "email",
          "lookupId",
          "location",
          "phone",
          "month",
          "year",
          "skierCountStr",
          "skiDaysStr",
        ],
        distance: 400,
      },
    );
    return index;
  }, [searchResults]);
  const indexedResults = useMemo(() => {
    return fuseIndex.search(searchTerms).map((r) => r.item);
  }, [fuseIndex, searchTerms]);

  const currentSearch = useMemo(() => {
    if (indexedResults.length > 0) {
      return indexedResults;
    }
    return searchResults;
  }, [indexedResults, searchResults]);

  return (
    <>
      <Box ml="100px" pr="8" as="button" ref={btnRef} onClick={onOpen}>
        <Icon as={FiSearch} boxSize={7} />
      </Box>
      <Drawer
        size="md"
        isOpen={isOpen}
        placement="right"
        onClose={closeDrawer}
        finalFocusRef={btnRef}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <HStack>
              <Input
                placeholder="Search for Last Name, Email or Lookup ID"
                variant="outline"
                // ref={searchRef}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                value={searchTerms}
              />
              <FiSearch onClick={doSearch} />
            </HStack>
          </DrawerHeader>

          <DrawerBody>
            {indexedResults.length + searchResults.length > 0 ? (
              <>
                <HStack alignItems="left">
                  <Text size="sm" fontWeight={"bold"}>
                    {[
                      "Search Results",
                      searchResults.length !== indexedResults.length && indexedResults.length > 0
                        ? `(${searchResults.length}/${indexedResults.length})`
                        : "",
                      searchResults.length === indexedResults.length || indexedResults.length == 0
                        ? `(${searchResults.length})`
                        : "",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  </Text>
                  <Text fontWeight={"hairline"} fontStyle={"italic"}>
                    {dbSearchTerms}
                  </Text>
                </HStack>

                <VStack alignItems="left" mt={4}>
                  {currentSearch.map((order) => (
                    <Card
                      key={order.id}
                      variant="outline"
                      onClick={(e) => {
                        const newPage = Boolean(e.ctrlKey || e.shiftKey);
                        setModalId(order.id, newPage);
                        if (newPage) {
                          return;
                        }
                        closeDrawer();
                      }}
                      cursor={"pointer"}
                      size={"sm"}
                    >
                      <CardBody w="100%">
                        <Flex>
                          <VStack alignItems="left" spacing={0} flex="2">
                            <HStack spacing={2}>
                              <Text fontWeight="bold">
                                {order.firstName} {order.lastName}
                              </Text>
                              <HStack spacing={1}>
                                <Icon as={HiUsers} boxSize={4} />
                                <Text fontWeight="bold">{order.skierCount}</Text>
                              </HStack>
                              <Spacer />
                              <Text>{dayjs.tz(order.deliverTime).format("D MMM YYYY")}</Text>
                            </HStack>
                            <HStack spacing={3} fontSize="sm">
                              <Text>{order.location}</Text>
                              <Text>({order.skiDays}d)</Text>
                              <Spacer />
                              <HStack spacing={1}>
                                <Text>Paid {asPrice(order?.paidAmount)}</Text>
                                {order.paidStatus == "unpaid" && (
                                  <Icon color="red.500" as={BiSolidDollarCircle} boxSize={4} />
                                )}
                              </HStack>
                              <Text>Due {asPrice(order?.balance)}</Text>
                            </HStack>
                            <HStack spacing={1}>
                              <Text fontSize="sm">{order.email}</Text>
                              <Spacer />
                              <Text fontSize="sm">{order.phone}</Text>
                            </HStack>
                          </VStack>
                          <Box ml={4} alignContent="center">
                            <OrderActionMenu
                              order={order}
                              onOrderDeleted={() => doSearch()}
                              actionsToShow={["delete", "duplicate"]}
                            />
                          </Box>
                        </Flex>
                      </CardBody>
                    </Card>
                  ))}
                </VStack>
              </>
            ) : (
              <HStack>
                {dbSearchTerms && (
                  <Text size="sm" fontWeight={"bold"}>
                    No Search Results for "{dbSearchTerms}"
                  </Text>
                )}
              </HStack>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
