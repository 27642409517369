const __currency = new Intl.NumberFormat("en-NZ", {
  style: "currency",
  currency: "NZD",
});

/**
 * @param {number|undefined} [num]
 * @param {string} [ifZero]
 *
 */
export function asPrice(num, ifZero) {
  if (!num || isNaN(num)) {
    num = 0;
  } else {
    num = Number(num);
  }
  if (typeof ifZero !== "undefined" && num === 0) {
    return ifZero;
  }
  return __currency.format(num);
}
