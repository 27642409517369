/**
 * @param {[string,TConfigDataProduct]} left
 * @param {[string,TConfigDataProduct]} right
 * @returns {number}
 */
export const compareProduct = ([, l], [, r]) => {
  let result = l.size === "junior" && r.size === "adult" ? -1 : 0;
  if (result === 0) {
    result = r.size === "junior" && l.size === "adult" ? 1 : 0;
  }
  if (result === 0) {
    result = l.name.localeCompare(r.name);
  }
  return result;
};
