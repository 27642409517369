import { Box } from "@chakra-ui/react";

/**
 *
 * @param {object} props
 * @param { () => void} props.handleUpdate
 * @param {boolean} [props.isFaded]
 * @param {any} [props.position]
 * @param {JSX.Element} [props.children]
 * @returns {JSX.Element}
 */
export default function DropZone({ handleUpdate, isFaded, position, children }) {
  return (
    <Box
      as="button"
      w="100px"
      h="40px"
      bg="white"
      borderColor={isFaded ? "gray.200" : "brand.500"}
      borderStyle="dashed"
      borderWidth="2px"
      borderRadius="8px"
      onClick={handleUpdate}
      position={position}
      top="0px"
      mt="0px!"
    >
      {children}
    </Box>
  );
}
