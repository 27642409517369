import { HStack, Icon, GridItem } from "@chakra-ui/react";
import { FaCheckCircle, FaRegCheckCircle } from "react-icons/fa/index.esm.js";

/**
 * 
 * @param {Object} props
 * @param {boolean} [props.isHidden]
 * @param {boolean} props.completed
 * @returns 
 */
export default function RowCheck({ isHidden, completed }) {
  return (
    <GridItem>
      <HStack justifyContent="right" w="50px">
        {isHidden ? (
          <></>
        ) : completed ? (
          <Icon as={FaCheckCircle} boxSize={8} color="brand.500" />
        ) : (
          <Icon as={FaRegCheckCircle} boxSize={8} color="gray.200" />
        )}
      </HStack>
    </GridItem>
  );
}
