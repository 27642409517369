import { useState } from "react";

import {
  Box,
  SlideFade
} from "@chakra-ui/react";

import CheckDeliverSkiPass from "../checks/CheckDeliverSkiPass";
import CheckSkierInfo from "../checks/CheckSkierInfo";
import SkierGrid from "../common/SkierGrid";
import SkierRow from "../common/SkierRow";
import StepHeader from "../common/StepHeader";

export default function StepDeliverSkiPasses({ order }) {
  const [isEditMode, SetEditMode] = useState(false);

  function isRowChecked(skier) {
    let bootFitted = false;
    let jacketChecked = false;
    let pantsChecked = false;
    let helmetChecked = false;

    if (skier.ownBoots) {
      if (skier.ownBootSole) bootFitted = true;
    } else {
      if (!skier.packageType) {
        bootFitted = true;
      } else {
        bootFitted = skier.progress?.deliverboot;
      }
    }

    if (skier.jacket) {
      jacketChecked = skier.progress?.deliverjacket;
    } else {
      jacketChecked = true;
    }

    if (skier.pants) {
      pantsChecked = skier.progress?.deliverpants;
    } else {
      pantsChecked = true;
    }

    if (skier.helmet) {
      helmetChecked = skier.progress?.deliverhelmet;
    } else {
      helmetChecked = true;
    }

    let allExtrasChecked = true;
    skier.extras?.every((product) => {
      if (!skier?.progress?.["deliver" + product.code]) {
        allExtrasChecked = false;
        return false;
      } else {
        return true;
      }
    });

    return bootFitted && jacketChecked && pantsChecked && helmetChecked && allExtrasChecked;
  }

  function toggleEditMode() {
    SetEditMode(!isEditMode);
  }

  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <Box width="1100px">
        <StepHeader
          customerName={order?.firstName + " " + order?.lastName}
          orderId={order?.id}
          showEditMode={true}
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
          referrer={order?.referrer.name}
        />
        <SkierGrid templateColumns="1fr 6fr">
          {order?.skiers.map((skier) => (
            <SkierRow key={skier.id} cols={3} my={isEditMode ? 0 : 4}>
              <CheckSkierInfo skier={skier} hideSurname={true} />
              <CheckDeliverSkiPass skier={skier} order={order} isEditMode={isEditMode} />
              {/* <RowCheck completed={isRowChecked(skier)} /> */}
            </SkierRow>
          ))}
        </SkierGrid>
      </Box>
    </SlideFade>
  );
}
