import { useCallback, useEffect, useState } from "react";

import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { AiOutlineColumnWidth } from "react-icons/ai";
import { BsFillWrenchAdjustableCircleFill } from "react-icons/bs";
import { CiGrid31 } from "react-icons/ci";
import { FaFemale, FaLock } from "react-icons/fa";
import { HiZoomIn, HiZoomOut } from "react-icons/hi";

import { db } from "@/services/firebase.js";

import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import dayjs from "dayjs";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useConfig } from "@/contexts/ConfigContext.jsx";
import { useOrderModalContext } from "@/contexts/OrderModalContext.jsx";
import { datepickerLocale } from "@/data/datepickerLocale.js";
import { initHardwareListOrUndefined } from "@/lib/initialize-states.js";

import DateBox from "../common/DateBox.jsx";
import PackageName from "../common/PackageName.jsx";

/**
 *
 * @param {string} prop
 * @param {any[]} list
 * @returns
 */
const sortOn = (prop, list) => {
  const order = list.reduce((obj, key, idx) => Object.assign(obj, { [key]: idx + 1 }), {});
  const getVal = (item) => order[item[prop]] || Infinity;

  return (a, b) => getVal(a) - getVal(b);
};

/**
 * @param {object} props
 * @param {string} props.text
 * @param {string} [props.size]
 * @param {boolean} [props.isButton]
 * @param {object} [props.buttonProps]
 * @param {string} [props.startDate]
 * @param {string} [props.defaultPackageType]
 * @param {TLocation} [props.defaultLocation]
 * @returns {JSX.Element}
 */
export default function Availability({
  text,
  size,
  isButton,
  buttonProps,
  startDate,
  defaultPackageType,
  defaultLocation,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hardwareList, setHardwareList] = useState(initHardwareListOrUndefined());

  const [allocations, setAllocations] = useState();

  const lookup = useConfig();
  const { currentUserDetails } = useLoggedInUser();
  const [hardwareLocation, setHardwareLocation] = useState(
    defaultLocation || currentUserDetails.defaultLocation,
  );
  const [filter, setFilter] = useState(defaultPackageType || "Ski");
  const [filterType, setFilterType] = useState(defaultPackageType ? "package" : "type");
  const [selectedDay, setSelectedDay] = useState(
    startDate ? dayjs.tz(startDate).add(-1, "day") : dayjs.tz(),
  );
  const [selectedAllocation, setSelectedAllocation] = useState(null);
  const { setModalId } = useOrderModalContext();
  const [zoomFactor, setZoomFactor] = useState(1);
  const baseDaysToShow = 15;
  const toast = useToast();

  const dateList = [];
  for (let i = 0; i < baseDaysToShow * zoomFactor; i++) {
    dateList.push(selectedDay.add(i, "day"));
  }

  function opening() {
    setHardwareLocation(defaultLocation || currentUserDetails.defaultLocation);
    setFilter(defaultPackageType || "Ski");
    setFilterType(defaultPackageType ? "package" : "type");
    if (startDate) {
      setSelectedDay(dayjs.tz(startDate));
    } else {
      setSelectedDay(dayjs.tz().add(-1, "day"));
    }
    getHardwareList();
    onOpen();
  }

  function closing() {
    onClose();
  }

  const getHardwareList = useCallback(
    async function () {
      const q = query(
        collection(db, "hardware"),
        where("location", "==", hardwareLocation),
        where(filterType, "==", filter),
        where("isActive", "==", true),
        orderBy("type", "asc"),
        orderBy("package", "asc"),
        orderBy("lengthActual", "asc"),
        orderBy("code", "asc"),
      );

      const querySnapshot = await getDocs(q);
      const hardwareList = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      hardwareList.sort(
        sortOn("package", [
          "Progression Ski",
          "Performer Ski",
          "Premier Ski",
          "Junior Ski",
          "Tweener Ski",
          "Performer Snowboard",
          "Premier Snowboard",
          "Junior Snowboard",
        ]),
      );

      setHardwareList(hardwareList);
    },
    [filter, filterType, hardwareLocation],
  );

  useEffect(() => {
    if (isOpen) {
      getHardwareList();
    }
  }, [isOpen, getHardwareList]);

  //get new data when hardware list loads, and when parameters change
  useEffect(() => {
    if (hardwareList) {
      const dateArray = [];
      for (let i = 0; i < dateList.length; i++) {
        dateArray.push(dayjs.tz(dateList[i]).format("YYYYMMDD"));
      }
      const constraints = [
        where("location", "==", hardwareLocation),
        where("dates", "array-contains-any", dateArray),
      ];
      if (filter == "Ski" || filter == "Snowboard") {
        constraints.push(where("hardwareType", "==", filter));
      } else {
        constraints.push(where("packageType", "==", filter));
      }
      const q = query(collection(db, "allocations"), ...constraints);

      //note: put hardware status in allocations so "Tune" state can be shown
      const unsub = onSnapshot(q, (querySnapshot) => {
        const allocations = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          status: hardwareList.find((o) => o["id"] === doc.data().hardwareId)?.status,
        }));

        setAllocations(allocations);
      });
      return unsub;
    }
  }, [hardwareList, selectedDay, zoomFactor]);

  function renderPackageOptions() {
    const options = [];

    const filters = [
      "Ski",
      "Snowboard",
      "Progression Ski",
      "Performer Ski",
      "Premier Ski",
      "Performer Snowboard",
      "Premier Snowboard",
      "Junior Ski",
      "Tweener Ski",
      "Junior Snowboard",
    ];

    filters.forEach((name) => {
      options.push(
        <option key={name} value={name}>
          {name}s
        </option>,
      );
    });

    return (
      <Select variant="outline" w="230px" onChange={changePackageType} defaultValue={filter}>
        {options}
      </Select>
    );
  }

  async function setHardware(hardware) {
    //save to firestore
    const skierDoc = doc(
      db,
      "orders",
      selectedAllocation.orderId,
      "skiers",
      selectedAllocation.skierId,
    );
    updateDoc(skierDoc, {
      selectedHardware: {
        id: hardware.id,
        location: hardware.location,
        make: hardware.make,
        model: hardware.model,
        code: hardware.code,
        length: hardware.length,
        package: hardware.package,
        type: hardware.type,
        locked: false,
      },
    });

    const allocationDoc = doc(db, "allocations", selectedAllocation.id);
    updateDoc(allocationDoc, {
      hardwareId: hardware.id,
      hardwareName: hardware.make + " " + hardware.model,
      hardwareMake: hardware.make,
      hardwareModel: hardware.model,
      hardwareCode: hardware.code,
      hardwareType: hardware.type,
      packageType: hardware.package,
      location: hardwareLocation,
      locked: false,
    });
    setSelectedAllocation(null);
  }

  function changePackageType(event) {
    setFilter(event.target.value);
    setFilterType(
      event.target.value == "Ski" || event.target.value == "Snowboard" ? "type" : "package",
    );
  }

  function convertRange(value, inMin, inMax, outMin, outMax) {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  /**
   * @param {THardware} hardware
   */
  function renderRowAllocations(hardware) {
    const allocationRow = [];
    let isFree = true;
    //TODO: filter as per dropdown for performance?
    if (allocations)
      for (const allocation of allocations) {
        if (allocation.hardwareId == hardware.id) {
          const dayOffset = Math.round(
            // need to round a float as default integer diff doesn't result in whole days
            dayjs
              .tz(allocation.start.toDate())
              .startOf("day")
              .diff(dayjs.tz(selectedDay.startOf("day").toDate()), "d", true),
          );

          const leftStart = (60 / zoomFactor) * dayOffset;
          const startOffset =
            convertRange(dayjs.tz(allocation.start.toDate()).hour(), 7, 22, 10, 50) / zoomFactor;
          const endOffset =
            convertRange(dayjs.tz(allocation.end.toDate()).hour(), 7, 22, 10, 50) / zoomFactor;
          const totalDays = dayjs(allocation.end.toDate())
            .startOf("d")
            .diff(dayjs(allocation.start.toDate()).startOf("d"), "d");

          allocationRow.push(
            <Box
              key={allocation.id}
              pos="absolute"
              height="48px"
              left={leftStart + startOffset + "px"}
              width={(totalDays * 60) / zoomFactor + endOffset - startOffset + "px"}
              borderWidth="1px"
              borderColor={
                allocation.status == "Tune" &&
                dayjs
                  .tz(allocation.end.toDate())
                  .isBetween(dayjs.tz(new Date()).subtract(3, "day"), dayjs.tz(new Date()))
                  ? "orange.500"
                  : "gray.300"
              }
              borderRadius="4"
              bg={allocation.id == selectedAllocation?.id ? "brand.500" : "gray.100"}
              overflowWrap="normal"
              overflowX="hidden"
              overflowY="hidden"
              whiteSpace="nowrap"
              pl="4px"
              fontSize="2xs"
              top="0px"
            >
              <HStack overflowX="hidden">
                <Text fontWeight="bold" isTruncated>
                  {allocation.skierName}
                </Text>
                <Text isTruncated> {allocation.customerName}</Text>
                <Spacer />
                {allocation.status == "Tune" &&
                  dayjs
                    .tz(allocation.end.toDate())
                    .isBetween(dayjs.tz(new Date()).subtract(3, "day"), dayjs.tz(new Date())) && (
                    <Box pr={1}>
                      <Icon
                        boxSize={3}
                        as={BsFillWrenchAdjustableCircleFill}
                        color="orange.500"
                        position="relative"
                        top="2px"
                      />
                    </Box>
                  )}
              </HStack>

              <Flex mt="-2px" gap={1}>
                <Text>{lookup.height.cm[allocation.height - 1]?.label}cm</Text>
                <Text fontWeight="bold">
                  {allocation.ability.charAt(0).toUpperCase() + allocation.ability.slice(1)}
                </Text>
                <Spacer />
              </Flex>

              <Flex mt="-2px" gap={1} align="center">
                {allocation.locked && <Icon as={FaLock} boxSize={3} mt="2px"></Icon>}
                <Text>{dayjs.tz(allocation.start.toDate()).format("h.mma")}</Text>
                <Spacer />
                {allocation.adjusted && <AiOutlineColumnWidth />}
                <Spacer />
                <Text pr="2px">{dayjs.tz(allocation.end.toDate()).format("h.mma")}</Text>
              </Flex>
              <Box
                as="button"
                pos="absolute"
                top="0px"
                left="0px"
                width={50 / zoomFactor + "px"}
                height="56px"
                onClick={() => !allocation.locked && selectAllocation(allocation)}
              />
              <Box
                as="button"
                pos="absolute"
                top="0px"
                right="0px"
                width={50 / zoomFactor + "px"}
                height="56px"
                onClick={(e) => {
                  setModalId(allocation.orderId, Boolean(e.ctrlKey || e.shiftKey));
                }}
              />
            </Box>,
          );
          if (selectedAllocation) {
            if (
              dayjs.tz(allocation.start.toDate()) <= dayjs.tz(selectedAllocation.end.toDate()) &&
              dayjs.tz(selectedAllocation.start.toDate()) <= dayjs.tz(allocation.end.toDate())
            ) {
              isFree = false;
            }
          }
        }
      }

    if (allocations && isFree && selectedAllocation) {
      const dayOffset = Math.round(
        // need to round a float as default integer diff doesn't result in whole days
        dayjs
          .tz(selectedAllocation.start.toDate())
          .startOf("day")
          .diff(selectedDay.startOf("day").toDate(), "day", true),
      );

      const leftStart = (60 / zoomFactor) * dayOffset;
      const startOffset =
        convertRange(dayjs.tz(selectedAllocation.start.toDate()).hour(), 7, 22, 10, 50) /
        zoomFactor;
      const endOffset =
        convertRange(dayjs.tz(selectedAllocation.end.toDate()).hour(), 7, 22, 10, 50) / zoomFactor;
      const totalDays = dayjs(selectedAllocation.end.toDate())
        .startOf("d")
        .diff(dayjs(selectedAllocation.start.toDate()).startOf("d"), "d"); //will actually return total - 1 which is intentional

      allocationRow.push(
        <Box
          key={hardware.id + "select"}
          as="button"
          height="48px"
          left={leftStart + startOffset + "px"}
          width={(totalDays * 60) / zoomFactor + endOffset - startOffset + "px"}
          borderWidth={2}
          borderStyle="dashed"
          borderColor="brand.500"
          borderRadius={8}
          pos="relative"
          overflowWrap="normal"
          overflowX="hidden"
          overflowY="hidden"
          whiteSpace="nowrap"
          px="4px"
          textAlign="center"
          onClick={() => setHardware(hardware)}
        ></Box>,
      );
    }

    return <>{allocationRow}</>;
  }

  function selectAllocation(allocation) {
    if (dayjs.tz(allocation.end.toDate()).isBefore(dayjs.tz(), "day")) {
      toast({
        title: "Past allocation, can't change!",
        status: "warning",
        duration: 3000,
        isClosable: false,
      });
      return;
    }
    setSelectedAllocation(allocation.id == selectedAllocation?.id ? null : allocation);
  }

  function renderBackgroundGrid(daysToShow) {
    const output = [];
    const width = 60 / zoomFactor;
    for (let i = 0; i < daysToShow; i++) {
      output.push(
        <Box
          key={i}
          height="50px"
          position="absolute"
          left={`${i * width}px`}
          borderColor="gray.100"
          borderRightWidth={i + 1 == daysToShow ? "" : "2px"}
          borderBottomWidth="2px"
          width={width + "px"}
          borderStyle="dotted"
          bgColor={
            selectedDay.add(i, "day").format("ddd") == "Sat" ||
            selectedDay.add(i, "day").format("ddd") == "Sun"
              ? "gray.50"
              : ""
          }
        ></Box>,
      );
    }
    return output;
  }

  function selectDate(date) {
    setSelectedDay(dayjs.tz(new Date(date.year, date.month - 1, date.day)));
  }

  const formatInputValue = () => {
    if (!selectedDay) return "";
    return selectedDay.format("ddd d MMM");
  };

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      placeholder=""
      value={selectedDay ? selectedDay.format("ddd D MMM") : ""}
      style={{
        textAlign: "center",
        fontFamily: "var(--chakra-fonts-heading)",
        padding: "4px",
        fontSize: "1.1rem",
        border: "0px",
        borderRadius: "100px",
        outline: "none",
        cursor: "pointer",
        width: "150px",
      }}
      className="my-custom-input-class" // a styling class
    />
  );

  return (
    <>
      <Button
        variant={isButton ? "solid" : "ghost"}
        leftIcon={<CiGrid31 />}
        onClick={opening}
        size={size}
        {...buttonProps}
      >
        {text}
      </Button>
      {isOpen && (
        <Modal
          size="full"
          isOpen={isOpen}
          onClose={closing}
          scrollBehavior="inside"
          initialFocusRef={null}
          trapFocus={false}
          motionPreset="slideInBottom"
          blockScrollOnMount={false}
        >
          <ModalOverlay />
          <ModalContent minH="100%" maxH="100%">
            <ModalHeader>
              <HStack>
                <Box>
                  <HStack spacing={4}>
                    <Text>Availability Grid</Text>
                    <Select
                      defaultValue={hardwareLocation}
                      onChange={(e) => setHardwareLocation(e.target.value)}
                      w="fit-content"
                      size="sm"
                    >
                      {["Queenstown", "Wanaka", "Hilton"].map((location) => (
                        <option key={location} value={location}>
                          {location}
                        </option>
                      ))}
                    </Select>
                  </HStack>

                  <Text fontSize="xs" fontWeight="normal">
                    Tap left end of boxes to relocate, right end to open
                  </Text>
                </Box>
                <Spacer />
                <Button
                  size="sm"
                  bgColor="gray.100"
                  onClick={() => setSelectedDay(selectedDay.add(-1 * zoomFactor, "week"))}
                >
                  <Text>{"<"}</Text>
                </Button>
                <DatePicker
                  value={{
                    year: selectedDay.year(),
                    month: selectedDay.month() + 1,
                    day: selectedDay.date(),
                  }}
                  onChange={selectDate}
                  inputPlaceholder="Select a day"
                  shouldHighlightWeekends
                  locale={datepickerLocale}
                  formatInputText={formatInputValue}
                  renderInput={renderCustomInput}
                  colorPrimary="var(--chakra-colors-brand-500)"
                  calendarTodayClassName="custom-today-day"
                />
                <Button
                  size="sm"
                  bgColor="gray.100"
                  onClick={() => setSelectedDay(selectedDay.add(1 * zoomFactor, "week"))}
                >
                  <Text>{">"}</Text>
                </Button>
                <Box w={6} />
                <Button
                  width={28}
                  size="sm"
                  leftIcon={zoomFactor == 1 ? <HiZoomOut /> : <HiZoomIn />}
                  onClick={() => {
                    setZoomFactor(zoomFactor == 1 ? 2 : 1);
                  }}
                >
                  {zoomFactor == 1 ? "zoom out" : "zoom in"}
                </Button>
                <Spacer />
                <Button size="sm" colorScheme="brand" onClick={onClose}>
                  DONE
                </Button>
              </HStack>
              <HStack mt={6}>
                <Spacer />
                <Box minW="260px">{renderPackageOptions()}</Box>

                <HStack spacing={10 / zoomFactor + "px"} position="relative" left="-8px">
                  {dateList.map((date, i) => (
                    <DateBox
                      key={date.toString()}
                      date={date}
                      highlightWeekends={true}
                      isCompact={zoomFactor == 2}
                    />
                  ))}
                </HStack>
                <Spacer />
              </HStack>
            </ModalHeader>

            <ModalBody
              position="static"
              pb={12}
              sx={{ "::-webkit-scrollbar": { display: "none" } }}
              onClick={() => {
                if (selectedAllocation) setSelectedAllocation(null);
              }}
            >
              {hardwareList?.map((hardware) => (
                <HStack align="flex-start" key={hardware.id}>
                  <Spacer />
                  <Box
                    minW="260px"
                    h="50px"
                    color={hardware.status === "Unavailable" ? "gray" : undefined}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        `/settings/hardware?location=${hardware.location}&hardwareId=${hardware.id}`,
                        "_blank",
                      );
                    }}
                  >
                    <Text
                      fontSize={hardware.name.length > 28 ? "sm" : "md"}
                      fontWeight="bold"
                      isTruncated
                    >
                      {hardware.make} {hardware.model} {hardware.length}cm
                      {hardware.isFemale && <Icon position="relative" top="2px" as={FaFemale} />}
                    </Text>

                    <PackageName packageName={hardware.package} suffix={` (${hardware.code})`} />
                  </Box>
                  <Box
                    pos="relative"
                    top="0"
                    left="-8px"
                    overflow="hidden"
                    h="50px"
                    w={baseDaysToShow * zoomFactor * (60 / zoomFactor) + "px"}
                  >
                    {renderBackgroundGrid(baseDaysToShow * zoomFactor)}
                    {renderRowAllocations(hardware)}
                  </Box>
                  <Spacer />
                </HStack>
              ))}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
