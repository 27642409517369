import { Box, Flex, HStack, Icon, Spacer, Text, useDisclosure } from "@chakra-ui/react";

import { HiExclamation } from "react-icons/hi/index.esm.js";
import { PiFirstAidFill } from "react-icons/pi";
import { RxDragHandleVertical } from "react-icons/rx/index.esm.js";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";

import { useShowError } from "@/lib/error.js";

import DirectSupportCallModal from "../modals/DirectSupportCallModal.jsx";

/**
 * @param {Object} props
 * @param {TDirectSupportCall} props.supportCall
 * @param {boolean} [props.isSelected]
 * @param {(item: TDirectSupportCall) => void} [props.onSelectCard]
 * @returns {JSX.Element}
 */
export default function JobCardDirectSupport({ supportCall, isSelected, onSelectCard }) {
  const { showError } = useShowError();
  const completeJobDisclosure = useDisclosure();

  return (
    <>
      <HStack spacing="0" position="relative">
        <Flex
          as="button"
          bg={isSelected ? "brand.500" : "gray.50"}
          borderColor={isSelected ? "brand.500" : ""}
          w="24px"
          m={0}
          h="100px"
          borderLeftRadius="16px"
          borderRightWidth="0"
          position="relative"
          left="6px"
          borderWidth={1}
          align="center"
          disabled={!onSelectCard}
          onClick={() => onSelectCard && onSelectCard(supportCall)}
        >
          <Icon as={RxDragHandleVertical} ml="4px"></Icon>
        </Flex>
        <Box
          as="button"
          bg={"gray.50"}
          borderWidth={1}
          borderRadius="lg"
          p={3}
          pl={3}
          h="100px"
          w={"220px"}
          onClick={() => {
            completeJobDisclosure.onOpen();
          }}
          textAlign="left"
        >
          <Flex>
            <HStack>
              <Text fontWeight="bold">{supportCall.type}</Text>
            </HStack>
            <Spacer />
            <HStack spacing="0">
              <Icon as={PiFirstAidFill} boxSize={6} />
            </HStack>
          </Flex>
          <Text isTruncated>{supportCall.addressDelivery}&nbsp;</Text>
          <HStack>
            <Box flexGrow={1} />
            {dayjs.tz(getFieldAsDate(supportCall.driveTime)).diff(new Date()) < 0 && (
              <Icon as={HiExclamation} boxSize={5} color="red.500" />
            )}
            <Text>{dayjs.tz(getFieldAsDate(supportCall.driveTime)).format("h.mma")}</Text>
          </HStack>
        </Box>
        <Box bg="transparent" w={7} m={0} h={16} borderRightRadius="xl" />
      </HStack>
      <DirectSupportCallModal
        selfDisclosure={completeJobDisclosure}
        supportCall={supportCall}
        operation="complete"
      />
    </>
  );
}
