import { HStack, Box, Text, GridItem } from "@chakra-ui/react";
import { TbShoe } from "react-icons/tb";
import PackageName from "../../common/PackageName";

export default function CheckPackageType({ skier }) {
  return (
    <GridItem justifySelf="start">
      {skier.packageType && <PackageName packageName={skier.packageType} />}
    </GridItem>
  );
}
