import { useState } from "react";

import { Box, SlideFade } from "@chakra-ui/react";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import { useLocationConfig } from "@/lib/locationLib.js";
import { sundriesByPackageItemType } from "@/lib/order-ui-utils.js";

import CheckBootDone from "../checks/CheckBootDone.jsx";
import CheckBootSelect from "../checks/CheckBootSelect.jsx";
import CheckDeliverExtras from "../checks/CheckDeliverExtras.jsx";
import CheckDeliverOuterwear from "../checks/CheckDeliverOuterwear.jsx";
import CheckSkierInfo from "../checks/CheckSkierInfo.jsx";
import RowCheck from "../checks/RowCheck.jsx";
import SkierGrid from "../common/SkierGrid.jsx";
import SkierRow from "../common/SkierRow.jsx";
import StepHeader from "../common/StepHeader.jsx";

/**
 * @param {object} props
 * @param {TOrderAndSkiersWithItems} props.order
 * @returns {JSX.Element}
 */
export default function StepDeliverFitSkiers({ order }) {
  const [isEditMode, SetEditMode] = useState(false);
  const { inStore } = useLocationConfig(order.location);

  const config = useConfig();

  /**
   * @param {TSkierWitItems} skier
   * @returns {boolean}
   */
  function isRowChecked(skier) {
    let bootFitted = false;
    let jacketChecked = false;
    let pantsChecked = false;
    let helmetChecked = false;

    if (skier.ownBoots) {
      if (skier.ownBootSole) bootFitted = true;
    } else {
      if (!skier.packageType) {
        bootFitted = true;
      } else {
        bootFitted = Boolean(inStore ? skier.selectedBoot : skier.progress?.deliverboot);
      }
    }

    if (skier.jacket) {
      jacketChecked = Boolean(skier.progress?.deliverjacket);
    } else {
      jacketChecked = true;
    }

    if (skier.pants) {
      pantsChecked = Boolean(skier.progress?.deliverpants);
    } else {
      pantsChecked = true;
    }

    if (skier.helmet) {
      helmetChecked = Boolean(skier.progress?.deliverhelmet);
    } else {
      helmetChecked = true;
    }

    let allExtrasChecked = true;
    skier.extras?.every((product) => {
      if (!skier?.progress?.["deliver" + product.code]) {
        allExtrasChecked = false;
        return false;
      } else {
        return true;
      }
    });

    return bootFitted && jacketChecked && pantsChecked && helmetChecked && allExtrasChecked;
  }

  function toggleEditMode() {
    SetEditMode(!isEditMode);
  }

  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <Box width="1100px">
        <StepHeader
          customerName={order.firstName + " " + order.lastName}
          orderId={order.id}
          showEditMode={true}
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
          referrer={order.referrer.name}
        />
        <SkierGrid templateColumns="3fr 2fr repeat(7, 1fr)">
          {order.skiers.map((skier) => {
            const hasSundriesBoots =
              sundriesByPackageItemType(config, skier, ["boots", "boots-deluxe"]).length > 0;
            return (
              <SkierRow key={skier.id} cols={9}>
                <CheckSkierInfo
                  skier={skier}
                  hideSurname={true}
                  sundriesWarning={hasSundriesBoots ? "Additional sundries boots" : undefined}
                />

                {inStore ? (
                  <CheckBootSelect
                    skier={skier}
                    order={order}
                    showDIN={true}
                    isEditMode={isEditMode}
                    inStore={inStore}
                    source="flow-deliver"
                  />
                ) : (
                  <CheckBootDone
                    skier={skier}
                    orderId={order.id}
                    prefix="deliver"
                    isEditMode={isEditMode}
                    source="flow-deliver"
                  />
                )}
                <CheckDeliverOuterwear
                  skier={skier}
                  orderId={order.id}
                  type="jacket"
                  isEditMode={isEditMode}
                />
                <CheckDeliverOuterwear
                  skier={skier}
                  orderId={order.id}
                  type="pants"
                  isEditMode={isEditMode}
                />
                <CheckDeliverOuterwear
                  skier={skier}
                  orderId={order.id}
                  type="helmet"
                  isEditMode={isEditMode}
                />
                <CheckDeliverExtras
                  skier={skier}
                  orderId={order.id}
                  type="goggle"
                  isEditMode={isEditMode}
                />
                <CheckDeliverExtras
                  skier={skier}
                  orderId={order.id}
                  type="glove"
                  isEditMode={isEditMode}
                />
                <CheckDeliverExtras
                  skier={skier}
                  orderId={order.id}
                  type="sock"
                  isEditMode={isEditMode}
                />

                <RowCheck completed={!hasSundriesBoots && isRowChecked(skier)} />
              </SkierRow>
            );
          })}
        </SkierGrid>
      </Box>
    </SlideFade>
  );
}
