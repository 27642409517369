import { getFieldAsDate } from "./firestore.js";
import { SkierItemSchema } from "./models.js";
import { twoDecimals } from "./numbers.js";
import { getDiff } from "./objects.js";
import { getSkiPassDescription, parseSkiPass } from "./ski-pass.js";

/**
 * @typedef {Object} TSkierCalc
 * @type {Pick<TSkier, "packageType"|"adjustments"|"insurance"|"costs"|"bootUpgrade"|"ownBoots"|"helmet"|"pants"|"jacket"|"extras"|"discountsPercentage"|"sundries"> & { skiPass?: TSkierSkiPass } }
 *
 * @typedef {Object} TOrderCalc
 * @type {Pick<TOrder, "skiDays" | "autoCalcDiscount">}

* @typedef {Object} TCalcResult
 * @type {{ costs: number; skiPass: number; total: number; }}
 */

export function getRentalPriceDiscountPercentage(skiDays) {
  if (skiDays > 4) {
    return 0.1;
  }
  return 0;
}

/**
 *
 * @param {Object} priceInfo
 * @param {number} priceInfo.quantity
 * @param {number} priceInfo.unitPrice
 * @param {number} [priceInfo.discountPercentage]
 * @returns {TSkierPrice}
 */
const calcPrice = ({ quantity, unitPrice, discountPercentage }) => {
  unitPrice = twoDecimals(unitPrice);
  const totalPrice = twoDecimals(unitPrice * quantity);
  discountPercentage = discountPercentage ?? 0;
  const unitPriceDiscount = twoDecimals(unitPrice * (1 - discountPercentage));
  const totalPriceDiscount = twoDecimals(unitPriceDiscount * quantity);

  return {
    discountPercentage,
    unitPrice,
    totalPrice,
    unitPriceDiscount,
    totalPriceDiscount,
    finalPrice: totalPriceDiscount,
  };
};

/**
 * @param  {TSkierItemType} itemType
 * @param  {TTypeOfCosts} [costType]
 * @param  {boolean} [isSundries]
 */
const getSkierItemSequence = (itemType, costType, isSundries) => {
  if (isSundries) {
    return 110;
  }

  if (itemType === "product") {
    return 90;
  }

  switch (costType) {
    case "packageType":
      return 1;
    case "insurance":
      return 20;
    case "bootUpgrade":
      return 30;
    case "ownBoots":
      return 40;
    case "helmet":
      return 50;
    case "jacket":
      return 60;
    case "pants":
      return 70;
    case "combo":
      return 80;
    case "skiPass":
      return 200;
    default:
      return 90;
  }
};
/**
 * @param {Pick<TSkierItem, "code"|"type"|"costType"| "description"|"quantity"|"shortName"|"price"|"isSundries"|"discountPercentage"|"adjustmentDays">} input
 * @returns {TSkierItem}
 */
export const mapSkierItem = (input) => {
  const sequence = getSkierItemSequence(input.type, input.costType, input.isSundries);

  const idMiddle = input.isSundries
    ? "sundries"
    : input.type === "product"
    ? "extras"
    : input.costType;
  const sequenceStr = sequence.toString().padStart(3, "0");
  /** @type {TSkierItem} */
  const item = {
    ...input,
    sequence,
    id: [sequenceStr, idMiddle, idMiddle === input.code ? "" : input.code]
      .filter(Boolean)
      .join("-"),
  };
  return SkierItemSchema.parse(item);
};
/**
 * @param {Omit<TOrderCalc, "autoCalcDiscount">} order
 * @param {TSkierCalc} skier
 * @param {Record<string,TSkiPass>} [skiPass]
 * @returns {TSkierItem[]}
 * */
export function mapSkierItems(order, skier, skiPass) {
  /** @type {TSkierItem[]} */
  const items = [];
  if (skier.packageType) {
    const quantity = order.skiDays + (skier.adjustments?.packageType_days || 0);
    const discountPercentage = skier.discountsPercentage?.packageType;
    const unitPrice = skier.costs.packageType ?? 0;
    items.push(
      mapSkierItem({
        costType: "packageType",
        description: skier.packageType,
        type: "rental",
        code: skier.packageType,
        quantity,
        price: calcPrice({ quantity, unitPrice, discountPercentage }),
      }),
    );
  }
  Object.values(skier.sundries ?? {})
    .sort((l, r) => {
      return l.id.localeCompare(r.id);
    })
    .forEach((sundries) => {
      const quantity =
        sundries.type === "Rental" ? order.skiDays + (sundries.adjustmentDays ?? 0) : 1;
      const discountPercentage =
        sundries.type === "Rental" && sundries.discountPercentage ? sundries.discountPercentage : 0;
      const unitPrice = sundries.amount;
      items.push(
        mapSkierItem({
          type: sundries.type === "Rental" ? "rental" : "sales",
          description: sundries.name,
          code: sundries.id,
          quantity,
          price: calcPrice({ quantity, unitPrice, discountPercentage }),
          ...(discountPercentage && { discountPercentage }),
          ...(sundries.adjustmentDays && { adjustmentDays: sundries.adjustmentDays }),
          isSundries: true,
        }),
      );
    });
  if (skier.insurance) {
    const quantity = order.skiDays + (skier.adjustments?.insurance_days || 0);
    const unitPrice = skier.costs.insurance ?? 0;
    items.push(
      mapSkierItem({
        costType: "insurance",
        type: "miscellaneous",
        description: "Insurance",
        quantity,
        price: calcPrice({ quantity, unitPrice }),
      }),
    );
  }

  if (skier.bootUpgrade) {
    const quantity = order.skiDays + (skier.adjustments?.bootUpgrade_days || 0);
    const discountPercentage = skier.discountsPercentage?.bootUpgrade;
    const unitPrice = skier.costs.bootUpgrade ?? 0;
    items.push(
      mapSkierItem({
        costType: "bootUpgrade",
        type: "rental",
        description: "Deluxe boot upgrade",
        quantity,
        price: calcPrice({ quantity, unitPrice, discountPercentage }),
      }),
    );
  }

  if (skier.ownBoots) {
    const quantity = order.skiDays + (skier.adjustments?.ownBoots_days || 0);
    const discountPercentage = 0;
    const unitPrice = skier.costs.ownBoots ?? 0;
    items.push(
      mapSkierItem({
        costType: "ownBoots",
        type: "rental",
        description: "Own boots",
        quantity,
        price: calcPrice({ quantity, unitPrice, discountPercentage }),
      }),
    );
  }

  if (skier.helmet) {
    const quantity = order.skiDays + (skier.adjustments?.helmet_days || 0);
    const discountPercentage = skier.discountsPercentage?.helmet;
    const unitPrice = skier.costs.helmet ?? 0;
    items.push(
      mapSkierItem({
        costType: "helmet",
        type: "rental",
        description: "Helmet",
        quantity,
        price: calcPrice({ quantity, unitPrice, discountPercentage }),
      }),
    );
  }

  if (skier.jacket && skier.pants) {
    const quantity = order.skiDays + (skier.adjustments?.combo_days || 0);
    const discountPercentage = skier.discountsPercentage?.combo;
    const unitPrice = skier.costs.combo ?? 0;
    items.push(
      mapSkierItem({
        costType: "combo",
        type: "rental",
        description: "Jacket/pants combo",
        quantity,
        price: calcPrice({ quantity, unitPrice, discountPercentage }),
      }),
    );
  } else {
    if (skier.jacket) {
      const quantity = order.skiDays + (skier.adjustments?.jacket_days || 0);
      const discountPercentage = skier.discountsPercentage?.jacket;
      const unitPrice = skier.costs.jacket ?? 0;
      items.push(
        mapSkierItem({
          costType: "jacket",
          type: "rental",
          description: "Jacket",
          quantity,
          price: calcPrice({ quantity, unitPrice, discountPercentage }),
        }),
      );
    }
    if (skier.pants) {
      const quantity = order.skiDays + (skier.adjustments?.pants_days || 0);
      const discountPercentage = skier.discountsPercentage?.pants;
      const unitPrice = skier.costs.pants ?? 0;
      items.push(
        mapSkierItem({
          costType: "pants",
          type: "rental",
          description: "Pants",
          quantity,
          price: calcPrice({ quantity, unitPrice, discountPercentage }),
        }),
      );
    }
  }

  skier.extras?.forEach((product) => {
    items.push(
      mapSkierItem({
        type: "product",
        code: product.code,
        description: product.name,
        shortName: product.shortname,
        quantity: 1,
        price: calcPrice({ quantity: 1, unitPrice: product.price }),
      }),
    );
  });

  if (skier.skiPass && typeof skier.costs.skiPass === "number") {
    items.push(
      mapSkierItem({
        costType: "skiPass",
        type: "skipass",
        description: [
          "Ski Pass",
          getSkiPassDescription(skiPass?.[skier.skiPass.id] ?? skier.skiPass.id),
          skier.skiPass?.passInfo || "",
        ]
          .filter(Boolean)
          .join(" - "),
        quantity: 1,
        price: calcPrice({ quantity: 1, unitPrice: skier.costs.skiPass }),
        code: skier.skiPass.id,
      }),
    );
  }
  items.sort((l, r) => l.sequence - r.sequence);
  return items;
}

/**
 * @param {TSkierItem[]} skierItems
 */
export function mapSummaryFromSkierItems(skierItems) {
  const value = skierItems.reduce(
    (acc, item) => {
      if (item.type === "skipass") {
        acc.skipass.finalPrice = twoDecimals(acc.skipass.finalPrice + item.price.finalPrice);
        acc.skipass.totalPrice = twoDecimals(acc.skipass.totalPrice + item.price.totalPrice);
        acc.skipass.totalDiscount = twoDecimals(acc.skipass.totalPrice - acc.skipass.finalPrice);
        acc.skipass.items.push(item);
      } else {
        acc.snopro.finalPrice = twoDecimals(acc.snopro.finalPrice + item.price.finalPrice);
        acc.snopro.totalPrice = twoDecimals(acc.snopro.totalPrice + item.price.totalPrice);
        acc.snopro.totalDiscount = twoDecimals(acc.snopro.totalPrice - acc.snopro.finalPrice);
        acc.snopro.items.push(item);
      }
      acc.total.finalPrice = twoDecimals(acc.total.finalPrice + item.price.finalPrice);
      acc.total.totalPrice = twoDecimals(acc.total.totalPrice + item.price.totalPrice);
      acc.total.totalDiscount = twoDecimals(acc.total.totalPrice - acc.total.finalPrice);
      acc.total.items.push(item);

      return acc;
    },
    {
      skipass: {
        finalPrice: 0,
        totalPrice: 0,
        totalDiscount: 0,
        /** @type {TSkierItem[]} */ items: [],
      },
      snopro: {
        finalPrice: 0,
        totalPrice: 0,
        totalDiscount: 0,
        /** @type {TSkierItem[]} */ items: [],
      },
      total: {
        finalPrice: 0,
        totalPrice: 0,
        totalDiscount: 0,
        /** @type {TSkierItem[]} */ items: [],
      },
    },
  );

  return value;
}

/**
 * @param {TOrder} order
 * @returns {TOrderPaymentTransaction[]}
 */
export function getOrderPaymentTransactionList(order) {
  return Object.values(order.payment.transactionsById)
    .map((transaction) => transaction)
    .sort(
      (l, r) =>
        getFieldAsDate(l.createdOn ?? new Date()).getTime() -
        getFieldAsDate(r.createdOn ?? new Date()).getTime(),
    );
}

/**
 * @param {TSkier} l
 * @param {TSkier} r
 * @returns {number}
 */
export function compareSkierDefaultSorting(l, r) {
  let result = r.age - l.age;
  if (result === 0) {
    result = l.firstName.localeCompare(r.firstName);
  }

  if (result === 0) {
    result = (l.lastName ?? "").localeCompare(r.lastName ?? "");
  }

  if (l.id === r.duplicateOf) {
    result = -1;
  } else if (r.id === l.duplicateOf) {
    result = 1;
  }

  if (result === 0) {
    result = getFieldAsDate(l.created).getTime() - getFieldAsDate(r.created).getTime();
  }

  return result;
}

/**
 * Maps the order and skiers to a new enhanced order object
 * @param {Object} props
 * @param {TOrder} props.order
 * @param {TSkier[]} [props.skiers]
 * @returns {TCalculatedOrder}
 */
export const mapCalculatedOrder = ({ order, skiers }) => {
  /** @type {Record<string, TSkierItem[]>} */
  const itemsBySkierIds = {};
  if (!order) {
    throw new Error("Order is required");
  }

  let totalDiscount = 0;
  let totalPrice = 0;
  let finalPrice = 0;
  let skiPassesTotal = 0;
  let numberOfSkiPasses = 0;
  let paymentsOnAccount = 0;
  let paymentsOnCash = 0;
  let paymentsOnPOS = 0;
  let paymentsOnCreditCard = 0;
  let skiPassDaysCount = 0;

  const newSikiers = (skiers ?? []).map(
    /** @returns {TSkierWitItems} */ (skier) => {
      const items = mapSkierItems(order, skier);
      itemsBySkierIds[skier.id] = items;
      const summary = mapSummaryFromSkierItems(items).total;
      totalDiscount = twoDecimals(totalDiscount + summary.totalDiscount);
      totalPrice = twoDecimals(totalPrice + summary.totalPrice);
      finalPrice = twoDecimals(finalPrice + summary.finalPrice);
      const skipassItem = items.find((item) => item.type === "skipass");
      if (skipassItem) {
        skiPassDaysCount += parseSkiPass(skipassItem.code ?? "")?.days ?? 0;
        numberOfSkiPasses += 1;
      }
      const skipassPrice = skipassItem && "price" in skipassItem ? skipassItem.price : null;
      if (skipassPrice) {
        skiPassesTotal += skipassPrice.finalPrice;
      }

      return {
        ...skier,
        items,
        totalDiscount: summary.totalDiscount,
        totalPrice: summary.totalPrice,
        finalPrice: summary.finalPrice,
      };
    },
  );
  const balance = twoDecimals(finalPrice - order.paidAmount);

  getOrderPaymentTransactionList(order).forEach((transaction) => {
    if (transaction.type !== "payment") {
      return;
    }

    switch (transaction.method) {
      case "on-account":
        paymentsOnAccount = twoDecimals(
          paymentsOnAccount + transaction.amount - (transaction.refundedAmount ?? 0),
        );
        break;
      case "credit-card":
        paymentsOnCreditCard = twoDecimals(
          paymentsOnCreditCard + transaction.amount - (transaction.refundedAmount ?? 0),
        );
        break;
      case "cash":
        paymentsOnCash = twoDecimals(
          paymentsOnCash + transaction.amount - (transaction.refundedAmount ?? 0),
        );
        break;
      case "pos":
        paymentsOnPOS = twoDecimals(
          paymentsOnPOS + transaction.amount - (transaction.refundedAmount ?? 0),
        );
        break;
      default:
        // @ts-expect-error - because we are not running lint
        throw new Error(`Unknown payment method: ${transaction.method}`);
    }
  });
  return {
    order: { ...order, balance, totalDiscount, totalPrice, finalPrice, skiers: newSikiers },
    itemsBySkierIds,
    numberOfSkiPasses,
    skiPassesTotal: skiPassesTotal,
    rentalsTotal: finalPrice - skiPassesTotal,
    customerName: [order.firstName, order.lastName].join(" "),
    paymentsOnAccount,
    paymentsOnCash,
    paymentsOnCreditCard,
    paymentsOnPOS,
    skiPassDaysCount,
  };
};

/**
 * @param {Pick<TOrder, "payment">} order
 */
export const getPaidAmount = (order) =>
  Object.values(order.payment.transactionsById).reduce(
    (acc, t) => twoDecimals(acc + twoDecimals((t.type == "refund" ? -1 : 1) * t.amount)),
    0,
  ) ?? 0;

/**
 * @param {Pick<TOrder, "payment"|"finalPrice">} order
 */
export const getBalance = (order) => twoDecimals(order.finalPrice - getPaidAmount(order));

/**
 * @param {Pick<TOrder, |"payment"|"finalPrice">} order
 * @returns {TPaymentStatus}
 */
export const getOrderPaymentStatus = (order) => {
  const balance = getBalance(order);
  const paidAmount = getPaidAmount(order);
  if (balance === 0) {
    return "paid";
  }
  if (paidAmount === 0) {
    return "unpaid";
  }

  if (balance < 0) {
    return "overpaid";
  }

  return "partial";
};

/**
 * @description scenarios where the order needs to be re-calculated:
 * - change in ski days
 * - change in autoCalcDiscount property
 * - changes in the skiers e.g.:
 *   - adding or removing any product, miscellanous or ski pass
 *   - adjust days
 *   - discounts value
 * @param {TOrder} order
 * @param {TSkier[]} skiers
 * @returns {SAS2.firebase.TUpdateOrder}
 */
export function recalculateOrder(order, skiers) {
  /**
   * @type {SAS2.firebase.TUpdateOrder}
   */
  const update = {
    orderId: order.id,
    payload: {},
    skiers: [],
  };

  skiers.forEach((skier) => {
    let discountsPercentage = { ...skier.discountsPercentage };
    const sundriesList = Object.values(skier.sundries ?? {});
    /** @type {SAS2.firebase.TChangeSkierPayload} */
    const changes = {
      totalDiscount: skier.totalDiscount,
      totalPrice: skier.totalPrice,
      finalPrice: skier.finalPrice,
      discountsPercentage,
    };

    /** @type {SAS2.firebase.TChangeSkierPayload} */
    const current = {
      totalDiscount: skier.totalDiscount,
      totalPrice: skier.totalPrice,
      finalPrice: skier.finalPrice,
      discountsPercentage: skier.discountsPercentage,
    };

    if (skier.sundries && sundriesList.length > 0) {
      sundriesList.forEach((sundries) => {
        current[`sundries.${sundries.id}`] = { ...sundries };
      });
    }

    if (order.autoCalcDiscount) {
      sundriesList.forEach((item) => {
        if (item.type !== "Rental") {
          return;
        }

        const discountPercentage = getRentalPriceDiscountPercentage(
          order.skiDays + (item.adjustmentDays ?? 0),
        );
        if (!discountPercentage && item.discountPercentage) {
          delete item.discountPercentage;
          changes[`sundries.${item.id}`] = item;
        } else if (discountPercentage && discountPercentage !== item.discountPercentage) {
          item.discountPercentage = discountPercentage;
          changes[`sundries.${item.id}`] = item;
        }
      });

      discountsPercentage = {
        bootUpgrade: getRentalPriceDiscountPercentage(
          order.skiDays + (skier.adjustments.bootUpgrade_days ?? 0),
        ),
        helmet: getRentalPriceDiscountPercentage(
          order.skiDays + (skier.adjustments.helmet_days ?? 0),
        ),
        packageType: getRentalPriceDiscountPercentage(
          order.skiDays + (skier.adjustments.packageType_days ?? 0),
        ),
      };

      if (skier.jacket && skier.pants) {
        discountsPercentage.combo = getRentalPriceDiscountPercentage(
          order.skiDays + (skier.adjustments.combo_days ?? 0),
        );
      } else {
        discountsPercentage.jacket = getRentalPriceDiscountPercentage(
          order.skiDays + (skier.adjustments.jacket_days ?? 0),
        );
        discountsPercentage.pants = getRentalPriceDiscountPercentage(
          order.skiDays + (skier.adjustments.pants_days ?? 0),
        );
      }
    }

    const items = mapSkierItems(order, { ...skier, discountsPercentage });
    const summary = mapSummaryFromSkierItems(items).total;

    changes.totalDiscount = summary.totalDiscount;
    changes.totalPrice = summary.totalPrice;
    changes.finalPrice = summary.finalPrice;
    changes.discountsPercentage = discountsPercentage;

    const skierDiff = getDiff(current, changes);

    if (Object.keys(skierDiff).length > 0) {
      skier.totalDiscount = summary.totalDiscount;
      skier.totalPrice = summary.totalPrice;
      skier.finalPrice = summary.finalPrice;
      skier.discountsPercentage = discountsPercentage;
      update.skiers.push({ skierId: skier.id, payload: changes });
    }
  });

  const paidAmount = getPaidAmount(order);

  /**
   * @type {SAS2.firebase.TUpdateOrder["payload"]}
   */
  const updatedOrder = {
    totalPrice: skiers.reduce((acc, skier) => twoDecimals(acc + skier.totalPrice), 0),
    finalPrice: skiers.reduce((acc, skier) => twoDecimals(acc + skier.finalPrice), 0),
    totalDiscount: skiers.reduce((acc, skier) => twoDecimals(acc + skier.totalDiscount), 0),
    paidAmount,
  };

  if (typeof updatedOrder.totalPrice === "number") {
    order.totalPrice = updatedOrder.totalPrice;
  }
  if (typeof updatedOrder.finalPrice === "number") {
    order.finalPrice = updatedOrder.finalPrice;
  }
  if (typeof updatedOrder.totalDiscount === "number") {
    order.totalDiscount = updatedOrder.totalDiscount;
  }
  if (typeof updatedOrder.paidAmount === "number") {
    order.paidAmount = updatedOrder.paidAmount;
  }

  updatedOrder.balance = getBalance(order);
  order.balance = updatedOrder.balance;

  updatedOrder.paidStatus = getOrderPaymentStatus(order);
  order.paidStatus = updatedOrder.paidStatus;

  update.payload = { ...update.payload, ...updatedOrder };

  return update;
}

/**
 * @returns {TOrderState[]}
 */
export const getAllOrderStatuses = () => {
  /** @type {Record<TOrderState, number>} */
  const status = {
    toSize: 1,
    toPack: 2,
    toDeliver: 3,
    toCollect: 4,
    completed: 5,
    inactive: 7,
  };
  /** @type {any[]} */
  const listOfStatus = Object.entries(status)
    .sort((l, r) => l[1] - r[1])
    .map((v) => v[0]);
  return listOfStatus;
};
export const __AllOrderStatuses = Object.freeze(getAllOrderStatuses());
/**
 * @param {TOrderState} state
 */
export const orderStateToStatusDescription = (state) => {
  switch (state) {
    case "toSize":
      return "Created";
    case "toPack":
      return "Allocated";
    case "toDeliver":
      return "Packed";
    case "toCollect":
      return "Delivered";
    case "completed":
      return "Completed";
    case "inactive":
      return "Inactive";
    default:
      // @ts-expect-error just because there shouldn't be any other state
      return state.toUpperCase();
  }
};

/**
 * @param {TOrder} order
 * @param {string[]} newIds
 * @returns {string}
 */
export const getNewTransactionId = (order, newIds) => {
  let lastSequence = 0;
  let newId = "t" + (++lastSequence).toString().padStart(2, "0");
  while (order.payment.transactionsById[newId] || newIds.find((v) => v === newId)) {
    newId = "t" + (++lastSequence).toString().padStart(2, "0");
  }
  return newId;
};
