import { useEffect, useRef, useState } from "react";

import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import { SkierSchema } from "@snopro/common/models.js";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import { addSkier, duplicateSkier, removeSkier, updateSkier } from "@/lib/skier.api.js";
import { initRefInput, initRefInputSelect } from "@/lib/initialize-states.js";

/**
 * @param {{ skier?: TISkier; orderId:string }} props
 * @returns {JSX.Element}
 */
export default function EditSkierModal({ skier, orderId }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const skierFirstName = useRef(initRefInput());
  const skierLastName = useRef(initRefInput());
  const skierAge = useRef(initRefInputSelect());
  const skierWeight = useRef(initRefInputSelect());
  const skierHeight = useRef(initRefInputSelect());
  const skierShoe = useRef(initRefInputSelect());
  const skierAbility = useRef(initRefInputSelect());
  const skierStance = useRef(initRefInputSelect());
  const lookup = useConfig();
  const [gender, setGender] = useState(skier?.gender || "Male");
  const [error, setError] = useState("");

  useEffect(() => {
    if (skier) {
      setGender(skier.gender);
    }
  }, [skier, isOpen]);

  const getSkierData = () => {
    const parsed = SkierSchema.pick({
      firstName: true,
      lastName: true,
      gender: true,
      ability: true,
      stance: true,
      age: true,
      weight: true,
      height: true,
      shoe: true,
    }).safeParse({
      firstName: skierFirstName.current?.value ?? "",
      lastName: skierLastName.current?.value ?? "",
      gender,
      ability: skierAbility.current?.value ?? "",
      stance: skierStance.current?.value,
      age: Number(skierAge.current?.value),
      weight: Number(skierWeight.current?.value),
      height: Number(skierHeight.current?.value),
      shoe: skierShoe.current?.value?.trim() ?? "",
    });
    if (!parsed.success) {
      console.error("Invalid data", parsed.error.flatten().fieldErrors);
      throw new Error("Invalid data");
    }
    return parsed.data;
  };

  function validateFields() {
    if (!skierFirstName.current?.value || !skierLastName.current?.value) {
      setError("Check skier name!");
      return false;
    }
    setError("");
    return true;
  }

  function changeGender(value) {
    setGender(value);
  }
  if (!isOpen) {
    return (
      <Button onClick={onOpen} size="xs">
        {skier ? "edit" : "add skier"}
      </Button>
    );
  }

  return (
    <>
      <Button onClick={onOpen} size="xs">
        {skier ? "edit" : "add skier"}
      </Button>
      <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {skier ? (
              <>
                Skier Info
                <Button
                  size="xs"
                  ml={6}
                  onClick={async function () {
                    if (!skier || !skier.id) {
                      setError("Skier not found");
                      return;
                    }

                    try {
                      await removeSkier(orderId, skier);
                      onClose();
                    } catch (/** @type {any} */ error) {
                      console.error("Error deleting skier", error);
                      setError(error.message || "Error deleting skier");
                    }
                  }}
                >
                  remove
                </Button>
                {!skier.duplicateOf && (
                  <Button
                    size="xs"
                    ml={6}
                    onClick={async () => {
                      try {
                        if (!skier || !skier.id) {
                          setError("Skier not found");
                          return;
                        }
                        await duplicateSkier(orderId, skier);
                        onClose();
                      } catch (/** @type {any} */ error) {
                        console.error("Error duplicating skier", error);
                        setError(error.message || "Error duplicating skier");
                      }
                    }}
                  >
                    duplicate
                  </Button>
                )}
              </>
            ) : (
              <>New Skier</>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={6} mb={12}>
              <HStack>
                <Input
                  isDisabled={Boolean(skier?.duplicateOf)}
                  ref={skierFirstName}
                  placeholder="First Name"
                  defaultValue={skier?.firstName}
                />
                <Input
                  isDisabled={Boolean(skier?.duplicateOf)}
                  ref={skierLastName}
                  placeholder="Last Name"
                  defaultValue={skier?.lastName}
                />
              </HStack>

              <HStack>
                <RadioGroup value={gender} onChange={changeGender}>
                  <HStack spacing={5}>
                    <Radio value="Male" colorScheme="brand">
                      Male
                    </Radio>
                    <Radio value="Female" colorScheme="brand">
                      Female
                    </Radio>
                  </HStack>
                </RadioGroup>
              </HStack>

              <HStack width="100%">
                <Select ref={skierAbility} defaultValue={skier?.ability}>
                  {[0, 1, 2, 3].map((item, i) => (
                    <option key={i} value={"type " + item}>
                      {"Type " + item}
                    </option>
                  ))}
                </Select>

                <Select ref={skierStance} defaultValue={skier?.stance}>
                  <option value="">Stance Unsure</option>
                  <option value="Regular">Regular</option>
                  <option value="Goofy">Goofy</option>
                </Select>
              </HStack>

              <HStack width="100%">
                <Select ref={skierAge} defaultValue={skier?.age || 6}>
                  {lookup.age.years.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label} years
                    </option>
                  ))}
                </Select>
                <Select ref={skierWeight} defaultValue={skier?.weight || 15}>
                  {lookup.weight.kg.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label} {item.value < 99 && "kg"}
                    </option>
                  ))}
                </Select>
              </HStack>
              <HStack width="100%">
                <Select ref={skierHeight} defaultValue={skier?.height || 15}>
                  {lookup.height.cm.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label} {item.value < 99 && "cm"}
                    </option>
                  ))}
                </Select>
                <Select ref={skierShoe} defaultValue={skier ? skier.shoe : "M27"}>
                  {lookup.shoe[gender.toLowerCase()].usmondo.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onClose}>
              CANCEL
            </Button>
            <Spacer />
            <Text color="red.500">{error}</Text>
            <Spacer />
            {skier ? (
              <Button
                colorScheme="brand"
                size="sm"
                onClick={async () => {
                  if (!validateFields()) {
                    return;
                  }
                  if (!skier || !skier.id) {
                    setError("Skier not found");
                    return;
                  }

                  try {
                    await updateSkier(orderId, skier.id, getSkierData());
                    onClose();
                  } catch (/** @type {any} */ error) {
                    console.error("Error updating skier", error);
                    setError(error.message || "Error updating skier");
                  }
                }}
              >
                UPDATE SKIER INFO
              </Button>
            ) : (
              <Button
                colorScheme="brand"
                size="sm"
                onClick={async () => {
                  try {
                    if (!validateFields()) {
                      return;
                    }
                    const payload = getSkierData();
                    await addSkier(orderId, payload);
                    onClose();
                  } catch (/** @type {any} */ error) {
                    console.error("Error adding skier", error);
                    setError(error.message || "Error adding skier");
                  }
                }}
              >
                ADD SKIER
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
