import { useState, useEffect, useMemo } from "react";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration.js";
import isBetween from "dayjs/plugin/isBetween.js";
import isToday from "dayjs/plugin/isToday.js";
import isTomorrow from "dayjs/plugin/isTomorrow.js";
import relativeTime from "dayjs/plugin/relativeTime.js";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isBetween);
dayjs.tz.setDefault("Pacific/Auckland");
dayjs.extend(duration);

export default dayjs;

const __1Sec = 1000;
const __1Minute = __1Sec * 60;
const __1Hour = __1Minute * 60;
const __1Day = __1Hour * 24;

/**
 * @param {number} diffInMS
 * @returns {number}
 * */
const calcStepInMS = (diffInMS) => {
  const absDiffInMS = Math.abs(diffInMS);
  if (absDiffInMS > __1Day * 30) {
    return __1Day * 30;
  }
  if (absDiffInMS > __1Day * 3) {
    return __1Day * 2;
  }
  if (absDiffInMS > __1Day * 2) {
    return __1Day;
  }
  if (absDiffInMS > __1Hour * 2) {
    return __1Hour;
  }

  if (absDiffInMS > __1Hour * 1.5) {
    return __1Hour / 2;
  }

  if (absDiffInMS > __1Hour * 1.25) {
    return __1Hour / 4;
  }

  if (absDiffInMS > __1Minute * 2) {
    return __1Minute;
  }
  return __1Sec;
};

/**
 * @returns {number|undefined}
 */
const initNumberOrUndefined = () => undefined;
/**
 * @param {number} [dateMS]
 * @returns {{ msLeft?: number; timeLeftStr: string }}
 * */
export const useCountdownToDate = (dateMS) => {
  const [currentTargetDate, setCurrentTargetDate] = useState(dateMS);
  const [countDown, setCountDown] = useState(
    dateMS ? dateMS - +new Date() : initNumberOrUndefined(),
  );
  const [stepInMS, setStepInMS] = useState(0);

  useEffect(() => {
    const now = +new Date();
    if (currentTargetDate === undefined || isNaN(currentTargetDate) === true) {
      return;
    }

    const newInterval = calcStepInMS(currentTargetDate - now);

    if (stepInMS !== newInterval) {
      setStepInMS(newInterval);
      return;
    }

    setCountDown(currentTargetDate - +new Date());
    if (stepInMS >= __1Day) {
      return;
    }

    const intervalId = setInterval(() => {
      const timeLeft = currentTargetDate - +new Date();

      setCountDown(timeLeft);

      const calculatedStep = calcStepInMS(timeLeft);
      if (calculatedStep !== stepInMS) {
        setStepInMS(calculatedStep);
      }
    }, Math.max(stepInMS, __1Sec));

    return () => clearInterval(intervalId);
  }, [currentTargetDate, stepInMS]);

  const timeLeftStr = useMemo(() => {
    if (typeof countDown === "undefined" || isNaN(countDown) === true || !currentTargetDate) {
      return "";
    }

    const timeleft = countDown ?? 0;
    const timeleftAbs = Math.abs(countDown ?? 0);
    const seconds = Math.trunc(timeleftAbs / 1000);
    const minutes = Math.trunc(timeleftAbs / 1000 / 60);

    if (seconds === 0) {
      return "just now";
    }

    const prefix = timeleft > 0 ? "in " : "";
    const sufix = timeleft > 0 ? "" : " ago";

    if (minutes > 60) {
      return dayjs(currentTargetDate).fromNow();
    }

    if (seconds > 60) {
      return `${prefix}${minutes} minute${minutes > 1 ? "s" : ""}${sufix}`;
    }

    return `${prefix}${seconds} second${seconds > 1 ? "s" : ""}${sufix}`;
  }, [countDown, currentTargetDate]);

  if (currentTargetDate !== dateMS) {
    setCurrentTargetDate(dateMS);
  }

  return { msLeft: countDown, timeLeftStr };
};
