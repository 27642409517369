import { useState, useEffect } from "react";

import {
  Button,
  Fade,
  Grid,
  GridItem,
  Heading,
  SlideFade,
  VStack,
  Text,
  Divider,
  Box,
  HStack,
} from "@chakra-ui/react";

import { CiSettings } from "react-icons/ci/index.esm.js";
import { TbReport } from "react-icons/tb/index.esm.js";

import { db } from "@/services/firebase.js";

import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";

import { NavLink } from "react-router-dom";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useSystemAlerts } from "@/hooks/systemAlerts.js";
import { getDefaultOverviewActivityTypes } from "@/services/activityLog.js";

import ActivityFeedFilter from "../common/ActivityFeedFilter.jsx";
import ActivityList from "../common/ActivityList.jsx";
import Alert from "../common/Alert.jsx";
import Stats from "../common/Stats.jsx";
import { SystemAlertCard } from "../common/SystemAlertCard.jsx";
import Availability from "../modals/Availability.jsx";

export default function Overview() {
  const { currentUser, currentUserDetails } = useLoggedInUser();
  const { systemAlerts } = useSystemAlerts();
  const [alerts, setAlerts] = useState([]);
  const [activityTypes, setActivityTypes] = useState(getDefaultOverviewActivityTypes());
  const [activityUserId, setActivityUserId] = useState("");

  useEffect(() => {
    const q = query(
      collection(db, "alerts"),
      where("recipients", "array-contains", currentUser.uid),
      where("completed", "==", null),
      where("messageType", "==", "task"),
      orderBy("dueDateTime", "asc"),
      limit(50),
    );
    const unsub = onSnapshot(
      q,
      (snapshot) => {
        const alerts = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setAlerts(alerts);
      },
      (error) => {
        console.error("Error getting alerts: ", error);
      },
    );
    return unsub;
  }, [currentUser.uid]);

  return (
    <SlideFade in={true} offsetY="30px">
      <Fade in={true}>
        <Grid
          templateColumns="3fr 2fr 3fr"
          templateRows="30px 150px 30px auto"
          width="full"
          height="85vh"
          alignSelf="center"
          gap={6}
          columnGap={12}
        >
          <GridItem>
            <Heading size="md">Today's Stats</Heading>
          </GridItem>

          <GridItem>
            <Heading size="md">{currentUserDetails.isManager ? "Admin" : "Tools"} </Heading>
          </GridItem>

          <GridItem minH="150px">
            <HStack>
              <Heading size="md">Activity</Heading>
              <ActivityFeedFilter
                onChange={(userId, selectdActivityTypes) => {
                  setActivityUserId(userId ?? "");
                  setActivityTypes(selectdActivityTypes);
                }}
                selectdActivityTypes={activityTypes}
                selectedUserId={activityUserId}
              />
            </HStack>
          </GridItem>

          <GridItem rowSpan={3}>
            <Stats />

            <GridItem>
              <Heading size="md" my={6}>
                System alerts
              </Heading>
            </GridItem>
            <GridItem overflow="auto" sx={{ "::-webkit-scrollbar": { display: "none" } }}>
              <VStack align="flex-start">
                {systemAlerts.length === 0 ? (
                  <Text>none to show</Text>
                ) : (
                  <Box>
                    <Divider mb={2} />
                    {systemAlerts.map((alert) => (
                      <SystemAlertCard alert={alert} key={alert.id} />
                    ))}
                  </Box>
                )}
              </VStack>
            </GridItem>
          </GridItem>

          <GridItem>
            <VStack align="start" spacing={2} mb={12}>
              <Availability text="Availability Grid" buttonProps={{ pl: 0 }} />
              <Button as={NavLink} to="/settings" variant="ghost" leftIcon={<CiSettings />} pl={0}>
                Settings
              </Button>
              <Button as={NavLink} to="/reports" variant="ghost" leftIcon={<TbReport />} pl={0}>
                Reports
              </Button>
            </VStack>
          </GridItem>

          <GridItem rowSpan={3} overflow="auto" sx={{ "::-webkit-scrollbar": { display: "none" } }}>
            <ActivityList onlyActivityTypes={activityTypes} userId={activityUserId} />
          </GridItem>

          <GridItem>
            <Heading size="md" mb={6}>
              Tasks
            </Heading>
          </GridItem>

          <GridItem overflow="auto" sx={{ "::-webkit-scrollbar": { display: "none" } }}>
            {alerts.length > 0 ? (
              <>
                <Divider mb={2} />
                <VStack align="flex-start">
                  {alerts.map((alert) => {
                    return <Alert key={alert.id} alert={alert}></Alert>;
                  })}
                </VStack>
              </>
            ) : (
              <Text>none to show</Text>
            )}
          </GridItem>
        </Grid>
      </Fade>
    </SlideFade>
  );
}
