import { useState, useEffect } from "react";

import { Text, SlideFade, Grid, GridItem, HStack, useToast } from "@chakra-ui/react";

import { HiUsers } from "react-icons/hi/index.esm.js";

import { db } from "../../../services/firebase.js";

import { doc, updateDoc } from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";

import { asPrice } from "../../../lib/numbers.js";
import EditableField from "../../common/EditableField.jsx";
import StepHeader from "../common/StepHeader.jsx";

/** @return {TOrder|null} */
const initialOrderAsNull = () => null;

/**
 * @param {object} props
 * @param {TOrder} props.order
 * @returns
 */
export default function StepSizeCheckOrder({ order: orderIn }) {
  const [order, SetOrder] = useState(initialOrderAsNull());

  useEffect(() => {
    SetOrder(orderIn);
  }, [orderIn]);

  const handleInputChange = (name, value) => {
    SetOrder({
      ...order,
      [name]: value,
    });
  };

  const updateField = async (name, value) => {
    if (!order) {
      return;
    }
    const orderRef = doc(db, "orders", order?.id);
    await updateDoc(orderRef, {
      [name]: value,
    });
  };

  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <StepHeader
        customerName={order?.firstName + " " + order?.lastName}
        orderId={order?.id}
        previousOrderId={order?.previousOrder}
        referrer={order?.referrer.name}
      />
      <Grid gridTemplateColumns={"1fr 1fr"} gap={24} width="600px">
        <GridItem>
          <EditableField
            fieldName="firstName"
            value={order?.firstName}
            displayName="Cutomer First Name"
            displayType="text"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />
          <EditableField
            fieldName="lastName"
            value={order?.lastName}
            displayName="Customer Last Name"
            displayType="text"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />

          <Text fontSize={10} fontWeight="bold" mb={-1} mt={4}>
            Group
          </Text>

          <HStack mt={1} mb={4}>
            <HiUsers />
            <Text>{order?.skierCount}</Text>
          </HStack>

          <EditableField
            fieldName="comments"
            value={order?.comments}
            displayName="Customer Comments"
            displayType="textarea"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />
          <EditableField
            fieldName="commentsInternal"
            value={order?.commentsInternal}
            displayName="Internal Comments"
            displayType="textarea"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />

          <Text fontSize={10} fontWeight="bold">
            Booked
          </Text>
          <Text>
            {dayjs.tz(getFieldAsDate(order?.bookingTime)).format("D MMM YYYY")} (
            {dayjs.tz(getFieldAsDate(order?.bookingTime)).fromNow()})
          </Text>
        </GridItem>
        <GridItem>
          <EditableField
            fieldName="addressDelivery"
            value={order?.addressDelivery}
            displayName="Deliver to"
            displayType="textarea"
            handleInput={handleInputChange}
            handleUpdate={updateField}
            location={order?.location}
          />

          <EditableField
            fieldName="addressCollection"
            value={order?.addressCollection}
            displayName="Collect from"
            displayType="textarea"
            handleInput={handleInputChange}
            handleUpdate={updateField}
            location={order?.location}
          />

          <EditableField
            fieldName="referrer"
            value={order?.referrer.name}
            displayName="Referrer"
            displayType="text"
            isReadOnly={true}
            isDisabled={true}
            handleInput={() => {}}
            handleUpdate={() => {}}
          />

          <Text fontSize={10} fontWeight="bold" mb={-1} mt={4}>
            Paid Amount
          </Text>
          <Text mt={1} mb={4}>
            {asPrice(order?.paidAmount ?? 0)} ({order?.paidStatus})
          </Text>
        </GridItem>
      </Grid>
    </SlideFade>
  );
}
