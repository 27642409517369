import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";

import { CheckIcon } from "@chakra-ui/icons";

import { useFormContext } from "react-hook-form";

export default function ValidatedInput({
  name,
  leftElement = "",
  inputMode = "text",
  type = "text",
  ...rest
}) {
  const {
    register,
    formState: { errors },
    getFieldState,
  } = useFormContext();

  const hasError = !!errors[name];
  const isDirty = getFieldState(name).isDirty;
  return (
    <InputGroup size={"lg"}>
      {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
      <Input
        autoComplete="off"
        {...register(name)}
        isInvalid={!!errors[name]}
        required
        type={type}
        inputMode={inputMode}
        focusBorderColor={hasError ? "red.500" : "blue.500"}
        {...rest}
      />
      {isDirty && !hasError && (
        <InputRightElement>
          <CheckIcon color="green.500" />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
