import { auth } from "./firebase.js";

import { signInWithEmailAndPassword } from "firebase/auth";

import { maskEmail } from "@snopro/common/utils.js";

/**
 * @param {string} email
 * @param {string} password
 * @returns {Promise<void>}
 */
export async function logIn(email, password) {
  await signInWithEmailAndPassword(auth, maskEmail(email), password).catch(() => {
    throw new Error("Invalid email or password");
  });
}
