import { createContext, useContext, useEffect, useState } from "react";

import { auth, db } from "../services/firebase.js";

import { signOut } from "firebase/auth";
import { getDoc, doc, updateDoc } from "firebase/firestore";

import { signal } from "@preact/signals-core";
import { UserSchema } from "@snopro/common/models.js";

import { initFirebaseUserOrNull, initUserV2OrNull } from "@/lib/initialize-states.js";

/**
 * @param {string} userId
 * @returns {string}
 */
export function getUserAvatarByUID(userId) {
  return location.hostname == "localhost"
    ? `${location.protocol}//${location.hostname}:9199/v0/b/sas2-44fe0.appspot.com/o/users%2F${userId}?alt=media`
    : `https://firebasestorage.googleapis.com/v0/b/sas2-44fe0.appspot.com/o/users%2F${userId}?alt=media`;
}

/**
 * @typedef {Object} TAuthContextValue
 * @property {import("firebase/auth").User|null} currentUser
 * @property {() => import("firebase/auth").User|null} getUser
 * @property {() => Promise<void>} logOut
 * @property {TUserV2|null} currentUserDetails
 * @property {(location: string) => Promise<void>} switchLocation
 */

/**
 * @typedef {Object} TLoggedInUserAuthContextValue
 * @property {import("firebase/auth").User} currentUser
 * @property {() => import("firebase/auth").User} getUser
 * @property {() => Promise<void>} logOut
 * @property {TUserV2} currentUserDetails
 * @property {(location: string) => Promise<void>} switchLocation
 */

/**
 * @returns {TAuthContextValue}
 */
const InitialAuthContextValue = () =>
  // @ts-ignore
  null;

const AuthContext = createContext(InitialAuthContextValue());

export function useAuth() {
  return useContext(AuthContext);
}
/**
 * @returns {TLoggedInUserAuthContextValue}
 */
export function useLoggedInUser() {
  const context = useContext(AuthContext);
  if (!context.currentUserDetails || !context.currentUser) {
    throw new Error("User is not logged in");
  }

  // @ts-ignore
  return context;
}

function logOut() {
  return signOut(auth);
}

function getUser() {
  return auth.currentUser;
}

export const firebaseUserSignal = signal(initFirebaseUserOrNull());
export const SASUserSignal = signal(initUserV2OrNull());

/** @returns {TUserV2} */
export const getLoggedInUser = () => {
  const user = SASUserSignal.value;
  if (!user) {
    throw new Error("User is not logged in");
  }
  return user;
};
auth.onAuthStateChanged(async (user) => {
  firebaseUserSignal.value = user;
  if (user) {
    const userId = user.uid;
    const userSnap = await getDoc(doc(db, "users", userId));
    if (!userSnap.exists()) {
      console.error("User not found in database", { userId });
      return;
    }
    const userTmp = { ...UserSchema.parse(userSnap.data()), id: userId };
    const isAdmin = userTmp.role === "admin";
    const isManager = isAdmin || userTmp.role === "manager";
    SASUserSignal.value = { ...userTmp, isManager, isAdmin };
  } else {
    SASUserSignal.value = null;
  }
});

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(initFirebaseUserOrNull());
  const [currentUserDetails, setCurrentUserDetails] = useState(initUserV2OrNull());
  const [loading, setLoading] = useState(true);

  async function switchLocation(location) {
    if (currentUserDetails && currentUser && currentUser.uid) {
      await updateDoc(doc(db, "users", currentUser.uid), {
        defaultLocation: location,
      });
      window.location.reload();
    }
  }

  async function getExtraUserData(userId) {
    const userSnap = await getDoc(doc(db, "users", userId));
    if (!userSnap.exists()) {
      console.error("User not found in database", { userId });
      return;
    }
    const userTmp = { ...UserSchema.parse(userSnap.data()), id: userId };
    const isAdmin = userTmp.role === "admin";
    const isManager = isAdmin || userTmp.role === "manager";
    setCurrentUserDetails({ ...userTmp, isManager, isAdmin });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
      if (user) {
        getExtraUserData(user.uid);
      } else {
        setCurrentUserDetails(null);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    getUser,
    logOut,
    currentUserDetails,
    switchLocation,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
