import { createContext, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export const ModalContext = createContext();

export function ItemModalContextProvider({ children }) {
  const [modal, setModal] = useState();

  const openModal = (props) => setModal(props);
  const closeModal = () => setModal(undefined);

  return (
    <ModalContext.Provider value={[openModal, closeModal]}>
      {children}
      <Modal isOpen={modal} onClose={closeModal} key="modal" {...modal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{modal?.title}</ModalHeader>
          <ModalBody sx={{ "::-webkit-scrollbar": { display: "none" } }}>
            {modal?.content}
          </ModalBody>
        </ModalContent>
      </Modal>
    </ModalContext.Provider>
  );
}
