import { Icon } from "@chakra-ui/react";

import { FaTruck, FaCalendar } from "react-icons/fa/index.esm.js";
import { HiBuildingOffice } from "react-icons/hi2/index.esm.js";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import { twoDecimals } from "@snopro/common/numbers.js";
import dayjs from "dayjs";

/**
 * @type {Record<TOrderPaymentTransactionSource, { label:string, icon: JSX.Element|null}>} transaction
 */
const paymentSourceDisplayNames = {
  "on-delivery": { label: "On delivery", icon: <Icon as={FaTruck} /> },
  "on-booking": { label: "On booking", icon: <Icon as={FaCalendar} /> },
  manager: { label: "By office", icon: <Icon as={HiBuildingOffice} /> },
  sas1: { label: "SAS1", icon: null },
};

const paymentMethodDisplayNames = {
  "credit-card": "Credit Card",
  "on-account": "On Account",
  cash: "Cash",
  pos: "EFTPOS",
};

/**
 * @param {TOrderPaymentTransaction|null} transaction
 */
export const useTransaction = (transaction) => {
  if (!transaction) return {};
  const {
    refundedAmount = 0,
    createdOn,
    amount,
    method,
    comments,
    source,
    id,
    cardName,
    maskedCardNumber,
  } = transaction;
  const date = dayjs.tz(getFieldAsDate(createdOn)).format("DD/MMM h:mma");

  const { icon, label } = paymentSourceDisplayNames[source] || { icon: null, label: source };
  const displayName = paymentMethodDisplayNames[method] ?? method;

  return {
    date,
    amountToShow: twoDecimals(amount),
    sourceIcon: icon,
    sourceLabel: label,
    fullyRefunded: refundedAmount >= amount,
    id,
    method,
    comments,
    displayName,
    cardName,
    maskedCardNumber,
    showRefundButton:
      (transaction.method == "credit-card" ||
        transaction.method === "pos" ||
        transaction.method === "on-account" ||
        transaction.method === "cash") &&
      transaction.type != "refund" &&
      refundedAmount < amount,
    type: transaction.type,
    isRefund: transaction.type === "refund",
    refundedAmount,
  };
};

export default useTransaction;
