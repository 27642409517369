import {
  Box,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  IconButton,
  Icon,
  MenuOptionGroup,
  MenuItemOption,
} from "@chakra-ui/react";

import { MdOutlineHistory } from "react-icons/md/index.esm.js";

import { useLatestOpenOrders, useOrderModalContext } from "@/contexts/OrderModalContext.jsx";

/**
 * @returns {JSX.Element}
 */
export function OrderNavigationHistory() {
  const { setModalId } = useOrderModalContext();
  const { latestOrders } = useLatestOpenOrders();

  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        size="sm"
        variant={"outline"}
        icon={<Icon as={MdOutlineHistory} boxSize={6} />}
      />
      <MenuList zIndex={200}>
        <MenuOptionGroup title="Orders history" type="radio">
          <MenuList
            width={"full"}
            maxHeight="500px"
            overflowY="auto"
            borderBottom={0}
            borderRight={0}
            borderLeft={0}
            sx={{ "::-webkit-scrollbar": { display: "none" } }}
          >
            {latestOrders.length === 0 && (
              <MenuItemOption isDisabled>No orders found</MenuItemOption>
            )}
            {latestOrders.map((orderInfo) => (
              <MenuItem key={orderInfo.id} onClick={() => setModalId(orderInfo.id)}>
                <Box display="flex" alignItems="center">
                  {orderInfo.location} - #{orderInfo.lookupId} - {orderInfo.firstName}{" "}
                  {orderInfo.lastName}
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
