import { useEffect, useRef, useState } from "react";

import {
  Button,
  Text,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Textarea,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import { useShowError } from "@/lib/error.js";
import { disposeHardware } from "@/lib/hardwareLib.jsx";
import { initRefTextArea } from "@/lib/initialize-states.js";

import BasicHardwareInfo from "../common/BasicHardwareInfo.jsx";

/**
 * @param {object} props
 * @param {THardware} props.hardware
 * @param {TChakraDisclosure} props.selfDisclosure
 * @param {() => void} props.onSaved
 * @returns {JSX.Element}
 */
export default function DisposeHardwareModal({ hardware, selfDisclosure, onSaved }) {
  const { showError } = useShowError();
  const [isSaving, setIsSaving] = useState(false);

  const notesRef = useRef(initRefTextArea());
  useEffect(() => {
    if (notesRef.current) {
      notesRef.current.value = hardware.notes ?? "";
    }
  }, [hardware.notes]);

  return (
    <>
      <Modal isOpen={selfDisclosure.isOpen} onClose={selfDisclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dipose hardware</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BasicHardwareInfo hardware={hardware} />
            <Alert my={4} status="warning">
              <AlertIcon />
              All future allocations of this hardware will be removed and the orders moved back to
              Allocate. This action cannot be undone.
            </Alert>
            <Divider />
            <Text pt={4} fontSize={"large"}>
              Reason
            </Text>
            <Textarea defaultValue={hardware.notes ?? ""} rows={3} ref={notesRef} />
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button isDisabled={isSaving} size="sm" onClick={selfDisclosure.onClose}>
              CLOSE
            </Button>
            <Spacer />
            <Button
              isLoading={isSaving}
              size="sm"
              colorScheme="brand"
              onClick={async () => {
                try {
                  setIsSaving(true);
                  await disposeHardware(hardware.id, notesRef.current?.value);
                  onSaved();
                } catch (error) {
                  showError(error);
                } finally {
                  setIsSaving(false);
                }
              }}
            >
              CONFIRM
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
