import { functions } from "@/services/firebase.js";

import { httpsCallable } from "firebase/functions";

const LightspeedAPICallable = httpsCallable(functions, "LightspeedAPI");
/**
 * @param {SAS2.API.TLightSpeedAPI} data
 * @returns {Promise<any>}
 */
const lightspeed = async (data) => {
  const response = await LightspeedAPICallable(data);
  return response.data;
};

/**
 * @param {object} param
 * @param {string} param.orderId
 * @returns {Promise<void>}
 */
export const lightspeedCreateSales = async ({ orderId }) => {
  await lightspeed({ operation: "create-sale", payload: { orderId } });
};
