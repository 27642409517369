import { useEffect, useState } from "react";

import { db } from "@/services/firebase.js";

import { doc, onSnapshot } from "firebase/firestore";

import { WebSiteVersionSchema } from "@snopro/common/version-models.js";

import { useAuth } from "@/contexts/AuthContext.jsx";

/**
 * @param {object} props
 * @param { (opts:{newVersion:string; currentVersion:string}) => void} props.onNewVersion
 */
export const useWatchLatestWebSiteVersion = ({ onNewVersion }) => {
  const [currentVersion, setCurrentVersion] = useState(-1);
  const { currentUserDetails } = useAuth();

  useEffect(() => {
    if (!currentUserDetails) {
      setCurrentVersion(0);
      return;
    }

    const unsub = onSnapshot(doc(db, "versions", "latest"), {
      next: (doc) => {
        const data = doc.data();
        const websiteVersionData = WebSiteVersionSchema.parse(data);
        const newVersionStr = websiteVersionData.version.toString();
        if (newVersionStr != import.meta.env.VITE_APP_VERSION) {
          try {
            onNewVersion({
              newVersion: newVersionStr,
              currentVersion: import.meta.env.VITE_APP_VERSION,
            });
          } catch (error) {
            console.error("Error in onNewVersion", error);
          }
        }
        setCurrentVersion(websiteVersionData.version);
      },
      error: (error) => {
        console.error("Error getting latest website version", error);
      },
    });
    return unsub;
  }, [currentUserDetails, onNewVersion]);

  return { currentVersion };
};
